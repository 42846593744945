import React, {useState} from 'react';
import styles from './styles.scss';

const FaqDetail = (props) => {

    const [isOpen, setSummary] = useState(false);

    const handleOnClick = () => {
        setSummary(isOpen => !isOpen);
    }

    return (
        <div className={styles.faqDetailWrap}>
            <div className={isOpen ? [styles.details, styles.active].join(' ') : styles.details } >
                <div className={styles.summary} id="summary" onClick={handleOnClick}>
                    {props.faq}<img src="/static/media/ic-arrow-down.svg" alt="arrow-down"/>
                </div>
                <p className={styles.contents} style={props.styles}>{props.faqDesc}</p>
            </div>
        </div>
    )
};

export default FaqDetail;