import React, { useCallback, useEffect, useState } from "react";
import { envProps } from "module/configureStore";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import * as SVG from "components/SVG";

const CHECK = "check";
const CheckBox = (props, context) => {
    const [isBottom, setBottom] = useState(false);
    // const [isGroup,setIsGroup] = useState(false);

    const handleMouseDown = type => e => {
        !props.isMobile && !props.isTablet && props.onMouseDown && props.onMouseDown(e, props.inx, type);
    };

    const handleMouseUp = e => {
        props.onMouseUp && props.onMouseUp(e, props.inx);
    };

    const handleTouchStart = type => e => {
        props.onTouchStart && props.onTouchStart(e, props.inx, type);
    };

    const handleMobileClick = () => {
        props.onMobileTouchCondition && props.onMobileTouchCondition(props.inx);
    };

    const handleOptionChange = (name, value) => e => {
        let data = { name, value };

        props.onOptionChange && props.onOptionChange(props.inx, CHECK, data);
    };
    const handleDeleteField = () => {
        props.onDeleteField && props.onDeleteField();
    };

    const handleCloneField = () => {
        props.onCloneField && props.onCloneField([props.inx]);
    };

    const handleMouseEnter = e => {
        props.onCheckEnter && props.onCheckEnter(props.inx);
    };
    const handleMouseLeave = e => {
        props.onCheckLeave && props.onCheckLeave();
    };

    const handleKeyDown = e => {
        e.stopPropagation();
        props.onKeyDown && props.onKeyDown(e, "condition", props.inx);
    };

    const handleKeyUp = e => {
        e.stopPropagation();
        props.onKeyUp && props.onKeyUp(e, "condition", props.inx);
    };

    useEffect(() => {
        if (!props.isSigning && !props.isDragging && props.isSelected) {
            let id = props.isSigning ? props.fieldData.coordId : props.fieldData.id;
            let top = props.isSigning ? props.fieldData.top : props.fieldData.style.top;
            let contain = document.getElementById(id);
            let option = contain.querySelector("#option");

            if (option) {
                if (contain.parentNode.offsetHeight * (top / 100) < option.offsetHeight) {
                    setBottom(true);
                } else {
                    setBottom(false);
                }
            }
        }
    }, [props.isDragging]);

    return props.isSigning ? (
        <div
            className={styles.setItemsBox}
            id={props.fieldData.coordId}
            data-name={"check"}
            data-user={props.isSelected ? ((props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10) : -1}
            style={{
                top: props.fieldData.top + "%",
                left: props.fieldData.left + "%",
                width: props.fieldData.width + "%",
                height: props.fieldData.height + "%",
                pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                zIndex: props.isSelected ? 2 : 1,
                touchAction: "none",
                userSelect: "none",
                msUserSelect: "none"
            }}
        >
            {props.children}
            {props.is_othercond ? (
                props.fieldData?.isChecked ? (
                    <div
                        className={[styles.setCheck, props.isSelected ? styles.select : null].join(" ")}
                        name={"isChecked"}
                        value={props.fieldData.isChecked}
                        onClick={undefined}
                        onMouseEnter={undefined}
                        onMouseLeave={undefined}
                        style={props.hoverCheck === props.inx ? { zIndex: 1000 } : undefined}
                    >
                        <div className={[styles.checkBtn, props.fieldData.isChecked ? styles.on : null].join(" ")} style={{ cursor: "default" }}>
                            {props.fieldData.isChecked ? <SVG.Mark type="checked" color={"#333333"} /> : undefined}
                        </div>
                    </div>
                ) : (
                    undefined
                )
            ) : (
                <div
                    className={[styles.setCheck, props.isSelected ? styles.select : null].join(" ")}
                    name={"isChecked"}
                    value={props.fieldData.isChecked}
                    onClick={
                        props.referer === "previewtemplate"
                            ? // 미리보기 팝업 서명조건 분기 (수정자:JADE)
                              props.preview_cond
                                ? props.onCondValue
                                : undefined
                            : props.isMobile || props.isTablet
                            ? props.signedField
                                ? undefined
                                : handleMobileClick
                            : handleOptionChange("isChecked", !props.fieldData.isChecked)
                    }
                    onMouseEnter={props.isMobile || props.isTablet ? undefined : props.signedField ? undefined : handleMouseEnter}
                    onMouseLeave={props.isMobile || props.isTablet ? undefined : props.signedField ? undefined : handleMouseLeave}
                    style={props.hoverCheck === props.inx ? { zIndex: 1000 } : undefined}
                >
                    {props.referer === "previewtemplate" && props.preview_complete
                        ? undefined
                        : !props.isMobile &&
                          !props.isTablet &&
                          props.hoverCheck === props.inx && (
                              <div
                                  className={styles.checkContent}
                                  style={{ opacity: 1, width: props.fieldData.content ? props.fieldData.content?.length * 12 : 25 }}
                              >
                                  {props.fieldData.content ? props.fieldData.content : ("선택")}
                              </div>
                          )}
                    <div
                        className={[
                            styles.checkBtn,
                            props.fieldData.isChecked ? styles.on : null,
                            props.referer === "previewtemplate" && props.preview_complete ? undefined : props.isGroup ? styles.isGroup : undefined,
                            props.referer === "previewtemplate" && props.preview_complete
                                ? undefined
                                : props.hoverCheck === props.inx
                                ? styles.isHover
                                : undefined
                        ].join(" ")}
                        style={props.referer === "previewtemplate" && props.preview_complete ? { cursor: "default" } : undefined}
                    >
                        {props.preview_cond ? (
                            props.preCondValue ? (
                                <SVG.Mark
                                    type="checked"
                                    color={"#333333"}
                                />
                            ) : (
                                undefined
                            )
                        ) : props.fieldData.isChecked ? (
                            <SVG.Mark
                                type="checked"
                                color={"#333333"}
                            />
                        ) : (
                            undefined
                        )}
                    </div>
                </div>
            )}
        </div>
    ) : (
        <div
            id={props.fieldData.id}
            className={[styles.fieldBox, props.single ? styles.single : null].join(" ")}
            data-name={"check"}
            data-user={(props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10}
            style={{
                top: props.fieldData.style.top + "%",
                left: props.fieldData.style.left + "%",
                width: props.fieldData.style.width + "%",
                height: props.fieldData.style.height + "%",
                pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                zIndex: props.isSelected ? 2 : 1,
                touchAction: "none",
                userSelect: "none",
                msUserSelect: "none"
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            tabIndex={0}
        >
            {props.children}
            {!props.isMulti && (
                <div
                    id="option"
                    className={[
                        styles.fieldOption,
                        !props.isDragging && props.isSelected ? styles.on : null,
                        props.single ? styles.single : null,
                        isBottom ? styles.bottom : null
                    ].join(" ")}
                    data-noselect={true}
                >
                    <div className={styles.optionBtn} data-noselect={true}>
                        <button className={styles.on} onClick={handleDeleteField} data-noselect={true}>
                            {("삭제")}
                        </button>
                        <button onClick={handleCloneField} data-noselect={true}>
                            {("복사")}
                        </button>
                    </div>
                </div>
            )}
            <div
                className={styles.fieldLine}
                style={{
                    pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                    opacity: (props.isResizing || props.isDragging) && props.isSelected ? 0.5 : 0.9
                }}
                onMouseDown={handleMouseDown("drag")}
                onMouseUp={handleMouseUp}
                onTouchStart={handleTouchStart("drag")}
            >
                <div
                    className={[
                        styles.fieldCheck,
                        props.isSelected ? styles.fieldOn : null,
                        props.isGroup ? styles.fieldGroup : undefined,
                        props.fieldData.signed ? styles.signedField : null
                    ].join(" ")}
                >
                    {props.fieldData.check && props.fieldData.check.checked ? (
                        <SVG.Mark
                            type="checked"
                            color={props.single || props.fieldData.signed ? "#bababa" : envProps.colorList[props.fieldData?.user]}
                        />
                    ) : (
                        undefined
                    )}
                </div>
            </div>
            {!props.isMulti && (
                <button
                    className={[
                        styles.fieldBtnSize,
                        props.isResizing ? styles.resizeOn : null,
                        !props.isDragging && props.isSelected ? styles.on : null
                    ].join(" ")}
                    onMouseDown={handleMouseDown("resize")}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart("resize")}
                >
                    <img
                        src="/static/media/contractpage/resize.png"
                        srcSet="/static/media/contractpage/resize@2x.png, /static/media/contractpage/resize@3x.png"
                        alt="resize"
                    />
                </button>
            )}
        </div>
    );
};

CheckBox.propTypes = {
    pdf: PropTypes.object,
    fieldData: PropTypes.object
};

// Render Dom
export default CheckBox;
