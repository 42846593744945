import React from 'react';
import styles from './styles.scss';

const Loading = props => {
	return (
		<div className={styles.wrap}>
			<div className={styles.spinner}>
				<img src="/static/media/loading.png" alt="loading" />
			</div>
		</div>
	)
}

export default Loading;
