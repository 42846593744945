import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { successorInfo, docsAction } from "module/contractModule";
import styles from "./styles.scss";
import mainTitleStyles from "components/Title/MainTitle/styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";
import Button from "components/Button";
import SubTitle from "components/Title/SubTitle";
import Table from "components/Table";

const SuccessorAfterContractInfo2 = (props) => {
    const dispatch = useDispatch();
    const EGUBUN = useSelector((state) => state.contract?.EGUBUN);
    const Estimate = useSelector((state) => state.contract?.estimate);
    const contractCond = useSelector((state) => state.contract?.contractInfo);
    const etcCond = useSelector((state) => state.contract?.contractEtc);
    const carInfo = useSelector((state) => state.contract?.contractDefault);
    const { data } = useSelector((state) => state.contract);
    const username = useSelector((state) => state.pass.name);
    const personal = useSelector((state) => state.contract.isPersonal);

    const ES_ETCINFO = useSelector((state) => state.contract?.ES_ETCINFO);
    const ES_MAINT = useSelector((state) => state.contract?.ES_MAINT);
    const ES_INSU = useSelector((state) => state.contract?.ES_INSU);
    const ES_KUNNR_INFO = useSelector((state) => state.contract?.ES_KUNNR_INFO);
    const ES_CTR = useSelector((state) => state.contract?.ES_CTR);
    const ES_CAR = useSelector((state) => state.contract?.ES_CAR);
    const ET_LIST = useSelector((state) => state.contract?.ET_LIST);

    const [btndisabled, setBtndisabled] = useState(true);
    const [contractType, setContractType] = useState("A");

    useEffect(() => {
        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        } else {
            if (!data) {
                props.history.push("/successor/entry/b");
            }
        }
    }, []);

    const handleOnClickBtn = () => {
        props.history.push("/successor/confirm");
    };

    const handleCheckBox = () => {
        setBtndisabled(!btndisabled);
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")} />
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <div className={styles.stepOut}>
                            <div className={styles.stepString}>Step  </div>
                            <div className={styles.stepNumber}> 4/10</div>
                        </div>
                        <div className={mainTitleStyles.topInfo}>
                            <h1 style={{ marginBottom: "16px" }}>
                                {personal && (
                                    <>
                                        <span className={mainTitleStyles.spanNmBasic}>{username ? username : "-"}</span>
                                        {"님의"}
                                        <br />
                                    </>
                                )}
                                계약정보를 확인해주세요
                            </h1>
                            <div className={mainTitleStyles.desc}>
                                <p>
                                    <span className={mainTitleStyles.spanBasic}>계약번호 :</span>
                                    <span className={mainTitleStyles.spanNum}>{Estimate}</span>
                                </p>
                            </div>
                        </div>
                        <div className={styles.contBox}>
                            {/* {(EGUBUN === "LM" || EGUBUN === "LF" || EGUBUN === "HH" || EGUBUN === "HA") && (
                                <div className={tableCommonStyles.tblWrap}>
                                    {EGUBUN === "LM" || EGUBUN === "LF" ? (
                                        <SubTitle title="차량 기본 정보" isSpecial descYn="N" />
                                    ) : EGUBUN === "HH" || EGUBUN === "HA" ? (
                                        <SubTitle title="차량/건설기계 기본 정보" isSpecial descYn="N" />
                                    ) : undefined}
                                    <table className={tableCommonStyles.tbl01}>
                                        <tbody>
                                            {carInfo?.map((contact, i) => {
                                                return (
                                                    <Table
                                                        name={contact?.title ? contact?.title : "-"}
                                                        value={contact?.value ? contact?.value : "-"}
                                                        key={i}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )} */}

                            <div className={tableCommonStyles.tblWrap}>
                                {/* <SubTitle title="계약 조건" isSpecial descYn="N" /> */}
                                <SubTitle title="승계 조건" isSpecial descYn="N" />
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                        {/* {contractCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            );
                                        })} */}
                                        {
                                            <>
                                                <Table name={"제조사"} value={ET_LIST?.A002 ? ET_LIST?.A002 : "-"} />
                                                <Table name={"차종"} value={ET_LIST?.A003 ? ET_LIST?.A003 : "-"} />
                                                <Table name={"옵션/용품"} value={ET_LIST?.A004 ? ET_LIST?.A004 : "-"} />
                                                <Table name={"자동차금액"} value={ET_LIST?.A031 ? ET_LIST?.A031 : "-"} />
                                                <Table name={"대수"} value={ET_LIST?.A005 ? ET_LIST?.A005 : "-"} />
                                                <Table name={"외장색상"} value={ET_LIST?.A006 ? ET_LIST?.A006 : "-"} />
                                                <Table name={"내장색상"} value={ET_LIST?.A007 ? ET_LIST?.A007 : "-"} />
                                                <Table name={"등록명의구분"} value={ET_LIST?.A008 ? ET_LIST?.A008 : "-"} />
                                                <Table name={"리스상품"} value={ET_LIST?.A009 ? ET_LIST?.A009 : "-"} />
                                                <Table name={"종료시처리"} value={ET_LIST?.A010 ? ET_LIST?.A010 : "-"} />
                                                <Table name={"월리스료"} value={ET_LIST?.A012 ? ET_LIST?.A012 : "-"} />
                                                <Table name={"부가세"} value={ET_LIST?.A013 ? ET_LIST?.A013 : "-"} />
                                                <Table name={"월보험료"} value={ET_LIST?.A014 ? ET_LIST?.A014 : "-"} />
                                                <Table name={"월납입액"} value={ET_LIST?.A015 ? ET_LIST?.A015 : "-"} />
                                                <Table name={"리스기간"} value={ET_LIST?.A016 ? ET_LIST?.A016 : "-"} />
                                                <Table name={"규정손해배상금"} value={ET_LIST?.A017 ? ET_LIST?.A017 : "-"} />
                                                <Table name={"중도해지손해배상금"} value={ET_LIST?.A018 ? ET_LIST?.A018 : "-"} />
                                                <Table name={"KM당 초과운행료"} value={ET_LIST?.A021 ? ET_LIST?.A021 : "-"} />
                                                <Table name={"반환가치감가비용"} value={ET_LIST?.A022 ? ET_LIST?.A022 : "-"} />
                                                <Table name={"승계수수료"} value={ET_LIST?.A023 ? ET_LIST?.A023 : "-"} />
                                                <Table name={"보증금"} value={ET_LIST?.A024 ? ET_LIST?.A024 : "-"} />
                                                <Table name={"선납금"} value={ET_LIST?.A025 ? ET_LIST?.A025 : "-"} />
                                                <Table name={"잔존가치"} value={ET_LIST?.A026 ? ET_LIST?.A026 : "-"} />
                                                <Table name={"계약주행거리"} value={ET_LIST?.A027 ? ET_LIST?.A027 : "-"} />
                                                <Table name={"정비상품종류"} value={ET_LIST?.A028 ? ET_LIST?.A028 : "-"} />
                                                <Table name={"납입방법"} value={ET_LIST?.A029 ? ET_LIST?.A029 : "-"} />
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="기타 조건" isSpecial descYn="N" />
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                        {etcCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            );
                                        })}
                                        <Table name={"-"} value={"-"} key={1} />
                                    </tbody>
                                </table>
                            </div> */}

                            {/* <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> */}
                            {/* <div className={styles.confirmCheck}>
                                <input type="checkbox" onChange={handleCheckBox} checked={!btndisabled} />
                                <label onClick={handleCheckBox}>
                                    {
                                        "승계 계약을 진행하기 전 미납된 금액과 승계일 이전에 발생한 기타채무(과태료, 범칙금) 등이 확인될 경우 납부에 대한 책임이 있음을 확인합니다."
                                    }
                                </label>
                            </div> */}

                            <Button style={{ width: "100%" }} text={"계약 작성 진행"} onClick={handleOnClickBtn} disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuccessorAfterContractInfo2;
