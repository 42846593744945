import { makeAsyncMethodsActions, makeAsyncMethodsCreator, customActions, makeAsyncActions } from "./common";

export const CONTRACT = makeAsyncMethodsActions("contract"); // GET, POST, DELETE, PUT
export const getContract = makeAsyncMethodsCreator(CONTRACT); // INDEX, REQUEST, SUCCESS, FAIL

export const TERMS = makeAsyncMethodsActions("TERMS");
export const getTerms = makeAsyncMethodsCreator(TERMS);

export const COMPLETE = makeAsyncMethodsActions("COMPLETE");
export const contractComplete = makeAsyncMethodsCreator(COMPLETE);

export const SUCCESSORCOMPLETE = makeAsyncMethodsActions("SUCCESSORCOMPLETE");
export const sussessorComplete = makeAsyncMethodsCreator(SUCCESSORCOMPLETE);

export const USER = makeAsyncMethodsActions("USER");
export const userInfo = makeAsyncMethodsCreator(USER);

export const SUCCESSOR = makeAsyncMethodsActions("SUCCESSOR");
export const successorInfo = makeAsyncMethodsCreator(SUCCESSOR);

export const DOCS = makeAsyncMethodsActions("DOCS");
export const docsAction = makeAsyncMethodsCreator(DOCS);

export const MANUAL = makeAsyncMethodsActions("MANUAL");
export const manualAction = makeAsyncMethodsCreator(MANUAL);

export const MAPPING = makeAsyncMethodsActions("MAPPING");
export const mappingAction = makeAsyncMethodsCreator(MAPPING);

export const initialState = {
    isLoading: false,
    message: "",
    estimate: "", // 견적 번호
    custId: "",
    isPersonal: false,
    token: "",

    E_KATR7: "",
    EGUBUN: "", // [LM: 리스 운용], [LF: 리스 금융], [HA: 오토론], [HC: 신용대출], [HH: 할부]
    isSeames: "",
    E_SUBMICD: "", // A: 가상 계좌, B: CMS, C: 지로
    templateId: -1,
    data: undefined,
    mapping: undefined, // 최종 문서에 찍히는 값들
    manualSigncondition: undefined, // 상품 설명서 조건
    coreSigncondition: undefined, // 부속 서류 조건
    contractInfo: undefined, // 계약 정보
    contractDefault: undefined, // 기본 정보
    contractEtc: undefined, // 기타 정보
    contractConfirm: undefined,

    /* 문서 */
    //successortest
    docsList: undefined, // 최종 문서
    manualDocsList: undefined, // 상품 설명서
    coreManualDocsList: undefined, // 부속 서류
    manualList: undefined, // 상품 설명서

    terms: {}, // 이용 약관
    complete: "",
    isComplete: false,

    /* 결제 방식 가상계좌/지로일 때 저장 */
    RPMDD: "", // 결제일
    BANKNM: "", // 결제은행
    VTACNO: "", // 계좌번호,
    ES_ETCINFO: undefined,
    ES_MAINT: undefined,
    ES_INSU: undefined,
    ES_KUNNR_INFO: undefined,
    ES_CTR: undefined,
    ES_CAR: undefined,
    ET_LIST: undefined,
};

const Reducer = customActions(
    {
        [CONTRACT.GET.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                message: "",
                isLoading: true,
            });
        },
        [CONTRACT.GET.SUCCESS]: (state, action) => {
            const { payload } = action;
            return Object.assign({}, state, {
                message: "",
                isLoading: false,
                E_KATR7: payload.E_KATR7,
                EGUBUN: payload.EGUBUN,
                isSeames: payload.isSeames,
                E_SUBMICD: payload.E_SUBMICD,
                templateId: payload.templateId,
                data: [...payload[payload.EGUBUN]],
                manualSigncondition: payload.manualSigncondition,
                coreSigncondition: payload.coreSigncondition,
                contractInfo: payload.contractInfo,
                contractDefault: payload.contractDefault,
                contractEtc: payload.contractEtc,
                contractConfirm: payload.contractConfirm,
                manualDocsList: payload.manualDocsList,
                coreManualDocsList: payload.coreManualDocsList,
                manualList: payload.manualList,
                KUNNR: payload.KUNNR,
                RPMDD: payload?.RPMDD,
                BANKNM: payload?.BANKNM,
                VTACNO: payload?.VTACNO,
            });
        },
        [CONTRACT.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                message: "error",
                isLoading: false,
            });
        },
        [CONTRACT.POST.REQUEST]: (state, action) => {
            // reset data
            return Object.assign({}, state, {
                EGUBUN: "",
                isSeames: "",
                E_SUBMICD: "",
                templateId: -1,
                data: undefined,
                manualSigncondition: undefined,
                coreSigncondition: undefined,
                contractInfo: undefined,
                contractDefault: undefined,
                contractEtc: undefined,
                contractConfirm: undefined,
                docsList: undefined,
                manualDocsList: undefined,
                coreManualDocsList: undefined,
                manualList: undefined,
            });
        },
        [TERMS.GET.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                terms: { ...state.terms, [action.payload.type]: action.payload.response.terms },
            });
        },
        [COMPLETE.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                complete: "",
            });
        },
        [COMPLETE.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                complete: "success",
                docsList: action.payload.docsList,
                mapping: action.payload.mapping,
                isComplete: action.payload.isComplete,
            });
        },
        [COMPLETE.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                complete: "fail",
                isComplete: false,
            });
        },
        [COMPLETE.GET.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                complete: "",
            });
        },
        [COMPLETE.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                isLoading: false,
                message: "",
                estimate: "",
                custId: "",
                isPersonal: false,
                E_KATR7: "",
                EGUBUN: "",
                isSeames: "",
                E_SUBMICD: "",
                templateId: -1,
                data: undefined,
                manualSigncondition: undefined,
                coreSigncondition: undefined,
                contractInfo: undefined,
                contractDefault: undefined,
                contractEtc: undefined,
                contractConfirm: undefined,
                docsList: undefined,
                manualDocsList: undefined,
                coreManualDocsList: undefined,
                manualList: undefined,
                terms: {},
                complete: "",
            });
        },
        [USER.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                isPersonal: false,
                estimate: "",
                custId: "",
            });
        },
        [USER.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                isPersonal: action.payload.E_KATR7 === "2",
                estimate: action.payload.estimate,
                custId: action.payload.custId,
            });
        },
        [USER.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                isPersonal: false,
                estimate: "",
                custId: "",
            });
        },
        [SUCCESSOR.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                message: "",
                isLoading: true,
            });
        },

        [SUCCESSOR.POST.SUCCESS]: (state, action) => {
            const { payload } = action;
            return Object.assign({}, state, {
                isLoading: false,
                ES_ETCINFO: payload.ES_ETCINFO,
                ES_MAINT: payload.ES_MAINT,
                ES_INSU: payload.ES_INSU,
                ES_KUNNR_INFO: payload.ES_KUNNR_INFO,
                ES_CTR: payload.ES_CTR,
                ES_CAR: payload.ES_CAR,
                ET_LIST: payload.ET_LIST,
                data: payload.data ? payload.data : "successor",
                mapping: payload.mapping,
                docsList: payload.docsList,
                manualList: payload.manualList,
                manualDocsList: payload.manualDocsList,
                isPersonal: payload.isPersonal,
                coreManualDocsList: payload.coreManualDocsList,
                manualSigncondition: payload.manualSigncondition,
                coreSigncondition: payload.coreSigncondition,
            });
        },

        [SUCCESSOR.POST.FAIL]: (state, action) => {
            const { payload } = action;
            return Object.assign({}, state, {
                isLoading: false,
            });
        },
        [DOCS.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                docsList: action.payload,
            });
        },
        [MAPPING.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                mapping: action.payload,
            });
        },
        [MANUAL.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                manualList: action.payload,
            });
        },
        [MANUAL.PUT.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                manualDocsList: action.payload,
            });
        },

        [SUCCESSORCOMPLETE.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                complete: "",
            });
        },
        [SUCCESSORCOMPLETE.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                complete: "success",
                docsList: action.payload.docsList,
                mapping: action.payload.mapping,
                isComplete: action.payload.isComplete,
            });
        },
        [SUCCESSORCOMPLETE.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                complete: "fail",
                isComplete: false,
            });
        },
    },
    initialState,
    [
        "contract/CONTRACT",
        "contract/TERMS",
        "contract/COMPLETE",
        "contract/USER",
        "contract/SUCCESSOR",
        "DOCS",
        "MANUAL",
        "contract/SUCCESSORCOMPLETE",
        "MAPPING",
    ]
);

export const updateDocsList = (docsList) => ({
    type: DOCS.POST.REQUEST,
    payload: { docsList },
});

export const updateManualList = (manualList) => ({
    type: MANUAL.POST.REQUEST,
    payload: { manualList },
});
export const updateManualDocsList = (manualDocsList) => ({
    type: MANUAL.PUT.REQUEST,
    payload: { manualDocsList },
});
export const updateMapping = (mapping) => ({
    type: MAPPING.POST.REQUEST,
    payload: { mapping },
});

export default Reducer;
