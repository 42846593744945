import { call, put, takeEvery } from "redux-saga/effects";
import { SIGN, FITNESS, ISALL, LOGOUT, GQ, newCdd } from "module/userModule";

function* UserSaga() {
    yield takeEvery(SIGN.POST.REQUEST, setSign);
    yield takeEvery(FITNESS.POST.REQUEST, setFitnessInfo);
    yield takeEvery(ISALL.POST.REQUEST, setIsAll);
    yield takeEvery(LOGOUT.POST.REQUEST, userLogout);
    yield takeEvery(GQ.POST.REQUEST, setGQList);
    yield takeEvery(newCdd.POST.REQUEST, newCddInfo);
}

// 서명 이미지 base64 저장
function* setSign(action) {
    try {
        yield put({ type: SIGN.POST.SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: SIGN.POST.FAIL });
    }
}

function* setFitnessInfo(payload) {
    try {
        yield put({ type: FITNESS.POST.SUCCESS, payload });
    } catch (e) {
        yield put({ type: FITNESS.POST.FAIL });
    }
}

function* setIsAll(action) {
    try {
        yield put({ type: ISALL.POST.SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: ISALL.POST.FAIL });
    }
}

function* userLogout(payload) {
    try {
        yield put({ type: LOGOUT.POST.SUCCESS });
    } catch (e) {
        yield put({ type: LOGOUT.POST.FAIL });
    }
}

function* setGQList(action) {
    try {
        yield put({ type: GQ.POST.SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: GQ.POST.FAIL });
    }
}

function _newCddInfo(payload) {
    return new Promise((resolve, reject) => {
        try {
            let requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    I_ETMTSEQ: payload.I_ETMTSEQ,
                }),
            };

            fetch(`/api/v1/cdd/info`, requestOptions)
                .then((response) => {
                    if (response.status === 401 || response.status === 400) {
                        resolve(response.status);
                    }
                    if (response.status === 200) {
                        resolve(response.json());
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* newCddInfo(action) {
    try {
        const response = yield call(_newCddInfo, action.payload);
        yield put({ type: newCdd.POST.SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: newCdd.POST.FAIL });
    }
}

export default UserSaga;
