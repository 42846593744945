import { call, put, takeEvery } from "redux-saga/effects";
import { CONTRACT, TERMS, COMPLETE, USER, SUCCESSOR, SUCCESSORCOMPLETE } from "module/contractModule";
import { LOGOUT } from "module/userModule";

function* ContractSaga() {
    yield takeEvery(CONTRACT.GET.REQUEST, contract);
    yield takeEvery(TERMS.GET.REQUEST, getTermsValue);
    yield takeEvery(COMPLETE.POST.REQUEST, postComplete);
    yield takeEvery(USER.POST.REQUEST, setUserInfo);
    yield takeEvery(SUCCESSOR.POST.REQUEST, successor);
    yield takeEvery(SUCCESSORCOMPLETE.POST.REQUEST, successorComplete);
}

function _getcontractAPI(payload) {
    return new Promise((resolve, reject) => {
        try {
            //임시 토큰
            fetch(`/api/v1/contract/${payload.estimate}/detail/${payload.custId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.status === 401 || response.status === 400) {
                        resolve(response.status);
                    } else if (response.status === 200) {
                        resolve(response.json());
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* contract(action) {
    try {
        const response = yield call(_getcontractAPI, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else {
            yield put({ type: CONTRACT.GET.SUCCESS, payload: response });
        }
    } catch (e) {
        yield put({ type: CONTRACT.GET.FAIL });
    }
}

function _getTerms(payload) {
    return new Promise((resolve, reject) => {
        try {
            fetch(`/api/v1/contract/terms?type=${payload.type}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res.json());
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* getTermsValue(action) {
    try {
        const response = yield call(_getTerms, action.payload);
        yield put({ type: TERMS.GET.SUCCESS, payload: { response, type: action.payload.type } });
    } catch (e) {
        yield put({ type: TERMS.GET.FAIL });
    }
}

function _postComplete(payload) {
    return new Promise((resolve, reject) => {
        try {
            let url = "";
            if (payload.isComplete) url = `/api/v1/contract/${payload.I_ETMTSEQ}/complete`;
            else url = `/api/v1/contract/${payload.I_ETMTSEQ}/final/docs`;
            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...payload,
                }),
            })
                .then((res) => {
                    if (res.status === 401 || res.status === 400) {
                        resolve(res.status);
                    } else if (res.status === 200) {
                        resolve(res.json());
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* postComplete(action) {
    try {
        const response = yield call(_postComplete, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else {
            yield put({ type: COMPLETE.POST.SUCCESS, payload: { ...response, isComplete: action.payload?.isComplete } });
        }
    } catch (e) {
        yield put({ type: COMPLETE.POST.FAIL });
    }
}

function* setUserInfo(action) {
    try {
        yield put({ type: USER.POST.SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: USER.POST.FAIL });
    }
}

function _getSuccessorInfoAPI(payload) {
    return new Promise((resolve, reject) => {
        try {
            const jsonData = {
                I_VBELN: payload.I_VBELN,
                I_GUBUN: payload.I_GUBUN,
                estimate: payload.estimate
            };

            //임시 토큰
            // fetch(`/api/v1/contract/${payload.estimate}/detail/${payload.custId}`, {
            fetch(`/api/v1/successor/info`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonData),
            })
                .then((response) => {
                    if (response.status === 401 || response.status === 400|| response.status === 502) {
                        resolve(response.status);
                    } else if (response.status === 200) {
                        resolve(response.json());
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* successor(action) {
    try {
        const response = yield call(_getSuccessorInfoAPI, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else if (response === 502) {
            yield put({ type: SUCCESSOR.POST.FAIL });
        } else {
            yield put({ type: SUCCESSOR.POST.SUCCESS, payload: response });
        }
    } catch (e) {
        yield put({ type: SUCCESSOR.POST.FAIL });
    }
}

function _successorComplete(payload) {
    return new Promise((resolve, reject) => {
        try {
            let url = `/api/v1/successor/save`;
            fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...payload,
                }),
            })
                .then((res) => {
                    if (res.status === 401 || res.status === 400) {
                        resolve(res.status);
                    } else if (res.status === 200) {
                        resolve(res.json());
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (e) {
            reject(e);
        }
    });
}

function* successorComplete(action) {
    try {
        const response = yield call(_successorComplete, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else {
            yield put({ type: SUCCESSORCOMPLETE.POST.SUCCESS, payload: { ...response, isComplete: action.payload?.isComplete } });
        }
    } catch (e) {
        yield put({ type: COMPLETE.POST.FAIL });
    }
}

export default ContractSaga;
