import UserSaga from "./UserAPI";
import ArsSaga from "./ArsAPI";
import PassSaga from "./PassAPI";
import ContractSaga from "./ContractAPI";
import ChangeCmsSaga from "./ChangeCmsAPI";

function sagaConfigure(sagaMiddleware) {
    sagaMiddleware.run(UserSaga);
    sagaMiddleware.run(ArsSaga);
    sagaMiddleware.run(PassSaga);
    sagaMiddleware.run(ContractSaga);
    sagaMiddleware.run(ChangeCmsSaga);
}

export default sagaConfigure;
