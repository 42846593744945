import React, { useEffect } from "react";
import * as _ from "lodash";

import { Document, Page } from "react-pdf/dist/entry.webpack";

import UploadLoading from "components/Loading/uploadLoading";
import CheckGroup from "components/FieldLayerBox/CheckGroup";
import * as SVG from "components/SVG";
import FieldLayerBox from "components/FieldLayerBox";

import styles from "./styles.scss";

const PDFDocument = (props, context) => {
    return (
        <Document
            file={props.file}
            className={[
                styles.pdfViewer,
                props.popup ? styles.popupView : null,
                props.tempPopup ? styles.tempPopup : undefined,
                props.previewPopup ? styles.previewPopup : undefined
            ].join(" ")}
            onLoadSuccess={props.LoadTotal ? pdf => props.LoadTotal(pdf, props.idx) : undefined}
            onLoadError={error => props.togglePdfErrorPopup()}
            options={{
                cMapUrl: "/static/media/cmaps/",
                cMapPacked: true
            }}
            loading={<UploadLoading />}
        >
            {!props.docRendering &&
                props.docs_pages &&
                props.docs_pages.length > props.idx &&
                props.docs_pages[props.idx].map((x, k) => {
                    if (props.blankViewPage[props.idx]) {
                        return (
                            <div
                                className={styles.pdfCont}
                                style={
                                    props.pdfCanvas &&
                                    props.pdfCanvas[props.idx] &&
                                    props.pdfCanvas[props.idx].pageSize &&
                                    props.pdfCanvas[props.idx].pageSize.length > k
                                        ? {
                                              width: props.pdfCanvas[props.idx].pageSize[k].width * props.scale,
                                              height: props.pdfCanvas[props.idx].pageSize[k].height * props.scale
                                          }
                                        : undefined
                                }
                                key={k}
                                data-docs={props.idx}
                                data-page={k + 1}
                                tabIndex={0}
                            >
                                    <Page
                                        className={styles.pdfPage}
                                        pageNumber={x}
                                        scale={Number(props.scale)}
                                        width={props.isMobile || props.isTablet ? 500 : 2000}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                        onMouseMove={props.onMouseMove ? e => props.onMouseMove(e) : null}
                                        onMouseOut={props.onMouseOut ? e => props.onMouseOut(e, x) : null}
                                        onMouseUp={props.referer === "templateEdit" ? undefined : props.onMouseUp ? e => props.onMouseUp(e) : null}
                                        onMouseDown={props.referer === "templateEdit" ? undefined : e => props.onMouseDown(e)}
                                        onTouchMove={props.onTouchMove ? e => props.onTouchMove(e, k) : null}
                                        onTouchStart={props.onTouchStart ? e => props.onTouchStart(e) : null}
                                        onTouchEnd={props.onTouchEnd ? e => props.onTouchEnd(e) : null}
                                        onMouseEnter={props.onMouseEnter ? e => props.onMouseEnter(props.idx, x) : null}
                                        loading={<UploadLoading />}
                                    >
                                        {props.presignCond &&
                                            props.presignCond.map((fieldData, i) => {
                                                if (fieldData.docIdx === props.idx && fieldData.docPage === x) {
                                                    if (
                                                        ((fieldData.coordType === "text" || fieldData.coordType === "date") &&
                                                            (fieldData.content === "" || fieldData.content === undefined || fieldData.content === null || !fieldData.content)) ||
                                                        (fieldData.coordType === "check" && !fieldData.isChecked)
                                                    ) {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <FieldLayerBox
                                                                key={i}
                                                                onMouseDown={props.onMouseDown}
                                                                onDeleteField={props.onDeleteField}
                                                                onCloneField={props.onCloneField}
                                                                onOpenSignModal={props.onOpenSignModal}
                                                                onOptionChange={props.onOptionChange}
                                                                onRequire={props.onRequire}
                                                                onIsMove={props.onIsMove}
                                                                onReadOnly={props.onReadOnly}
                                                                onChangeColorPicker={props.onChangeColorPicker}
                                                                onDropzone={props.onDropzone}
                                                                onSetDate={props.onSetDate}
                                                                onSelectDropOption={props.onSelectDropOption}
                                                                onDisplayDrop={props.onDisplayDrop}
                                                                onRemoveSign={props.onRemoveSign}
                                                                onRemoveImage={props.onRemoveImage}
                                                                onMobileInputClick={props.onMobileInputClick}
                                                                onLoad={props.onLoad}
                                                                isDisplayDropOption={props.isDisplayDropOption}
                                                                isDragging={props.isDragging}
                                                                isResizing={props.isResizing}
                                                                isSelected={false}
                                                                isMobile={props.isMobile}
                                                                isTablet={props.isTablet}
                                                                isSigning={true}
                                                                inx={i}
                                                                type={fieldData.coordType}
                                                                fieldData={fieldData}
                                                                scale={props.scale}
                                                                single={props.single}
                                                                receiver={props.receiver}
                                                                selectedSign={props.selectedSign}
                                                                onReleaseSelected={props.onReleaseSelected}
                                                                profile={props.profile}
                                                                onChangeMemoAt={props.onChangeMemoAt}
                                                                onChangeConnect={props.onChangeConnect}
                                                                room={props.room}
                                                                referer={props.referer}
                                                                onDeleteMemo={props.onDeleteMemo}
                                                                fieldDataList={props.fieldDataList}
                                                                isGroup={false}
                                                                onCheckEnter={props.onCheckEnter}
                                                                onCheckLeave={props.onCheckLeave}
                                                                hoverCheck={props.hoverCheck}
                                                                onMobileTouchCondition={props.onMobileTouchCondition}
                                                                setting={props.setting}
                                                                onUpdateState={props.onUpdateState}
                                                                openCalendar={props.openCalendar}
                                                                is_last={
                                                                    props.docs_pages?.length - 1 === props.idx &&
                                                                    props.docs_pages[props.idx][props.docs_pages[props.idx]?.length - 1] === x
                                                                }
                                                                preview_complete={props.preview_complete}
                                                                preview_cond={props.preview_cond}
                                                                is_othercond={true}
                                                                handleApplyText={props.handleApplyText}
                                                                checkTextApply={props.checkTextApply}
                                                                resetSelectedField={props.resetSelectedField}
                                                                findText={props.findText}
                                                                handleAddrList={props.handleAddrList}
                                                                addrList={props.addrList}
                                                                clickList={props.clickList}
                                                                onClickList={props.onClickList}
                                                                readonly={true}
                                                            ></FieldLayerBox>
                                                        );
                                                    }
                                                }
                                            })}
                                        {!props.tempPopup &&
                                            props.showField &&
                                            props.fieldDataList &&
                                            props.fieldDataList.map((fieldData, i) => {
                                                    if (fieldData.docIdx === props.idx && fieldData.docPage === x) {
                                                        return (
                                                            <FieldLayerBox
                                                                key={i}
                                                                onMouseDown={props.onMouseDown}
                                                                onTouchStart={props.onTouchStart}
                                                                onDeleteField={props.onDeleteField}
                                                                onCloneField={props.onCloneField}
                                                                onOpenSignModal={props.onOpenSignModal}
                                                                onChangeFieldUser={props.onChangeFieldUser}
                                                                onChangeColorPicker={props.onChangeColorPicker}
                                                                onOptionChange={props.onOptionChange}
                                                                onRequire={props.onRequire}
                                                                onIsMove={props.onIsMove}
                                                                onReadOnly={props.onReadOnly}
                                                                onChangeText={props.onChangeText}
                                                                onAddOption={props.onAddOption}
                                                                onDropzone={props.onDropzone}
                                                                onAddItem={props.onAddItem}
                                                                onDeleteItem={props.onDeleteItem}
                                                                onSelectDropOption={props.onSelectDropOption}
                                                                onDisplayDrop={props.onDisplayDrop}
                                                                onRemoveSign={props.onRemoveSign}
                                                                onDeleteOption={props.onDeleteOption}
                                                                onMobileInputClick={props.onMobileInputClick}
                                                                onRemoveImage={props.onRemoveImage}
                                                                onLoad={props.onLoad}
                                                                // onKeyDown={props.onKeyDown}
                                                                onKeyUp={props.onKeyUp}
                                                                isDragging={props.isDragging}
                                                                isResizing={props.isResizing}
                                                                isSelected={
                                                                    props.selectedField && props.selectedField.length > 0
                                                                        ? props.selectedField.filter(inx => {
                                                                              return i === inx;
                                                                          })?.length > 0
                                                                        : false
                                                                }
                                                                isMobile={props.isMobile}
                                                                isTablet={props.isTablet}
                                                                isSigning={true}
                                                                isDisplayDropOption={props.isDisplayDropOption}
                                                                isMulti={props.multiFlag[0] > -1 && props.multiFlag[1] > -1}
                                                                inx={i}
                                                                // type={fieldData?.type}
                                                                type={fieldData?.coordType}
                                                                fieldData={fieldData}
                                                                scale={props.scale}
                                                                single={props.single}
                                                                receiver={props.receiver}
                                                                selectedSign={props.selectedSign}
                                                                onReleaseSelected={props.onReleaseSelected}
                                                                profile={props.profile}
                                                                onChangeMemoAt={props.onChangeMemoAt}
                                                                room={props.room}
                                                                referer={props.referer}
                                                                fieldDataList={props.fieldDataList}
                                                                isGroup={
                                                                    fieldData.coordType === "check" && (props.isMobile || props.isTablet)
                                                                        ? props.fieldDataList &&
                                                                            props.selectedField &&
                                                                            props.selectedField.length > 0 &&
                                                                            props.fieldDataList[props.selectedField[0]]?.coordType === "check" &&
                                                                            props.fieldDataList[props.selectedField[0]]?.id !== fieldData.id &&
                                                                            props.fieldDataList[props.selectedField[0]]?.user === fieldData.user &&
                                                                            props.fieldDataList[props.selectedField[0]]?.label === fieldData.label
                                                                        : props.hoverCheck > -1 &&
                                                                            props.fieldDataList &&
                                                                            props.fieldDataList[props.hoverCheck]?.coordType === "check" &&
                                                                            props.fieldDataList[props.hoverCheck]?.id !== fieldData.id &&
                                                                            props.fieldDataList[props.hoverCheck]?.user === fieldData.user &&
                                                                            props.fieldDataList[props.hoverCheck]?.label === fieldData.label
                                                                }
                                                                setting={props.setting}
                                                                handleApplyText={props.handleApplyText}
                                                                checkTextApply={props.checkTextApply}
                                                                resetSelectedField={props.resetSelectedField}
                                                                findText={props.findText}
                                                                handleAddrList={props.handleAddrList}
                                                                addrList={props.addrList}
                                                                clickList={props.clickList}
                                                                onClickList={props.onClickList}
                                                            >
                                                                {!props.single && fieldData.require && !props.onCheckCondition(fieldData) ? (
                                                                    <div className={styles.requireStar}>
                                                                        <p style={{ color: "red", fontSize: 20 }}>*</p>
                                                                    </div>
                                                                ) : null}
                                                            </FieldLayerBox>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                            })}
                                    </Page>
                            </div>
                        );
                    } else {
                        return <div key={k} />;
                    }
                })}
            {["previewtemplate", "signform", "facial"].includes(props.referer)
                ? undefined
                : !props.tempPopup &&
                  props.multiFlag[0] === -1 &&
                  props.multiFlag[1] === -1 &&
                  ((props.selectedField && props.selectedField.length > 0) || props.hoverCheck > -1) &&
                  props.checkFlag[0] > -1 &&
                  props.checkFlag[0] === props.idx && (
                      <CheckGroup
                          style={{ ...props.checkStyle }}
                          checkField={props.hoverCheck > -1 ? props.fieldDataList[props.hoverCheck] : undefined}
                          isMobile={props.isMobile}
                          isTablet={props.isTablet}
                          onAddCheckToGroup={props.onAddCheckToGroup}
                          selectedField={props.selectedField}
                          isSigning={props.isSigning}
                      />
                  )}
            {!props.isSigning &&
                props.selectedField &&
                props.selectedField.length === 1 &&
                props.fieldDataList[props.selectedField[0]]?.type === "check" &&
                props.onAddCheckToGroup &&
                props.checkFlag[0] > -1 &&
                props.checkFlag[0] === props.idx && (
                    <div
                        className={styles.addCheckBox}
                        onClick={props.fieldAddButton}
                        data-noselect={true}
                        style={{
                            ...props.addStyle,
                            backgroundColor: props.setting && props.setting.comp_color ? props.setting.comp_color : "#1e8ffa"
                        }}
                    >
                        <SVG.Mark type="plus" color={"#fff"} />
                    </div>
                )}
        </Document>
    );
};

export default PDFDocument;
