import { createAction } from "redux-actions";
import { handleActions } from "redux-actions";

export function makeAsyncMethodsActions(actionName) {
    return {
        GET: makeAsyncActions(`${actionName}_GET`),
        POST: makeAsyncActions(`${actionName}_POST`),
        PUT: makeAsyncActions(`${actionName}_PUT`),
        DELETE: makeAsyncActions(`${actionName}_DELETE`),
    };
}

export function makeAsyncActions(actionName) {
    return {
        INDEX: actionName + "_INDEX",
        REQUEST: actionName + "_REQUEST",
        SUCCESS: actionName + "_SUCCESS",
        FAIL: actionName + "_FAIL",
    };
}

export function makeAsyncMethodsCreator(method) {
    return {
        get: makeAsyncActionCreator(method.GET),
        post: makeAsyncActionCreator(method.POST),
        put: makeAsyncActionCreator(method.PUT),
        delete: makeAsyncActionCreator(method.DELETE),
    };
}

export function makeAsyncActionCreator(actions) {
    let actionCreator = {
        index: createAction(actions.INDEX),
        request: createAction(actions.REQUEST),
        success: createAction(actions.SUCCESS),
        fail: createAction(actions.FAIL),
    };

    return actionCreator;
}

export function customActions(
    reducerMap,
    initialState,
    actions
) {
    let initial = reducerMap;

    if (actions && actions.length > 0) {
        actions.map((action) => {
            if (!initial[`${action}_GET_REQUEST`]) {
                initial[`${action}_GET_REQUEST`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_GET_REQUEST`,
                    });
                };
            }
            if (!initial[`${action}_POST_REQUEST`]) {
                initial[`${action}_POST_REQUEST`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_POST_REQUEST`,
                    });
                };
            }
            if (!initial[`${action}_PUT_REQUEST`]) {
                initial[`${action}_PUT_REQUEST`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_PUT_REQUEST`,
                    });
                };
            }
            if (!initial[`${action}_DELETE_REQUEST`]) {
                initial[`${action}_DELETE_REQUEST`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_DELETE_REQUEST`,
                    });
                };
            }
            if (!initial[`${action}_GET_SUCCESS`]) {
                initial[`${action}_GET_SUCCESS`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_GET_SUCCESS`,
                    });
                };
            }
            if (!initial[`${action}_POST_SUCCESS`]) {
                initial[`${action}_POST_SUCCESS`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_POST_SUCCESS`,
                    });
                };
            }
            if (!initial[`${action}_PUT_SUCCESS`]) {
                initial[`${action}_PUT_SUCCESS`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_PUT_SUCCESS`,
                    });
                };
            }
            if (!initial[`${action}_DELETE_SUCCESS`]) {
                initial[`${action}_DELETE_SUCCESS`] = (state) => {
                    return Object.assign({}, state, {
                        isFetching: true,
                        error: undefined,
                        ACTION_TYPE: `${action}_DELETE_SUCCESS`,
                    });
                };
            }
            if (!initial[`${action}_GET_FAIL`]) {
                initial[`${action}_GET_FAIL`] = (state, action) => {
                    return Object.assign({}, state, {
                        isFetching: false,
                        error: action.error,
                        ACTION_TYPE: `${action}_GET_FAIL`,
                    });
                };
            }
            if (!initial[`${action}_POST_FAIL`]) {
                initial[`${action}_POST_FAIL`] = (state, action) => {
                    return Object.assign({}, state, {
                        isFetching: false,
                        error: action.error,
                        ACTION_TYPE: `${action}_POST_FAIL`,
                    });
                };
            }
            if (!initial[`${action}_PUT_FAIL`]) {
                initial[`${action}_PUT_FAIL`] = (state, action) => {
                    return Object.assign({}, state, {
                        isFetching: false,
                        error: action.error,
                        ACTION_TYPE: `${action}_PUT_FAIL`,
                    });
                };
            }
            if (!initial[`${action}_DELETE_FAIL`]) {
                initial[`${action}_DELETE_FAIL`] = (state, action) => {
                    return Object.assign({}, state, {
                        isFetching: false,
                        error: action.error,
                        ACTION_TYPE: `${action}_DELETE_FAIL`,
                    });
                };
            }
            if (!initial[`${action}`]) {
                initial[`${action}`] = (state, action) => {
                    return Object.assign({}, state, {
                        ACTION_TYPE: action,
                    });
                };
            }
        });
    }

    return handleActions(initial, initialState);
}