import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.scss";

import Header from "components/Layout/Header";
import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";

import CheckboxDetail from "components/List/CheckboxDetail";
import Modal01 from "components/Modal/Modal01";

import { contractComplete } from "module/contractModule";

const CorpMember = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.contract);
    const complete = useSelector(state => state.contract.complete);
    
    const [btndisabled, setBtndisabled] = useState(true);
    const [modalOpen1, setModalOpen1] = useState(false);

    useEffect(() => {
        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        } else {
            window.addEventListener("beforeunload", function(e) {
                e.preventDefault();
                e.returnValue = "변경사항은 저장되지 않습니다";
            });
            if (!data) {
                props.history.push("/auth/contractNum");
            }
        }
    }, []);

    useEffect(() => {
        if (complete === "success") {
            dispatch(contractComplete.get.request());
            props.history.push("/signature/signStep2");
        }
    }, [complete]);

    const handleModalClose = () => {
        setModalOpen1(false);
    };

    const handleOnChangeCheck = (e) => {
        if (e.target.checked) {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    };

    const handleOnClickBtn = async () => {
        if (btndisabled) {
            setModalOpen1(true);
        } else {
            setModalOpen1(false);
            props.history.push("/signature/sign");
        }
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="계약 담당자" descYN="Y">
                            <p>계약완료 후, 계약 담당자에게 계약서와 보증조건 안내 정보가 발송 됩니다.</p>
                        </MainTitle>
                        <div className={styles.contBox}>
                            <div className={styles.tblWrap}>
                                <SubTitle title="기본 정보" isSpecial descYn="N"/>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>이름</td>
                                        <td>
                                            <span>{data && data?.length > 0 && data?.filter(x => x?.id === "P1_NAME1" && x?.value)?.length > 0 ? data?.filter(x => x?.id === "P1_NAME1" && x?.value)[0]?.value : "-"}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-mail</td>
                                        <td>
                                            <span>{data && data?.length > 0 && data?.filter(x => x?.id === "P1_SMTP_ADDR" && x?.value)?.length > 0 ? data?.filter(x => x?.id === "P1_SMTP_ADDR" && x?.value)[0]?.value : "-"}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>휴대전화번호</td>
                                        <td>
                                            <span>{data && data?.length > 0 && data?.filter(x => x?.id === "P1_MOB_NUMBER" && x?.value)?.length > 0 ? data?.filter(x => x?.id === "P1_MOB_NUMBER" && x?.value)[0]?.value : "-"}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>사무실 전화번호</td>
                                        <td>
                                            <span>{data && data?.length > 0 && data?.filter(x => x?.id === "P1_TEL_NUMBER" && x?.value)?.length > 0 ? data?.filter(x => x?.id === "P1_TEL_NUMBER" && x?.value)[0]?.value : "-"}</span>
                                        </td>
                                    </tr>
                                    <tr className={styles.adress}>
                                        <td>사무실 주소</td>
                                        <td>
                                            <span>{data && data?.length > 0 && data?.filter(x => x?.id === "P1_HDTAD" && x?.value)?.length > 0 ? data?.filter(x => x?.id === "P1_HDTAD" && x?.value)[0]?.value : "-"}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={styles.termsWrap}>
                                <SubTitle title="개인(신용)정보 필수적 동의" isSpecial descYn="N"/>
                                <CheckboxDetail
                                    checkLabel={"req_check1"}
                                    styles={{borderTop: "1px solid #cccccc"}}
                                    onChange={handleOnChangeCheck}
                                    termsTitle={"개인(신용)정보 필수적 수집,이용 동의"}
                                    termsDescTitle={"롯데오토리스 귀중"}
                                    isTerms={true}
                                    type={"21"}
                                />
                            </div>

                            <Button text="계약 담당자 서명" disabled={btndisabled}
                                    style={btndisabled ? {color: "#999999"} : undefined} onClick={handleOnClickBtn}/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal01 open={modalOpen1} close={handleModalClose} header="안내">
                서명 단계로 넘어가기 위해서는 <br/>
                필수 약관에 동의가 필요합니다.
            </Modal01>
        </>
    )
}

export default CorpMember;