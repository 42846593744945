import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "./styles.scss";
import mainTitleStyles from "components/Title/MainTitle/styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";
import Button from "components/Button";
import SubTitle from "components/Title/SubTitle";
import Table from "components/Table"

const ContractInfo = (props) => {
    const EGUBUN = useSelector(state => state.contract?.EGUBUN);
    const Estimate = useSelector(state => state.contract?.estimate);
    const contractCond = useSelector(state => state.contract?.contractInfo);
    const etcCond = useSelector(state => state.contract?.contractEtc);
    const carInfo = useSelector(state => state.contract?.contractDefault);
    const { data } = useSelector(state => state.contract);
    const username = useSelector(state => state.pass.name);
    const personal = useSelector(state => state.contract.isPersonal);

    useEffect(() => {
        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        } else {
            if (!data) {
                props.history.push("/auth/contractNum");
            }
        }
    }, []);

    const handleOnClickBtn = () => {
        props.history.push("/confirm/fitness");
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>

                        <div className={mainTitleStyles.topInfo}>
                            <h1 style={{marginBottom: "16px"}}>
                                {personal && (
                                    <>
                                        <span className={mainTitleStyles.spanNmBasic}>{username ? username : "-"}</span>
                                        {"님의"}
                                        <br/>
                                    </>
                                )}
                                계약정보를 확인해주세요
                            </h1>
                            <div className={mainTitleStyles.desc}>
                                <p>
                                    <span className={mainTitleStyles.spanBasic}>견적번호 :</span>
                                    <span className={mainTitleStyles.spanNum}>{Estimate}</span>
                                </p>
                            </div>
                        </div>
                        <div className={styles.contBox}>
                            { (EGUBUN === "LM" || EGUBUN === "LF" || EGUBUN === "HH" || EGUBUN === "HA") &&
                                <div className={tableCommonStyles.tblWrap}>
                                    {(EGUBUN === "LM" || EGUBUN === "LF") ?
                                        <SubTitle title="차량 기본 정보" isSpecial descYn="N"/>
                                    : (EGUBUN === "HH" || EGUBUN === "HA") ?
                                        <SubTitle title="차량/건설기계 기본 정보" isSpecial descYn="N"/>
                                    : undefined}
                                    <table className={tableCommonStyles.tbl01}>
                                        <tbody>
                                        {
                                            carInfo?.map((contact, i) => {
                                                return (
                                                    <Table
                                                        name={contact?.title ? contact?.title : "-"}
                                                        value={contact?.value ? contact?.value : "-"}
                                                        key={i}
                                                    />
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="계약 조건" isSpecial descYn="N"/>
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                    {
                                        contractCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="기타 조건" isSpecial descYn="N"/>
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                    {
                                        etcCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <Button style={{ backgroundColor: "#DA291C" }} text={"전자서명 진행"}
                                    onClick={handleOnClickBtn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractInfo;
