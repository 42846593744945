import * as React from "react";
import * as _ from "lodash";
import SignBox from "./SignBox";
import TextBox from "./TextBox";
import CheckBox from "./CheckBox";

const FieldLayerBox = (props, context) => {
    const [fieldData, setFieldData] = React.useState(undefined);
    const [preCondValue, setPreCondValue] = React.useState(false);
    const handleCondValue = () => {
        setPreCondValue(!preCondValue);
    };
    React.useEffect(() => {
        if (!_.isEqual(props.fieldData, fieldData)) {
            setFieldData(props.fieldData);
            setPreCondValue(false);
        }
    }, [props.fieldData]);
    
    switch (props.fieldData?.coordType) {
        case "sign": case "stamp":
            return <SignBox {...props} preCondValue={preCondValue} onCondValue={handleCondValue} />;
        case "text": case "date":
            return <TextBox {...props} preCondValue={preCondValue} onCondValue={handleCondValue} />;
        case "check":
            return <CheckBox {...props} preCondValue={preCondValue} onCondValue={handleCondValue} />;
        default:
            return <SignBox {...props} preCondValue={preCondValue} onCondValue={handleCondValue} />;
    }
};

export default FieldLayerBox;
