import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.scss";

import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Header from "components/Layout/Header";
import Button from "components/Button";

import Select from "react-select"
import Modal01 from "components/Modal/Modal01";

import { getArs, setCert, setCms } from "module/arsModule";
import { userLogout } from "module/userModule";

const Apply = (props) => {
    const E_SUBMICD = useSelector(state => state.contract.E_SUBMICD);
    const EGUBUN = useSelector(state => state.contract?.EGUBUN);
    const personal = useSelector(state => state.contract.isPersonal);
    const { data } = useSelector(state => state.contract);
    const token = useSelector(state => state.pass.accessToken);
    const username = decodeURI(useSelector(state => state.pass?.name));
    const RPMDD = useSelector(state => state.contract.RPMDD);
    const BANKNM = useSelector(state => state.contract.BANKNM);
    const VTACNO = useSelector(state => state.contract.VTACNO);

    const script_arr = [
        "/SW/initech/extension/common/exinterface.js",
        "/SW/initech/extension/crosswebex6.js",
        "/SW/initech/extension/common/exinstall.js",
        "/SW/initech/extension/crosswebexInit.js",
        "/SW/initech/extension/cw_web6_adt.js",
        "/SW/initech/extension/common/js/exproto_ext_daemon.js"
    ];
    
    const options1 = {
        haveEnd: [
            { value: "CMS_BILDT_05", label: "5일" },
            { value: "CMS_BILDT_10", label: "10일" },
            { value: "CMS_BILDT_15", label: "15일" },
            { value: "CMS_BILDT_20", label: "20일" },
            { value: "CMS_BILDT_25", label: "25일" },
            { value: "CMS_BILDT_31", label: "말일" },
        ],
        noHaveEnd: [
            { value: "CMS_BILDT_05", label: "5일" },
            { value: "CMS_BILDT_10", label: "10일" },
            { value: "CMS_BILDT_15", label: "15일" },
            { value: "CMS_BILDT_20", label: "20일" },
            { value: "CMS_BILDT_25", label: "25일" },
        ]
    };

    const options2 = [
        { value: "001",	label: "한국은행" },
        { value: "002",	label: "산업은행" },
        { value: "003",	label: "기업은행" },
        { value: "004",	label: "국민은행" },
        // { value: "005",	label: "외환은행" },
        { value: "007",	label: "수협중앙회" },
        { value: "008",	label: "수출입은행" },
        { value: "011",	label: "농협은행" },
        { value: "012",	label: "지역농축협" },
        { value: "020",	label: "우리은행" },
        { value: "023",	label: "한국스탠다드차타드은행" },
        { value: "027",	label: "한국씨티은행" },
        { value: "031",	label: "대구은행" },
        { value: "032",	label: "부산은행" },
        { value: "034",	label: "광주은행" },
        { value: "035",	label: "제주은행" },
        { value: "037",	label: "전북은행" },
        { value: "039",	label: "경남은행" },
        { value: "045",	label: "새마을금고중앙회" },
        { value: "048",	label: "신협중앙회" },
        { value: "050",	label: "상호저축은행" },
        { value: "054",	label: "HSBC은행" },
        { value: "055",	label: "도이치은행" },
        { value: "059",	label: "미쓰비시도쿄UFJ" },
        { value: "060",	label: "BOA은행" },
        { value: "061",	label: "비엔피파리바" },
        { value: "064",	label: "산림조합중앙회" },
        { value: "071",	label: "우체국" },
        { value: "081",	label: "KEB하나은행" },
        { value: "088",	label: "신한은행" },
        { value: "089",	label: "케이뱅크" },
        { value: "090",	label: "카카오뱅크" },
        { value: "095",	label: "경찰청" },
        { value: "096",	label: "한국전자금융㈜" },
        { value: "099",	label: "금융결제원" },
        { value: "175",	label: "한양증권" },
        { value: "209",	label: "동양증권" },
        { value: "218",	label: "현대증권" },
        { value: "224",	label: "BNK투자증권" },
        { value: "230",	label: "미래에셋증권" },
        { value: "238",	label: "대우증권" },
        { value: "240",	label: "삼성증권" },
        { value: "243",	label: "한국투자증권" },
        { value: "247", label: "NH투자증권" },
        { value: "261", label: "교보증권" },
        { value: "262", label: "하이투자증권" },
        { value: "263", label: "HMC투자증궈" },
        { value: "264", label: "키움증권" },
        { value: "265", label: "이트레이드증권" },
        { value: "266", label: "에스케이증권" },
        { value: "267", label: "대신증권" },
        { value: "268", label: "아이엠투자증권" },
        { value: "269", label: "한화투자증권" },
        { value: "270", label: "하나대투증권" },
        { value: "278", label: "신한금융투자" },
        { value: "279", label: "DB금융투자(주)" },
        { value: "280", label: "유진투자증권" },
        { value: "287", label: "메리츠종합금융증권" },
        { value: "289", label: "엔에이치농협증권" },
        { value: "290", label: "부국증권" },
        { value: "291", label: "신영증권" },
        { value: "292", label: "엘아이지투자증권" },
        { value: "300", label: "IBK 투자증권" },
        { value: "301", label: "KB증권" },
        { value: "302", label: "우리종합금융" },
        { value: "303", label: "한화증" },
        { value: "304", label: "KTB 투자증권" },
        { value: "305", label: "코리아에셋투자증권" },
        { value: "991", label: "뉴스타오토월드제일차 유한회사" },
    ];

    const options3 = [
        {value: "SKT", label: "SKT"},
        {value: "KTF", label: "KT"},
        {value: "LGT", label: "LGU+"},
        {value: "SKM", label: "SKT 알뜰폰"},
        {value: "KTM", label: "KT 알뜰폰"},
        {value: "LGM", label: "LGU 알뜰폰"},
    ];

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "0",
            fontSize: "13px",
            boxShadow: "none",
            backgroundColor: state.selectProps.backgroundColor,
            "input":{
                textIndent: "-99999999em"
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "0 8px"
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: 0
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.08)" : undefined,
            color: state.isSelected ? "" : "0",
            height: "48px",
            lineHeight: "48px",
            padding: "0 0 0 16px",
            fontSize: "14px"
        }),
        menu: (provided) => ({
            ...provided,
            position: "absolute",
            top: "33px",
            borderRadius: "4px",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
        }),
        placeholder: () => ({
            color: "#cccccc"
        }),
    };

    const dispatch = useDispatch();
    const arsCode = useSelector(state => state.ars?.arsCode);
    const custId = useSelector(state => state.contract.custId);
    const estimate = useSelector(state => state.contract.estimate);
    const [btndisabled, setBtndisabled] = useState(false);
    const [pushBtn, setPushBtn] = useState(false);

    /*ARS API*/
    const [telecd] = useState(useSelector(state => state.pass.mobileCo));
    const [phoneno, setPhoneno] = useState(useSelector(state => state.pass?.phoneNumber));
    const [custnm] = useState(decodeURI(useSelector(state => state.pass?.name)));
    const [birthday] = useState(useSelector(state => state.pass?.birthDate));
    const [banknm, setBanknm] = useState("");
    const [bankcd, setBankcd] = useState("");
    const [acctno, setAcctno] = useState("");
    const [bildt, setBildt] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [BILDT_ETC, setBILDT_ETC] = useState("");
    const [acctnoErr, setAcctnoErr] = useState(false);
    const numList = [
        "051", "053", "032", "062", "042", "052", "044", "031", "033", "043", "041", '063', "061", "054", "055", "064",
        "010", "011", "016", "017", "018", "019",
        "070"
    ];

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }
    }, []);

    useEffect(() => {
        if (arsCode !== "" && pushBtn) {
            props.history.push("/cms/ars1", {
                telecd,
                phoneno,
                custnm,
                birthday,
                bildt,
                bankcd,
                banknm,
                acctno,
                url: "apply"
            });
        }
    }, [arsCode]);

    useEffect(() => {
        if (bildt !== "" && banknm !== "" && acctno !== "" && phoneno !== "" && bankcd !== "") {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    }, [banknm, acctno, phoneno]);

    const handleOnClickBtnSubmit1 = async () => {
        if (bildt !== "" && banknm !== "" && acctno !== "" && bankcd !== "" && (phoneno !== "" || !personal)) {
            if (bankcd === "003" && (numList.includes(acctno.slice(0, 3)) || acctno.slice(0, 2) === "02") && (acctno.length === 10 || acctno.length === 11)) {
                // 기업은행
                setAcctnoErr(bankcd);
            } else if (bankcd === "011" && ((acctno.slice(1, 2) === "5" && acctno.length === 13) || (acctno.slice(6, 7) === "5" && acctno.length === 14))) {
                // 농협은행
                setAcctnoErr(bankcd);
            } else if (bankcd === "012" && ((acctno.slice(1, 2) !== "5" && acctno.length === 13) || (acctno.slice(6, 7) !== "5" && acctno.length === 14))) {
                // 지역농축협
                setAcctnoErr(bankcd);
            } else {
                fetch(`/api/v1/contract/bank`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${ token }`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        bankCd: bankcd, // 은행코드
                        bankAccount: acctno, // 은행 계좌번호 
                        bankIdentification: custId, // 사업자 또는 주민등록번호
                        custnm: custnm
                    })
                }).then(result => {
                    if (result.status === 401) {
                        dispatch(userLogout.post.request());
                    } else if (result.status === 400) {
                        setOpenModal(true);
                    } else if (result.status === 200) {
                        return result.json();
                    }
                }).then(res => {
                    if (res) {
                        dispatch(setCms.post.request({ custnm: res?.custnm }));
                        if (personal) {
                            // 개인이면 ars
                            setPushBtn(true);
                            dispatch(getArs.post.request({
                                telecd: telecd,
                                phoneno: phoneno,
                                custnm: custnm,
                                birthday: birthday,
                                bildt: bildt, // 결제일
                                banknm: banknm,
                                acctno: acctno,
                                bankcd: bankcd,
                                BILDT_ETC: BILDT_ETC
                            }));
                        } else {
                            // 기업이면 공동인증서 인증
                            let p1 = new Promise((resolve, reject) => {
                                for (let sc_src of script_arr) {
                                    let ex6sc = document.querySelector('script[src="' + sc_src + '"]');
                                    if (!ex6sc) {
                                        let sc = document.createElement("script");
                                        sc.setAttribute("src", sc_src);
                                        if (sc_src === "/SW/initech/extension/crosswebex6.js") {
                                            sc.setAttribute("charset", "UTF-8");
                                        }
                                        document.body.appendChild(sc);
                                    }
                                }
                                resolve();
                            });
                            p1.then(() => {
                                setTimeout(() => {
                                    handleClickCertPopup();
                                }, 1200);
                            });
                        }
                    } else {
                        setOpenModal(true);
                    }
                }).catch(err => {
                    setOpenModal(true);
                });
            }
        }
    };

    const SendForm = (result, postData) => {
        if (result) {
            let postData = {
                juminNO: custId,
                PKCS7SignedData: result,
                chkvid: "1",
                isCms: true,
                I_ETMTSEQ: estimate
            };

            window
                .fetch(`/api/v1/derauth`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${ token }`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(postData)
                })
                .then(result => {
                    if (result.status === 401) {
                        dispatch(userLogout.post.request());
                    } else if (result.status === 400) {
                        setOpenModal(true);
                    } else if (result.status === 200) {
                        return result.json();
                    }
                }).then(res => {
                    if (res) {
                        dispatch(setCert.post.request(res));
                        dispatch(setCms.post.request({
                            bildt: bildt,
                            bankName: banknm,
                            bankAccount: acctno,
                            bankCd: bankcd,
                            BILDT_ETC: BILDT_ETC
                        }));
                        props.history.push("/signature/signStep1");
                    }
                })
        } else {
            if (window.INI_ALERT) window.INI_ALERT("전자서명에 실패하였습니다.");
            else alert("전자서명에 실패하였습니다.");
        }
    };

    const handleClickCertPopup = () => {
        if (window.INIWEBEX) {
            let signStr = encodeURIComponent("이용기관명") + "=" + encodeURIComponent("롯데 오토리스");
            signStr += "&" + encodeURIComponent("요금출금은행") + "=" + encodeURIComponent(banknm);
            signStr += "&" + encodeURIComponent("출금계좌번호") + "=" + encodeURIComponent(acctno);
            signStr += "&" + encodeURIComponent("출금계좌 예금주명") + "=" + encodeURIComponent(username);
            signStr += "&" + encodeURIComponent("예금주 생년월일(사업자번호)") + "=" + encodeURIComponent(custId);
            window.INIWEBEX.sign({
                signType: "P7",
                targetType: "data",
                data: signStr,
                form: "",
                processCallback: SendForm,
                isHtml5: true,
                iniCache: true, //캐시된 인증서가 있다면 캐시된 인증서 사용
                viewType: "NONE", //서명할 원문은 GRID 형식으로 보이도록 함
                vid: true, //본인확인을 위한 R값 포함한 서명 생성
            });
        }
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        {E_SUBMICD === "B" && (
                            <MainTitle mainTitle="CMS 출금이체 신청" descYN="N"/>
                        )}
                        {(E_SUBMICD === "A" || E_SUBMICD === "C") && (
                            <MainTitle mainTitle="가상계좌정보" descYN="N"/>
                        )}
                        <div className={styles.contBox}>
                            <div className={styles.tblWrap}>
                                {E_SUBMICD === "B" ? (
                                    <>
                                        <SubTitle title="결제 정보" isSpecial descYn="N"/>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>결제일</td>
                                                <td>
                                                    <Select
                                                        options={EGUBUN === "LM" || EGUBUN === "LF" ? options1.haveEnd : options1.noHaveEnd}
                                                        styles={selectStyles}
                                                        isSearchable={false}
                                                        placeholder="결제일자를 선택해주세요."
                                                        onChange={(e) => {
                                                            setBildt(e.value);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>결제은행</td>
                                                <td>
                                                    <Select
                                                        options={options2}
                                                        styles={selectStyles}
                                                        isSearchable={false}
                                                        placeholder="결제은행을 선택해주세요."
                                                        onChange={(e) => {
                                                            setBankcd(e.value);
                                                            setBanknm(e.label);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>계좌번호</td>
                                                <td>
                                                    <input type="text" placeholder="계좌번호를 입력해주세요." value={acctno}
                                                           onChange={(e) => {
                                                               setAcctno((e.target.value).replace(/[^0-9]/g, ''));
                                                           }}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <>
                                        <SubTitle title="결제 정보" isSpecial descYn="Y">
                                            가상계좌번호는 결제담당자에게 발송된 청구서에서 확인 가능합니다.&nbsp;&nbsp;<br/>
                                            지로청구를 선택하신 경우 별도의 지로용지가 발송됩니다.
                                        </SubTitle>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>결제일</td>
                                                <td>{RPMDD !== "" ? RPMDD : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>결제은행</td>
                                                <td>{BANKNM !== "" ? BANKNM : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>계좌번호</td>
                                                <td>{VTACNO !== "" ? VTACNO : "-"}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className={styles.subDesc}>
                                            <p>
                                                계산서 발행일 5~7일 전 청구내역서가 전송됩니다.
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>

                            {E_SUBMICD === "B" ? (
                                <>
                                    <div className={styles.tblWrap}>
                                        <SubTitle title={personal ? "자동이체 ARS 인증" : "자동이체 공동인증서 인증"} isSpecial descYn="N"/>
                                        {personal && (
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>통신사</td>
                                                    <td>
                                                        <Select
                                                            name="telecd"
                                                            options={options3}
                                                            styles={selectStyles}
                                                            isSearchable={false}
                                                            placeholder="통신사를 선택해주세요."
                                                            isDisabled={true}
                                                            value={telecd !== "" ? options3.filter(o => o.value === telecd) : undefined}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>휴대전화 번호</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            placeholder="휴대폰 번호를 입력해주세요."
                                                            value={phoneno}
                                                            onChange={(e) => {
                                                                setPhoneno((e.target.value).replace(/[^0-9]/g, ''));
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                    <Button
                                        text={personal ? "인증 요청" : "인증하기"}
                                        onClick={handleOnClickBtnSubmit1}
                                    />
                                </>
                            ) : (
                                <Button text="확인"
                                    disabled={false}
                                    onClick={() => {props.history.push("/signature/signStep1");} }
                                />
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <Modal01 open={openModal} close={() => setOpenModal(false)} header="계좌 인증 실패">
                계좌 인증에 실패하였습니다.<br/>
                계좌 정보를 확인해주세요.
            </Modal01>
            <Modal01
                open={acctnoErr === "003"}
                close={() => setAcctnoErr("")}
                header="계좌 등록 실패"
                width={{ maxWidth: "300px" }}
            >
                평생계좌번호는 CMS 등록이 불가합니다.<br/>
                예시) 010-xxxx-xxxx, 031-xxx-xxxx
            </Modal01>
            <Modal01
                open={acctnoErr === "011" || acctnoErr === "012"}
                close={() => setAcctnoErr("")}
                header="계좌 등록 실패"
                width={{ maxWidth: "320px" }}
            >
                농협은행의 경우 "농협은행"과 "지역농축협"<br/>
                은행을 구분하여 선택해 주시기 바랍니다.
                <div className={styles.table}>
                    <div className={styles.row}>
                        <div className={styles.cell}>
                            {"계좌번호"}
                            <p>{"(농협은행)"}</p>
                        </div>
                        <div className={styles.cell}>
                            {"계좌번호"}
                            <p>{"(지역농축협)"}</p>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-01-******</div>
                        <div className={styles.cell}>******-51-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-02-******</div>
                        <div className={styles.cell}>******-52-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-12-******</div>
                        <div className={styles.cell}>******-56-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>301-****-****-**</div>
                        <div className={styles.cell}>351-****-****-**</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>302-****-****-**</div>
                        <div className={styles.cell}>352-****-****-**</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>312-****-****-**</div>
                        <div className={styles.cell}>356-****-****-**</div>
                    </div>
                </div>
            </Modal01>
        </>
    )
}

export default Apply;