import React from "react";

const Check = (props, context) => {
    return (
        props.checked ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <path fill="#dc000c" d="M12.445 14H1.555A1.557 1.557 0 0 1 0 12.445V1.555A1.557 1.557 0 0 1 1.555 0h10.89A1.557 1.557 0 0 1 14 1.555v10.89A1.557 1.557 0 0 1 12.445 14zM2.652 5.9L1.555 7l3.889 3.889 7-7-1.1-1.1-5.9 5.9L2.652 5.9z"/>
            </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <g>
                    <g fill="#fafafa" stroke="#8e8e8e">
                        <rect width="14" height="14" stroke="none" rx="2"/>
                        <rect width="13" height="13" x=".5" y=".5" fill="none" rx="1.5"/>
                    </g>
                </g>
            </svg>
        )
    );
};

export default Check;
