import React, { useEffect, useState } from "react";

import styles from "./styles.scss";
import mainTitleStyles from "components/Title/MainTitle/styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";

import SubTitle from "components/Title/SubTitle";
import Table from "components/Table";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { mappingAction } from "module/contractModule";
import updateMappingListContent from "functions/updateMappingListContent";

const SuccessorSignStep1 = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.contract);
    const username = useSelector((state) => state.pass.name);
    const personal = useSelector((state) => state.contract.isPersonal);
    const contractInfo = useSelector((state) => state.contract.contractInfo);
    const contractEtc = useSelector((state) => state.contract.contractEtc);
    const contractConfirm = useSelector((state) => state.contract.contractConfirm);
    const E_SUBMICD = useSelector((state) => state.contract?.E_SUBMICD);
    const BILDT_ETC = useSelector((state) => state.ars.BILDT_ETC);
    const RPMDD = useSelector((state) => state.contract.RPMDD);
    const BANKNM = useSelector((state) => state.contract.BANKNM);
    const VTACNO = useSelector((state) => state.contract.VTACNO);
    const ET_LIST = useSelector((state) => state.contract?.ET_LIST);
    const ES_CTR = useSelector((state) => state.contract?.ES_CTR);
    const mapping = useSelector((state) => state.contract.mapping);
    const custId = useSelector((state) => state.contract.custId);
    const custNm = useSelector((state) => state.ars.custnm);

    const data2 = {
        S1: [
            { name: "은행이름", value: useSelector((state) => state.ars?.bankName) },
            { name: "계좌정보", value: useSelector((state) => state.ars?.bankAccount) },
            { name: "결제일", value: useSelector((state) => state.ars?.bildt) },
            { name: "예금주명", value: decodeURI(useSelector((state) => state.pass?.name)) },
            { name: "생년월일", value: useSelector((state) => state.pass?.birthDate) },
        ],
        S2: [
            { name: "은행이름", value: useSelector((state) => state.ars?.bankName) },
            { name: "계좌정보", value: useSelector((state) => state.ars?.bankAccount) },
            { name: "결제일", value: useSelector((state) => state.ars?.bildt) },
        ],
    };

    const [btndisabled, setBtndisabled] = useState(true);
    const [contractCont, setContractCont] = useState([]);

    useEffect(() => {
        window.addEventListener("beforeunload", function (e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/successor/entry/a");
        }

        dispatch(mappingAction.post.request(updateMappingListContent(mapping, "E_PASS_NO", custId)));
        dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CMS_KOINH", custNm)));
    }, []);

    useEffect(() => {
        if (contractInfo?.length > 0 && contractEtc?.length > 0) {
            setContractCont([...contractInfo, ...contractEtc]);
        }
    }, [contractInfo, contractEtc]);

    const handleOnChangeCheck = (e) => {
        if (e.target.checked) {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    };
    const handleBtnOnClick = () => {
        if (!btndisabled) {
            if (personal) {
                // props.history.push("/signature/sign");
                props.history.push("/successor/signStep2/a");
            } else {
                // props.history.push("/final/corpMember");
                props.history.push("/successor/signStep2/a");
            }
        }
    };

    const returnBildt = (bildt) => {
        if (bildt) {
            if (bildt.includes("BILDT_01")) return "1일";
            else if (bildt.includes("BILDT_05")) return "5일";
            else if (bildt.includes("BILDT_10")) return "10일";
            else if (bildt.includes("BILDT_15")) return "15일";
            else if (bildt.includes("BILDT_20")) return "20일";
            else if (bildt.includes("BILDT_25")) return "25일";
            else if (bildt.includes("BILDT_31")) return "말일";
            else if (bildt.includes("BILDT_ETC")) return `${BILDT_ETC}일`;
        }
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")} />
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <div className={styles.stepOut}>
                            <div className={styles.stepString}>Step </div>
                            <div className={styles.stepNumber}> 9/10</div>
                        </div>
                        <div className={mainTitleStyles.topInfo}>
                            <h1>
                                {personal && (
                                    <>
                                        <span name="username">{username ? username : "-"}</span> 님의
                                        <br />
                                    </>
                                )}
                                계약정보 확인
                            </h1>
                            <div className={mainTitleStyles.desc}>
                                <p>전자서명을 진행하기 전, 계약내용을 확인해주세요.</p>
                            </div>
                        </div>
                        <div className={styles.contBox}>
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="계약 내용" descYn="N" isSpecial />
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                        {/* {contractConfirm?.map((contact, i) => {
                                        return (
                                            <Table
                                                name={contact?.title}
                                                value={contact?.value ? contact?.value : "-"}
                                                key={i}
                                            />
                                        );
                                    })} */}

                                        <Table name={"보증금"} value={ET_LIST?.A024 ? ET_LIST?.A024 : "-"} />
                                        <Table name={"선납금"} value={ET_LIST?.A025 ? ET_LIST?.A025 : "-"} />
                                        <Table name={"잔존가치"} value={ET_LIST?.A026 ? ET_LIST?.A026 : "-"} />
                                        <Table name={"계약주행거리"} value={ET_LIST?.A027 ? ET_LIST?.A027 : "-"} />
                                        <Table name={"납입방법"} value={ET_LIST?.A029 ? ET_LIST?.A029 : "-"} />
                                        <Table name={"리스상품"} value={ET_LIST?.A009 ? ET_LIST?.A009 : "-"} />
                                        <Table name={"리스기간"} value={ET_LIST?.A016 ? ET_LIST?.A016 : "-"} />
                                        <Table name={"차종"} value={ET_LIST?.A003 ? ET_LIST?.A003 : "-"} />
                                        <Table name={"대수"} value={ET_LIST?.A005 ? ET_LIST?.A005 : "-"} />
                                    </tbody>
                                </table>
                            </div>
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="약관 동의" descYn="N" isSpecial />
                                <table className={tableCommonStyles.tbl02}>
                                    <tbody>
                                        <tr>
                                            <td>상품이용약관 동의</td>
                                            <td>동의함</td>
                                        </tr>
                                        {ES_CTR?.SUBMICDA === "B" && (
                                            <tr>
                                                <td>자동이체약관 동의</td>
                                                <td>동의함</td>
                                            </tr>
                                        )}
                                        {personal && (
                                            <tr>
                                                <td>개인(신용)정보 필수적 동의</td>
                                                <td>동의함</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="결제정보" descYn="N" isSpecial />

                                <table className={tableCommonStyles.tbl01}>
                                    {/* <tbody>
                                        <Table name={"contact.name"} value={"-"} />
                                    </tbody> */}
                                    {/* {E_SUBMICD === "B" && ( */}
                                    {ES_CTR?.SUBMICDA === "B" && (
                                        <tbody>
                                            {personal
                                                ? data2.S1.map((contact, i) => {
                                                      return (
                                                          <Table
                                                              name={contact.name}
                                                              value={
                                                                  contact.value
                                                                      ? contact.name === "결제일"
                                                                          ? returnBildt(contact.value)
                                                                          : contact.value
                                                                      : "-"
                                                              }
                                                              key={i}
                                                          />
                                                      );
                                                  })
                                                : data2.S2.map((contact, i) => {
                                                      return (
                                                          <Table
                                                              name={contact.name}
                                                              value={
                                                                  contact.value
                                                                      ? contact.name === "결제일"
                                                                          ? returnBildt(contact.value)
                                                                          : contact.value
                                                                      : "-"
                                                              }
                                                              key={i}
                                                          />
                                                      );
                                                  })}
                                        </tbody>
                                    )}

                                    {(ES_CTR?.SUBMICDA === "A" || ES_CTR?.SUBMICDA === "C") && (
                                        <tbody>
                                            <tr>
                                                <td>결제일</td>
                                                <td>{RPMDD ? RPMDD : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>결제은행</td>
                                                <td>{BANKNM ? BANKNM : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td>계좌번호</td>
                                                <td>{VTACNO ? VTACNO : "-"}</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>

                            <label htmlFor="check1">
                                <div className={styles.agreeCheck}>
                                    <input type="checkBox" id="check1" onChange={handleOnChangeCheck}></input>
                                    <span htmlFor="check1"></span>
                                    <p>본인은 상기 계약 내용에 대하여 동의합니다.</p>
                                </div>
                            </label>

                            <Button style={{ fontSize: "14px" }} text={"전자서명 진행"} disabled={btndisabled} onClick={handleBtnOnClick} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuccessorSignStep1;
