import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from './styles.scss';
import mainTitleStyles from 'components/Title/MainTitle/styles.scss';

import Header from "components/Layout/Header";
import Button from "components/Button";
import Modal01 from "components/Modal/Modal01";

import { userLogout } from "module/userModule";

const Complete = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.contract);
    const username = useSelector(state => state.pass.name);
    const personal = useSelector(state => state.contract.isPersonal);
    const estimate = useSelector(state => state.contract.estimate);
    const token = useSelector(state => state.pass.accessToken);

    const [errModal, setErrModal] = useState(false);
    const [retry, setRetry] = useState(false);

    useEffect(() => {
        let query = {};
        async function setData() {
            await window.location?.search?.split("&")?.map((str, idx) => {
                let split = str.split("=");
                if (idx === 0) {
                    query[split[0].substring(1)] = decodeURIComponent(split[1]);
                } else {
                    query[split[0]] = decodeURIComponent(split[1]);
                }
            });
            if (window.opener && !window.opener.closed) {
                window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                window.close();
            } else {
                // window.addEventListener("beforeunload", function(e) {
                //     e.preventDefault();
                //     e.returnValue = "변경사항은 저장되지 않습니다";
                // });
                if (!data) {
                    props.history.push("/auth/contractNum");
                }
                try {
                    fetch(`/api/v1/contract/${estimate}/complete/check`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        if (res.status === 401) {
                            setErrModal(true);
                        } else if (res.status === 400) {
                            setRetry(true);
                        } else if (res.status === 200) {
                            // 정상 처리
                        }
                    });
                } catch(e) {
                    setErrModal(true);
                }
            }
        }
        setData();
    }, []);

    const close = () => {
        window.location.href = "/";
    }

    const handleModal = () => {
        dispatch(userLogout.post.fail());
        setErrModal(false);
        window.location.href = "/";
    }

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    {!errModal && (
                        <div className={styles.innerWrap}>
                            <div className={[mainTitleStyles.topInfo, mainTitleStyles.complete].join(" ")}>
                                <h1>
                                    {personal && (
                                        <>
                                            <span name="username">{username ? username : "-"}</span> 님의<br/>
                                        </>
                                    )}
                                    <span className={mainTitleStyles.spanRed}>계약 신청이 완료</span>되었습니다.
                                </h1>
                                <div className={mainTitleStyles.desc}>
                                    <p>영업매니저 확인 후 계약이 최종 완료될 예정이며, <br/> SMS를 통하여 알려드리겠습니다.</p>
                                    <p>감사합니다.</p>
                                </div>
                            </div>

                            {/* <Button onClick={close}
                                    style={{backgroundColor: "#DA291C"}} text={"닫기"}/> */}
                        </div>
                    )}
                </div>
            </div>
            <Modal01 open={retry} close={() => setRetry(!retry)} header="요청 실패">
                요청에 실패했습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
            <Modal01 open={errModal} close={handleModal} header="세션 만료">
                유효하지 않은 계약입니다.<br/>
                처음부터 다시 시도해주세요.
            </Modal01>
        </>

    )
}


export default Complete;

