import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import reducer, { history } from "./index";
import sagaConfigure from "api";

const env = process.env.NODE_ENV;

const envProps = {
    HOST_PROTOCOL: window.location.protocol,
    HOST_DOMAIN: window.location.hostname, // 도메인
    HOST_PORT: window.location.port,
    HOST_NAME: window.location.host, // 도메인 + 포트
    MEDIA_URL: "",
    SERVER_URL: "",

    fetchHeader: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type",
    },
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

let store;

if (env === "development") {
    store = () => {
        const store = createStore(reducer, undefined,composeWithDevTools(applyMiddleware(...middlewares))); // 개발자도구 Redux 사용
        //const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
        sagaConfigure(sagaMiddleware);
        return store;
    };
} else {
    store = () => {
        const store = createStore(reducer, applyMiddleware(...middlewares));
        sagaConfigure(sagaMiddleware);
        return store;
    };
}

export { history, envProps };

export default store();
