import { makeAsyncMethodsActions, makeAsyncMethodsCreator, customActions } from "./common";

export const PASS = makeAsyncMethodsActions("pass/PASS"); // GET, POST, DELETE, PUT
export const getPass = makeAsyncMethodsCreator(PASS); // INDEX, REQUEST, SUCCESS, FAIL

export const RESET = makeAsyncMethodsActions("pass/RESET");
export const reset = makeAsyncMethodsCreator(RESET);

export const TOKEN = makeAsyncMethodsActions("pass/TOKEN");
export const userToken = makeAsyncMethodsCreator(TOKEN);

export const initialState = {
    status:"",

    birthDate: "",
    mobileCo: "",
    name: "",
    phoneNumber: "",
    state: "",
    accessToken: "",

    info: "",
};

const Reducer = customActions(
    {
        [PASS.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                status: action.payload?.message,
            });
        },
        [PASS.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                status: "FAIL"
            });
        },
        [PASS.GET.SUCCESS]: (state, action) => {
            const { payload } = action.payload;
            return Object.assign({}, state, {
                ...payload
            });
        },
        [RESET.GET.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                status: "",
            });
        },
        [RESET.GET.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                status:"",
                birthDate: "",
                mobileCo: "",
                name: "",
                phoneNumber: "",
                state: "",
                info: "",
            });
        },
        [RESET.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                status: "",
            });
        },
        [TOKEN.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                accessToken: action.payload.payload?.accessToken
            });
        }
    },
    initialState,
    [
        "pass/PASS",
        "pass/RESET",
        "pass/TOKEN"
    ]
);

export default Reducer;
