import React from "react";

const Mark = (props, context) => {
    if (props.type === "plus") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.w ? props.w : "25.456"}
                height={props.h ? props.h : "25.456"}
                viewBox="0 0 25.456 25.456"
            >
                <g id="clear-24px" transform="rotate(-45 15.364 6.364)">
                    <path fill={"none"} id="패스_3969" d="M0 0h18v18H0z" data-name="패스 3969" />
                    <path
                        fill={props.color ? props.color : "#6c7382"}
                        id="패스_3970"
                        d="M12.433 5.572a.543.543 0 0 0-.768 0L9 8.232 6.335 5.567a.543.543 0 1 0-.768.768L8.232 9l-2.665 2.665a.543.543 0 1 0 .768.768L9 9.768l2.665 2.665a.543.543 0 0 0 .768-.768L9.768 9l2.665-2.665a.546.546 0 0 0 0-.763z"
                        data-name="패스 3970"
                    />
                </g>
            </svg>
        );
    } else if (props.type === "delete") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="clear-24px" width="18" height="18" viewBox="0 0 18 18">
                <path fill={"none"} id="패스_3969" d="M0 0h18v18H0z" data-name="패스 3969" />
                <path
                    fill={props.color ? props.color : "#6c7382"}
                    id="패스_3970"
                    d="M12.433 5.572a.543.543 0 0 0-.768 0L9 8.232 6.335 5.567a.543.543 0 1 0-.768.768L8.232 9l-2.665 2.665a.543.543 0 1 0 .768.768L9 9.768l2.665 2.665a.543.543 0 0 0 .768-.768L9.768 9l2.665-2.665a.546.546 0 0 0 0-.763z"
                    data-name="패스 3970"
                />
            </svg>
        );
    } else if (props.type === "download") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <g id="그룹_5618" data-name="그룹 5618" transform="translate(0 .003)">
                    <g id="그룹_5616" data-name="그룹 5616" transform="translate(2.371 -.002)">
                        <path
                            fill={props.color ? props.color : "#6c7382"}
                            id="패스_4094"
                            d="M7.438.165a.57.57 0 0 0-.807 0L4.575 2.22a.571.571 0 0 0 .807.807l1.082-1.076v4.964a.573.573 0 1 0 1.145 0V1.951l1.083 1.077A.571.571 0 0 0 9.5 2.22z"
                            data-name="패스 4094"
                            transform="translate(-4.407 .002)"
                        />
                    </g>
                    <g id="그룹_5617" data-name="그룹 5617" transform="translate(0 4.845)">
                        <path
                            fill={props.color ? props.color : "#6c7382"}
                            id="패스_4095"
                            d="M9.444 8a.566.566 0 0 0-.556.573v2.863a.566.566 0 0 1-.556.573H1.667a.566.566 0 0 1-.556-.573V8.573A.566.566 0 0 0 .556 8 .566.566 0 0 0 0 8.573v3.435a1.132 1.132 0 0 0 1.111 1.145h7.778A1.132 1.132 0 0 0 10 12.008V8.573A.566.566 0 0 0 9.444 8z"
                            data-name="패스 4095"
                            transform="translate(0 -8)"
                        />
                    </g>
                </g>
            </svg>
        );
    } else if (props.type === "person") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="person-24px"
                width={props.w ? props.w : "13"}
                height={props.h ? props.h : "13"}
                viewBox="0 0 13 13"
            >
                <path fill={"none"} id="패스_3961" d="M13 0H0v13h13z" data-name="패스 3961" />
                <path
                    fill={props.color ? props.color : "#6c7382"}
                    id="패스_3962"
                    d="M8.312 8.312a2.156 2.156 0 1 1 2.156-2.156 2.155 2.155 0 0 1-2.156 2.156zm0 1.078c1.439 0 4.312.722 4.312 2.156v.539a.541.541 0 0 1-.539.539H4.539A.541.541 0 0 1 4 12.085v-.539C4 10.112 6.873 9.39 8.312 9.39z"
                    data-name="패스 3962"
                    transform="translate(-1.812 -1.812)"
                />
            </svg>
        );
    } else if (props.type === "attach") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__attach_file-24px"
                width={props.w ? props.w : "24"}
                height={props.h ? props.h : "24"}
                viewBox="0 0 24 24"
            >
                <path id="prefix__패스_10591" d="M0 0h24v24H0z" data-name="패스 10591" fill={"none"} />
                <path
                    fill={props.color ? props.color : "#dbdfe7"}
                    id="prefix__패스_10592"
                    d="M16.5 6.75v10.58a4.116 4.116 0 0 1-3.61 4.15A3.993 3.993 0 0 1 8.5 17.5V5.14a2.6 2.6 0 0 1 2.24-2.63A2.5 2.5 0 0 1 13.5 5v10.5a1 1 0 0 1-2 0V6.75a.75.75 0 0 0-1.5 0v8.61a2.6 2.6 0 0 0 2.24 2.63A2.5 2.5 0 0 0 15 15.5V5.17a4.116 4.116 0 0 0-3.61-4.15A4 4 0 0 0 7 5v12.27a5.659 5.659 0 0 0 4.96 5.71A5.505 5.505 0 0 0 18 17.5V6.75a.75.75 0 0 0-1.5 0z"
                    data-name="패스 10592"
                />
            </svg>
        );
    } else if (props.type === "checked") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="13.996" height="12.469" viewBox="0 0 13.996 12.469">
                <path
                    style={{
                        fill: "none",
                        stroke: props.color ? props.color : "#fff",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: "2px"
                    }}
                    id="패스_4139"
                    d="M-1543.014-1000.5l3.724 3.711 4.371-5.9 3.088-4.169"
                    data-name="패스 4139"
                    transform="translate(1544.428 1008.258)"
                />
            </svg>
        );
    } else if (props.type === "pin") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12.316" height="18" viewBox="0 0 12.316 18">
                <path
                    id="패스_4148"
                    style={{ fill: "#6c7382", fillRule: "evenodd" }}
                    d="M6.158-179a6.317 6.317 0 0 1 6.158 6.465q0 3.57-6.158 11.535Q0-168.965 0-172.535A6.317 6.317 0 0 1 6.158-179zm0 2.929a3.553 3.553 0 0 0-3.464 3.637 3.553 3.553 0 0 0 3.464 3.634 3.553 3.553 0 0 0 3.464-3.637 3.553 3.553 0 0 0-3.464-3.634z"
                    data-name="패스 4148"
                    transform="translate(0 179)"
                />
            </svg>
        );
    } else if (props.type === "upload") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="44.817" height="42.328" viewBox="0 0 44.817 42.328">
                <g id="그룹_6811" data-name="그룹 6811" transform="translate(-767.498 -385.409)">
                    <path
                        style={{ fill: props.color ? props.color : "#6c7382" }}
                        id="패스_6201"
                        d="M809.825 393.409a2.5 2.5 0 0 0-2.49 2.49v12.449a2.5 2.5 0 0 1-2.49 2.49h-29.877a2.5 2.5 0 0 1-2.49-2.49V395.9a2.49 2.49 0 1 0-4.98 0v14.939a5 5 0 0 0 4.98 4.98h34.858a5 5 0 0 0 4.98-4.98V395.9a2.5 2.5 0 0 0-2.491-2.491z"
                        data-name="패스 6201"
                        transform="translate(0 11.919)"
                    />
                    <path
                        style={{ fill: props.color ? props.color : "#6c7382" }}
                        id="패스_6202"
                        d="M785.088 386.137a2.48 2.48 0 0 0-3.511 0l-8.939 8.938a2.482 2.482 0 1 0 3.511 3.511l4.681-4.681v21.587a2.49 2.49 0 1 0 4.98 0v-21.587l4.706 4.681a2.482 2.482 0 1 0 3.511-3.511z"
                        data-name="패스 6202"
                        transform="translate(6.574)"
                    />
                </g>
            </svg>
        );
    } else if (props.type === "newUpload") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="51.934" height="39.369" viewBox="0 0 51.934 39.369">
                <g id="prefix__그룹_7686" data-name="그룹 7686" transform="translate(9464 -3164)">
                    <g id="prefix__그룹_7684" data-name="그룹 7684" transform="translate(-9464 3164)">
                        <g id="prefix__그룹_7683" data-name="그룹 7683">
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6895"
                                d="M105.723 153.332h-2.635a1.317 1.317 0 1 1 0-2.635h1.317v-1.317a1.317 1.317 0 1 1 2.635 0v2.635a1.317 1.317 0 0 1-1.317 1.317z"
                                data-name="패스 6895"
                                transform="translate(-65.36 -119.688)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6896"
                                d="M105.237 152.7h-4.825a1.317 1.317 0 1 1 0-2.635h4.825a1.317 1.317 0 1 1 0 2.635zm-9.651 0h-4.824a1.317 1.317 0 0 1 0-2.635h4.824a1.317 1.317 0 1 1 0 2.635zm-9.649 0h-4.826a1.317 1.317 0 0 1 0-2.635h4.825a1.317 1.317 0 0 1 0 2.635z"
                                data-name="패스 6896"
                                transform="translate(-72.334 -119.053)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6897"
                                d="M78.083 153.332h-2.635a1.317 1.317 0 0 1-1.317-1.317v-2.635a1.317 1.317 0 1 1 2.635 0v1.32h1.317a1.317 1.317 0 1 1 0 2.635z"
                                data-name="패스 6897"
                                transform="translate(-74.131 -119.688)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6898"
                                d="M75.448 150.51a1.317 1.317 0 0 1-1.317-1.317v-5.148a1.317 1.317 0 0 1 2.635 0v5.148a1.316 1.316 0 0 1-1.318 1.317zm0-10.3a1.318 1.318 0 0 1-1.317-1.317v-5.148a1.317 1.317 0 1 1 2.635 0v5.155a1.317 1.317 0 0 1-1.318 1.314z"
                                data-name="패스 6898"
                                transform="translate(-74.131 -124.648)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6899"
                                d="M75.448 131.792a1.318 1.318 0 0 1-1.317-1.317v-2.635a1.318 1.318 0 0 1 1.317-1.317h2.635a1.317 1.317 0 1 1 0 2.635h-1.317v1.317a1.317 1.317 0 0 1-1.318 1.317z"
                                data-name="패스 6899"
                                transform="translate(-74.131 -126.523)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6900"
                                d="M105.237 129.158h-4.825a1.317 1.317 0 1 1 0-2.635h4.825a1.317 1.317 0 1 1 0 2.635zm-9.651 0h-4.824a1.317 1.317 0 1 1 0-2.635h4.824a1.317 1.317 0 1 1 0 2.635zm-9.649 0h-4.826a1.317 1.317 0 1 1 0-2.635h4.825a1.317 1.317 0 0 1 0 2.635z"
                                data-name="패스 6900"
                                transform="translate(-72.334 -126.523)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6901"
                                d="M105.723 131.792a1.318 1.318 0 0 1-1.317-1.317v-1.317h-1.317a1.317 1.317 0 1 1 0-2.635h2.635a1.318 1.318 0 0 1 1.317 1.317v2.635a1.318 1.318 0 0 1-1.318 1.317z"
                                data-name="패스 6901"
                                transform="translate(-65.36 -126.523)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6902"
                                d="M105.088 150.51a1.317 1.317 0 0 1-1.317-1.317v-5.147a1.317 1.317 0 0 1 2.635 0v5.147a1.317 1.317 0 0 1-1.318 1.317zm0-10.3a1.317 1.317 0 0 1-1.317-1.317v-5.148a1.317 1.317 0 1 1 2.635 0v5.155a1.317 1.317 0 0 1-1.318 1.314z"
                                data-name="패스 6902"
                                transform="translate(-64.726 -124.648)"
                            />
                        </g>
                    </g>
                    <g id="prefix__그룹_7682" data-name="그룹 7682" transform="translate(-9433.454 3182.604)">
                        <path
                            fill={props.color ? props.color : "#6980A6"}
                            id="prefix__패스_6903"
                            d="M113.885 158.746a2.1 2.1 0 0 1-1.452-.581l-9.01-8.6a2.1 2.1 0 0 1 2.9-3.043l9.012 8.6a2.1 2.1 0 0 1-1.452 3.624z"
                            data-name="패스 6903"
                            transform="translate(-94.937 -138.272)"
                        />
                        <g id="prefix__그룹_7685" data-name="그룹 7685">
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6893"
                                d="M96.319 139.645l6.276 18.663 4.095-8.475 8.915-3.71z"
                                data-name="패스 6893"
                                transform="translate(-95.268 -138.595)"
                            />
                            <path
                                fill={props.color ? props.color : "#6980A6"}
                                id="prefix__패스_6904"
                                d="M102.647 159.411h-.066a1.048 1.048 0 0 1-.93-.714l-6.277-18.663A1.051 1.051 0 0 1 96.7 138.7l19.285 6.477a1.051 1.051 0 0 1 .069 1.967l-8.546 3.556-3.92 8.116a1.05 1.05 0 0 1-.941.595zm-4.607-18.044l4.781 14.214 2.972-6.154a1.054 1.054 0 0 1 .542-.513l6.331-2.634z"
                                data-name="패스 6904"
                                transform="translate(-95.319 -138.646)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else if (props.type === "template") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="42.12" height="42.328" viewBox="0 0 42.12 42.328">
                <g id="그룹_6810" data-name="그룹 6810" transform="translate(-817 -381.909)">
                    <path
                        style={{ fill: props.color ? props.color : "#6c7382" }}
                        id="패스_6199"
                        d="M848.6 415.5h-25.28a2.113 2.113 0 0 1-2.107-2.107v-25.286a2.107 2.107 0 0 0-4.214 0V415.5a4.226 4.226 0 0 0 4.214 4.214H848.6a2.107 2.107 0 0 0 0-4.214z"
                        data-name="패스 6199"
                        transform="translate(0 4.528)"
                    />
                    <path
                        style={{ fill: props.color ? props.color : "#6c7382" }}
                        id="패스_6200"
                        d="M850.488 381.909h-25.282a4.226 4.226 0 0 0-4.214 4.214V411.4a4.227 4.227 0 0 0 4.214 4.214h25.282a4.227 4.227 0 0 0 4.212-4.214v-25.277a4.226 4.226 0 0 0-4.212-4.214zM837.847 409.3h-8.427a2.107 2.107 0 0 1 0-4.214h8.427a2.107 2.107 0 0 1 0 4.214zm8.427-8.427h-16.855a2.107 2.107 0 0 1 0-4.214h16.855a2.107 2.107 0 0 1 0 4.214zm0-8.427h-16.855a2.107 2.107 0 0 1 0-4.214h16.855a2.107 2.107 0 0 1 0 4.214z"
                        data-name="패스 6200"
                        transform="translate(4.418)"
                    />
                </g>
            </svg>
        );
    } else if (props.type === "setting") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "20"} height={props.h ? props.h : "20"} viewBox="0 0 20 20">
                <path
                    id="정보ᄀ_ᆫ리"
                    d="M19.65 8.374l-2.641-.52a6.893 6.893 0 0 0-.53-1.281l1.531-2.241a.44.44 0 0 0-.06-.56l-1.712-1.681a.44.44 0 0 0-.56-.09l-2.241 1.521A6.893 6.893 0 0 0 12.156 3l-.52-2.64A.45.45 0 0 0 11.2 0H8.814a.45.45 0 0 0-.44.36L7.854 3a6.894 6.894 0 0 0-1.281.53L4.332 2a.44.44 0 0 0-.56.06L2.091 3.772a.44.44 0 0 0-.09.56l1.521 2.241A6.893 6.893 0 0 0 3 7.854l-2.64.52a.45.45 0 0 0-.36.44V11.2a.45.45 0 0 0 .36.44l2.64.516a6.893 6.893 0 0 0 .53 1.281L2 15.678a.44.44 0 0 0 .06.56l1.681 1.681a.44.44 0 0 0 .56.06l2.241-1.491a6.893 6.893 0 0 0 1.311.52l.52 2.631a.45.45 0 0 0 .44.36H11.2a.45.45 0 0 0 .44-.36l.52-2.631a6.893 6.893 0 0 0 1.281-.53l2.241 1.531a.44.44 0 0 0 .56-.06l1.681-1.681a.44.44 0 0 0 .06-.56l-1.491-2.241a6.893 6.893 0 0 0 .52-1.311l2.631-.52A.45.45 0 0 0 20 11.2V8.814a.45.45 0 0 0-.35-.44zm-9.645 4.742a3.112 3.112 0 1 1 3.112-3.112 3.112 3.112 0 0 1-3.112 3.112z"
                    fill={props.color ? props.color : "#b0b3bb"}
                    data-name="정보관리"
                />
            </svg>
        );
    } else if (props.type === "star") {
        return props.marked ? (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="star-24px"
                width={props.w ? props.w : "24"}
                height={props.h ? props.h : "24"}
                viewBox="0 0 24 24"
            >
                <g id="그룹_5612" data-name="그룹 5612">
                    <path fill={"none"} id="패스_4098" d="M0 0h24v24H0z" data-name="패스 4098" />
                    <path fill={"none"} id="패스_4099" d="M0 0h24v24H0z" data-name="패스 4099" />
                </g>
                <g id="그룹_5613" data-name="그룹 5613">
                    <path
                        fill={props.color ? props.color : "#1e8ffa"}
                        id="패스_4100"
                        d="M12 17.27l4.15 2.51a1 1 0 0 0 1.49-1.08l-1.1-4.72 3.67-3.18a1 1 0 0 0-.57-1.75l-4.83-.41-1.89-4.46a1 1 0 0 0-1.84 0L9.19 8.63l-4.83.41a1 1 0 0 0-.57 1.75l3.67 3.18-1.1 4.72a1 1 0 0 0 1.49 1.08z"
                        data-name="패스 4100"
                    />
                </g>
            </svg>
        ) : (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="star_border-24px"
                width={props.w ? props.w : "24"}
                height={props.h ? props.h : "24"}
                viewBox="0 0 24 24"
            >
                <path fill={"none"} id="패스_4101" d="M0 0h24v24H0z" data-name="패스 4101" />
                <path
                    fill={props.color ? props.color : "#c6cdd9"}
                    id="패스_4102"
                    d="M19.65 9.04l-4.84-.42-1.89-4.45a1 1 0 0 0-1.84 0L9.19 8.63l-4.83.41a1 1 0 0 0-.57 1.75l3.67 3.18-1.1 4.72a1 1 0 0 0 1.49 1.08l4.15-2.5 4.15 2.51a1 1 0 0 0 1.49-1.08l-1.1-4.73 3.67-3.18a1 1 0 0 0-.56-1.75zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28z"
                    data-name="패스 4102"
                />
            </svg>
        );
    } else if (props.type === "info") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="info-24px"
                width={props.w ? props.w : "20"}
                height={props.h ? props.h : "20"}
                viewBox="0 0 20 20"
            >
                <path fill={"none"} id="패스_6291" d="M0 0h20v20H0z" data-name="패스 6291" />
                <path
                    fill={props.color ? props.color : "#1e8ffa"}
                    id="패스_6292"
                    d="M10 2a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm0 12a.8.8 0 0 1-.8-.8V10a.8.8 0 1 1 1.6 0v3.2a.8.8 0 0 1-.8.8zm.8-6.4H9.2V6h1.6z"
                    data-name="패스 6292"
                />
            </svg>
        );
    } else if (props.type === "eye") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__remove_red_eye-24px_1_"
                width="24"
                height="24"
                data-name="remove_red_eye-24px (1)"
                viewBox="0 0 24 24"
            >
                <path fill={"none"} id="prefix__패스_6813" d="M0 0h24v24H0z" data-name="패스 6813" />
                <path
                    fill={props.color ? props.color : "#dbdfe7"}
                    id="prefix__패스_6814"
                    d="M12 4.5A11.827 11.827 0 001 12a11.817 11.817 0 0022 0 11.827 11.827 0 00-11-7.5zM12 17a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3z"
                    data-name="패스 6814"
                />
            </svg>
        );
    } else if (props.type === "dropdrag") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.w ? props.w : "39.424"}
                height={props.h ? props.h : "29.886"}
                viewBox="0 0 39.424 29.886"
            >
                <g id="prefix__그룹_7686" data-name="그룹 7686" transform="translate(9464 -3164)">
                    <g id="prefix__그룹_7684" data-name="그룹 7684" transform="translate(-9538.131 3037.477)">
                        <g id="prefix__그룹_7683" data-name="그룹 7683">
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6895"
                                d="M104.771 152.063h-2a1 1 0 0 1 0-2h1v-1a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1z"
                                data-name="패스 6895"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6896"
                                d="M99.108 152.063h-3.663a1 1 0 0 1 0-2h3.663a1 1 0 1 1 0 2zm-7.326 0H88.12a1 1 0 0 1 0-2h3.662a1 1 0 0 1 0 2zm-7.325 0h-3.663a1 1 0 0 1 0-2h3.663a1 1 0 0 1 0 2z"
                                data-name="패스 6896"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6897"
                                d="M77.131 152.063h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v1h1a1 1 0 0 1 0 2z"
                                data-name="패스 6897"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6898"
                                d="M75.131 146.155a1 1 0 0 1-1-1v-3.908a1 1 0 0 1 2 0v3.908a1 1 0 0 1-1 1zm0-7.816a1 1 0 0 1-1-1v-3.908a1 1 0 0 1 2 0v3.908a1 1 0 0 1-1 1z"
                                data-name="패스 6898"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6899"
                                d="M75.131 130.523a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-1v1a1 1 0 0 1-1 1z"
                                data-name="패스 6899"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6900"
                                d="M99.108 128.523h-3.663a1 1 0 1 1 0-2h3.663a1 1 0 1 1 0 2zm-7.326 0H88.12a1 1 0 1 1 0-2h3.662a1 1 0 1 1 0 2zm-7.325 0h-3.663a1 1 0 1 1 0-2h3.663a1 1 0 1 1 0 2z"
                                data-name="패스 6900"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6901"
                                d="M104.771 130.523a1 1 0 0 1-1-1v-1h-1a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
                                data-name="패스 6901"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6902"
                                d="M104.771 146.155a1 1 0 0 1-1-1v-3.907a1 1 0 0 1 2 0v3.907a1 1 0 0 1-1 1zm0-7.816a1 1 0 0 1-1-1v-3.908a1 1 0 0 1 2 0v3.908a1 1 0 0 1-1 1z"
                                data-name="패스 6902"
                            />
                        </g>
                    </g>
                    <g id="prefix__그룹_7682" data-name="그룹 7682" transform="translate(-9536.131 3039.477)">
                        <path
                            fill={props.color ? props.color : "#8c9cb4"}
                            id="prefix__패스_6903"
                            d="M111.208 155.663a1.6 1.6 0 0 1-1.1-.441l-6.84-6.525a1.6 1.6 0 0 1 2.2-2.31l6.842 6.526a1.6 1.6 0 0 1-1.1 2.751z"
                            data-name="패스 6903"
                            transform="translate(-1.505 -1.474)"
                        />
                        <g id="prefix__그룹_7685" data-name="그룹 7685" transform="translate(95.319 138.646)">
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6893"
                                d="M96.319 139.645l4.764 14.167 3.108-6.434 6.767-2.816z"
                                data-name="패스 6893"
                                transform="translate(-95.521 -138.848)"
                            />
                            <path
                                fill={props.color ? props.color : "#8c9cb4"}
                                id="prefix__패스_6904"
                                d="M100.882 154.409h-.05a.8.8 0 0 1-.706-.542L95.36 139.7a.8.8 0 0 1 1.01-1.01l14.64 4.917a.8.8 0 0 1 .053 1.493l-6.487 2.7-2.976 6.161a.8.8 0 0 1-.718.448zm-3.5-13.7l3.629 10.79 2.256-4.672a.8.8 0 0 1 .412-.389l4.806-2z"
                                data-name="패스 6904"
                                transform="translate(-95.319 -138.646)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else if (props.type === "arrowback") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__arrow_back-24px"
                width={props.w ? props.w : "24"}
                height={props.h ? props.h : "24"}
                viewBox="0 0 24 24"
            >
                <path fill={"none"} id="prefix__패스_6905" d="M0 0h24v24H0z" data-name="패스 6905" />
                <path
                    fill={props.color ? props.color : "#8c9cb4"}
                    id="prefix__패스_6906"
                    d="M19 11H7.83l4.88-4.88a1.008 1.008 0 0 0 0-1.42 1 1 0 0 0-1.41 0l-6.59 6.59a1 1 0 0 0 0 1.41l6.59 6.59a1 1 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 0-2z"
                    data-name="패스 6906"
                />
            </svg>
        );
    } else if (props.type === "resend") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__near_me-24px"
                width={props.w ? props.w : "20"}
                height={props.h ? props.h : "20"}
                viewBox="0 0 20 20"
            >
                <path fill={"none"} id="prefix__패스_6911" d="M0 0h20v20H0z" data-name="패스 6911" />
                <path
                    fill={props.color ? props.color : "#8c9cb4"}
                    id="prefix__패스_6912"
                    d="M15.345 3.923L3.934 8.7a.776.776 0 0 0 .016 1.438l4.15 1.604a.777.777 0 0 1 .443.443l1.6 4.143a.78.78 0 0 0 1.446.023l4.781-11.4a.789.789 0 0 0-1.025-1.028z"
                    data-name="패스 6912"
                    transform="translate(-.135 -.15)"
                />
            </svg>
        );
    } else if (props.type === "represent") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__bookmark-24px"
                width={props.w ? props.w : "13.444"}
                height={props.h ? props.h : "13.445"}
                viewBox="0 0 13.444 13.445"
            >
                <path fill={"none"} id="prefix__패스_6797" d="M0 0h13.444v13.444H0z" data-name="패스 6797" />
                <path
                    fill={props.color ? props.color : "#dbdfe7"}
                    id="prefix__패스_6798"
                    d="M11.7 3H6.117A1.121 1.121 0 0 0 5 4.119v8.952l3.911-1.678 3.911 1.678V4.119A1.121 1.121 0 0 0 11.7 3z"
                    data-name="패스 6798"
                    transform="translate(-2.189 -1.313)"
                />
            </svg>
        );
    } else if (props.type === "previewbtn") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__pageview-24px"
                width={props.w ? props.w : "23.816"}
                height={props.h ? props.h : "23.816"}
                viewBox="0 0 23.816 23.816"
            >
                <path fill={"none"} id="prefix__패스_6895" d="M0 0h23.816v23.816H0z" data-name="패스 6895" />
                <path
                    fill={props.color ? props.color : "#8c9cb4"}
                    id="prefix__패스_6896"
                    d="M11.256 8.736a2.369 2.369 0 1 0 2.436 2.364 2.4 2.4 0 0 0-2.436-2.364zM19.538 4H3.949A1.928 1.928 0 0 0 2 5.894v11.367a1.928 1.928 0 0 0 1.949 1.894h15.589a1.928 1.928 0 0 0 1.949-1.894V5.894A1.928 1.928 0 0 0 19.538 4zm-3.82 12.788L13.575 14.7a4.407 4.407 0 0 1-3.137.587 4.341 4.341 0 0 1-3.527-3.628 4.39 4.39 0 0 1 8.681-1.26 4.1 4.1 0 0 1-.624 2.955l2.143 2.074a.936.936 0 0 1 0 1.345 1 1 0 0 1-1.393.015z"
                    data-name="패스 6896"
                    transform="translate(.165 .33)"
                />
            </svg>
        );
    } else if (props.type === "zoomin") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="prefix__zoom_in-24px" width="24" height="24" viewBox="0 0 24 24">
                <path fill={"none"} id="prefix__패스_6898" d="M0 0h24v24H0z" data-name="패스 6898" />
                <path
                    fill={props.color ? props.color : "#6980a6"}
                    id="prefix__패스_6899"
                    d="M15.5 14h-.79l-.28-.27a6.518 6.518 0 1 0-.7.7l.27.28v.79l4.26 4.25a1.044 1.044 0 0 0 1.48 0l.01-.01a1.044 1.044 0 0 0 0-1.48zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14zm0-7a.5.5 0 0 0-.5.5V9H7.5a.5.5 0 0 0 0 1H9v1.5a.5.5 0 0 0 1 0V10h1.5a.5.5 0 0 0 0-1H10V7.5a.5.5 0 0 0-.5-.5z"
                    data-name="패스 6899"
                />
            </svg>
        );
    } else if (props.type === "zoomout") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="prefix__zoom_out-24px" width="24" height="24" viewBox="0 0 24 24">
                <path fill={"none"} id="prefix__패스_6900" d="M0 0h24v24H0z" data-name="패스 6900" />
                <path
                    fill={props.color ? props.color : "#6980a6"}
                    id="prefix__패스_6901"
                    d="M15.5 14h-.79l-.28-.27a6.518 6.518 0 1 0-.7.7l.27.28v.79l4.26 4.25a1.044 1.044 0 0 0 1.48 0l.01-.01a1.044 1.044 0 0 0 0-1.48zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14zm-2-5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1z"
                    data-name="패스 6901"
                />
            </svg>
        );
    } else if (props.type === "keyboard") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11.9" viewBox="0 0 17 11.9">
                <path
                    fill={props.color ? props.color : "#1e8ffa"}
                    id="prefix__keyboard-24px"
                    d="M17.3 5H3.7a1.7 1.7 0 0 0-1.692 1.7L2 15.2a1.705 1.705 0 0 0 1.7 1.7h13.6a1.705 1.705 0 0 0 1.7-1.7V6.7A1.7 1.7 0 0 0 17.3 5zM9.65 7.55h1.7v1.7h-1.7zm0 2.55h1.7v1.7h-1.7zM7.1 7.55h1.7v1.7H7.1zm0 2.55h1.7v1.7H7.1zm-.85 1.7h-1.7v-1.7h1.7zm0-2.55h-1.7v-1.7h1.7zm6.8 5.95h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 0 1 0 1.7zm.85-3.4h-1.7v-1.7h1.7zm0-2.55h-1.7v-1.7h1.7zm2.55 2.55h-1.7v-1.7h1.7zm0-2.55h-1.7v-1.7h1.7z"
                    transform="translate(-2 -5)"
                />
            </svg>
        );
    } else if (props.type === "arrowkey") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="6.667" height="10" viewBox="0 0 6.667 10">
                <path
                    fill={props.color ? props.color : "#303348"}
                    id="prefix__다각형_45"
                    d="M4.2 1.067a1 1 0 0 1 1.6 0l3 4a1 1 0 0 1-.8 1.6H2a1 1 0 0 1-.8-1.6z"
                    data-name="다각형 45"
                    transform="rotate(-90 5 5)"
                />
            </svg>
        );
    } else if (props.type === "company") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix___01_1_"
                width={props.w ? props.w : "24"}
                height={props.h ? props.h : "24"}
                data-name="01 (1)"
                viewBox="0 0 24 24"
            >
                <g id="prefix__그룹_8216" data-name="그룹 8216">
                    <path fill={"none"} id="prefix__사각형_8218" d="M0 0H24V24H0z" data-name="사각형 8218" />
                    <path fill={"none"} id="prefix__사각형_8219" d="M0 0H24V24H0z" data-name="사각형 8219" />
                </g>
                <g id="prefix__그룹_8217" data-name="그룹 8217">
                    <path
                        fill={props.color ? props.color : "#1e8ffa"}
                        id="prefix__패스_7710"
                        d="M17 11V5a2.006 2.006 0 0 0-2-2H9a2.006 2.006 0 0 0-2 2v2H5a2.006 2.006 0 0 0-2 2v10a2.006 2.006 0 0 0 2 2h5a1 1 0 0 0 1-1v-3h2v3a1 1 0 0 0 1 1h5a2.006 2.006 0 0 0 2-2v-6a2.006 2.006 0 0 0-2-2zM7 19H5v-2h2zm0-4H5v-2h2zm0-4H5V9h2zm4 4H9v-2h2zm0-4H9V9h2zm0-4H9V5h2zm4 8h-2v-2h2zm0-4h-2V9h2zm0-4h-2V5h2zm4 12h-2v-2h2zm0-4h-2v-2h2z"
                        data-name="패스 7710"
                    />
                </g>
            </svg>
        );
    } else if (props.type === "userperson") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__group-24px"
                width={props.w ? props.w : "40.915"}
                height={props.h ? props.h : "40.915"}
                viewBox="0 0 40.915 40.915"
            >
                <path id="prefix__패스_10842" d="M0 0h40.915v40.915H0z" data-name="패스 10842" fill={"none"} />
                <path
                    fill={props.color ? props.color : "#6980a6"}
                    id="prefix__패스_10843"
                    d="M26.572 15.229a5.114 5.114 0 1 0-5.114-5.114 5.093 5.093 0 0 0 5.114 5.114zm-13.638 0a5.114 5.114 0 1 0-5.114-5.114 5.093 5.093 0 0 0 5.114 5.114zm0 3.41C8.961 18.638 1 20.633 1 24.605v2.557a1.71 1.71 0 0 0 1.7 1.7h20.462a1.71 1.71 0 0 0 1.7-1.7v-2.557c.005-3.972-7.956-5.967-11.928-5.967zm13.638 0c-.494 0-1.057.034-1.654.085.034.017.051.051.068.068a7.091 7.091 0 0 1 3.29 5.813v2.557a5.122 5.122 0 0 1-.307 1.7H36.8a1.71 1.71 0 0 0 1.7-1.7v-2.557c.005-3.972-7.956-5.967-11.928-5.967z"
                    data-name="패스 10843"
                    transform="translate(.705 3.524)"
                />
            </svg>
        );
    } else if (props.type === "flag") {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="prefix__flag-24px"
                width={props.w ? props.w : "35.914"}
                height={props.h ? props.h : "35.914"}
                viewBox="0 0 35.914 35.914"
            >
                <path id="prefix__패스_10840" d="M0 0h35.914v35.914H0z" data-name="패스 10840" fill={"none"} />
                <path
                    fill={props.color ? props.color : "#6980a6"}
                    id="prefix__패스_10841"
                    d="M21.176 7.3l-.413-1.98A1.7 1.7 0 0 0 19.077 4H6.721A1.692 1.692 0 0 0 5 5.65v24.757a1.722 1.722 0 0 0 3.442 0V20.5h9.637l.413 1.981a1.684 1.684 0 0 0 1.686 1.32h8.914a1.692 1.692 0 0 0 1.721-1.65v-13.2A1.692 1.692 0 0 0 29.092 7.3z"
                    data-name="패스 10841"
                    transform="translate(.612 .489)"
                />
            </svg>
        );
    }
};

export default Mark;
