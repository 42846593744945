export const validateRegisNum = value => {
    // 이메일 유효성 검사
    // let re = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4][0-9]{6}$/g;
    // let re2 = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))[1-4][0-9]{6}$/g;
    // if (re.test(value) || re2.test(value)) {
    if (!isNaN(Number(value) * 1)) {
        return true;
    } else {
        return false;
    }
};

export default validateRegisNum;
