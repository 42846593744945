import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";

import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";
import Select from "react-select"
import Modal01 from "components/Modal/Modal01";
import RadioboxDetail from "components/List/RadioboxDetail";

import { getFitness } from "module/userModule";

const ConfirmFitness = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.contract);
    const personal = useSelector(state => state.contract.isPersonal);

    const [btndisabled, setBtndisabled] = useState(true);
    const [modalOpen1, setModalOpen1] = useState(false);
    const [txtErr, setTxtErr] = useState(false);
    const [value, setValue] = useState(false);

    /* 전문금융소비자 해당여부 */
    const [CRE, setCRE] = useState("");
    const CRE_OPTIONS = [
        { value_S1: "S1_CRE_CK1", value_S2: "S2_CRE_CK1", label: "전문 금융소비자" },
        { value_S1: "S1_CRE_CK2", value_S2: "S2_CRE_CK2", label: "일반 금융소비자" }
    ];

    /* 업력(법인) */
    const [S2_TERM, setS2_TERM] = useState("");
    /* 연령(개인) */
    const [S1_AGE, setS1_AGE] = useState("");
    const [ageErr, setAgeErr] = useState(false);

    /* 대출용도 */
    const [USE, setUSE] = useState("");
    const [USE_ETC_ABLE, setUSE_ETC_ABLE] = useState(false); // 대출 용도 기타 텍스트 활성화
    const [USE_ETC, setUSE_ETC] = useState(""); // 대출 용도 기타 텍스트
    const USE_OPTIONS = {
        S1: [
            { value: "S1_USE_CK1", label: "가계자금" },
            { value: "S1_USE_CK2", label: "사업자금" },
            { value: "S1_USE_CK3", label: "주택자금" },
            { value: "S1_USE_CK4", label: "경조자금" },
            { value: "S1_USE_CK5", label: "교육비" },
            { value: "S1_USE_CK6", label: "의료비" },
            { value: "S1_USE_CK7", label: "타기관 대출금 상환" },
            { value: "S1_USE_CK8", label: "기타" },
        ],
        S2: [
            { value: "S2_USE_CK1", label: "운전자금" },
            { value: "S2_USE_CK2", label: "시설자금" },
            { value: "S2_USE_CK3", label: "기타" },
        ]
    };

    /* 개인 : 보유자산(ASS), 연간소득(INCOME), 부채(DEPT)  */
    /* 법인 : 보유자산(ASS), 매출액(INCOME), 순이익(GAIN), 부채(DEPT) */
    const [ASS, setASS] = useState("");
    const [INCOME, setINCOME] = useState("");
    const [GAIN, setGAIN] = useState("");
    const [DEPT, setDEPT] = useState("");
    const COM_OPTIONS = {
        S1: [
            { value_ASS: "S1_ASS_CK1", value_INCOME: "SI_INCOME_CK1", value_DEPT: "SI_DEPT_CK1", label: "3,000만원 미만" },
            { value_ASS: "S1_ASS_CK2", value_INCOME: "SI_INCOME_CK2", value_DEPT: "SI_DEPT_CK2", label: "3,000만원 이상 1억원 미만" },
            { value_ASS: "S1_ASS_CK3", value_INCOME: "SI_INCOME_CK3", value_DEPT: "SI_DEPT_CK3", label: "1억원 이상" }
        ],
        S2: [
            { value_ASS: "S2_ASS_CK1", value_INCOME: "S2_INCOME_CK1", value_GAIN: "S2_GAIN_CK1", value_DEPT: "S2_DEPT_CK1", label: "1억 미만" },
            { value_ASS: "S2_ASS_CK2", value_INCOME: "S2_INCOME_CK2", value_GAIN: "S2_GAIN_CK2", value_DEPT: "S2_DEPT_CK2", label: "1억 이상 10억미만" },
            { value_ASS: "S2_ASS_CK3", value_INCOME: "S2_INCOME_CK3", value_GAIN: "S2_GAIN_CK3", value_DEPT: "S2_DEPT_CK3", label: "10억 이상" }
        ]
    };

    /* 변제계획 */
    const [PLAN, setPLAN] = useState("");
    const [PLAN_ETC_ABLE, setPLAN_ETC_ABLE] = useState(false); // 변제 계획 기타 활성화
    const [PLAN_ETC_TX, setPLAN_ETC_TX] = useState(""); // 변제 계획 기타 텍스트
    const PLAN_OPTIONS = [
        { value_S1: "S1_PLAN_05", value_S2: "S2_PLAN_05", label: "매월 5일" },
        { value_S1: "S1_PLAN_10", value_S2: "S2_PLAN_10", label: "매월 10일" },
        { value_S1: "S1_PLAN_15", value_S2: "S2_PLAN_15", label: "매월 15일" },
        { value_S1: "S1_PLAN_20", value_S2: "S2_PLAN_20", label: "매월 20일" },
        { value_S1: "S1_PLAN_25", value_S2: "S2_PLAN_25", label: "매월 25일" },
        { value_S1: "S1_PLAN_30", value_S2: "S2_PLAN_30", label: "매월 30일" },
        { value_S1: "S1_PLAN_ETC", value_S2: "S2_PLAN_ETC", label: "기타" }
    ];

    /* 고정지출 */
    const [FIX, setFIX] = useState("");
    const FIX_OPTIONS = {
        S1: [
            { value: "S1_FIX_CK1", label: "연간소득의 30% 미만" },
            { value: "S1_FIX_CK2", label: "연간소득의 30% 이상 70% 미만" },
            { value: "S1_FIX_CK3", label: "연간소득의 70% 이상" }
        ],
        S2: [
            { value: "S2_FIX_CK1", label: "연간매출의 50%미만" },
            { value: "S2_FIX_CK2", label: "연간매출의 50%이상 70% 미만" },
            { value: "S2_FIX_CK3", label: "연간매출의 70%미만 이상" }
        ]
    };

    /* 연체정보 */
    const [OVER, setOVER] = useState("");
    const OVER_OPTIONS = {
        S1: [
            { value: "S1_OVER_N", label: "연체정보 없음" },
            { value: "S1_OVER_Y", label: "연체정보 있음" }
        ],
        S2: [
            { value: "S2_OVER_N", label: "연체정보 없음" },
            { value: "S2_OVER_Y", label: "연체정보 있음" }
        ]
    };

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }

        document.getElementById("agreeN").checked = true;

    }, []);

    useEffect( () => {
        if(USE === "S1_USE_CK8" || USE === "S2_USE_CK3"){
            setUSE_ETC_ABLE(true);
        } else{
            setUSE_ETC_ABLE(false);
        }
        if(PLAN === "S1_PLAN_ETC" || PLAN === "S2_PLAN_ETC"){
            setPLAN_ETC_ABLE(true);
        } else{
            setPLAN_ETC_ABLE(false);
        }
    }, [USE, PLAN]);

    useEffect(() => {
        if (personal){
            if (CRE !== "" && USE !== "" && ASS !== "" && INCOME !== "" && DEPT !== "" && PLAN !== "" && FIX !== ""){
                if ((USE === "S1_USE_CK8" && USE_ETC === "") || (PLAN === "S1_PLAN_ETC" && PLAN_ETC_TX === "")) {
                    setBtndisabled(true);
                } else {
                    if (value) {
                        setBtndisabled(false);
                    } else {
                        setBtndisabled(true);
                    }
                }
            } else {
                setBtndisabled(true);
            }
        } else {
            if (CRE !== "" && S2_TERM !== ""  && USE !== "" && ASS !== "" &&
                INCOME !== "" && GAIN !== "" && DEPT !== "" && PLAN !== "" && FIX !== ""){
                if ((USE === "S2_USE_CK3" && USE_ETC === "") || (PLAN === "S2_PLAN_ETC" && PLAN_ETC_TX === "")) {
                    setBtndisabled(true);
                } else {
                    if (value) {
                        setBtndisabled(false);
                    } else {
                        setBtndisabled(true);
                    }
                }
            } else {
                setBtndisabled(true);
            }
        }
    });

    useEffect(() => {
        if (!USE_ETC_ABLE && USE_ETC !== "") {
            setUSE_ETC("");
        }
    }, [USE_ETC_ABLE]);

    const selectStyles = {
        control: (provided) => ({
            ...provided,
            border: "0",
            fontSize: "13px",
            boxShadow: "none",
            "input": {
                textIndent: "-99999999em"
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: 0
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.08)" : undefined,
            color: "rgba(0, 0, 0, 0.87)",
            height: "48px",
            lineHeight: "48px",
            padding: "0 0 0 16px",
            fontSize: "14px"
        }),
        menu: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: "33px",
            borderRadius: "4px",
            boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.14)"
        }),
        placeholder: () => ({
            color: "#cccccc",
        }),
    };
    
    useEffect(() => {
        if (!PLAN_ETC_ABLE) {
            setPLAN_ETC_TX("");
        }
    }, [PLAN_ETC_ABLE]);

    const handleOnClickBtn = () => {
        if (!btndisabled) {
            let payload = {
                CRE_CK: CRE,
                USE_CK: USE,
                USE_ETC: USE_ETC,
                ASS_CK: ASS,
                INCOME: INCOME,
                DEPT_CK: DEPT,
                PLAN: PLAN,
                PLAN_ETC: PLAN_ETC_TX,
                FIX_CK: FIX,
                S2_TERM: S2_TERM,
                GAIN_CK: GAIN,
                OVER: OVER,
                S1_POINT: data && data?.length > 0 && data?.filter(x => x?.id === "S1_POINT" && x?.value)[0]?.value,
                AGREE: value ? (personal ? "S1_AGREE_Y" : "S2_AGREE_Y") : (personal ? "S1_AGREE_N" : "S2_AGREE_N")
            };
            dispatch(getFitness.post.request(payload));
            props.history.push("/confirm/productDesc");
        } else {
            if (USE_ETC_ABLE && USE_ETC === "") {
                setTxtErr(true);
            } else {
                setModalOpen1(true);
            }
        }
    };

    const handleOnChangeRadio = (v) => {
        setValue(v === "Y");
    }

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="적합성, 적정성의 원칙 확인서">
                            <p>고객님, 저희 롯데오토리스를 이용해 주셔서 감사합니다.</p>
                            <p>본 확인서는 「금융소비자 보호에 관한 법률」에 의거하여 고객님의 재산상황, 금융상품 취득·처분 경험 등을 파악하여, 고객님께서
                                신청하신 상품이 고객님의 상황에 적합·적정한지 여부를 확인하기 위해 활용됩니다.</p>
                            <p>아래 체크리스트에 고객님의 상황에 부합하거나 가장 가까운 항목을 정확히 선택하여 주시기 바랍니다.</p>
                        </MainTitle>

                        <div className={styles.contBox}>
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="체크리스트" isSpecial descYn="N" styles={{ paddingBottom: "8px" }} />
                                <p>전문금융소비자 : 국가기관, 한국은행, 금융회사, 상시근로자 5인 이상 법인,조합 단체, 대출 모집인, SPC 등이 해당</p>
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                    <tr>
                                        <td>전문금융소비자<br/>해당여부</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={CRE_OPTIONS}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {
                                                    {personal ? setCRE(e.value_S1) : setCRE(e.value_S2)}
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {personal ? (
                                        <tr>
                                            <td>연령</td>
                                            <td>
                                                {data && data?.length > 0 && data?.filter(x => x?.id === "S1_AGE" && x?.value)?.length > 0 ? (
                                                    <span>{data && data?.filter(x => x?.id === "S1_AGE" && x?.value)[0]?.value}세</span>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td>업력</td>
                                            <td>
                                                <span>
                                                    <input type="text" className={styles.basic}
                                                        style={{ width: "30px", textAlign: "center", paddingBottom: "0", borderBottom: "1px solid #333" }}
                                                        value={S2_TERM}
                                                        onChange={(e) => {
                                                            setS2_TERM((e.target.value).replace(/[^0-9]/g, ""))
                                                        }}
                                                    />
                                                    {" 년"}
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>대출 용도</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? USE_OPTIONS.S1 : (!personal ? USE_OPTIONS.S2 : undefined )}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setUSE(e.value)}}
                                            />
                                            {USE_ETC_ABLE && (
                                                <input type="text" className={[styles.basic, txtErr ? styles.err : undefined].join(" ")} placeholder={"기타 항목을 입력하세요."}
                                                   value={USE_ETC}
                                                   onChange={(e) => {
                                                       setUSE_ETC(e.target.value);
                                                       if (e.target.value !== "" && txtErr) setTxtErr(false);
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>보유 자산</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? COM_OPTIONS.S1 : (!personal ? COM_OPTIONS.S2 : undefined )}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setASS(e.value_ASS)} }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {personal ? "연간 소득" : "매출액"}
                                        </td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? COM_OPTIONS.S1 : (!personal ? COM_OPTIONS.S2 : undefined )}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setINCOME(e.value_INCOME)}}
                                            />
                                        </td>
                                    </tr>
                                    {!personal && (
                                        <tr>
                                            <td>순이익</td>
                                            <td>
                                                <Select
                                                    styles={selectStyles}
                                                    options={COM_OPTIONS.S2}
                                                    isSearchable={false}
                                                    placeholder="항목을 선택해주세요"
                                                    onChange={(e) => {setGAIN(e.value_GAIN)} }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>부채</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? COM_OPTIONS.S1 : (!personal ? COM_OPTIONS.S2 : undefined )}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setDEPT(e.value_DEPT)}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>연체 여부</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? OVER_OPTIONS.S1 : OVER_OPTIONS.S2}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setOVER(e.value)}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{personal ? "신용 점수" : "신용 등급"}</td>
                                        <td>
                                            {data && data?.length > 0 && data?.filter(x => x?.id === "S1_POINT" && x?.value)?.length > 0 ? (
                                                <span style={{color: "#fb0f10"}}>{data && data?.filter(x => x?.id === "S1_POINT" && x?.value)[0]?.value}</span>
                                            ) : (
                                                <span style={{color: "#fb0f10"}}>-</span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>변제 계획</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={PLAN_OPTIONS}
                                                maxMenuHeight={"160px"}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {
                                                    {personal ? setPLAN(e.value_S1) : setPLAN(e.value_S2)}
                                                }}
                                            />
                                            {PLAN_ETC_ABLE ? (
                                                <input type="text" className={styles.basic} placeholder="기타 항목을 입력하세요."
                                                       value={ PLAN_ETC_TX }
                                                       onChange={(e) => {setPLAN_ETC_TX(e.target.value)}}/>
                                            ) : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>고정 지출</td>
                                        <td>
                                            <Select
                                                styles={selectStyles}
                                                options={personal ? FIX_OPTIONS.S1 : (!personal ? FIX_OPTIONS.S2 : undefined )}
                                                menuPlacement="auto"
                                                maxMenuHeight={"160px"}
                                                isSearchable={false}
                                                placeholder="항목을 선택해주세요"
                                                onChange={(e) => {setFIX(e.value);}}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            
                            <SubTitle title={"적합성, 적정성 관련 정보"} isSpecial>
                            본인은 당사에 제공한 적합성, 적정성 관련 정보와 관련하여 다음의 사항을 확인합니다.<br /><br />
                            1. 당사에 제공한 적합성, 적정성 관련 정보는 본인의 재산상황, 용도 등의 정보를 정확히 알려드린 것입니다.<br />
                            2. 본인이 제공한 정보가 정확하지 않거나, 정보에 변경사항이 발생한 경우에는 적합성, 적정성 판단이 달라질 수 있음을 설명 받았습니다.<br />
                            3. 상기목적을 위해 본인의 개인정보를 수집, 이용, 제공하는 것에 동의합니다.
                            </SubTitle>
                            <RadioboxDetail
                                name={"agreeYn"}
                                id1={"agreeY"} value1={"Y"} lblNm1={"동의함"}
                                id2={"agreeN"} value2={"N"} lblNm2={"동의하지 않음"}
                                onChange={handleOnChangeRadio}
                                styles={{ marginBottom: "12px" }}
                            />
                            <Button text={"확인"} disabled={btndisabled}
                                    onClick={handleOnClickBtn}/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal01 open={modalOpen1} close={() => setModalOpen1(false)} header="안내">
                다음 단계로 넘어가기 위해<br/>
                모든 선택사항을 선택해주세요.
            </Modal01>
        </>
    )
}


export default ConfirmFitness;

