import React, { useEffect } from "react";
import styles from "./styles.scss";

import * as SVG from "components/SVG";

const CheckingBox = (props) => {
    useEffect(() => {
        if (document.getElementById(`No_${props.idx + 1}`) && props.isRadio) {
            document.getElementById(`No_${props.idx + 1}`).checked = true;
        }
    }, [props.idx]);

    const handleOnChangeRadio = (e) => {
        if (e.target.name === `Required${props.idx + 1}`) {
            if (e.target.id === `Yes_${props.idx + 1}`) {
                document.getElementById(`Yes_${props.idx + 1}`).checked = true;
            } else {
                document.getElementById(`No_${props.idx + 1}`).checked = true;
            }
            props.handleReqItem && props.handleReqItem(props.idx);
        }
    };

    const handleOnChangeCheck = () => {
        if (props.handleReqItem) {
            props.handleReqItem(props.idx);
        }
    };

    return (
        <div className={[styles.boxWrap, props.isRadio ? undefined : styles.check, props.checked ? styles.checked : undefined].join(" ")}>
            {props.isTitle && (
                <div className={styles.title}>{props.title}</div>
            )}
            <div className={styles.body}>
                {!props.isRadio && (
                    <div className={styles.check} onClick={handleOnChangeCheck}>
                        <SVG.Check checked={props.checked} />
                    </div>
                )}
                <div className={styles.script}>{props.script}</div>
                {props.isRadio && (
                    <div className={styles.radioContainer}>
                        <div className={styles.radioWrap}>
                            <input type="radio" name={`Required${props.idx + 1}`} id={`Yes_${props.idx + 1}`} onChange={handleOnChangeRadio}></input>
                            <label htmlFor={`Yes_${props.idx + 1}`}>예</label>
                        </div>

                        <div className={styles.radioWrap}>
                            <input type="radio" name={`Required${props.idx + 1}`} id={`No_${props.idx + 1}`} onChange={handleOnChangeRadio}></input>
                            <label htmlFor={`No_${props.idx + 1}`}>아니오</label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CheckingBox;