import React from 'react';
import styles from "./styles.scss";


const Modal01 = (props) => {

    return (
        <>
            <div className={props.open ? [styles.modalWrap, styles.modalOpen].join(' ') : styles.modalWrap}>
                {props.open ? (
                    <section style={props.width}>
                        <header>
                            {props.header}
                        </header>
                        <div style={props.style}>
                            {props.children}
                        </div>
                        <footer>
                            <button className={styles.btnSubmit} onClick={props.close}>확인</button>
                        </footer>
                    </section>
                ) : null}
            </div>
        </>
    )
}

export default Modal01;