export const validateEmail = email => {
    // 이메일 유효성 검사
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{1})?)$/i;
    if (email === "" || email === undefined) {
        return false;
    } else {
        if (re.test(email)) {
            return true;
        } else {
            return false;
        }
    }
};

export const PasswordCheck = password => {
    if (password.length > 0) {
        if (password.length < 8) {
            return "length";
        } else if (password.search(/[0-9]/g) === -1) {
            return "comb";
        } else if (password.search(/[a-z]/gi) === -1) {
            return "comb";
        } else if (password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi) === -1) {
            return "comb";
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const StrengthPassword = password => {
    if (password.length > 7 && password.length < 11) {
        return "normal";
    } else if (password.length > 10 && password.length < 15) {
        return "normal";
    } else if (password.length > 10 && password.length < 15 && (password.match(/[a-z]/) === null || password.match(/[A-Z]/) === null)) {
        return "normal";
    } else if (password.length > 14 && (password.match(/[a-z]/) === null || password.match(/[A-Z]/) === null)) {
        return "normal";
    } else if (password.length > 14 && password.match(/[a-z]/) !== null && password.match(/[A-Z]/) !== null) {
        return "good";
    }
};

export default validateEmail;
