import React from "react";
import styles from "./styles.scss";

const SubTitle = (props) => {

    return (
        <div className={styles.subTitle} style={props.styles}>
            {props.title && (
                <div className={styles.subTitleWrap}>
                    <label>{props.isSpecial ? <em style={{color: "#DA291C"}}>*</em> : null} {props.title}</label>
                </div>
            )}
            {props.descYn !== 'N' ? (
                <div className={styles.subTitleDescWrap} style={props.title ? undefined : { paddingTop: 0 }}>
                    <p>
                        {props.children}
                    </p>
                </div>
            ) : null}
        </div>
    )
}

export default SubTitle;