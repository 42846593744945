import React, { useEffect, useState } from "react";
import { useSelector, useDispatch,batch } from "react-redux";
import styles from "./styles.scss";
import Select from "react-select"

import Header from "components/Layout/Header";
import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";
import Modal01 from "components/Modal/Modal01";
import * as SVG from "components/SVG";

import { setCDD } from "module/userModule";
import updateMappingListContent from "functions/updateMappingListContent";
import { mappingAction } from "module/contractModule";


const SuccessorCDD = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.contract);
    const mapping = useSelector((state) => state.contract.mapping);
    
    const [isMobile, setIsMobile] = useState(false);
    const [owner, setOwner] = useState(1);
    const [btndisabled, setBtndisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const [CDD1_J_1KFREPRE, setCDD1_J_1KFREPRE] = useState("");
    const [CDD1_J_NATIONAL, setCDD1_J_NATIONAL] = useState("");
    const [CDD1_J_NATIONALC, setCDD1_J_NATIONALC] = useState("");
    const [CDD1_J_BIRTH, setCDD1_J_BIRTH] = useState("");
    const [CDD1_J_TELF2, setCDD1_J_TELF2] = useState("");
    const [CDD1_J_HDTAD, setCDD1_J_HDTAD] = useState("");

    const [CDD1_O_NAMEK, setCDD1_O_NAMEK] = useState("");
    const [CDD1_O_NAMEE, setCDD1_O_NAMEE] = useState("");
    const [CDD1_O_BIRTH, setCDD1_O_BIRTH] = useState("");
    const [CDD1_O_NATIONAL, setCDD1_O_NATIONAL] = useState("");
    const [CDD1_O_NATIONALC, setCDD1_O_NATIONALC] = useState("");
    const [CDD1_O_RATE, setCDD1_O_RATE] = useState("");

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/successor/entry/a");
        }
        
        let w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;

        if (w < 768) {
            setIsMobile(true);
        }
    }, []);

    useEffect(() => {
        if (owner === 3) {
            setCDD1_O_BIRTH("");
            setCDD1_O_RATE("");
        }
    }, [owner]);

    useEffect(() => {
        if (CDD1_J_1KFREPRE !== "" && CDD1_J_NATIONAL !== "" && CDD1_J_BIRTH !== "" && CDD1_J_TELF2 !== "" &&
            CDD1_J_HDTAD !== "" && CDD1_O_NAMEK !== "" && CDD1_O_NATIONAL !== "" &&
            (owner === 3 || (CDD1_O_BIRTH !== "" && CDD1_O_RATE !== ""))) {
                setBtndisabled(false);
            } else {
                if (!btndisabled) setBtndisabled(true);
            }
    }, [
        CDD1_J_1KFREPRE,
        CDD1_J_NATIONAL,
        CDD1_J_BIRTH,
        CDD1_J_TELF2,
        CDD1_J_HDTAD,
        CDD1_O_NAMEK,
        CDD1_O_NAMEE,
        CDD1_O_BIRTH,
        CDD1_O_NATIONAL,
        CDD1_O_RATE,
        owner
    ]);

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "0",
            fontSize: "13px",
            boxShadow: "none",
            backgroundColor: state.selectProps.backgroundColor,
            "input":{
                textIndent: "-99999999em"
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "0 8px"
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: 0
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.08)" : undefined,
            color: state.isSelected ? "" : "0",
            height: "48px",
            lineHeight: "48px",
            padding: "0 0 0 16px",
            fontSize: "14px"
        }),
        menu: (provided) => ({
            ...provided,
            position: "absolute",
            top: "33px",
            borderRadius: "4px",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
        }),
        placeholder: () => ({
            color: "#cccccc"
        }),
    };

    const national = [
        { value: "KR", label: "한국" },
        { value: "AD", label: "안도라" },
        { value: "AE", label: "아랍에미리트" },
        { value: "AF", label: "아프가니스탄" },
        { value: "AG", label: "앤티가 바부다" },
        { value: "AI", label: "앵귈라" },
        { value: "AL", label: "알바니아" },
        { value: "AM", label: "아르메니아" },
        { value: "AN", label: "Dutch Antilles" },
        { value: "AO", label: "앙골라" },
        { value: "AQ", label: "남극 대륙" },
        { value: "AR", label: "아르헨티나" },
        { value: "AS", label: "미국령 사모아" },
        { value: "AT", label: "오스트리아" },
        { value: "AU", label: "오스트레일리아" },
        { value: "AW", label: "아루바" },
        { value: "AZ", label: "아제르바이잔" },
        { value: "BA", label: "Bosnia-Herz." },
        { value: "BB", label: "바베이도스" },
        { value: "BD", label: "방글라데시" },
        { value: "BE", label: "벨기에" },
        { value: "BF", label: "부르키나파소" },
        { value: "BG", label: "불가리아" },
        { value: "BH", label: "바레인" },
        { value: "BI", label: "부룬디" },
        { value: "BJ", label: "베냉" },
        { value: "BL", label: "파랑" },
        { value: "BM", label: "버뮤다" },
        { value: "BN", label: "브루나이" },
        { value: "BO", label: "볼리비아" },
        { value: "BR", label: "브라질" },
        { value: "BS", label: "바하마" },
        { value: "BT", label: "부탄" },
        { value: "BV", label: "부베이 섬" },
        { value: "BW", label: "보츠와나" },
        { value: "BY", label: "벨로루시" },
        { value: "BZ", label: "벨리즈" },
        { value: "CA", label: "캐나다" },
        { value: "CC", label: "코코넛 섬" },
        { value: "CD", label: "콩고민주공화국" },
        { value: "CF", label: "CAR" },
        { value: "CG", label: "콩고" },
        { value: "CH", label: "스위스" },
        { value: "CI", label: "코트디부아르" },
        { value: "CK", label: "쿡 제도" },
        { value: "CL", label: "칠레" },
        { value: "CM", label: "카메룬" },
        { value: "CN", label: "중국" },
        { value: "CO", label: "콜롬비아" },
        { value: "CR", label: "코스타리카" },
        { value: "CS", label: "Serbia/Monten." },
        { value: "CU", label: "쿠바" },
        { value: "CV", label: "카보베르데" },
        { value: "CX", label: "크리스마스 섬" },
        { value: "CY", label: "키프로스" },
        { value: "CZ", label: "체코" },
        { value: "DE", label: "독일" },
        { value: "DJ", label: "지부티" },
        { value: "DK", label: "덴마크" },
        { value: "DM", label: "도미니카" },
        { value: "DO", label: "도미니카 공화국" },
        { value: "DZ", label: "알제리" },
        { value: "EC", label: "에콰도르" },
        { value: "EE", label: "에스토니아" },
        { value: "EG", label: "이집트" },
        { value: "EH", label: "서사하라" },
        { value: "ER", label: "에리트레아" },
        { value: "ES", label: "스페인" },
        { value: "ET", label: "에티오피아" },
        { value: "EU", label: "유럽연합" },
        { value: "FI", label: "핀란드" },
        { value: "FJ", label: "피지" },
        { value: "FK", label: "포클랜드 제도" },
        { value: "FM", label: "미크로네시아" },
        { value: "FO", label: "페로 제도" },
        { value: "FR", label: "프랑스" },
        { value: "GA", label: "가봉" },
        { value: "GB", label: "영국" },
        { value: "GD", label: "그레나다" },
        { value: "GE", label: "그루지야" },
        { value: "GF", label: "프랑스령 기아나" },
        { value: "GH", label: "가나" },
        { value: "GI", label: "지브롤터" },
        { value: "GL", label: "그린란드" },
        { value: "GM", label: "감비아" },
        { value: "GN", label: "기니" },
        { value: "GP", label: "과들루프" },
        { value: "GQ", label: "적도 기니" },
        { value: "GR", label: "그리스" },
        { value: "GS", label: "S. Sandwich Ins" },
        { value: "GT", label: "과테말라" },
        { value: "GU", label: "괌" },
        { value: "GW", label: "기니비사우" },
        { value: "GY", label: "가이아나" },
        { value: "HK", label: "홍콩" },
        { value: "HM", label: "Heard/McDon.Isl" },
        { value: "HN", label: "온두라스" },
        { value: "HR", label: "크로아티아" },
        { value: "HT", label: "아이티" },
        { value: "HU", label: "헝가리" },
        { value: "ID", label: "인도네시아" },
        { value: "IE", label: "아일랜드" },
        { value: "IL", label: "이스라엘" },
        { value: "IN", label: "인도" },
        { value: "IO", label: "Brit.Ind.Oc.Ter" },
        { value: "IQ", label: "이라크" },
        { value: "IR", label: "이란" },
        { value: "IS", label: "아이슬란드" },
        { value: "IT", label: "이탈리아" },
        { value: "JM", label: "자메이카" },
        { value: "JO", label: "요르단" },
        { value: "JP", label: "일본" },
        { value: "KE", label: "케냐" },
        { value: "KG", label: "키르기스스탄" },
        { value: "KH", label: "캄보디아" },
        { value: "KI", label: "키리바시" },
        { value: "KM", label: "코모로" },
        { value: "KN", label: "St Kitts&Nevis" },
        { value: "KP", label: "북한" },
        { value: "KW", label: "쿠웨이트" },
        { value: "KY", label: "케이맨 제도" },
        { value: "KZ", label: "카자흐스탄" },
        { value: "LA", label: "라오스" },
        { value: "LB", label: "레바논" },
        { value: "LC", label: "세인트 루시아" },
        { value: "LI", label: "리히텐슈타인" },
        { value: "LK", label: "스리랑카" },
        { value: "LR", label: "라이베리아" },
        { value: "LS", label: "레소토" },
        { value: "LT", label: "리투아니아" },
        { value: "LU", label: "룩셈부르크" },
        { value: "LV", label: "라트비아" },
        { value: "LY", label: "리비아" },
        { value: "MA", label: "모로코" },
        { value: "MC", label: "모나코" },
        { value: "MD", label: "몰도바" },
        { value: "MG", label: "마다가스카르" },
        { value: "MH", label: "마셜 제도" },
        { value: "MK", label: "마케도니아" },
        { value: "ML", label: "말리" },
        { value: "MM", label: "미얀마" },
        { value: "MN", label: "몽골" },
        { value: "MO", label: "마카오" },
        { value: "MP", label: "북마리아나 제도" },
        { value: "MQ", label: "마르티니크" },
        { value: "MR", label: "마우레타니아" },
        { value: "MS", label: "몬트세라트" },
        { value: "MT", label: "몰타" },
        { value: "MU", label: "모리셔스" },
        { value: "MV", label: "몰디브" },
        { value: "MW", label: "말라위" },
        { value: "MX", label: "멕시코" },
        { value: "MY", label: "말레이시아" },
        { value: "MZ", label: "모잠비크" },
        { value: "NA", label: "나미비아" },
        { value: "NC", label: "뉴칼레도니아" },
        { value: "NE", label: "니제르" },
        { value: "NF", label: "노퍽 섬" },
        { value: "NG", label: "나이지리아" },
        { value: "NI", label: "니카라과" },
        { value: "NL", label: "네덜란드" },
        { value: "NO", label: "노르웨이" },
        { value: "NP", label: "네팔" },
        { value: "NR", label: "나우루" },
        { value: "NT", label: "NATO" },
        { value: "NU", label: "니우에" },
        { value: "NZ", label: "뉴질랜드" },
        { value: "OM", label: "오만" },
        { value: "OR", label: "주황색" },
        { value: "PA", label: "파나마" },
        { value: "PE", label: "페루" },
        { value: "PF", label: "Frenc.Polynesia" },
        { value: "PG", label: "파푸아뉴기니" },
        { value: "PH", label: "필리핀" },
        { value: "PK", label: "파키스탄" },
        { value: "PL", label: "폴란드" },
        { value: "PM", label: "St.Pier,Miquel." },
        { value: "PN", label: "핏케언 제도" },
        { value: "PR", label: "푸에르토리코" },
        { value: "PS", label: "팔레스타인" },
        { value: "PT", label: "포르투갈" },
        { value: "PW", label: "팔라우" },
        { value: "PY", label: "파라과이" },
        { value: "QA", label: "카타르" },
        { value: "RE", label: "리유니언 섬" },
        { value: "RO", label: "루마니아" },
        { value: "RU", label: "러시아" },
        { value: "RW", label: "르완다" },
        { value: "SA", label: "사우디아라비아" },
        { value: "SB", label: "솔로몬 제도" },
        { value: "SC", label: "세이셸" },
        { value: "SD", label: "수단" },
        { value: "SE", label: "스웨덴" },
        { value: "SG", label: "싱가포르" },
        { value: "SH", label: "세인트 헬레나" },
        { value: "SI", label: "슬로베니아" },
        { value: "SJ", label: "스발바르" },
        { value: "SK", label: "슬로바키아" },
        { value: "SL", label: "시에라리온" },
        { value: "SM", label: "산마리노" },
        { value: "SN", label: "세네갈" },
        { value: "SO", label: "소말리아" },
        { value: "SR", label: "수리남" },
        { value: "ST", label: "상투메 프린시페" },
        { value: "SV", label: "엘살바도르" },
        { value: "SY", label: "시리아" },
        { value: "SZ", label: "스와질란드" },
        { value: "TC", label: "Turksh Caicosin" },
        { value: "TD", label: "차드" },
        { value: "TF", label: "프랑스남쪽영역" },
        { value: "TG", label: "토고" },
        { value: "TH", label: "태국" },
        { value: "TJ", label: "타지키스탄" },
        { value: "TK", label: "토켈라우" },
        { value: "TL", label: "동티모르" },
        { value: "TM", label: "투르크메니스탄" },
        { value: "TN", label: "튀니지" },
        { value: "TO", label: "통가" },
        { value: "TP", label: "동티모르" },
        { value: "TR", label: "터키" },
        { value: "TT", label: "Trinidad,Tobago" },
        { value: "TV", label: "투발루" },
        { value: "TW", label: "대만" },
        { value: "TZ", label: "탄자니아" },
        { value: "UA", label: "우크라이나" },
        { value: "UG", label: "우간다" },
        { value: "UM", label: "소수 외부 제도" },
        { value: "UN", label: "UN" },
        { value: "US", label: "미국" },
        { value: "UY", label: "우르과이" },
        { value: "UZ", label: "우즈베키스탄" },
        { value: "VA", label: "바티칸" },
        { value: "VC", label: "세인트 빈센트" },
        { value: "VE", label: "베네수엘라" },
        { value: "VG", label: "Brit.Virgin Is." },
        { value: "VI", label: "Amer.Virgin Is." },
        { value: "VN", label: "베트남" },
        { value: "VU", label: "바누아투" },
        { value: "WF", label: "월리스 푸투나" },
        { value: "WS", label: "사모아" },
        { value: "YE", label: "예멘" },
        { value: "YT", label: "마요트" },
        { value: "ZA", label: "South Africa" },
        { value: "ZM", label: "잠비아" },
        { value: "ZW", label: "짐바브웨" },
    ];

    const handleOnClickBtnSubmit = () => {
        if (btndisabled) {
            setModalOpen(true);
        } else {
            dispatch(setCDD.post.request({
                CDD1_J_1KFREPRE: CDD1_J_1KFREPRE,
                CDD1_J_NATIONAL: CDD1_J_NATIONAL,
                CDD1_J_NATIONALC: CDD1_J_NATIONALC,
                CDD1_J_BIRTH: CDD1_J_BIRTH,
                CDD1_J_TELF2: CDD1_J_TELF2,
                CDD1_J_HDTAD: CDD1_J_HDTAD,
                CDD1_O_NAMEK: CDD1_O_NAMEK,
                CDD1_O_NAMEE: CDD1_O_NAMEE,
                CDD1_O_BIRTH: CDD1_O_BIRTH,
                CDD1_O_NATIONAL: CDD1_O_NATIONAL,
                CDD1_O_NATIONALC: CDD1_O_NATIONALC,
                CDD1_O_RATE: CDD1_O_RATE,
                owner: owner
            }));

            


            batch(()=>{
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_1KFREPRE", CDD1_J_1KFREPRE)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_NATIONAL", CDD1_J_NATIONAL)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_NATIONALC", CDD1_J_NATIONALC)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_BIRTH", CDD1_J_BIRTH)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_TELF2", CDD1_J_TELF2)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_J_HDTAD", CDD1_J_HDTAD)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_NAMEK", CDD1_O_NAMEK)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_NAMEE", CDD1_O_NAMEE)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_BIRTH", CDD1_O_BIRTH)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_NATIONAL", CDD1_O_NATIONAL)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_NATIONALC", CDD1_O_NATIONALC)));
                dispatch(mappingAction.post.request(updateMappingListContent(mapping, "CDD1_O_RATE", CDD1_O_RATE)));
            })
            console.log("batch dispatch success ");
            props.history.push("/successor/cms/apply");
        }
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>


                        <MainTitle mainTitle="고객확인서(CDD)">
                            <p>「특정금융거래정보의 보고 및 이용 등에 관한 법률」 및 「공증 등 협박목적 및 대량살상무기확산을 위한 자금조달행위의 금지에 관한 법률」에 따라 고객이 당사와 거래시에 아래의 내용을 기재하셔야 하며 제공하신 정보는 동법에 의거 용도 이외의 목적으로 사용되지 않습니다.</p>
                            <p>금융기관은 정보확인 및 검증을 위해 문서로 자료제출을 요청할 수 있으며, 이를 거부하거나 검증이 불가능한 경우 금융거래가 제한되거나 거절될 수 있습니다.</p>
                        </MainTitle>
                        <div className={styles.contents}>
                            <SubTitle title="법인 대표자 정보" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc" }} />
                            <div className={styles.tableWrap}>
                                <div className={[styles.row, styles.two, styles.web].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"성명"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"성명을 입력해주세요."}
                                                value={CDD1_J_1KFREPRE}
                                                onChange={e => setCDD1_J_1KFREPRE(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"생년월일"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"생년월일을 입력해주세요."}
                                                value={CDD1_J_BIRTH}
                                                onChange={e => setCDD1_J_BIRTH(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.mobile].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"성명"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"성명을 입력해주세요."}
                                                value={CDD1_J_1KFREPRE}
                                                onChange={e => setCDD1_J_1KFREPRE(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.mobile].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"생년월일"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"생년월일을 입력해주세요."}
                                                value={CDD1_J_BIRTH}
                                                onChange={e => setCDD1_J_BIRTH(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"연락처"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"연락처를 입력해주세요."}
                                                value={CDD1_J_TELF2}
                                                onChange={e => setCDD1_J_TELF2(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"국적"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <Select
                                                name="national"
                                                options={national}
                                                styles={selectStyles}
                                                isSearchable={false}
                                                placeholder="국가를 선택해주세요."
                                                onChange={e => {
                                                    setCDD1_J_NATIONAL(e.label);
                                                    setCDD1_J_NATIONALC(e.value);
                                                }}
                                                maxMenuHeight={"160px"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"주소"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"주소를 입력해주세요."}
                                                value={CDD1_J_HDTAD}
                                                onChange={e => setCDD1_J_HDTAD(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SubTitle title="법인의 실소유자 확인" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc" }} />
                            <div className={styles.tableWrap}>
                                <div className={[styles.row, styles.check].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.text}>
                                            <div className={styles.svg} onClick={() => setOwner(1)}>
                                                <SVG.Radio checked={owner === 1} />
                                            </div>
                                            <p onClick={() => setOwner(1)}>{"①  25% 이상의 지분을 소유한 자가 있는 경우"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.check].join(" ")}>
                                    <div className={[styles.cellWrap, styles.two].join(" ")}>
                                        <div className={styles.text}>
                                            <div className={styles.svg} onClick={() => setOwner(2)}>
                                                <SVG.Radio checked={owner === 2} />
                                            </div>
                                            <p onClick={() => setOwner(2)}>{"②  ①에 해당되는 자가 없는 경우"}</p>
                                        </div>
                                        <div className={styles.text}>
                                            <p>{"1) 최대 주주"}</p>
                                        </div>
                                        <div className={styles.text}>
                                            <p>{"2) 대표자, 임원 등의 과반수를 선임한 주주"}</p>
                                        </div>
                                        <div className={styles.text}>
                                            <p>{"3) 법인을 사실상 지배하는 실제 소유자"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.check].join(" ")}>
                                    <div className={[styles.cellWrap, styles.two].join(" ")}>
                                        <div className={styles.text}>
                                            <div className={styles.svg} onClick={() => setOwner(3)}>
                                                <SVG.Radio checked={owner === 3} />
                                            </div>
                                            <p onClick={() => setOwner(3)}>{"③  ②,①를 확인할 수 없는 경우"}</p>
                                        </div>
                                        <div className={styles.text}>
                                            <p>{"법인 또는 단체의 대표자"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SubTitle title="실제 소유자 정보" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc" }} />
                            <div className={styles.tableWrap}>
                                <div className={[styles.row, styles.two, styles.web].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={[styles.label, styles.two].join(" ")}>
                                            <div>{"성명"}</div>
                                            <div>{"한글"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"한글명을 입력해주세요."}
                                                value={CDD1_O_NAMEK}
                                                onChange={e => setCDD1_O_NAMEK(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"영문"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"영문명을 입력해주세요."}
                                                value={CDD1_O_NAMEE}
                                                onChange={e => setCDD1_O_NAMEE(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.mobile].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"한글명"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"한글명을 입력해주세요."}
                                                value={CDD1_O_NAMEK}
                                                onChange={e => setCDD1_O_NAMEK(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[styles.row, styles.mobile].join(" ")}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"영문명"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <input
                                                placeholder={"영문명을 입력해주세요."}
                                                value={CDD1_O_NAMEE}
                                                onChange={e => setCDD1_O_NAMEE(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {owner !== 3 && (
                                    <>
                                        <div className={[styles.row, styles.two, styles.web].join(" ")}>
                                            <div className={styles.cellWrap}>
                                                <div className={styles.label}>
                                                    <div>{"생년월일"}</div>
                                                </div>
                                                <div className={styles.input}>
                                                    <input
                                                        placeholder={"생년월일을 입력해주세요."}
                                                        value={CDD1_O_BIRTH}
                                                        onChange={e => setCDD1_O_BIRTH(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.cellWrap}>
                                                <div className={styles.label}>
                                                    <div>{"지분율(%)"}</div>
                                                </div>
                                                <div className={styles.input}>
                                                    <input
                                                        placeholder={"지분율을 입력해주세요."}
                                                        value={CDD1_O_RATE}
                                                        onChange={e => setCDD1_O_RATE(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={[styles.row, styles.mobile].join(" ")}>
                                            <div className={styles.cellWrap}>
                                                <div className={styles.label}>
                                                    <div>{"생년월일"}</div>
                                                </div>
                                                <div className={styles.input}>
                                                    <input
                                                        placeholder={"생년월일을 입력해주세요."}
                                                        value={CDD1_O_BIRTH}
                                                        onChange={e => setCDD1_O_BIRTH(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={[styles.row, styles.mobile].join(" ")}>
                                            <div className={styles.cellWrap}>
                                                <div className={styles.label}>
                                                    <div>{"지분율(%)"}</div>
                                                </div>
                                                <div className={styles.input}>
                                                    <input
                                                        placeholder={"지분율을 입력해주세요."}
                                                        value={CDD1_O_RATE}
                                                        onChange={e => setCDD1_O_RATE(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className={styles.row}>
                                    <div className={styles.cellWrap}>
                                        <div className={styles.label}>
                                            <div>{"국적"}</div>
                                        </div>
                                        <div className={styles.input}>
                                            <Select
                                                name="national"
                                                options={national}
                                                styles={selectStyles}
                                                isSearchable={false}
                                                placeholder="국가를 선택해주세요."
                                                onChange={e => {
                                                    setCDD1_O_NATIONAL(e.label);
                                                    setCDD1_O_NATIONALC(e.value)
                                                }}
                                                maxMenuHeight={isMobile ? "100px" : "120px"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button text={"확인"} style={{ width: "100%" }} disabled={btndisabled}
                                onClick={handleOnClickBtnSubmit}/>
                    </div>
                </div>
            </div>
            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="안내">
                모든 항목을<br/>
                필수로 입력해주세요.
            </Modal01>
        </>

    )
}


export default SuccessorCDD;

