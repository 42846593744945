import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import styles from './styles.scss';
import mainTitleStyles from "components/Title/MainTitle/styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";

import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";
import { useSelector } from "react-redux";

const SignStep1 = (props) => {
    const history = useHistory();
    const { data } = useSelector(state => state.contract);
    const estimate = useSelector(state => state.contract.estimate);

    const [custnm] = useState(decodeURI(useSelector(state => state.change?.name)));
    const [birthday] = useState(useSelector(state => state.change?.birthday));
    const [banknm] = useState(useSelector(state => state.change?.banknm));
    const [acctno] = useState(useSelector(state => state.change?.acctno));
    const [isCompany] = useState(useSelector(state => state.change?.isCompany));

    useEffect( () => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!estimate && !props.location?.state?.estimate) {
            props.history.push("/cms/change/contractnum");
        }
    }, []);
    
    // 브라우저 뒤로가기 감지
    useEffect(() => {
        let unlisten = history.listen((location) => {
            if (history.action === 'POP') {
                history.push('/cms/change/contractnum');
            }
        });

        return () => {
            unlisten();
        };
    }, [history]);
    
    const handleBtnOnClick = () => {
        if (banknm !== "" && acctno !== "" && custnm !== "" && birthday !== "") {
            props.history.push("/cms/change/contractnum");
        }
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <div className={mainTitleStyles.topInfo}>
                            <h1>
                                결제정보 확인
                            </h1>
                            <div className={mainTitleStyles.desc}>
                                <p>
                                    CMS 출금 신청이 아래 결제 정보로 완료 되었습니다.
                                </p>
                            </div>
                        </div>
                        <div className={styles.contBox}>
                            <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="결제정보" descYn="N" isSpecial/>

                                <table className={tableCommonStyles.tbl01}>
                                        <tbody>
                                        <tr>
                                            <td>은행이름</td>
                                            <td>{banknm ? banknm : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>계좌정보</td>
                                            <td>{acctno ? acctno : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>예금주명</td>
                                            <td>{custnm ? custnm : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{isCompany ? "법인번호" : "생년월일"}</td>
                                            <td>{birthday ? birthday : "-"}</td>
                                        </tr>
                                        </tbody>
                                </table>
                            </div>

                            <div className={styles.infoBox}>
                                <p>출금이체는 신청 후 5영업일 이후 가능하오니 결제일을 확인하여 주시기 바랍니다.</p>
                            </div>

                            <Button
                                style={{fontSize: "14px"}}
                                text={"확인"}
                                onClick={handleBtnOnClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SignStep1;