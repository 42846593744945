import { call, put, takeEvery } from "redux-saga/effects";
import { PASS, TOKEN } from "module/passModule";
import { initialState as passState } from "module/passModule";
import { LOGOUT } from "module/userModule";

function* PassSaga() {
     yield takeEvery(PASS.POST.REQUEST, pass);
     yield takeEvery(PASS.GET.REQUEST, setPassResponse);
     yield takeEvery(TOKEN.POST.REQUEST, setToken);
}

function _postpassAPI(payload){
    return new Promise((resolve, reject) => {
        try {
            const { token } = passState;

            let requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    state: payload.state, // auth || complete || manager
                    I_ETMTSEQ: payload.estimate
                }),
            };

            fetch(`/api/v1/niceplus/auth`, requestOptions)
                .then(response => {
                    if (response.status === 401 || response.status === 400) {
                        resolve(response.status);
                    } if (response.status === 200) {
                        resolve(response.json());
                    }
                })
                .catch(error => {
                    reject(error);
                });
        } catch(e) {
            reject(e);
        }
    });
}

function* pass(action) {
    try {
        const response = yield call(_postpassAPI, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else {
            yield put({ type: PASS.POST.SUCCESS, payload: response });
        }
    } catch (e) {
        yield put({ type: PASS.POST.FAIL });
    }
}

function* setPassResponse(payload) {
    try {
        yield put({ type: PASS.GET.SUCCESS, payload });
    } catch (e) {
        yield put({ type: PASS.GET.FAIL });
    }
}

function* setToken(payload) {
    try {
        yield put({ type: TOKEN.POST.SUCCESS, payload });
    } catch (e) {
        yield put({ type: TOKEN.POST.FAIL });
    }
}

export default PassSaga;
