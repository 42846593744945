import React from "react";
import styles from "./styles.scss";

const Button = (props) => {
    return (
        <div
            className={props.disabled ? [styles.buttonWrap, styles.buttonDisabled].join(' ') : styles.buttonWrap}
            style={props.style}
            onClick={props.onClick}
            name = {props.name}
        >
            {props.isCheck && <em className={styles.isCheckIco}></em>}
            <span>{props.text}</span>
            
        </div>
    )
};

export default Button;

