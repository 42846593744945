import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import PDFViewer from "components/PDFViewer";

const Document = (props, context) => {
    const mapping = useSelector(state => state.contract.mapping);
    const manualSigncondition = useSelector(state => state.contract.manualSigncondition);
    const coreSigncondition = useSelector(state => state.contract.coreSigncondition);
    const docsList = useSelector(state => state.contract.docsList);
    const manualDocsList = useSelector(state => state.contract.manualDocsList);

    const [fieldDataList, setFieldDataList] = useState([]);
    const [fieldDataOrigin, setFieldDataOrigin] = useState([]);

    const [sendDocs, setSendDocs] = useState([]);

    const [scale, setScale] = useState(1);
    const [isExpired, setIsExpired] = useState(false);
    const [selected, setSelected] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const [selectedSign, setSelectedSign] = useState({});
    const [receiver, setReceiver] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [attachmentList, setAttachmentList] = useState([]);
    const [room, setRoom] = useState([]);
    const [profile, setProfile] = useState({});
    const [conditionMap, setConditionMap] = useState([]);
    const [msgList, setMsgList] = useState([]);
    const [detail, setDetail] = useState({});
    const [checkAttachment, setCheckAttachment] = useState(false);
    const [isOpenInputBox, setIsOpenInputBox] = useState(false);
    const [isOpenMobileChat, setIsOpenMobileChat] = useState(false);
    const [selectedField, setSelectedField] = useState([]);
    const [setting, setSetting] = useState({});
    const [timezone, setTimezone] = useState({});
    const [isOpenMobileHistory, setIsOpenMobileHistory] = useState(false);
    const [otherConds, setOtherConds] = useState([]);
    const [addrList, setAddrList] = useState([]);
    const [clickList, setClickList] = useState([]);
    const [doneSignCount, setDoneSignCount] = useState(0);
    const [signType, setSignType] = useState(undefined);
    const [isOpenSignModal, setIsOpenSignModal] = useState(false);

    useEffect(() => {
        let w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;

        if (w < 768) {
            setIsMobile(true);
        } else if (w > 767 && w < 1024) {
            setIsTablet(true);
        }
        
        let _scale = 1;
        if (w < 451) {
            _scale = 0.6;
        } else if (w > 450 && w < 1025) {
            _scale = 0.8;
        } else if (w > 1024 && w < 1367) {
            _scale = 1;
        } else {
            _scale = 1.4;
        }
        setScale(_scale);
    }, []);

    useEffect(() => {
        async function setDocument() {
            let temp = [];
            if (props.type === "all" && docsList && docsList?.length > 0) {
                // 전체
                await docsList.map((doc) => {
                    temp.push({ docs: `/api/v1/template/docs/${doc}`, pages: 1 });
                })
            } else if (props.type === "keyPoint" || props.type === "keyPointSuccessor") {
                // 부속 서류
                temp.push({ docs: `/api/v1/template/docs/${props.document}`, pages: 1 });
            } else if (props.type === "product" && manualDocsList && manualDocsList?.length > 0) {
                // 상품 설명서
                await manualDocsList.map((doc) => {
                    temp.push({ docs: `/api/v1/template/docs/${doc}`, pages: 1 });
                })
            }
            setSendDocs(temp);
        }
        setDocument();
    }, [docsList, manualDocsList]);

    useEffect(() => {
        async function setData() {
            if (props.type === "all" && mapping?.length > 0) {
                setFieldDataList(mapping);
                setFieldDataOrigin(mapping);
            } else if (props.type === "product" && manualSigncondition?.length > 0) {
                setFieldDataList(manualSigncondition);
                setFieldDataOrigin(manualSigncondition);
            } else if (props.type === "keyPoint" && coreSigncondition?.length > 0) {
                setFieldDataList(coreSigncondition.filter(x => x?.docsId === props.document)[0]?.signcondition);
                setFieldDataOrigin(coreSigncondition.filter(x => x?.docsId === props.document)[0]?.signcondition);
            } else if (props.type === "keyPointSuccessor" && coreSigncondition?.length > 0) {
                setFieldDataList(coreSigncondition);
                setFieldDataOrigin(coreSigncondition);
            }
        }
        setData();
    }, [mapping, manualSigncondition, coreSigncondition, props.addFieldData]);

    useEffect(() => {
        if (fieldDataList && fieldDataList.length > 0) {
            if (fieldDataList.filter(x => x.require && x.coordType === "sign")?.length === fieldDataList.filter(x => x.coordType === "sign" && x.signDone).length
            && fieldDataList.filter(x => x.coordType === "text" && x.coordId.includes("popup")).length === clickList.length) {
                props.handleIsComplete && props.handleIsComplete();
            }
        }
    }, [fieldDataList]);

    const handleSelected = (selectedIdx, selected) => {
        setSelected(selected);
        setSelectedIdx(selectedIdx);
    };

    const handleOpenSignModal = inx => {
        let _fieldDataList = _.cloneDeep(fieldDataList);
        let signType = _fieldDataList[inx].coordType;
        let list = _fieldDataList.filter(x => x.coordType === signType && x.preview);

        if (list?.length > 0) {
            let image = list[0].preview;
            let signId = list[0].signId;
            if (!_fieldDataList[inx].signDone) {
                _fieldDataList[inx].signDone = true;
                _fieldDataList[inx].preview = image;
                _fieldDataList[inx].signId = signId;

                setFieldDataList(_fieldDataList);
                setSelectedField([inx]);
                setDoneSignCount(doneSignCount + 1);
                setSignType(signType);
            }
        } else {
            setIsOpenSignModal(true);
            setSelectedField([inx]);
            setSignType(signType);
        }
    };

    const RenderText = (text, context) => {
    };

    const handleToggleMobileInput = () => {
    };

    const handleMobileFieldUpdate = () => {
    };

    const handleUpdate = (data) => {
        if (Object.keys(data).includes("fieldDataList")) {
            setFieldDataList(data.fieldDataList);
            setSelectedField([]);
        }
    };

    const handleAddNewMemo = () => {
    };

    const handleDeleteMemo = () => {
    };

    const handleToggleTutorial = () => {
    };

    const handleUploadAttachment = () => {
    };

    const handleGetMsgs = () => {
    };

    const resetSelectedField = () => {
        setSelectedField([]);
    };

    const handleCloseMobileChat = () => {
    };

    const handleAddrList = () => {
    };

    const handleClickList = item => {
        let _clickList = _.cloneDeep(clickList);
        if (_clickList.length > 0) {
            if (_clickList.findIndex(x => x.coordId === item.coordId) < 0) {
                _clickList.push(item);
            }
        } else {
            _clickList.push(item);
        }
        setClickList(_clickList);
    };

    const handleResize = async pdfWidth => {
        let w = window.innerWidth;
        let _scale = 1;
        if (typeof pdfWidth === "object") {
            if (w < 451) {
                _scale = 0.6;
            } else if (w > 450 && w < 1025) {
                _scale = 0.8;
            } else if (w > 1024 && w < 1367) {
                _scale = 1;
            } else {
                _scale = 1.4;
            }
        } else {
            if (props.popup || props.tempPopup) {
                let preview = document.getElementById("preview");
                if (preview) {
                    w = preview.offsetWidth;
                }
            }

            if (props.referer === "previewtemplate") {
                _scale = 1;
            } else {
                _scale = 1;
            }

            if (isMobile) {
                _scale = Number((w - 10) / pdfWidth).toFixed(2);
            } else {
                for (let i = 0.4; i <= 1; i += 0.2) {
                    if (props.tempPopup || props.popup) {
                        _scale = Number((w - 80) / pdfWidth).toFixed(2);
                    } else {
                        if (w - 30 < pdfWidth * i) {
                            _scale = Number(i - 0.2).toFixed(1);
                            break;
                        }
                    }
                }
            }
        }

        return Number(_scale);
    };

    return (
        <PDFViewer
            referer={props.sign ? "signform" : "templateEdit"}
            fieldDataList={props.sign ? fieldDataList : undefined}
            fieldDataOrigin={props.sign ? fieldDataOrigin : undefined}
            docs={sendDocs}
            scale={scale}
            showField={!props.sign && isExpired ? false : true}
            type="bigView"
            isSigning={props.sign}
            selected={selected}
            selectedIdx={selectedIdx}
            onSelected={handleSelected}
            selectedField={selectedField}
            selectedSign={selectedSign}
            receiver={receiver}
            onOpenSignModal={handleOpenSignModal}
            isSignComplete={props.isSignComplete}
            RenderText={text => RenderText(text, context)}
            isMobile={isMobile}
            isTablet={isTablet}
            onToggleMobileInput={handleToggleMobileInput}
            onMobileFieldUpdate={handleMobileFieldUpdate}
            attachmentList={attachmentList}
            onUpdateState={handleUpdate}
            room={room}
            onAddNewMemo={handleAddNewMemo}
            profile={profile}
            onDeleteMemo={handleDeleteMemo}
            onToggleTutorial={handleToggleTutorial}
            onUploadAttachment={handleUploadAttachment}
            // RIGHT SIDEBAR
            conditionMap={conditionMap}
            messages={msgList}
            contract_id={detail && detail.contract?.id}
            onGetMsgs={handleGetMsgs}
            // MOBILE
            detail={detail}
            checkAttachment={checkAttachment}
            openInputBox={isOpenInputBox}
            // MOBILE CHAT
            openMobileChat={isOpenMobileChat}
            is_half={
                selectedField?.length > 0 &&
                selectedField.length < fieldDataList?.length &&
                fieldDataList?.length > selectedField[0] &&
                fieldDataList[selectedField[0]].coordType === "memo"
            }
            resetSelectedField={resetSelectedField}
            onCloseMobileChat={handleCloseMobileChat}
            // 모바일 상세보기
            setting={setting}
            timezone={timezone}
            openMobileHistory={isOpenMobileHistory}
            // 본인 이외의 조건들
            presignCond={props.type === "all" || props.type === "product" || props.type === "keyPoint" ||props.type === "keyPointSuccessor" ? fieldDataList : props.fieldDataList ? props.fieldDataList : otherConds}
            handleAddrList={handleAddrList}
            addrList={addrList}
            clickList={clickList}
            onClickList={handleClickList}
        />
    );
};

export default Document;
