import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { successorInfo, docsAction } from "module/contractModule";
import styles from "./styles.scss";
import mainTitleStyles from "components/Title/MainTitle/styles.scss";
import tableCommonStyles from "components/Table/styles.scss";

import Header from "components/Layout/Header";
import Button from "components/Button";
import SubTitle from "components/Title/SubTitle";
import Table from "components/Table";

const SuccessorAfterContractInfo1 = (props) => {
    const dispatch = useDispatch();
    const EGUBUN = useSelector((state) => state.contract?.EGUBUN);
    const Estimate = useSelector((state) => state.contract?.estimate);
    const contractCond = useSelector((state) => state.contract?.contractInfo);
    const etcCond = useSelector((state) => state.contract?.contractEtc);
    const carInfo = useSelector((state) => state.contract?.contractDefault);
    const { data } = useSelector((state) => state.contract);
    const username = useSelector((state) => state.pass.name);
    const personal = useSelector((state) => state.contract.isPersonal);

    const ES_ETCINFO = useSelector((state) => state.contract?.ES_ETCINFO);
    const ES_MAINT = useSelector((state) => state.contract?.ES_MAINT);
    const ES_INSU = useSelector((state) => state.contract?.ES_INSU);
    const ES_KUNNR_INFO = useSelector((state) => state.contract?.ES_KUNNR_INFO);
    const ES_CTR = useSelector((state) => state.contract?.ES_CTR);
    const ES_CAR = useSelector((state) => state.contract?.ES_CAR);

    const [btndisabled, setBtndisabled] = useState(true);
    const [contractType, setContractType] = useState("A");

    useEffect(() => {
        //보여줄 문서를 순서대로 삽입
        //docList
        // dispatch(docsAction.post.request([201, 202, 203]));

        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        } else {
            if (!data) {
                props.history.push("/successor/entry/b");
            }
        }
    }, []);

    const handleOnClickBtn = () => {
        if (!btndisabled) {
            props.history.push("/successor/afterinfo2");
        }
    };

    const handleCheckBox = () => {
        setBtndisabled(!btndisabled);
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")} />
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        
                        <div className={styles.stepOut}>
                            <div className={styles.stepString}>Step  </div>
                            <div className={styles.stepNumber}> 3/10</div>
                        </div>

                        <div className={mainTitleStyles.topInfo}>
                            <h1 style={{ marginBottom: "16px" }}>
                                {personal && (
                                    <>
                                        <span className={mainTitleStyles.spanNmBasic}>{username ? username : "-"}</span>
                                        {"님의"}
                                        <br />
                                    </>
                                )}
                                계약정보를 확인해주세요
                            </h1>
                            <div className={mainTitleStyles.desc}>
                                <p>
                                    <span className={mainTitleStyles.spanBasic}>계약번호 :</span>
                                    <span className={mainTitleStyles.spanNum}>{Estimate}</span>
                                </p>
                            </div>
                        </div>
                        <div className={styles.contBox}>
                            {/* {(EGUBUN === "LM" || EGUBUN === "LF" || EGUBUN === "HH" || EGUBUN === "HA") && (
                                <div className={tableCommonStyles.tblWrap}>
                                    {EGUBUN === "LM" || EGUBUN === "LF" ? (
                                        <SubTitle title="차량 기본 정보" isSpecial descYn="N" />
                                    ) : EGUBUN === "HH" || EGUBUN === "HA" ? (
                                        <SubTitle title="차량/건설기계 기본 정보" isSpecial descYn="N" />
                                    ) : undefined}
                                    <table className={tableCommonStyles.tbl01}>
                                        <tbody>
                                            {carInfo?.map((contact, i) => {
                                                return (
                                                    <Table
                                                        name={contact?.title ? contact?.title : "-"}
                                                        value={contact?.value ? contact?.value : "-"}
                                                        key={i}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )} */}

                            <div className={tableCommonStyles.tblWrap}>
                                {/* <SubTitle title="계약 조건" isSpecial descYn="N" /> */}
                                <SubTitle title="승계 조건" isSpecial descYn="N" />
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                        {/* {contractCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            );
                                        })} */}
                                        {
                                            <>
                                                <Table name={"차종"} value={ES_CAR?.MAKTX || "-"} />
                                                <Table name={"차량번호"} value={ES_CAR?.INVNR || "-"} />
                                                <Table name={"승계일자"} value={ES_CAR?.C_UPDAT || "-"} />
                                                <Table name={"승계금액"} value={ES_CAR?.A_FPJAAMT1 || "-"} />
                                                <Table name={"보증금"} value={ES_CAR?.A_GURWR1 || "-"} />
                                                <Table name={"선수금"} value={ES_CAR?.A_FAMT1 || "-"} />
                                                <Table name={"선납금"} value={ES_CAR?.A_PREAMT1 || "-"} />
                                                <Table name={"연체금"} value={ES_CAR?.A_DILAMT || "-"} />
                                                <Table name={"보증증권"} value={ES_CAR?.A_INSWRA1 || "-"} />
                                                <Table name={"월리스료"} value={ES_CAR?.A_RTAAMT1 || "-"} />
                                                <Table name={"승계수수료"} value={ES_CAR?.A_SCSCWR || "-"} />
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className={tableCommonStyles.tblWrap}>
                                <SubTitle title="기타 조건" isSpecial descYn="N" />
                                <table className={tableCommonStyles.tbl01}>
                                    <tbody>
                                        {etcCond?.map((contact, i) => {
                                            return (
                                                <Table
                                                    name={contact?.title ? contact?.title : "-"}
                                                    value={contact?.value ? contact?.value : "-"}
                                                    key={i}
                                                />
                                            );
                                        })}
                                        <Table name={"-"} value={"-"} key={1} />
                                    </tbody>
                                </table>
                            </div> */}

                            {/* <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> */}
                            <div className={styles.confirmCheck}>
                                <input type="checkbox" onChange={handleCheckBox} checked={!btndisabled} />
                                <label onClick={handleCheckBox}>
                                    {
                                        "승계 계약을 진행하기 전 미납된 금액과 승계일 이전에 발생한 기타채무(과태료, 범칙금) 등이 확인될 경우 납부에 대한 책임이 있음을 확인합니다."
                                    }
                                </label>
                            </div>

                            <Button style={{ width: "100%" }} text={"승계내용 확인"} onClick={handleOnClickBtn} disabled={btndisabled} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SuccessorAfterContractInfo1;
