import React from "react";
import styles from "./styles.scss";

const Header = (props) => {
    return (
        <div className={styles.headerWrap}>
            <div className={styles.innerWrap}>
                <img src="/static/media/logo.svg" alt="logo" onClick={props.onClick} />
            </div>
        </div>
    );
};

export default Header;