import React from "react";
import styles from "./styles.scss";

const CheckGroup = (props, context) => {
    return (
        <div
            tabIndex={0}
            id={"checkgroup"}
            data-name={"checkgroup"}
            style={props.style}
            className={[
                styles.fieldBox,
                !props.isSigning && props.selectedField && props.selectedField.length > 0 && props.onAddCheckToGroup ? styles.addCheck : undefined
            ].join(" ")}
        >
            {!props.isMobile && !props.isTablet && props.checkField ? (
                <div className={styles.title} style={{ width: props.checkField.title ? props.checkField.title.length * 12 + 60 : 90 }}>
                    {props.checkField.title ? props.checkField.title : ("체크박스")}
                    {"-"}
                    {props.checkField.minNum}
                    {"~"}
                    {props.checkField.maxNum}
                    {("개 선택")}
                </div>
            ) : (
                undefined
            )}
        </div>
    );
};

export default CheckGroup;
