const updateMappingListContent = (mappings, updateCheckKey, updateValue, onceCheck) => {
    for (let item of mappings) {
        if (item.tagName === updateCheckKey) {
            item.content = updateValue;

            if (onceCheck) {
                return [...mappings];
            }
        }
    }

    return mappings;
};

export default updateMappingListContent;
