import * as React from "react";
import update from "react-addons-update";
import _ from "lodash";
import { history, envProps } from "module/configureStore";

import Moment from "moment";

import styles from "./styles.scss";

import ConfirmPopup from "components/ConfirmPopup";
import * as SVG from "components/SVG";
import PDFDocument from "./PDFDocument";
import validateEmail from "functions/validateEmail";
import checkPhoneNum from "functions/checkPhoneNum";
import validateRegisNum from "functions/validateRegisNum";

const MIN_SCALE = 1;
const MAX_SCALE = 4;
const ADDITIONAL_LIMIT = 0;

const TEXT_TYPE = ["name", "email", "addr", "phone", "regisNum", "bankAcnt", "popup"];
let tempLoadData = [];

const PDFViewer = (props, context) => {
    const [docsIdx, setDocsIdx] = React.useState(0);
    const [pages, setPages] = React.useState(1);
    const [docs_pages, setDocs_pages] = React.useState(undefined);
    const [blankViewPage, setBlankViewPage] = React.useState([]);
    const [maxRendering, setMaxRendering] = React.useState([]);
    const [docRendering, setDocRendering] = React.useState(true);
    const [maxPage, setMaxPage] = React.useState([]);
    const [docsHeight, setDocsHeight] = React.useState([]);
    const [pdfArray, setPdfArray] = React.useState([]);
    const [pdfArrayFlag, setPdfArrayFlag] = React.useState([]);
    const [pdfCanvas, setPdfCanvas] = React.useState([]);
    const [mobileInputIdx, setMobileInputIdx] = React.useState(-1);
    const [mobileInputType, setMobileInputType] = React.useState("");
    const [mobileInputData, setMobileInputData] = React.useState(undefined);
    const [isDragging, setIsDragging] = React.useState(false);
    const [isResizing, setIsResizing] = React.useState(false);
    const [isRange, setIsRange] = React.useState(false);
    const [isDisplayDropOption, setIsDisplayDropOption] = React.useState(false);
    const [acceptFiles, setAcceptFiles] = React.useState(envProps.acceptFiles);
    const [isPdfError, setIsPdfError] = React.useState(false);
    const [copyIdx, setCopyIdx] = React.useState([]);
    const [multiFlag, setMultiFlag] = React.useState([-1, -1]);
    const [multiStyle, setMultiStyle] = React.useState({ width: 0, height: 0, top: 0, left: 0 });
    const [isOpen, setIsOpen] = React.useState(false);
    const [noDragging, setNoDragging] = React.useState(false);
    const [checkFlag, setCheckFlag] = React.useState([-1, -1]);
    const [checkStyle, setCheckStyle] = React.useState({ width: 0, height: 0, top: 0, left: 0 });
    const [addStyle, setAddStyle] = React.useState({ width: 0, height: 0, top: 0, left: 0 });
    const [hoverCheck, setHoverCheck] = React.useState(-1);
    const [openTxt, setOpenTxt] = React.useState({
        sign: true,
        stamp: true,
        type: true,
        label: true,
        content: true,
        checkbox: true,
        date: true,
        tag: true
    });
    const [totalPage, setTotalPage] = React.useState(1);
    const [totalNowPage, setTotalNowPage] = React.useState(1);
    const [openCalendar, setOpenCalendar] = React.useState(-1);
    const [completeErrMsg, setCompleteErrMsg] = React.useState("");
    const [loadData, setLoadData] = React.useState([]);
    const [isMobile, setIsMobile] = React.useState(false);
    const [isTablet, setIsTablet] = React.useState(false);

    const [fieldDataOrigin, setFieldDataOrigin] = React.useState(undefined);
    const [mouseDownTimer, setMouseDownTimer] = React.useState(0);
    const [scale, setScale] = React.useState(1);

    const [isDestroy, setIsDestroy] = React.useState(false);
    const [rangePos, setRangePos] = React.useState({ top: 0, left: 0 });
    const [mouseDownPos, setMouseDownPos] = React.useState({ x: 0, y: 0 });
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [selectedDoc, setSelectedDoc] = React.useState(0);
    const [adjustPos, setAdjustPos] = React.useState({ top: 0, left: 0 });
    const [lastPanPoint, setLastPanPoint] = React.useState({ x: 0, y: 0 });
    const [lastDistance, setLastDistance] = React.useState(0);
    const [firstPanPoint, setFirstPanPoint] = React.useState({ x: 0, y: 0 });
    const [lastMobileScale, setLastMobileScale] = React.useState(1);
    const [mobileScale, setMobileScale] = React.useState(1);
    const viewer = React.useRef(undefined);
    const pdfViewer = React.useRef(undefined);
    const pdfWrap = React.useRef(undefined);

    React.useEffect(() => {
        window.addEventListener("scroll", onScroll);

        let w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
        let agent = navigator.userAgent;
        let apple_mobile = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;

        if (w < 768 || agent.match(/(iPhone|Android|Windows Phone)/) || apple_mobile) {
            setIsMobile(true);
        } else if ((w > 767 && w < 1024) || agent.match(/(iPad|Android)/) || apple_mobile) {
            setIsTablet(true);
        }

        return () => {
            setIsDestroy(true);
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    React.useEffect(() => {
        let _isOpen = isOpen;
        if (props.selectedField?.length > 0) {
            if (
                (props.selectedField?.length === 1 &&
                    props.fieldDataList?.length > 0 &&
                    props.fieldDataList?.length > props.selectedField[0] &&
                    props.fieldDataList[props.selectedField[0]]?.type === "memo") ||
                props.isSigning
            ) {
                _isOpen = false;
            } else {
                _isOpen = true;
            }
        } else {
            _isOpen = false;
        }

        setIsOpen(_isOpen);

        if (lastMobileScale > -1) {
            setLastMobileScale(1);
            setMobileScale(1);
            if (pdfWrap?.current?.style) {
                pdfWrap.current.style.width = null;
                pdfWrap.current.style.height = null;
            }
            if (pdfViewer?.current?.style) {
                pdfViewer.current.style.transform = null;
            }
        }
    }, [props.selectedField]);

    React.useEffect(() => {
        if (props.docs) {
            let _tempLoadData = [];
            let _docs_pages = [];
            let _docsHeight = _.cloneDeep(docsHeight);
            let _maxPage = _.cloneDeep(maxPage);
            let _pdfArrayFlag = _.cloneDeep(pdfArrayFlag);
            let _fieldDataOrigin = _.cloneDeep(fieldDataOrigin);
            props.docs &&
                props.docs?.length > 0 &&
                props.docs.map(doc => {
                    _docs_pages.push([...Array(doc.pages).keys()].map(x => x + 1));
                    _docsHeight.push(0);
                    _maxPage.push(0);
                    _pdfArrayFlag.push(false);
                    _tempLoadData.push(undefined);
                    return true;
                });
            if (_docs_pages === undefined) {
                _fieldDataOrigin = props.fieldDataList ? _.cloneDeep(props.fieldDataList) : [];
            }

            setDocs_pages(_docs_pages);
            setDocsHeight(_docsHeight);
            setMaxPage(_maxPage);
            setPdfArrayFlag(_pdfArrayFlag);
            setDocRendering(false);
            setFieldDataOrigin(_fieldDataOrigin);
            tempLoadData = _.cloneDeep(_tempLoadData);

            if (viewer?.current) {
                viewer.current.scrollTop = 0;
                setPdfCanvas([]);
            }
        }
    }, [props.docs]);

    React.useEffect(() => {
        if (fieldDataOrigin && !_.isEqual(fieldDataOrigin, props.fieldDataOrigin) && !props.isSigning) {
            setIsDragging(noDragging ? false : !isMobile);
            setNoDragging(false);
        }
        if (props.fieldDataOrigin) {
            if (!props.loading && !props.isSigning) {
                let _fieldData = _.cloneDeep(props.fieldDataOrigin[props.fieldDataOrigin?.length - 1]);
                let _adjustPos = _.cloneDeep(adjustPos);
                if (_fieldData && _fieldData.type !== "attachment") {
                    let selectorList = document.querySelectorAll(`div[data-docs='${_fieldData.doc.idx}']`);
                    if (selectorList?.length > 0) {
                        let selector = selectorList[_fieldData.doc.page - 1];
                        if (selector) {
                            _adjustPos.top = ((selector.offsetHeight - 15) * (_fieldData.style.height / 100)) / 2;
                            _adjustPos.left = (selector.offsetWidth * (_fieldData.style.width / 100)) / 2;
                        }
                        setMultiFlag(props.selectedField?.length > 1 ? [selectedDoc, selectedPage] : [-1, -1]);
                    }
                }

                if (props.isSigning && props.fieldDataOrigin && props.selectedField?.length > -1) {
                    let _fieldData = props.fieldDataOrigin[props.fieldDataOrigin?.length - 1];
                    if (_fieldData && _fieldData.coordType !== "attachment") {
                        let selectorList = document.querySelectorAll(`div[data-docs='${_fieldData.docIdx}']`);
                        if (selectorList?.length > 0) {
                            let selector = selectorList[_fieldData.docPage - 1];
                            if (selector) {
                                _adjustPos.top = ((selector.offsetHeight - 15) * (_fieldData.height / 100)) / 2;
                                _adjustPos.left = (selector.offsetWidth * (_fieldData.width / 100)) / 2;
                            }
                        }
                    }
                }
                if (!_.isEqual(_adjustPos, adjustPos)) {
                    setAdjustPos(_adjustPos);
                }
            }
        }
        setFieldDataOrigin(props.fieldDataOrigin);
    }, [props.fieldDataOrigin]);

    React.useEffect(() => {
        if (props.fieldDataList?.length === 0) {
            setIsOpen(false);
            setMultiFlag([-1, -1]);
            setMultiStyle({ width: 0, height: 0, top: 0, left: 0 });
        }
    }, [props.fieldDataList]);

    React.useEffect(() => {
        if (props.selectedIdx !== undefined && props.selected !== undefined) {
            if (!_.isEqual(pages, props.selected) || !_.isEqual(docsIdx, props.selectedIdx)) {
                if (props.selectedIdx < blankViewPage?.length && props.selected <= blankViewPage[props.selectedIdx]?.length) {
                    handleSetScroll(props.selectedIdx, props.selected);
                }
            }
        }
    }, [props.selectedIdx, props.selected]);

    React.useEffect(() => {
        if (!_.isEqual(scale, props.scale)) {
            setScale(props.scale);
        }
    }, [props.scale]);

    React.useEffect(() => {
        if (loadData) {
            if (loadData?.length === props.docs?.length) {
                setLoadState();
            }
        }
    }, [loadData]);

    const calcPage = data => {
        let { pdf, pages, idx } = data;

        let _blankPage = [];
        let _maxPage;
        let _docsHeight;
        let _maxRendering;
        let _pdfArray;
        let _pdfArrayFlag;
        let _pdfCanvas;

        return new Promise(async (resolve, reject) => {
            let blankPage = [];
            let refArray = [];
            let pageSize = [];

            for (let page of pages) {
                refArray.push(React.createRef());

                let viewport = page.getViewport({ scale: 1 });
                pageSize.push({ width: viewport.width, height: viewport.height });
                blankPage.push({ width: viewport.width, height: viewport.height });
            }
            _blankPage = blankPage;

            let maxHeight = 0;
            let maxWidth = 0;
            for (let i = 0; i < blankPage?.length; i++) {
                maxHeight = maxHeight + Math.floor(blankPage[i].height) + 17;
                if (maxWidth < Math.floor(blankPage[i].width)) {
                    maxWidth = Math.floor(blankPage[i].width);
                }
            }

            _docsHeight = maxHeight + 100;
            _maxRendering = 5;
            _maxPage = pdf.numPages;
            _pdfCanvas = { ref: refArray, pageSize };
            _pdfArray = { pdf, fileName: props.docs[idx].filename };
            _pdfArrayFlag = true;

            resolve({
                _blankPage,
                _maxPage,
                _docsHeight,
                _maxRendering,
                _pdfArray,
                _pdfCanvas,
                _pdfArrayFlag,
                _maxWidth: maxWidth
            });
        });
    };

    const setLoadState = async () => {
        let _loadData = _.cloneDeep(loadData);

        if (_loadData?.length > 0) {
            let _blankViewPage = [];
            let _maxPage = [];
            let _docsHeight = [];
            let _maxRendering = [];
            let _pdfArray = [];
            let _pdfArrayFlag = [];
            let _pdfCanvas = [];
            let _totalPage = 0;
            let _maxWidth = [];
            for (let loadidx = 0; loadidx < _loadData.length; loadidx++) {
                let pdf = _loadData[loadidx].pdf;
                let pages = _loadData[loadidx].pages;
                let idx = _loadData[loadidx].idx;

                if (!isDestroy) {
                    let calcData = await calcPage({ pdf, pages, idx });
                    if (calcData) {
                        _blankViewPage = update(_blankViewPage, { $merge: { [idx]: calcData._blankPage } });
                        _docsHeight = update(_docsHeight, { $merge: { [idx]: calcData._docsHeight } });
                        _maxRendering = update(_maxRendering, { $merge: { [idx]: 5 } });
                        _maxPage = update(_maxPage, { $merge: { [idx]: calcData._maxPage } });
                        _pdfCanvas = update(_pdfCanvas, { $merge: { [idx]: calcData._pdfCanvas } });
                        _pdfArray = update(_pdfArray, { $merge: { [idx]: calcData._pdfArray } });
                        _pdfArrayFlag = update(_pdfArrayFlag, { $merge: { [idx]: calcData._pdfArrayFlag } });
                        _totalPage += calcData._maxPage;
                        _maxWidth.push(calcData._maxWidth);
                    }
                }
            }
            let maxwidth = _.max(_maxWidth);
            let _scale = await handleResize(maxwidth);

            let p1 = new Promise((resolve, reject) => {
                setBlankViewPage(_blankViewPage);
                setMaxPage(_maxPage);
                setDocsHeight(_docsHeight);
                setMaxRendering(_maxRendering);
                setPdfArray(_pdfArray);
                setPdfArrayFlag(_pdfArrayFlag);

                setPdfCanvas(_pdfCanvas);
                setTotalPage(_totalPage);
                setDocRendering(false);
                setScale(_scale);
                resolve();
            });
            p1.then(() => {
                props.onUpdateState && props.onUpdateState({ scale: _scale, loading: false });
            });
        }
    };

    const LoadTotal = async (pdf, idx) => {
        if (pdf && idx > -1) {
            let pages = [];
            for (let i = 1; i <= pdf.numPages; i++) {
                await pdf.getPage(i).then(async page => {
                    pages.push(page);
                });
            }

            let data = {
                pdf,
                pages,
                idx
            };

            if (tempLoadData?.length === props.docs?.length) {
                tempLoadData[idx] = data;
            }
        }
        if (tempLoadData?.length === props.docs?.length && tempLoadData.filter(x => x === undefined)?.length === 0) {
            setLoadData(tempLoadData);
        }
    };

    const handleResize = async pdfWidth => {
        let w = window.innerWidth;
        let _scale = 1;
        if (typeof pdfWidth === "object") {
            if (w < 451) {
                _scale = 0.6;
            } else if (w > 450 && w < 1025) {
                _scale = 0.8;
            } else if (w > 1024 && w < 1367) {
                _scale = 1;
            } else {
                _scale = 1.4;
            }
        } else {
            if (props.popup || props.tempPopup) {
                let preview = document.getElementById("preview");
                if (preview) {
                    w = preview.offsetWidth;
                }
            }

            if (props.referer === "previewtemplate") {
                _scale = 1;
            } else {
                _scale = 1;
            }

            if (isMobile) {
                _scale = Number((w - 10) / pdfWidth).toFixed(2);
            } else {
                for (let i = 0.4; i <= 1; i += 0.2) {
                    if (props.tempPopup || props.popup) {
                        _scale = Number((w - 80) / pdfWidth).toFixed(2);
                    } else {
                        if (w - 30 < pdfWidth * i) {
                            _scale = Number(i - 0.2).toFixed(1);
                            break;
                        }
                    }
                }
            }
        }

        return Number(_scale);
    };

    const onScroll = () => {
    }

    const handleSetScroll = (selectedIdx, selected) => {
    };

    const handleMobileInputClick = (type, inx) => {
        const { fieldDataList } = props;
        let _mobileInputData = _.cloneDeep(fieldDataList[inx]);

        let p1 = new Promise((resolve, reject) => {
            setMobileInputIdx(inx);
            setMobileInputData(_mobileInputData);
            setMobileInputType(type);

            resolve();
        });
        p1.then(() => {
            props.onToggleMobileInput && props.onToggleMobileInput();
        });
    };

    const handleMouseEnter = (idx, page) => {
        if (selectedPage !== page || selectedDoc !== idx) {
            setSelectedPage(page);
            setSelectedDoc(idx);
        }

        if (!isRange && isDragging) {
            if (props.selectedField?.length > 0) {
                let _fieldDataList = _.cloneDeep(props.fieldDataList);

                if (props.isSigning && props.fieldDataList[props.selectedField].coordType === "memo") {
                    _fieldDataList = update(props.fieldDataList, {
                        [props.selectedField]: {
                            $set: { ...props.fieldDataList[props.selectedField], docIdx: idx, docPage: page }
                        }
                    });
                } else {
                    props.selectedField.map(inx => {
                        let fieldEle = document.getElementById(_fieldDataList[inx].id);
                        if (fieldEle) {
                            let left = Number(String(fieldEle.style.left)?.replace("%", ""));
                            let top = Number(String(fieldEle.style.top)?.replace("%", ""));
                            let width = Number(String(fieldEle.style.width)?.replace("%", ""));
                            let height = Number(String(fieldEle.style.height)?.replace("%", ""));

                            return (_fieldDataList = update(_fieldDataList, {
                                [inx]: {
                                    $set: {
                                        ..._fieldDataList[inx],
                                        doc: { idx, page },
                                        style: { ..._fieldDataList[inx].style, top, left, width, height }
                                    }
                                }
                            }));
                        }
                    });
                }

                let element = document.getElementById("multibox");
                let _multiStyle = _.cloneDeep(multiStyle);
                let _multiFlag = _.cloneDeep(multiFlag);
                if (element) {
                    _multiStyle = {
                        top: element.style.top,
                        left: element.style.left,
                        width: element.style.width,
                        height: element.style.height
                    };

                    _multiFlag = [idx, page];
                }

                let p1 = new Promise((resolve, reject) => {
                    setMultiStyle(_multiStyle);
                    setMultiFlag(_multiFlag);

                    resolve();
                });

                p1.then(() => {
                    props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
                });
            }
        }
    };

    const handleMinMaxStyle = (list, signing) => {
        let min_doc = undefined;
        let max_doc = undefined;
        let min_style = undefined;
        let max_style = undefined;
        list.map(check => {
            let top = signing ? check.top : check.style.top;
            let left = signing ? check.left : check.style.left;
            let width = signing ? check.width : check.style.width;
            let height = signing ? check.height : check.style.height;
            let doc_idx = signing ? check.docIdx : check.doc.idx;
            let doc_page = signing ? check.docPage : check.doc.page;
            if (min_style === undefined) {
                min_style = {
                    top: top,
                    left: left,
                    width: width,
                    height: height
                };
            }
            if (max_style === undefined) {
                max_style = {
                    top: top,
                    left: left,
                    width: width,
                    height: height
                };
            }
            if (min_doc === undefined) {
                min_doc = { idx: doc_idx, page: doc_page };
            }
            if (max_doc === undefined) {
                max_doc = { idx: doc_idx, page: doc_page };
            }
            if (doc_idx < min_doc.idx) {
                min_doc.idx = doc_idx;
                min_doc.page = doc_page;
                min_style.top = top;
                min_style.height = height;
            } else if (doc_idx === min_doc.idx) {
                if (doc_page < min_doc.page) {
                    min_doc.page = doc_page;
                    min_style.top = top;
                    min_style.height = height;
                } else if (doc_page === min_doc.page) {
                    if (min_style.top > top) {
                        min_style.top = top;
                        max_style.height = height;
                    }
                }
            }
            if (doc_idx > max_doc.idx) {
                max_doc.idx = doc_idx;
                max_doc.page = doc_page;
                max_style.top = top;
                max_style.height = height;
            } else if (doc_idx === max_doc.idx) {
                if (doc_page > max_doc.page) {
                    max_doc.page = doc_page;
                    max_style.top = top;
                    max_style.height = height;
                } else if (doc_page === max_doc.page) {
                    if (max_style.top < top) {
                        max_style.top = top;
                        max_style.height = height;
                    }
                }
            }
            if (max_style.left < left) {
                max_style.left = left;
                max_style.width = width;
            }
            if (min_style.left > left) {
                min_style.left = left;
                min_style.width = width;
            }
            return true;
        });

        return {
            min_doc,
            max_doc,
            min_style,
            max_style
        };
    };

    const handleMouseDown = (e, inx, type) => {
        if (props.pdfType === "preview" || e?.target?.id?.includes("addrbox")) {
            return false;
        }

        if (inx > -1) {
            let _isDragging = false;
            let _isResizing = false;
            let fieldData = props.fieldDataList[inx];
            let _checkStyle = _.cloneDeep(checkStyle);
            let _checkFlag = _.cloneDeep(checkFlag);
            let _addStyle = _.cloneDeep(addStyle);
            if (type === "drag") {
                if (!props.isSigning || fieldData.isMove) {
                    _isDragging = true;
                    _isResizing = false;
                }
            } else if (type === "resize") {
                _isDragging = false;
                _isResizing = true;
            }
            let _multiFlag = _.cloneDeep(multiFlag);

            if (e?.type === "touchstart") {
                let { top, left } = e.currentTarget.getBoundingClientRect();
                if (type !== "resize") {
                    if (viewer?.current?.style) {
                        viewer.current.style.overflowY = "hidden";
                        viewer.current.parentNode.parentNode.style.overflowX = "hidden";
                        setAdjustPos({
                            top: e.targetTouches[0].pageY - (top + viewer.current.scrollTop),
                            left: e.targetTouches[0].pageX - (left + viewer.current.scrollLeft)
                        });
                    }
                } else {
                    setAdjustPos({
                        top: 10,
                        left: 10
                    });
                }
                setSelectedPage(props.isSigning ? fieldData.docPage : fieldData.doc.page);
                setSelectedDoc(props.isSigning ? fieldData.docIdx : fieldData.doc.idx);
            } else if (props.selectedField?.length < 2) {
                setAdjustPos({ top: e.nativeEvent.offsetY, left: e.nativeEvent.offsetX });
            }

            if (props.selectedField?.length === 0 || props.selectedField.filter(filter => filter === inx)?.length < 1) {
                _multiFlag = [-1, -1];
                let _checkData = undefined;

                if (_checkData) {
                    _checkFlag = _checkData[0];
                    _checkStyle = _checkData[1];
                    _addStyle = _checkData[2];
                } else {
                    _checkFlag = [-1, -1];
                    _checkStyle = { top: 0, left: 0, width: 0, height: 0 };
                }

                let p1 = new Promise((resolve, reject) => {
                    setCheckFlag(_checkFlag);
                    setCheckStyle(_checkStyle);
                    setAddStyle(_addStyle);
                    setMultiFlag([-1, -1]);
                    setMultiStyle({ top: 0, left: 0, width: 0, height: 0 });

                    resolve();
                });
                p1.then(() => {
                    props.onUpdateState && props.onUpdateState({ selectedField: [inx] });
                });
            }

            if (type === "resize" || isTablet || isMobile) {
                setMouseDownTimer(0);
                setIsDragging(_isDragging);
                setIsResizing(_isResizing);
                setMultiFlag(_multiFlag);
            } else {
                if (props.isSigning) {
                    setMouseDownTimer(
                        setTimeout(() => {
                            let p1 = new Promise(async (resolve, reject) => {
                                setIsDragging(_isDragging);
                                setIsResizing(_isResizing);
                                resolve();
                            });
                            p1.then(() => {
                                setMouseDownTimer(0);
                            });
                        }, 200)
                    );
                } else {
                    if (props.selectedField?.length > 1) {
                        let p1 = new Promise(async (resolve, reject) => {
                            setIsDragging(_isDragging);
                            setIsResizing(_isResizing);
                            resolve();
                        });
                        p1.then(() => {
                            setMouseDownTimer(0);
                        });
                    } else {
                        setMouseDownTimer(
                            setTimeout(() => {
                                let p1 = new Promise(async (resolve, reject) => {
                                    setIsDragging(_isDragging);
                                    setIsResizing(_isResizing);
                                    resolve();
                                });
                                p1.then(() => {
                                    setMouseDownTimer(0);
                                });
                            }, 200)
                        );
                    }
                }
            }
        } else if (e.target && e.target.className === "react-pdf__Page__canvas") {
            if (!isRange) {
                if (e?.type === "touchstart") {
                    setMouseDownPos({
                        x: e.targetTouches[0].clientX,
                        y: e.targetTouches[0].clientY
                    });
                } else {
                    setMouseDownPos({
                        x: e.nativeEvent.offsetX,
                        y: e.nativeEvent.offsetY
                    });
                }
            }
            let _selectedField = isDragging ? props.selectedField : [];

            if (isMobile || isTablet) {
                _selectedField = props.selectedField;
            }

            let _multiFlag = _selectedField?.length > 1 ? (isDragging ? [selectedDoc, selectedPage] : [-1, -1]) : [-1, -1];

            let p1 = new Promise((resolve, reject) => {
                let _isRange = !isDragging;
                setIsDragging(true);
                setIsRange(_isRange);
                setIsResizing(false);
                setMultiFlag(_multiFlag);
                setIsDisplayDropOption(false);
                setCheckFlag([-1, -1]);
                setCheckStyle({ width: 0, height: 0, top: 0, left: 0 });
                resolve();
            });

            p1.then(() => {
                props.onUpdateState && props.onUpdateState({ selectedField: _selectedField });
            });
        }
    };

    const handleMouseUp = e => {
        if (!props.popup && !e?.target?.id?.includes("addrbox")) {
            if (mouseDownTimer > 0) {
                clearTimeout(mouseDownTimer);
                setMouseDownTimer(0);
                return false;
            }

            if ((isDragging || isResizing) && props.selectedField?.length > 0) {
                let _fieldDataList = _.cloneDeep(props.fieldDataList);
                let label_list = [];

                props.selectedField.map(inx => {
                    let fieldData = _fieldDataList[inx];

                    if (!props.isSigning && fieldData?.type === "check") {
                        label_list.push(fieldData);
                    }

                    let idName = props.isSigning ? "coordId" : "id";
                    let id = _fieldDataList[inx][idName];
                    let element = document.getElementById(id);
                    if (element) {
                        let { width, height, left, top } = element.style;
                        top = Number(String(top).slice(0, String(top)?.indexOf("%")));
                        left = Number(String(left).slice(0, String(left)?.indexOf("%")));
                        width = Number(String(width).slice(0, String(width)?.indexOf("%")));
                        height = Number(String(height).slice(0, String(height)?.indexOf("%")));
                        if (props.isSigning) {
                            _fieldDataList = update(_fieldDataList, {
                                [inx]: { $set: { ...fieldData, width, height, left, top } }
                            });
                        } else {
                            _fieldDataList = update(_fieldDataList, {
                                [inx]: { style: { $set: { ...fieldData.style, width, height, left, top, pointerEvents: "auto" } } }
                            });
                            if (fieldData?.type === "check") {
                                let _checkList = _fieldDataList.filter(
                                    x =>
                                        x.type === "check" &&
                                        Number(x.user) === Number(fieldData?.user) &&
                                        x.check &&
                                        fieldData.check &&
                                        Number(x.check.label) === Number(fieldData.check.label) &&
                                        x.signed === fieldData.signed &&
                                        x.id !== fieldData.id
                                );
                                _checkList.map(check => {
                                    let i = _fieldDataList.findIndex(x => x.id === check.id);
                                    if (i > -1) {
                                        _fieldDataList = update(_fieldDataList, {
                                            [i]: { style: { $set: { ...check.style, width, height, pointerEvents: "auto" } } }
                                        });
                                    }
                                    return true;
                                });
                            } else {
                            }
                        }
                    }

                    return true;
                });

                if (!props.isSigning) {
                    let _checkList = [];
                    label_list.map(check => {
                        let list = [];
                        if (props.presigned) {
                            list = _fieldDataList.filter(item => Number(item.user) === Number(check.user) && item.type === "check" && item.signed);
                        } else {
                            list = _fieldDataList.filter(item => Number(item.user) === Number(check.user) && item.type === "check" && !item.signed);
                        }
                        _checkList = _.cloneDeep(
                            _fieldDataList.filter(
                                x =>
                                    x.type === "check" &&
                                    Number(x.user) === Number(check.user) &&
                                    x.check &&
                                    x.check.label === check.check.label &&
                                    x.signed === check.signed
                            )
                        );
                        let idx_cnt = _.countBy(_checkList, "doc.idx");
                        let new_label = -1;
                        if (Object.keys(idx_cnt)?.length > 1) {
                            let new_check_list = _checkList.filter(x => x.doc.idx === label_list[0].doc.idx);
                            let check_label = _.uniqBy(list, "check.label");
                            if (check_label?.length === 0) {
                                new_label = 1;
                            } else {
                                let max_label = _.maxBy(check_label, function(c) {
                                    return Number(c.check.label);
                                });
                                new_label = Number(max_label.check.label) + 1;
                            }
                            new_check_list.map(check => {
                                check.check.label = new_label;
                                let i = _fieldDataList.findIndex(x => x.id === check.id);
                                _fieldDataList = update(_fieldDataList, { [i]: { $set: check } });
                                return true;
                            });
                        }
                        return true;
                    });
                }

                let _checkFlag = _.cloneDeep(checkFlag);
                let _checkStyle = _.cloneDeep(checkStyle);
                let _addStyle = _.cloneDeep(addStyle);

                if (props.selectedField?.length === 1) {
                    let fieldData = _.cloneDeep(props.fieldDataList[props.selectedField[0]]);
                    let _checkData = {};
                    if (_checkData) {
                        _checkFlag = _checkData[0];
                        _checkStyle = _checkData[1];
                        _addStyle = _checkData[2];
                    }
                }

                let p1 = new Promise((resolve, reject) => {
                    setIsDragging(false);
                    setIsResizing(false);
                    setCheckFlag(_checkFlag);
                    setCheckStyle(_checkStyle);
                    setAddStyle(_addStyle);
                    resolve();
                });
                p1.then(() => {
                    setAdjustPos({ top: 0, left: 0 });
                    props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
                });
            } else {
                if (!e.target.getAttribute("data-noselect")) {
                    let selected = [];
                    let _multiStyle = _.cloneDeep(multiStyle);
                    let _multiFlag = [-1, -1];

                    if (!props.isSigning && isRange) {
                        let selectorDoc = document.querySelectorAll(`div[data-docs='${selectedDoc}']`);
                        let selectorPage = selectorDoc[selectedPage - 1];

                        let { offsetHeight, offsetWidth } = selectorPage;

                        let startRangeX = (mouseDownPos.x / offsetWidth) * 100;
                        let startRangeY = (mouseDownPos.y / offsetHeight) * 100;
                        let endRangeX = (e.nativeEvent.offsetX / offsetWidth) * 100;
                        let endRangeY = (e.nativeEvent.offsetY / offsetHeight) * 100;

                        let maxRangeX = startRangeX > endRangeX ? startRangeX : endRangeX;
                        let minRangeX = startRangeX < endRangeX ? startRangeX : endRangeX;
                        let maxRangeY = startRangeY > endRangeY ? startRangeY : endRangeY;
                        let minRangeY = startRangeY < endRangeY ? startRangeY : endRangeY;

                        let maxFieldX = 0;
                        let minFieldX = 100;
                        let maxFieldY = 0;
                        let minFieldY = 100;
                        let addjustX = (3 / offsetWidth) * 100;
                        let addjustY = (3 / offsetHeight) * 100;
                        props.fieldDataList &&
                            props.fieldDataList.map((field, inx) => {
                                if (field.type !== "attachment" && field.doc.idx === selectedDoc && field.doc.page === selectedPage) {
                                    let xFlag = false;
                                    let yFlag = false;
                                    if (field.style.left > minRangeX && field.style.left < maxRangeX) {
                                        xFlag = true;
                                    }
                                    if (field.style.left < minRangeX && field.style.left + field.style.width > minRangeX) {
                                        xFlag = true;
                                    }
                                    if (field.style.top > minRangeY && field.style.top < maxRangeY) {
                                        yFlag = true;
                                    }
                                    if (field.style.top < minRangeY && field.style.top + field.style.height > minRangeY) {
                                        yFlag = true;
                                    }
                                    if (xFlag && yFlag) {
                                        if (field.style.left + field.style.width > maxFieldX) {
                                            maxFieldX = field.style.left + field.style.width - addjustX;
                                        }
                                        if (field.style.left < minFieldX) {
                                            minFieldX = field.style.left;
                                        }
                                        if (field.style.top + field.style.height > maxFieldY) {
                                            maxFieldY = field.style.top + field.style.height - addjustY;
                                        }
                                        if (field.style.top < minFieldY) {
                                            minFieldY = field.style.top;
                                        }
                                        selected.push(inx);
                                    }
                                }
                                return true;
                            });

                        _multiFlag = selected?.length > 1 ? [selectedDoc, selectedPage] : [-1, -1];

                        if (_multiFlag) {
                            _multiStyle = {
                                top: `${minFieldY}%`,
                                left: `${minFieldX}%`,
                                width: `${maxFieldX - minFieldX}%`,
                                height: `${maxFieldY - minFieldY}%`
                            };
                        }
                    }

                    let p1 = new Promise(async (resolve, reject) => {
                        setIsDragging(false);
                        setIsRange(false);
                        setMultiFlag(_multiFlag);
                        setMultiStyle(_multiStyle);
                        resolve();
                    });
                    p1.then(() => {
                        props.onUpdateState({ selectedField: selected });
                        if (selected?.length > 1) {
                            let element = document.getElementById("multibox");
                            if (element) {
                                element.focus();
                            }
                        } else if (selected?.length === 1) {
                            let fieldId = props.fieldDataList[selected[0]].id;
                            let element = document.getElementById(fieldId);
                            if (element) {
                                element.focus();
                            }
                        }
                    });

                    setRangePos({ top: 0, left: 0 });
                }
            }
        }
    };

    const handleMouseMove = e => {
        if (isDragging && props.selectedField?.length > 0 && selectedPage > 0) {
            let idName = props.isSigning ? "coordId" : "id";
            let inx = props.selectedField[0];
            let id = props.fieldDataList[inx][idName];
            let element = document.getElementById(id);
            if (multiFlag[0] > -1) {
                element = document.getElementById("multibox");
            }

            if (element) {
                let top = 0;
                let left = 0;
                let width = element.parentNode.parentNode.offsetWidth;
                let height = element.parentNode.parentNode.offsetHeight - 15;

                let _adjustPos = _.cloneDeep(adjustPos);

                if (multiFlag[0] > -1 && _adjustPos.top === 0 && _adjustPos.left === 0) {
                    let multiTop = height * (Number(String(element.style.top)?.replace("%", "")) / 100);
                    let multiLeft = width * (Number(String(element.style.left)?.replace("%", "")) / 100);
                    _adjustPos.top = e.nativeEvent.offsetY - multiTop;
                    _adjustPos.left = e.nativeEvent.offsetX - multiLeft;
                }

                let docs = document.querySelectorAll(`div[data-docs='${selectedDoc}']`);
                let docsPage = docs[selectedPage - 1];
                let offsetTop = docsPage.offsetTop;

                if (e?.type === "touchmove") {
                    let bounce = docs[selectedPage - 1].getBoundingClientRect();
                    top = e.targetTouches[0].pageY - _adjustPos.top - 50;
                    left = e.targetTouches[0].pageX - _adjustPos.left - bounce.left;
                } else {
                    top = e.nativeEvent.offsetY - _adjustPos.top;
                    left = e.nativeEvent.offsetX - _adjustPos.left;
                }

                if (props.isSigning) {
                    let _fieldDataOrigin = _.cloneDeep(fieldDataOrigin[inx]);
                    let fieldData = _.cloneDeep(props.fieldDataList[inx]);

                    if (e?.type === "touchmove") {
                        top = ((top - offsetTop) / height) * 100;
                    } else {
                        top = (top / height) * 100;
                    }

                    left = (left / width) * 100;
                    if (top < 0) {
                        if (
                            fieldData.coordType !== "memo" &&
                            top + fieldData.height < _fieldDataOrigin.top + _fieldDataOrigin.height - 5 &&
                            top + fieldData.height > 0
                        ) {
                            top = _fieldDataOrigin.top - 5;
                        } else {
                            top = 0;
                        }
                    } else if (top + fieldData.height > 100) {
                        if (
                            fieldData.coordType !== "memo" &&
                            top + fieldData.height > _fieldDataOrigin.top + _fieldDataOrigin.height + 5 &&
                            _fieldDataOrigin.top + _fieldDataOrigin.height + 5 < 100
                        ) {
                            top = _fieldDataOrigin.top + _fieldDataOrigin.height + 5 - fieldData.height;
                        } else {
                            top = 100 - fieldData.height;
                        }
                    } else if (fieldData.coordType !== "memo" && top + fieldData.height > _fieldDataOrigin.top + _fieldDataOrigin.height + 5) {
                        top = _fieldDataOrigin.top + _fieldDataOrigin.height + 5 - fieldData.height;
                    } else if (fieldData.coordType !== "memo" && top < _fieldDataOrigin.top - 5) {
                        top = _fieldDataOrigin.top - 5;
                    }

                    if (left < 0) {
                        if (
                            fieldData.coordType !== "memo" &&
                            left + fieldData.width < _fieldDataOrigin.left + _fieldDataOrigin.width - 5 &&
                            left + fieldData.width > 0
                        ) {
                            left = _fieldDataOrigin.left - 5;
                        } else {
                            left = 0;
                        }
                    } else if (left + fieldData.width > 100) {
                        if (
                            fieldData.coordType !== "memo" &&
                            left + fieldData.width > _fieldDataOrigin.left + _fieldDataOrigin.width + 5 &&
                            _fieldDataOrigin.left + _fieldDataOrigin.width + 5 < 100
                        ) {
                            left = _fieldDataOrigin.left + _fieldDataOrigin.width + 5 - fieldData.width;
                        } else {
                            left = 100 - fieldData.width;
                        }
                    } else if (fieldData.coordType !== "memo" && left + fieldData.width > _fieldDataOrigin.left + _fieldDataOrigin.width + 5) {
                        left = _fieldDataOrigin.left + _fieldDataOrigin.width + 5 - fieldData.width;
                    } else if (fieldData.coordType !== "memo" && left < _fieldDataOrigin.left - 5) {
                        left = _fieldDataOrigin.left - 5;
                    }

                    element.style.left = `${left}%`;
                    element.style.top = `${top}%`;
                } else {
                    let field_width = Number(String(element.style.width).slice(0, String(element.style.width)?.indexOf("%", 0)));
                    let field_height = Number(String(element.style.height).slice(0, String(element.style.height)?.indexOf("%", 0)));
                    if (e?.type === "touchmove") {
                        top = ((top - offsetTop) / height) * 100;
                        left = (left / width) * 100;
                    } else {
                        top = (top / height) * 100;
                        left = (left / width) * 100;
                    }

                    if (e?.type === "touchmove" && top < -5) {
                        let idx = selectedDoc;
                        let page = selectedPage;

                        if (selectedPage === 1 && selectedDoc === 0) {
                            top = 0;
                        } else {
                            if (selectedPage === 1 && selectedDoc > 0) {
                                idx = selectedDoc - 1;
                                page = maxPage[selectedDoc];
                                top = 100 - field_height;
                            } else if (selectedPage > 1 && selectedDoc > -1) {
                                page = page - 1;
                                top = 100 - field_height;
                            }

                            let doc = { idx, page };
                            let style = { ...props.fieldDataList[inx].style, top, left };
                            let _fieldDataList = update(props.fieldDataList, {
                                [inx]: { $merge: { doc, style } }
                            });
                            viewer.current.style.overflowY = "auto";
                            viewer.current.parentNode.parentNode.style.overflowX = "auto";

                            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList, selectedField: [] });
                        }
                    } else if (top < 0) {
                        top = 0;
                    } else if (e?.type === "touchmove" && top + field_height > 105) {
                        let idx = selectedDoc;
                        let page = selectedPage;

                        if (selectedDoc >= maxPage?.length - 1 && selectedPage === maxPage[selectedDoc]) {
                            top = 100 - field_height;
                        } else {
                            if (selectedPage === maxPage[selectedDoc]) {
                                idx = selectedDoc + 1;
                                page = 1;
                            } else if (selectedPage < maxPage[selectedDoc]) {
                                page = page + 1;
                            }
                            top = 0;

                            setSelectedDoc(idx);
                            setSelectedPage(page);
                            let doc = { idx, page };
                            let style = { ...props.fieldDataList[inx].style, top, left };
                            let _fieldDataList = update(props.fieldDataList, {
                                [inx]: { $merge: { doc, style } }
                            });

                            if (viewer?.current) {
                                viewer.current.style.overflowY = "auto";
                                viewer.current.parentNode.parentNode.style.overflowX = "auto";
                            }

                            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList, selectedField: [] });
                        }
                    } else if (top + field_height > 100) {
                        top = 100 - field_height;
                    }

                    if (left < 0) {
                        left = 0;
                    } else if (left + field_width > 100) {
                        left = 100 - field_width;
                    }

                    let eleLeft = Number(String(element.style.left)?.replace("%", ""));
                    let eleTop = Number(String(element.style.top)?.replace("%", ""));
                    if (multiFlag[0] > -1) {
                        props.selectedField.map(inx => {
                            let field = props.fieldDataList[inx];
                            let fieldEle = document.getElementById(field.id);
                            let fieldLeft = Number(String(fieldEle.style.left)?.replace("%", ""));
                            let fieldTop = Number(String(fieldEle.style.top)?.replace("%", ""));

                            fieldEle.style.top = `${fieldTop + top - eleTop}%`;
                            fieldEle.style.left = `${fieldLeft + left - eleLeft}%`;

                            return true;
                        });
                    }

                    element.style.left = `${left}%`;
                    element.style.top = `${top}%`;
                }

                if (!_.isEqual(_adjustPos, adjustPos)) {
                    setAdjustPos(_adjustPos);
                }

                if (isRange) {
                    setIsRange(false);
                }
            }

        } else if (isResizing) {
            let idName = props.isSigning ? "coordId" : "id";
            let id = props.fieldDataList[props.selectedField[0]][idName];
            let type = props.fieldDataList[props.selectedField[0]]?.type;
            let fieldData = _.cloneDeep(props.fieldDataList[props.selectedField[0]]);
            let element = document.getElementById(id);

            let { width, height, top, left } = element.parentNode.getBoundingClientRect();

            let field_width = 0;
            let field_height = 0;

            if (e?.type === "touchmove") {
                let docs = document.querySelectorAll(`div[data-docs='${selectedDoc}']`);
                let docsPage = docs[selectedPage - 1];
                let offsetTop = docsPage.offsetTop;
                let bounce = docs[selectedPage - 1].getBoundingClientRect();

                top = e.targetTouches[0].pageY - adjustPos.top - 50;
                top = ((top - offsetTop) / height) * 100;
                left = e.targetTouches[0].pageX - adjustPos.left - bounce.left;
                left = (left / width) * 100;
                field_width = left - Number(String(element.style.left).slice(0, String(element.style.left)?.indexOf("%", 0)));
                field_height = top - Number(String(element.style.top).slice(0, String(element.style.top)?.indexOf("%", 0)));
            } else {
                top = Number(String(element.style.top).slice(0, String(element.style.top)?.indexOf("%", 0)));
                left = Number(String(element.style.left).slice(0, String(element.style.left)?.indexOf("%", 0)));
                field_width = (e.nativeEvent.offsetX / width) * 100 - left;
                field_height = (e.nativeEvent.offsetY / height) * 100 - top;
            }

            if (props.isSigning) {
                let fieldData = _.cloneDeep(props.fieldDataList[props.selectedField[0]]);
                let eleLeft = Number(String(element.style.left).slice(0, String(element.style.left)?.indexOf("%", 0)));
                let eleTop = Number(String(element.style.top).slice(0, String(element.style.top)?.indexOf("%", 0)));
                if (fieldData.left + fieldData.width + 5 < field_width + eleLeft) {
                    field_width = fieldData.left - eleLeft + fieldData.width + 5;
                }
                if (fieldData.top + fieldData.height + 5 < field_height + eleTop) {
                    field_height = fieldData.top - eleTop + fieldData.height + 5;
                }

                element.style.width = `${field_width}%`;
                element.style.height = `${field_height}%`;
            } else {
                element.style.width = `${field_width}%`;
                element.style.height = `${field_height}%`;

                if (type === "radio") {
                    let itemCnt = props.fieldDataList[props.selectedField[0]]["radio"]?.item?.length;
                    let widthPer = ((height * (field_height / 100)) / itemCnt / width) * 100;
                    element.style.width = `${widthPer}%`;
                    element.style.height = `${field_height}%`;
                } else if (type === "check") {
                    let widthPer = ((height * (field_height / 100)) / width) * 100;
                    element.style.width = `${widthPer}%`;
                    element.style.height = `${field_height}%`;
                    let list = _.cloneDeep(
                        props.fieldDataList.filter(
                            x =>
                                Number(x.user) === Number(fieldData?.user) &&
                                type === "check" &&
                                x.check &&
                                x.check.label === fieldData.check.label &&
                                x.signed === fieldData.signed
                        )
                    );
                    list.map(x => {
                        if (x.id !== fieldData.id) {
                            let e = document.getElementById(x.id);
                            e.style.width = `${widthPer}%`;
                            e.style.height = `${field_height}%`;
                        }
                    });
                } else {
                    element.style.width = `${field_width}%`;
                    element.style.height = `${field_height}%`;
                }
            }
            if (isRange) {
                setIsRange(false);
            }
        } else if (isRange && isDragging) {
            let element = document.getElementById("range");
            if (element) {
                let { top, left } = rangePos;
                if (top === 0 && left === 0) {
                    top = e.nativeEvent.offsetY;
                    left = e.nativeEvent.offsetX;

                    element.style.top = `${top}px`;
                    element.style.left = `${left}px`;
                    setRangePos({ top, left });
                }

                let height = e.nativeEvent.offsetY - top;
                let width = e.nativeEvent.offsetX - left;
                if (width < 0) {
                    width = width * -1;
                    left = e.nativeEvent.offsetX;
                    element.style.left = `${left}px`;
                }
                if (height < 0) {
                    height = height * -1;
                    top = e.nativeEvent.offsetY;
                    element.style.top = `${top}px`;
                }
                element.style.width = `${width}px`;
                element.style.height = `${height}px`;
            }
        }
    };

    const handleTouchStart = (e, inx, type) => {
        // if (e?.touches?.length === 1) handleTapStart(e);
        // if (e?.touches?.length === 2) handlePinchStart(e);
    };

    const handlePinchMove = touches => {
        if (touches) {
            const pointA = getPointFromTouch(touches[0], viewer);
            const pointB = getPointFromTouch(touches[1], viewer);
            const distance = getDistanceBetweenPoints(pointA, pointB);
            const _scale = between(MIN_SCALE - ADDITIONAL_LIMIT, MAX_SCALE + ADDITIONAL_LIMIT, lastMobileScale * (distance / lastDistance));

            zoom(_scale);
        }
    };

    const handlePanMove = _.throttle(touches => {
        if (firstPanPoint.x > 0 && firstPanPoint.y > 0) {
            const point = getPointFromTouch(touches[0], viewer);
            const nextX = firstPanPoint.x - point.x + lastPanPoint.x;
            const nextY = firstPanPoint.y - point.y + lastPanPoint.y;

            if (nextY > 0) {
                if (viewer?.current) {
                    viewer.current.scrollTop = nextY;
                }
            }
            if (nextX > 0) {
                if (viewer?.current) {
                    viewer.current.scrollLeft = nextX;
                }
            }
        }
    }, 0);

    const zoom = _scale => {
        if (_scale < MAX_SCALE) {
            let pointW = firstPanPoint.x;
            let pointH = firstPanPoint.y;

            let nextY = pointH / _scale - pointH - (viewer.current.scrollTop - viewer.current.scrollTop / _scale);
            let nextX = pointW / _scale - pointW - (viewer.current.scrollLeft - viewer.current.scrollLeft / _scale);

            if (viewer.current.scrollTop === 0) {
                nextY = pointH / _scale - pointH;
            }

            pdfViewer.current.style.transform = `scale3d(${_scale},${_scale} , 1) translate3d(${nextX}px, ${nextY}px,0)`;
            setMobileScale(_scale);
        }
    };

    const getPointFromTouch = (touch, element) => {
        const rect = element.current.getBoundingClientRect();
        return {
            x: touch.clientX - rect.left,
            y: touch.clientY - rect.top
        };
    };

    const between = (min, max, value) => Math.min(max, Math.max(min, value));

    const getDistanceBetweenPoints = (pointA, pointB) => Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2));

    const handleTouchMove = e => {
        let touches = e.touches;

        if (touches && touches.length === 2) handlePinchMove(touches);
        if (touches && touches.length === 1) handlePanMove(touches);
    };

    const handleTouchEnd = e => {
        let _lastPanPoint = _.cloneDeep(lastPanPoint);
        let _firstPanPoint = _.cloneDeep(firstPanPoint);
        if (lastMobileScale !== mobileScale && mobileScale < 4) {
            let newWidth = pdfViewer.current.offsetWidth * mobileScale;
            let newHeight = pdfViewer.current.offsetHeight * mobileScale;
            let scrollTop = _firstPanPoint.y * mobileScale - _firstPanPoint.y + viewer.current.scrollTop * mobileScale;
            let scrollLeft = _firstPanPoint.x * mobileScale - _firstPanPoint.x + viewer.current.scrollLeft * mobileScale;

            pdfWrap.current.style.width = `${newWidth}px`;
            pdfWrap.current.style.height = `${newHeight}px`;
            pdfViewer.current.style.transform = `scale(${mobileScale},${mobileScale})`;
            viewer.current.scrollTop = scrollTop;
            viewer.current.scrollLeft = scrollLeft;

            if (_firstPanPoint.x > 0 && _firstPanPoint.y > 0) {
                _lastPanPoint.y = scrollTop;
                _lastPanPoint.x = scrollLeft;
            }
            _firstPanPoint.x = 0;
            _firstPanPoint.y = 0;
        }

        if (_firstPanPoint.x > 0 && _firstPanPoint.y > 0) {
            _lastPanPoint.y = viewer.current.scrollTop;
            _lastPanPoint.x = viewer.current.scrollLeft;
        }

        if (!_.isEqual(_lastPanPoint, lastPanPoint)) {
            setLastPanPoint(_lastPanPoint);
        }
        if (!_.isEqual(_firstPanPoint, firstPanPoint)) {
            setFirstPanPoint(_firstPanPoint);
        }

        setLastMobileScale(Number(mobileScale));
    };

    const handleCloneField = array => {
        let _fieldDataList = _.cloneDeep(props.fieldDataList);
        let _fieldDataOrigin = _.cloneDeep(props.fieldDataOrigin);
        let _selectedField = [];
        let maxFieldX = 0;
        let minFieldX = 100;
        let maxFieldY = 0;
        let minFieldY = 100;

        if (_fieldDataList?.length < 1) {
            return false;
        }
        let _selectedFieldData = [];
        let max_label = 0;
        array.map(inx => {
            return _selectedFieldData.push(_fieldDataList[inx]);
        });

        let check_list = [];
        Promise.all(
            array.map(inx => {
                let type = _fieldDataList[inx]?.type;
                let signed = _fieldDataList[inx]?.signed;
                let user = _fieldDataList[inx]?.user;
                let count = 0;
                let list = _.cloneDeep(_fieldDataList);

                let text_op_type = _fieldDataList[inx]?.id?.split("_")[0];

                let _fieldData = undefined;
                if (type === "check") {
                    _fieldData = _.cloneDeep({ ..._fieldDataList[inx], id: check_list?.length + 1 });
                } else {
                    if (signed) {
                        list = _fieldDataList.filter(item => Number(item.user) === Number(user) && item.type === type && item.signed);
                    } else {
                        list = _fieldDataList.filter(item => Number(item.user) === Number(user) && item.type === type && !item.signed);
                    }

                    if (TEXT_TYPE.includes(text_op_type)) {
                        list = list.filter(item => item.id?.includes(text_op_type));
                    }
                    if (list?.length > 0) {
                        list.sort((a, b) => {
                            let first_a = Number(String(a.id).lastIndexOf("_"));
                            let sortA = Number(String(a.id).substr(first_a + 1, String(a.id)?.length)) + 1;

                            let first_b = Number(String(b.id).lastIndexOf("_"));
                            let sortB = Number(String(b.id).substr(first_b + 1, String(b.id)?.length)) + 1;

                            return sortA < sortB ? 1 : -1;
                        });
                        let first = Number(String(list[0].id).lastIndexOf("_"));
                        count = Number(String(list[0].id).substr(first + 1, String(list[0].id)?.length)) + 1;
                    }
                    let id = "";
                    if (signed) {
                        if (TEXT_TYPE.includes(text_op_type)) {
                            id = text_op_type + "_" + count;
                        } else {
                            id = type + "_" + count;
                        }
                    } else {
                        if (TEXT_TYPE.includes(text_op_type)) {
                            id = text_op_type + "_" + user + "_" + count;
                        } else {
                            id = type + "_" + user + "_" + count;
                        }
                    }

                    _fieldData = _.cloneDeep({ ..._fieldDataList[inx], id });
                }

                _fieldData.tagList = null;

                _fieldData.style.top = _fieldData.style.top + _fieldData.style.height + 2 > 100 ? _fieldData.style.top : _fieldData.style.top + 2;
                _fieldData.style.left = _fieldData.style.left + _fieldData.style.width + 2 > 100 ? _fieldData.style.left : _fieldData.style.left + 2;
                _fieldData.doc = {
                    page: selectedPage,
                    idx: selectedDoc
                };

                let selectorDoc = document.querySelectorAll(`div[data-docs='${selectedDoc}']`);
                let selectorPage = selectorDoc[selectedPage - 1];

                let { offsetHeight, offsetWidth } = selectorPage;
                let addjustX = (3 / offsetWidth) * 100;
                let addjustY = (3 / offsetHeight) * 100;

                if (_fieldData.style.left + _fieldData.style.width > maxFieldX) {
                    maxFieldX = _fieldData.style.left + _fieldData.style.width - addjustX;
                }
                if (_fieldData.style.left < minFieldX) {
                    minFieldX = _fieldData.style.left;
                }
                if (_fieldData.style.top + _fieldData.style.height > maxFieldY) {
                    maxFieldY = _fieldData.style.top + _fieldData.style.height - addjustY;
                }
                if (_fieldData.style.top < minFieldY) {
                    minFieldY = _fieldData.style.top;
                }

                let selectorList = document.querySelectorAll(`div[data-docs='${_fieldData.doc.idx}']`);
                let selector = selectorList[_fieldData.doc.page - 1];
                if (selector) {
                    let _adjustPos = _.cloneDeep(adjustPos);

                    _adjustPos.top = ((selector.offsetHeight - 15) * (_fieldData.style.height / 100)) / 2;
                    _adjustPos.left = (selector.offsetWidth * (_fieldData.style.width / 100)) / 2;

                    setAdjustPos(_adjustPos);
                }

                if (_fieldData?.type === "check") {
                    check_list.push(_fieldData);
                } else {
                    _fieldDataList.push(_fieldData);
                    _fieldDataOrigin.push(_fieldData);
                    _selectedField.push(_fieldDataList?.length - 1);
                }

                return true;
            })
        );

        if (check_list?.length > 0) {
            let list_signed = check_list.filter(x => x.signed);
            let list_unsigned = check_list.filter(x => !x.signed);
            if (list_signed) {
                let tmp = _.uniqBy(list_signed, "user");
                tmp.map(field => {
                    let tmp_check = list_signed.filter(x => Number(x.user) === field.user);
                    tmp_check = _.uniqBy(tmp_check, "check.label");
                    tmp_check.map(c => {
                        let _checks = _fieldDataList.filter(x => x?.type === "check" && Number(x.user) === Number(c.user) && x.signed);
                        let _labels = _.uniqBy(_checks, "check.label");
                        max_label = _.maxBy(_labels, function(c) {
                            return Number(c.check.label);
                        });
                        max_label = Number(max_label.check.label);

                        let change_list = list_signed.filter(x => Number(x.user) === c.user && x.signed && c.check.label === x.check.label);

                        change_list.map(x => {
                            let count = 0;
                            let list = _fieldDataList.filter(item => Number(item.user) === Number(c.user) && item?.type === "check" && item.signed);
                            if (list?.length > 0) {
                                list.sort((a, b) => {
                                    let first_a = Number(String(a.id).lastIndexOf("_"));
                                    let sortA = Number(String(a.id).substr(first_a + 1, String(a.id)?.length)) + 1;

                                    let first_b = Number(String(b.id).lastIndexOf("_"));
                                    let sortB = Number(String(b.id).substr(first_b + 1, String(b.id)?.length)) + 1;

                                    return sortA < sortB ? 1 : -1;
                                });
                                let first = Number(String(list[0].id).lastIndexOf("_"));
                                count = Number(String(list[0].id).substr(first + 1, String(list[0].id)?.length)) + 1;
                            }
                            let id = "";
                            id = "check_" + count;
                            x.id = id;
                            x.check.label = Number(max_label) + 1;
                            if (x.check.minNum > change_list?.length) {
                                x.check.minNum = 0;
                            }
                            if (x.check.maxNum > change_list?.length) {
                                x.check.maxNum = change_list?.length;
                            }

                            _fieldDataList.push(x);
                            _fieldDataOrigin.push(x);
                            _selectedField.push(_fieldDataList?.length - 1);
                            return true;
                        });
                    });
                    return true;
                });
            }

            if (list_unsigned) {
                let tmp = _.uniqBy(list_unsigned, "user");
                tmp.map(field => {
                    let tmp_check = list_unsigned.filter(x => Number(x.user) === field.user);
                    tmp_check = _.uniqBy(tmp_check, "check.label");
                    tmp_check.map(c => {
                        let _checks = _fieldDataList.filter(x => x?.type === "check" && Number(x.user) === Number(c.user) && !x.signed);
                        let _labels = _.uniqBy(_checks, "check.label");
                        max_label = _.maxBy(_labels, function(c) {
                            return Number(c.check.label);
                        });
                        max_label = Number(max_label.check.label);

                        let change_list = list_unsigned.filter(x => Number(x.user) === c.user && !x.signed && c.check.label === x.check.label);

                        change_list.map(x => {
                            let count = 0;
                            let list = _fieldDataList.filter(item => Number(item.user) === Number(c.user) && item?.type === "check" && !item.signed);
                            if (list?.length > 0) {
                                // 내림차순
                                list.sort((a, b) => {
                                    let first_a = Number(String(a.id).lastIndexOf("_"));
                                    let sortA = Number(String(a.id).substr(first_a + 1, String(a.id)?.length)) + 1;

                                    let first_b = Number(String(b.id).lastIndexOf("_"));
                                    let sortB = Number(String(b.id).substr(first_b + 1, String(b.id)?.length)) + 1;

                                    return sortA < sortB ? 1 : -1;
                                });
                                let first = Number(String(list[0].id).lastIndexOf("_"));
                                count = Number(String(list[0].id).substr(first + 1, String(list[0].id)?.length)) + 1;
                            }
                            let id = "";
                            id = "check_" + x.user + "_" + count;
                            x.id = id;
                            x.check.label = Number(max_label) + 1;
                            if (x.check.minNum > change_list?.length) {
                                x.check.minNum = 0;
                            }
                            if (x.check.maxNum > change_list?.length) {
                                x.check.maxNum = change_list?.length;
                            }

                            _fieldDataList.push(x);
                            _fieldDataOrigin.push(x);
                            _selectedField.push(_fieldDataList?.length - 1);
                            return true;
                        });
                        return true;
                    });
                    return true;
                });
            }
        }

        let _multiStyle = _.cloneDeep(multiStyle);
        let multiDiv = document.getElementById("multibox");

        if (multiDiv) {
            multiDiv.style.top = _multiStyle.top + 2 > 100 ? _multiStyle.top : `${Number(String(minFieldY)?.replace("%", ""))}%`;
            multiDiv.style.left = _multiStyle.left + 2 > 100 ? _multiStyle.left : `${Number(String(minFieldX)?.replace("%", ""))}%`;
        } else {
            if (array?.length > 1) {
                _multiStyle = {
                    top: `${minFieldY}%`,
                    left: `${minFieldX}%`,
                    width: `${maxFieldX - minFieldX}%`,
                    height: `${maxFieldY - minFieldY}%`
                };
            }
        }

        let p1 = new Promise((resolve, reject) => {
            setMultiStyle(_multiStyle);
            resolve();
        });

        p1.then(() => {
            props.onUpdateState &&
                props.onUpdateState({
                    fieldDataList: _fieldDataList,
                    fieldDataOrigin: _fieldDataOrigin,
                    selectedField: _selectedField
                });
        });
    };

    const handleDeleteField = () => {
        let _fieldDataList = _.cloneDeep(
            props.fieldDataList.filter((x, inx) => {
                return (
                    props.selectedField.filter(filter => {
                        return filter === inx;
                    })?.length < 1
                );
            })
        );

        let p1 = new Promise((resolve, reject) => {
            setMultiFlag([-1, -1]);
            setCheckFlag([-1, -1]);
            setCheckStyle({ top: 0, left: 0, width: 0, height: 0 });
            setIsOpen(false);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList, selectedField: [] });
        });
    };

    const handleUpdateCheckGroupNum = (fieldDataList, fieldData, signed) => {
        let _checkList = [];
        let _fieldDataList = _.cloneDeep(fieldDataList);
        if (signed) {
            _checkList = _.cloneDeep(
                fieldDataList.filter(
                    item =>
                        Number(item.user) === Number(fieldData?.user) &&
                        item.type === "check" &&
                        item.signed &&
                        item.check &&
                        item.check.label === fieldData.check.label
                )
            );
        } else {
            _checkList = _.cloneDeep(
                fieldDataList.filter(
                    item =>
                        Number(item.user) === Number(fieldData?.user) &&
                        item.type === "check" &&
                        !item.signed &&
                        item.check &&
                        item.check.label === fieldData.check.label
                )
            );
        }

        let maxNum = _checkList?.length;
        _checkList.map(check => {
            check.check.maxNum = maxNum;
            let idx = fieldDataList.findIndex(x => x.id === check.id);
            if (idx > -1) {
                _fieldDataList = update(_fieldDataList, { [idx]: { $set: check } });
            }
            return true;
        });

        return _fieldDataList;
    };
    const handleAddCheckToGroup = inx => {
        let _fieldDataList = _.cloneDeep(props.fieldDataList);
        let _fieldDataOrigin = _.cloneDeep(props.fieldDataOrigin);
        let _selectedField = [];

        if (_fieldDataList?.length < 1) {
            return false;
        }
        let type = _fieldDataList[inx]?.type;
        let signed = _fieldDataList[inx]?.signed;
        let user = _fieldDataList[inx]?.user;
        let count = 0;
        let list = _.cloneDeep(_fieldDataList);
        if (signed) {
            list = _fieldDataList.filter(item => Number(item.user) === Number(user) && item.type === type && item.signed);
        } else {
            list = _fieldDataList.filter(item => Number(item.user) === Number(user) && item.type === type && !item.signed);
        }
        if (list?.length > 0) {
            list.sort((a, b) => {
                let first_a = Number(String(a.id).lastIndexOf("_"));
                let sortA = Number(String(a.id).substr(first_a + 1, String(a.id)?.length)) + 1;

                let first_b = Number(String(b.id).lastIndexOf("_"));
                let sortB = Number(String(b.id).substr(first_b + 1, String(b.id)?.length)) + 1;

                return sortA < sortB ? 1 : -1;
            });
            let first = Number(String(list[0].id).lastIndexOf("_"));
            count = Number(String(list[0].id).substr(first + 1, String(list[0].id)?.length)) + 1;
        }

        let id = "";
        if (signed) {
            id = type + "_" + count;
        } else {
            id = type + "_" + user + "_" + count;
        }

        let _fieldData = _.cloneDeep(_fieldDataList[inx]);
        _fieldData.id = id;
        _fieldData.check.checked = false;
        _fieldData.check.content = "";

        let checkList = list.filter(item => Number(item.check.label) === Number(_fieldData.check.label));

        let minmax = handleMinMaxStyle(checkList, false);

        let _checkStyle = _.cloneDeep(checkStyle);
        let _checkFlag = _.cloneDeep(checkFlag);
        let _addStyle = _.cloneDeep(addStyle);

        _fieldData.style.top = minmax.max_style.top + minmax.max_style.height + 1;

        let _selectedPage = minmax.max_doc.page;
        let _selectedDoc = selectedDoc;

        if (_fieldData.style.top > 100 - _fieldData.style.height) {
            if (maxPage[_selectedDoc] < _selectedPage + 1) {
                _fieldData.style.top = minmax.max_style.top;
                if (minmax.max_style.left + _fieldData.style.width + 1 > 100) {
                    _fieldData.style.left = minmax.min_style.left - _fieldData.style.width - 1;
                } else {
                    _fieldData.style.left = minmax.max_style.left + _fieldData.style.width + 1;
                }
            } else {
                _fieldData.style.top = _fieldData.style.top - 100;
                _selectedPage = minmax.max_doc.page + 1;
            }
        }

        _fieldData.doc = {
            page: _selectedPage,
            idx: _selectedDoc
        };

        _fieldDataList.push(_fieldData);
        _fieldDataOrigin.push(_fieldData);
        _selectedField.push(_fieldDataList?.length - 1);
        _fieldDataList = handleUpdateCheckGroupNum(_fieldDataList, _fieldData, signed);
        let checkData = {};
        if (checkData) {
            _checkFlag = checkData[0];
            _checkStyle = checkData[1];
            _addStyle = checkData[2];
        }

        let p1 = new Promise((resolve, reject) => {
            setIsDragging(false);
            setIsResizing(false);
            setNoDragging(true);
            setCheckFlag(_checkFlag);
            setCheckStyle(_checkStyle);
            setAddStyle(_addStyle);
            resolve();
        });

        p1.then(() => {
            setAdjustPos({ top: 0, left: 0 });

            props.onUpdateState &&
                props.onUpdateState({
                    fieldDataList: _fieldDataList,
                    fieldDataOrigin: _fieldDataOrigin,
                    selectedField: _selectedField
                });
        });
    };

    const fieldAddButton = () => {
        handleAddCheckToGroup(props.selectedField[0]);
    };

    const handleRequire = inx => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);
        fieldData.require = !fieldData.require;
        if (fieldData.require) {
            fieldData.readonly = false;
        }
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        let checkList = [];
        if (fieldData?.type === "check") {
            checkList = _.cloneDeep(
                props.fieldDataList.filter(
                    item =>
                        item.type === "check" &&
                        Number(item.user) === Number(fieldData?.user) &&
                        item.check &&
                        item.check.label === fieldData.check.label &&
                        item.signed === fieldData.signed
                )
            );
            checkList.map(check => {
                let i = _fieldDataList.findIndex(x => x.id === check.id);
                check.require = fieldData.require;
                _fieldDataList = update(_fieldDataList, { [i]: { $set: { ...check } } });
                return true;
            });
        }

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleIsMove = inx => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData.isMove = !fieldData.isMove;

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });
        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleReadOnly = inx => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData.readonly = !fieldData.readonly;
        if (fieldData.readonly) {
            fieldData.require = false;
        }

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });
        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleChangeColorPicker = (inx, type, color) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        if (props.isSigning) {
            fieldData["color"] = color.hex;
        } else {
            fieldData[type]["color"] = color.hex;
        }

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const checkTextValidation = (type, value) => {
        let result = true;
        if (type === "email") {
            if (value) {
                result = validateEmail(value);
            }
        } else if (type === "phone") {
            if (value) {
                result = checkPhoneNum(value);
            }
        } else if (type === "regisNum") {
            if (value) {
                result = validateRegisNum(value);
            }
        }

        return result;
    };

    const renderTypeName = type => {
        if (type === "name") {
            return ("이름");
        } else if (type === "email") {
            return ("이메일");
        } else if (type === "phone") {
            return ("전화번호");
        } else if (type === "addr") {
            return ("주소");
        } else if (type === "regisNum") {
            return ("숫자");
        } else if (type === "bankAcnt") {
            return ("계좌번호");
        } else {
            return "";
        }
    };

    const handleOptionChange = (inx, type, data) => {
        let _fieldDataList = _.cloneDeep(props.fieldDataList);
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        let { name, value } = data;
        if (props.isSigning) {
            if (name === "isChecked") {
                if (value) {
                    let _checkList = _.cloneDeep(
                        _fieldDataList.filter(
                            item =>
                                item.coordType === "check" &&
                                Number(item.user) === Number(fieldData?.user) &&
                                item.label === fieldData.label &&
                                item.signed === fieldData.signed
                        )
                    );
                    let checked_cnt = _checkList.filter(x => x.isChecked)?.length;
                    if (_checkList?.length === 2 && checked_cnt === 1 && _checkList[0].minNum === 1 && _checkList[0].maxNum === 1) {
                        let _checked = _checkList.filter(x => x.isChecked)[0];
                        _checked.isChecked = false;
                        let idx = _fieldDataList.findIndex(x => x.coordId === _checked.coordId);
                        _fieldDataList = update(_fieldDataList, { [idx]: { $set: { ..._checked } } });
                        fieldData[name] = true;
                    } else {
                        if (fieldData.maxNum > checked_cnt) {
                            fieldData[name] = value;
                        }
                    }
                } else {
                    fieldData[name] = value;
                }
            } else {
                fieldData[name] = value;
                if (fieldData?.coordType === "text") {
                    let id_split = fieldData?.coordId?.split("_");
                    if (TEXT_TYPE.includes(id_split[0])) {
                        let textType = id_split[0];
                        let typeValid = checkTextValidation(textType, value);
                        if (!typeValid) {
                            fieldData.is_error = true;
                        } else {
                            fieldData.is_error = false;
                        }
                    }
                }
            }
        } else {
            if (name === "textType") {
                if (value === "") {
                    let typeDataList = _fieldDataList.filter(
                        x =>
                            x.type === "text" && Number(x.user) === Number(fieldData?.user) && x.signed === fieldData.signed && x.id?.includes("text")
                    );
                    let typeCount = 0;
                    if (typeDataList.length > 0) {
                        typeDataList.sort((a, b) => {
                            let first_a = Number(String(a.id).lastIndexOf("_"));
                            let sortA = Number(String(a.id).substr(first_a + 1, String(a.id).length)) + 1;

                            let first_b = Number(String(b.id).lastIndexOf("_"));
                            let sortB = Number(String(b.id).substr(first_b + 1, String(b.id).length)) + 1;

                            return sortA < sortB ? 1 : -1;
                        });
                        let first = Number(String(typeDataList[0].id).lastIndexOf("_"));
                        typeCount = Number(String(typeDataList[0].id).substr(first + 1, String(typeDataList[0].id).length)) + 1;
                    }
                    fieldData.id = "text_" + fieldData?.user + "_" + typeCount;
                } else if (TEXT_TYPE.includes(value)) {
                    if (!fieldData.id?.includes(value)) {
                        let typeDataList = _fieldDataList.filter(
                            x =>
                                x.type === "text" &&
                                Number(x.user) === Number(fieldData?.user) &&
                                x.signed === fieldData.signed &&
                                x.id?.includes(value)
                        );
                        let typeCount = 0;
                        if (typeDataList.length > 0) {
                            typeDataList.sort((a, b) => {
                                let first_a = Number(String(a.id).lastIndexOf("_"));
                                let sortA = Number(String(a.id).substr(first_a + 1, String(a.id).length)) + 1;

                                let first_b = Number(String(b.id).lastIndexOf("_"));
                                let sortB = Number(String(b.id).substr(first_b + 1, String(b.id).length)) + 1;

                                return sortA < sortB ? 1 : -1;
                            });
                            let first = Number(String(typeDataList[0].id).lastIndexOf("_"));
                            typeCount = Number(String(typeDataList[0].id).substr(first + 1, String(typeDataList[0].id).length)) + 1;
                        }
                        fieldData.id = value + "_" + fieldData?.user + "_" + typeCount;
                        fieldData.text.label = renderTypeName(value);
                    }
                }
            } else {
                if (fieldData[type][name] === value) {
                    if (name === "fontWeight" || name === "fontStyle" || name === "textDecoration" || name === "delimiter") {
                        fieldData[type][name] = "";
                    }
                } else {
                    fieldData[type][name] = value;
                }
            }
        }
        if (type === "check" && (name === "minNum" || name === "maxNum")) {
            let list = _.cloneDeep(
                props.fieldDataList.filter(
                    x =>
                        x.type === "check" &&
                        Number(x.user) === Number(fieldData?.user) &&
                        x.signed === fieldData.signed &&
                        x.check &&
                        x.check.label === fieldData.check.label
                )
            );
            let idx = 0;
            list.map(check => {
                check.check[name] = value;
                idx = _fieldDataList.findIndex(x => x.id === check.id);
                if (idx > -1) {
                    _fieldDataList = update(_fieldDataList, { [idx]: { $set: { ...check } } });
                }
                return true;
            });
        } else {
            _fieldDataList = update(_fieldDataList, { [inx]: { $set: { ...fieldData } } });
        }

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleAddOption = (inx, type, text) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData[type].option.push(text);
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleDeleteOption = (inx, type, idx) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);
        fieldData.drop.option.splice(idx, 1);

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleRemoveSign = (inx, type) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData.preview = "";
        fieldData.signDone = false;

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $merge: { ...fieldData, sign: { done: false }, preview: "" } } });

        if (props.referer === "facial") {
            props.onUpdateState &&
                props.onUpdateState({
                    fieldDataList: _fieldDataList,
                    isSignComplete: false
                });
        } else {
            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
        }
    };

    const handleSetDate = (inx, value) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData.content = Moment(value).format("YYYY-MM-DD");

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        let p1 = new Promise((resolve, reject) => {
            setOpenCalendar(-1);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
        });
    };

    const checkCondition = condition => {
        let flag = false;
        if (condition) {
            if (condition.coordType === "sign" && condition.signDone) {
                flag = true;
            } else if (condition.coordType === "stamp" && condition.signDone) {
                flag = true;
            } else if (condition.coordType === "date" && condition.content !== "" && condition.content) {
                flag = true;
            } else if (
                condition.coordType === "text" &&
                condition.content?.length > 0 &&
                (!condition.coordId?.includes("popup") || props.clickList?.findIndex(x => x.id === condition.id) > -1)
            ) {
                flag = true;
            } else if (condition.coordType === "check" && condition.isChecked) {
                flag = true;
            } else if (condition.coordType === "drop" && Number(condition.selected) !== 0) {
                flag = true;
            } else if (condition.coordType === "radio" && condition.selected !== "-1") {
                flag = true;
            } else if (condition.coordType === "image" && condition.preview) {
                flag = true;
            }
        }

        return flag;
    };

    const handleSelectDropOption = (inx, k) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        if (props.isSigning) {
            fieldData["selected"] = k;
        } else {
            fieldData["drop"]["default"] = k;
        }

        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });
        let p1 = new Promise((resolve, reject) => {
            setIsDisplayDropOption(false);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
        });
    };

    const handleDisplayDrop = inx => {
        let p1 = new Promise((resolve, reject) => {
            setIsDisplayDropOption(!isDisplayDropOption);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ selectedField: [inx] });
        });
    };

    const handleRemoveImage = inx => {
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $merge: { preview: "" } } });
        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handlePdfError = () => {
        if (props.popup) {
            props.close();
        } else {
            history.goBack();
        }
    };

    const togglePdfErrorPopup = () => {
        let p1 = new Promise((resolve, reject) => {
            setIsPdfError(!isPdfError);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ loading: false });
        });
    };

    const handleLoad = (inx, img) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);
        fieldData.imageWidth = img.target.naturalWidth;
        fieldData.imageHeight = img.target.naturalHeight;
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });

        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };

    const handleMobileTouchCondition = (inx, value) => {
        let field = _.cloneDeep(props.fieldDataList[inx]);
        let _fieldDataList = _.cloneDeep(props.fieldDataList);
        let _checkFlag = _.cloneDeep(checkFlag);
        let _checkStyle = _.cloneDeep(checkStyle);
        let _addStyle = _.cloneDeep(addStyle);

        if (field.coordType === "check") {
            let checkData = {};
            if (checkData) {
                _checkFlag = checkData[0];
                _checkStyle = checkData[1];
                _addStyle = checkData[2];
            }
            if (field.isChecked) {
                field.isChecked = false;
            } else {
                let _checkList = _.cloneDeep(
                    _fieldDataList.filter(
                        item =>
                            item.coordType === "check" &&
                            Number(item.user) === Number(field?.user) &&
                            item.label === field.label &&
                            item.signed === field.signed
                    )
                );
                let checked_cnt = _checkList.filter(x => x.isChecked)?.length;
                if (_checkList?.length === 2 && checked_cnt === 1 && _checkList[0].minNum === 1 && _checkList[0].maxNum === 1) {
                    let _checked = _checkList.filter(x => x.isChecked)[0];
                    _checked.isChecked = false;
                    let idx = _fieldDataList.findIndex(x => x.coordId === _checked.coordId);
                    _fieldDataList = update(_fieldDataList, { [idx]: { $set: { ..._checked } } });
                    field.isChecked = true;
                } else {
                    if (field.maxNum > checked_cnt) {
                        field.isChecked = true;
                    }
                }
            }

            _fieldDataList = update(_fieldDataList, { [inx]: { $set: { ...field } } });
        }
        if (field.coordType === "date") {
            field.content = Moment(value).format("YYYY-MM-DD");
            _fieldDataList = update(_fieldDataList, { [inx]: { $set: { ...field } } });
        }

        let p1 = new Promise((resolve, reject) => {
            setCheckFlag(_checkFlag);
            setCheckStyle(_checkStyle);
            setAddStyle(_addStyle);
            setOpenCalendar(-1);
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ selectedField: [inx], openInputBox: false, fieldDataList: _fieldDataList });
        });
    };

    const handleReleaseSelected = text => {
        if (props.isSigning) {
            props.onAddNewMemo(text);
        } else {
            props.onUpdateState && props.onUpdateState({ selectedField: [] });
        }
    };

    const handleChangeMemoAt = (inx, data) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData["memoAt"] = data;
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });
        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };
    const handleChangeConnect = (inx, data) => {
        let fieldData = _.cloneDeep(props.fieldDataList[inx]);

        fieldData["connect"] = data;
        let _fieldDataList = update(props.fieldDataList, { [inx]: { $set: { ...fieldData } } });
        props.onUpdateState && props.onUpdateState({ fieldDataList: _fieldDataList });
    };
    const handleDeleteMemo = id => {
        props.onDeleteMemo && props.onDeleteMemo(id);
    };

    const handleCloseMobileInput = () => {
        let p1 = new Promise((resolve, reject) => {
            setCheckFlag([-1, -1]);
            setCheckStyle({ top: 0, left: 0, width: 0, height: 0 });
            setAddStyle({ top: 0, left: 0, width: 0, height: 0 });
            resolve();
        });
        p1.then(() => {
            props.onUpdateState && props.onUpdateState({ selectedField: [] });
        });
    };

    const handleUpdateState = state => {
        if (Object.keys(state).includes("openCalendar")) {
            setOpenCalendar(state.openCalendar);
        }
        if (Object.keys(state).includes("checkFlag")) {
            setCheckFlag(state.checkFlag);
        }
        if (Object.keys(state).includes("checkStyle")) {
            setCheckStyle(state.checkStyle);
        }
    };

    const handleMobilePos = (fieldData, selectedField) => {
        let _checkFlag = _.cloneDeep(checkFlag);
        let _checkStyle = _.cloneDeep(checkStyle);
        let _addStyle = _.cloneDeep(addStyle);

        if (fieldData) {
            if (fieldData.coordType === "check") {
                let checkData = {};
                if (checkData) {
                    _checkFlag = checkData[0];
                    _checkStyle = checkData[1];
                    _addStyle = checkData[2];
                }
            }

            let p1 = new Promise((resolve, reject) => {
                setCheckFlag(_checkFlag);
                setCheckStyle(_checkStyle);
                setAddStyle(_addStyle);
                resolve();
            });
            p1.then(() => {
                props.onUpdateState && props.onUpdateState({ selectedField });
            });
        } else {
            let p1 = new Promise((resolve, reject) => {
                setCheckFlag([-1, -1]);
                setCheckStyle({ top: 0, left: 0, width: 0, height: 0 });
                setAddStyle({ top: 0, left: 0, width: 0, height: 0 });
                resolve();
            });
            p1.then(() => {
                props.onUpdateState && props.onUpdateState({ selectedField: [] });
            });
        }
    };

    if (!isDestroy) {
        return (
            <>
                {props.popup && props.referer !== "tempPopup" && (
                    <div className={[styles.docsTitleBox, props.popup ? styles.popup : null].join(" ")}>
                        <div className={styles.docsPages}>
                            {"Pages"}
                            {" : "}
                            <div className={styles.totalPage}>
                                {props.docs && props.docs.length > 0 && props.docs?.length > docsIdx ? props.docs[docsIdx].pages : null}
                            </div>
                        </div>
                        <div className={styles.docsTitle}>
                            {props.docs && props.docs.length > 0 && props.docs?.length > docsIdx ? props.docs[docsIdx].filename : null}
                        </div>
                        {props.popup && (
                            <button className={styles.closeBtn} onClick={props.close}>
                                <SVG.Close />
                            </button>
                        )}
                    </div>
                )}

                {!props.verified_auth && !props.verified_cert && (
                    <div
                        ref={viewer}
                        data-pdf-viewer
                        className={styles.innerPdfBox}
                        onScroll={props.tempPopup ? undefined : onScroll}
                        style={
                            {
                                // transform: `scale(${mobileScale})`
                                // zoom: mobil
                            }
                        }
                    >
                        <div ref={pdfWrap} className={[styles.innerPdf, props.isSigning ? styles.signing : null].join(" ")} id="PDF">
                            <div ref={pdfViewer} className={styles.zoomContainer}>
                                {props.docs?.length > 0 &&
                                    props.docs?.length === tempLoadData?.length &&
                                    props.docs.map((doc, idx) => {
                                        return (
                                            <PDFDocument
                                                key={idx}
                                                idx={idx}
                                                file={doc.docs}
                                                docsIdx={docsIdx}
                                                pages={pages}
                                                docs_pages={docs_pages}
                                                blankViewPage={blankViewPage}
                                                maxRendering={maxRendering}
                                                docRendering={docRendering}
                                                maxPage={maxPage}
                                                docsHeight={docsHeight}
                                                pdfArray={pdfArray}
                                                pdfArrayFlag={pdfArrayFlag}
                                                pdfCanvas={pdfCanvas}
                                                mobileInputIdx={mobileInputIdx}
                                                mobileInputType={mobileInputType}
                                                mobileInputData={mobileInputData}
                                                isDragging={isDragging}
                                                isResizing={isResizing}
                                                isRange={isRange}
                                                isDisplayDropOption={isDisplayDropOption}
                                                acceptFiles={acceptFiles}
                                                isPdfError={isPdfError}
                                                copyIdx={copyIdx}
                                                multiFlag={multiFlag}
                                                multiStyle={multiStyle}
                                                isOpen={isOpen}
                                                noDragging={noDragging}
                                                checkFlag={checkFlag}
                                                checkStyle={checkStyle}
                                                addStyle={addStyle}
                                                hoverCheck={hoverCheck}
                                                openTxt={openTxt}
                                                totalPage={totalPage}
                                                totalNowPage={totalNowPage}
                                                openCalendar={openCalendar}
                                                completeErrMsg={completeErrMsg}
                                                selectedPage={selectedPage}
                                                selectedDoc={selectedDoc}
                                                scale={scale}
                                                isMobile={isMobile}
                                                isTablet={isTablet}
                                                onChangeColorPicker={handleChangeColorPicker}
                                                LoadTotal={LoadTotal}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseUp={handleMouseUp}
                                                onMouseDown={handleMouseDown}
                                                onMouseMove={handleMouseMove}
                                                onTouchMove={handleTouchMove}
                                                onMobileInputClick={handleMobileInputClick}
                                                onDeleteField={handleDeleteField}
                                                onCloneField={handleCloneField}
                                                onOptionChange={handleOptionChange}
                                                onRequire={handleRequire}
                                                onIsMove={handleIsMove}
                                                onReadOnly={handleReadOnly}
                                                onAddOption={handleAddOption}
                                                onCheckCondition={checkCondition}
                                                onSetDate={handleSetDate}
                                                onSelectDropOption={handleSelectDropOption}
                                                onDisplayDrop={handleDisplayDrop}
                                                onTouchStart={handleTouchStart}
                                                onTouchEnd={handleTouchEnd}
                                                onRemoveSign={handleRemoveSign}
                                                onDeleteOption={handleDeleteOption}
                                                onRemoveImage={handleRemoveImage}
                                                togglePdfErrorPopup={togglePdfErrorPopup}
                                                onLoad={handleLoad}
                                                onReleaseSelected={handleReleaseSelected}
                                                onChangeMemoAt={handleChangeMemoAt}
                                                onChangeConnect={handleChangeConnect}
                                                onDeleteMemo={handleDeleteMemo}
                                                onMobileTouchCondition={handleMobileTouchCondition}
                                                onAddCheckToGroup={handleAddCheckToGroup}
                                                fieldAddButton={fieldAddButton}
                                                onUpdateState={handleUpdateState}
                                                popup={props.popup}
                                                referer={props.referer}
                                                selectedField={props.selectedField}
                                                onOpenSignModal={props.onOpenSignModal}
                                                room={props.room}
                                                profile={props.profile}
                                                tempPopup={props.tempPopup}
                                                setting={props.setting}
                                                signedDataList={props.signedDataList}
                                                previewPopup={props.previewPopup}
                                                preview_complete={props.preview_complete}
                                                preview_cond={props.preview_cond}
                                                fieldDataList={props.fieldDataList}
                                                showField={props.showField}
                                                single={props.single}
                                                receiver={props.receiver}
                                                selectedSign={props.selectedSign}
                                                isSigning={props.isSigning}
                                                presignCond={props.presignCond}
                                                pdfId={props.pdfId}
                                                resetSelectedField={props.resetSelectedField}
                                                templateCond={props.templateCond}
                                                clickList={props.clickList}
                                                onClickList={props.onClickList}
                                            />
                                        );
                                    })}
                            </div>
                        </div>

                        {props.openMobileHistory && (
                            <div className={[styles.mobileChatBox].join(" ")}>
                                <div className={styles.chatHeader}>
                                    <div className={styles.goback} onClick={() => props.onUpdateState({ openMobileHistory: false })}>
                                        <SVG.Close />
                                    </div>
                                    <div className={styles.title}>{("계약 상세정보")}</div>
                                    <div className={styles.goback}></div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isPdfError && (
                    <ConfirmPopup
                        title={("PDF 에러")}
                        message={[("올바른 PDF가 아닙니다. 오류가 계속되면 문의 부탁드립니다.")]}
                        noCancel={true}
                        cancel={handlePdfError}
                        success={handlePdfError}
                        successText={("확인")}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default PDFViewer;
