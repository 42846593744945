import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.scss";
import { hexToRgb } from "components/shared/hexToRgb";
import { envProps } from "module/configureStore";

const COLOR_LIST = envProps.colorList;

const SIGN = "sign";
const SignBox = (props, context) => {
    const [isBottom, setBottom] = useState(false);
    const handleMouseDown = type => e => {
        if (!props.isMobile && !props.isTablet && !props.readonly) {
            props.onMouseDown && props.onMouseDown(e, props.inx, type);
        }
    };

    const handleMouseUp = e => {
        if (!props.readonly) {
            props.onMouseUp && props.onMouseUp(e, props.inx);
        }
    };

    const handleOpenSignModal = () => {
        props.onOpenSignModal && props.onOpenSignModal(props.inx);
    };

    const handleRemoveSign = () => {
        props.onRemoveSign && props.onRemoveSign(props.inx, SIGN);
    };

    const handleTouchStart = type => e => {
        props.onTouchStart && props.onTouchStart(e, props.inx, type);
    };

    const handleKeyDown = e => {
        e.stopPropagation();
        props.onKeyDown && props.onKeyDown(e, "condition", props.inx);
    };

    const handleKeyUp = e => {
        e.stopPropagation();
        props.onKeyUp && props.onKeyUp(e, "condition", props.inx);
    };

    const handleDeleteField = () => {
        props.onDeleteField && props.onDeleteField();
    };

    const handleCloneField = () => {
        props.onCloneField && props.onCloneField([props.inx]);
    };

    const handleMobileClick = () => {
        props.onMobileTouchCondition && props.onMobileTouchCondition(props.inx);
    };

    useEffect(() => {
        if (!props.isSigning && !props.isDragging && props.isSelected) {
            let id = props.fieldData.id;
            let top = props.fieldData.top;
            let contain = document.getElementById(id);
            let option = contain.querySelector("#option");
            if (option) {
                if (contain.parentNode.offsetHeight * (top / 100) < option.offsetHeight) {
                    setBottom(true);
                } else {
                    setBottom(false);
                }
            }
        }
    }, [props.isDragging]);

    const returnSrc = type => {
        switch (type) {
            case "sign":
                return "/static/media/progress/bg-shape-49@3x.png";
            case "stamp":
                return "https://glosign.com/static/media/progress/stamp@3x.png";
            case "text":
                return "/static/media/progress/shape-58@2x.png";
            case "date":
                return "/static/media/progress/shape-63@2x.png";
            case "check":
                return "/static/media/progress/ic-shape-check@2x.png";
            case "image":
                return "/static/media/progress/ic-shape-image@2x.png";
            default:
                return "/static/media/progress/bg-shape-49@3x.png";
        }
    };

    // 서명 뷰어
    return props.isSigning ? (
        <div
            className={styles.setItemsBox}
            id={props.fieldData.id}
            data-name={"sign"}
            data-user={props.isSelected ? ((props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10) : -1}
            style={{
                top: props.fieldData.top + "%",
                left: props.fieldData.left + "%",
                width: props.fieldData.width + "%",
                height: props.fieldData.height + "%",
                pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                zIndex: props.isSelected ? 2 : 1,
                userSelect: "none",
                msUserSelect: "none"
            }}
        >
            {props.children}
            <div
                className={[styles.setSign, props.isSelected ? styles.on : null].join(" ")}
                style={props.referer === "previewtemplate" && props.preview_complete ? { cursor: "default" } : undefined}
            >
                {/* 미리보기 팝업 서명조건 분기 (수정자:JADE) */}
                {props.is_template_cond ? (
                    // 서식 미리보기 팝업 조건 출력
                    <div
                        className={[styles.noSign, props.isSelected ? styles.on : null].join(" ")}
                        style={{
                            backgroundColor: `rgba(${hexToRgb(
                                props.fieldData?.preview_color
                                    ? props.fieldData.preview_color
                                    : props.fieldData.preview === ""
                                    ? COLOR_LIST[props.fieldData?.user]
                                    : "0,0,0",
                                "0.3"
                            )})`,
                            cursor: "initial",
                            border: "none"
                        }}
                    >
                        <div
                            className={[
                                styles.signField,
                                props.fontSize === 0 ? styles.small : null,
                                props.fieldData.preview !== "" &&
                                !props.fieldData.preview.includes("ic-shape-image") &&
                                props.fieldData.type === "image"
                                    ? styles.preview
                                    : undefined
                            ].join(" ")}
                        >
                            <img src={props.fieldData.preview === "" ? returnSrc(props.fieldData.type) : props.fieldData.preview} alt="sign" />
                        </div>
                    </div>
                ) : props.preview_cond ? (
                    props.preCondValue ? (
                        <div className={[styles.onSign, props.isSelected ? styles.on : null].join(" ")}>
                            {!props.preview_cond && (
                                <div id="option" className={[styles.fieldOption, props.isSelected ? styles.on : null].join(" ")}>
                                    <button onClick={!props.readonly ? (props.signedField ? undefined : handleRemoveSign) : undefined} data-noselect={true}>
                                        {("서명 삭제하기")}
                                    </button>
                                </div>
                            )}

                            <div
                                className={[
                                    styles.fieldLine,
                                    (props.selectedSign && props.selectedSign.stamp) ||
                                    (props.signerSign && props.signerSign[props.curSigner] && props.signerSign[props.curSigner][1] === "stamp")
                                        ? styles.stampBox
                                        : null
                                ].join(" ")}
                                style={{
                                    cursor: props.fieldData.cursor
                                }}
                                data-type="input"
                                // preview_cond : 미리보기 팝업에서의 조건 리스트 flag, true인 경우 mousedown 기능만
                                onMouseDown={
                                    !props.readonly ? 
                                        (props.isMobile || props.isTablet
                                            ? undefined
                                            : props.signedField
                                            ? undefined
                                            : props.preview_cond
                                            ? props.onCondValue
                                            : handleMouseDown("drag"))
                                    : undefined
                                }
                                onMouseUp={
                                    !props.readonly ?
                                        (props.isMobile || props.isTablet
                                            ? undefined
                                            : props.signedField
                                            ? undefined
                                            : props.preview_cond
                                            ? undefined
                                            : handleMouseUp)
                                    : undefined
                                }
                                // onTouchStart={handleTouchStart("drag")}
                                onClick={!props.readonly ? (props.isMobile || props.isTablet ? (props.signedField ? undefined : handleMobileClick) : undefined) : undefined}
                            >
                                {props.fieldData.preview ? (
                                    <img src={props.fieldData.preview} draggable={false} alt="signImage" />
                                ) : props.facialSelectedSign ? (
                                    <img src={props.facialSelectedSign} draggable={false} alt="signImage" />
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={[styles.noSign, props.isSelected ? styles.on : null].join(" ")}
                            onClick={!props.readonly ? (props.signedField ? undefined : props.preview_cond ? props.onCondValue : handleOpenSignModal) : undefined}
                        >
                            <div className={[styles.signField, props.fontSize === 0 ? styles.small : null].join(" ")}>
                                <div className={styles.signText} style={{ fontSize: props.fontSize }}>
                                    {("서명")}
                                </div>
                                <img
                                    src="/static/media/contractpage/pen.png"
                                    alt="sign"
                                />
                            </div>
                        </div>
                    )
                ) : props.is_othercond ? (
                    <div className={[styles.onSign, props.isSelected ? styles.on : null].join(" ")}>
                        <div
                            className={[styles.fieldLine].join(" ")}
                            style={{
                                cursor: "default"
                            }}
                            data-type="input"
                            onMouseDown={undefined}
                            onMouseUp={undefined}
                            onClick={undefined}
                        >
                            {props.fieldData.preview ? (
                                <img src={props.fieldData.preview} draggable={false} alt="signImage" />
                            ) : props.facialSelectedSign ? (
                                <img src={props.facialSelectedSign} draggable={false} alt="signImage" />
                            ) : null}
                        </div>
                    </div>
                ) :
                (
                    <div className={[styles.onSign, props.isSelected ? styles.on : null].join(" ")}>
                        <div
                            className={[
                                styles.fieldLine,
                                (props.selectedSign && props.selectedSign.stamp) ||
                                (props.signerSign && props.signerSign[props.curSigner] && props.signerSign[props.curSigner][1] === "stamp")
                                    ? styles.stampBox
                                    : null
                            ].join(" ")}
                            style={{
                                cursor: props.fieldData.cursor
                            }}
                            data-type="input"
                        >
                            {props.fieldData.preview ? (
                                <img src={props.fieldData.preview} draggable={false} alt="signImage" />
                            ) : props.facialSelectedSign ? (
                                <img src={props.facialSelectedSign} draggable={false} alt="signImage" />
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div
            id={props.fieldData.id}
            className={[styles.fieldBox, props.single ? styles.single : null].join(" ")}
            data-name={"sign"}
            data-user={(props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            tabIndex={0}
            style={{
                top: props.fieldData.top + "%",
                left: props.fieldData.left + "%",
                width: props.fieldData.width + "%",
                height: props.fieldData.height + "%",
                pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                zIndex: props.isSelected ? 2 : 1,
                touchAction: "none",
                userSelect: "none",
                msUserSelect: "none"
            }}
        >
            {props.children}
            <div
                className={[styles.fieldLine, props.single ? styles.single : null, props.fieldData.signed ? styles.signedLine : null].join(" ")}
                style={{
                    pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                    opacity: (props.isResizing || props.isDragging) && props.isSelected ? 0.5 : 0.9
                }}
                onMouseDown={!props.readonly ? handleMouseDown("drag") : undefined}
                onTouchStart={!props.readonly ? handleTouchStart("drag") : undefined}
                onMouseUp={!props.readonly ? handleMouseUp : undefined}
            >
                <div
                    className={[styles.fieldSign, props.isSelected ? styles.fieldOn : null, props.fieldData.signed ? styles.signedField : null].join(
                        " "
                    )}
                    style={(props.single || props.fieldData.signed) && props.selectedSign ? { backgroundColor: "#bababa", background: "none" } : null}
                >
                    {props.single || props.fieldData.signed ? (
                        props.fieldData.preview && (
                            <img className={[styles.singleSign].join(" ")} src={props.fieldData.preview && props.fieldData.preview} alt="sign" />
                        )
                    ) : (
                        <div className={[styles.signField, props.fontSize === 0 ? styles.small : null].join(" ")}>
                            {props.fieldData.height > 8 ? (
                                <div className={styles.signText} style={{ fontSize: props.fontSize }}>
                                    {("서명")}
                                </div>
                            ) : null}
                            <img
                                src="/static/media/contractpage/pen.png"
                                alt="sign"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(SignBox);
