const updateMappingListCheck = (mappings, updateCheckKey, isChecked, checkOnce) => {
    for (let item of mappings) {
        if (item.tagName === updateCheckKey) {
            item.isChecked = isChecked;

            if (checkOnce) {
                return [...mappings];
            }
        }
    }

    return mappings;
};

export default updateMappingListCheck;
