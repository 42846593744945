import React from "react";
import { useDispatch } from "react-redux";
import { getPass } from "module/passModule";
import styles from "./styles.scss";
import { useHistory } from "react-router";

import Header from "components/Layout/Header";
import MainTitle from "components/Title/MainTitle";
import Button from "components/Button";
import SubTitle from "components/Title/SubTitle";
import CheckboxAll from "components/List/CheckboxAll";
import CheckboxDetail from "components/List/CheckboxDetail";
import RadioboxDetail from "components/List/RadioboxDetail";
import RadioboxDetailTriple from "components/List/RadioboxDetailTriple";
import Modal01 from "components/Modal/Modal01";
import ConsentIshb from "./ishb";

const ConsentInput = (props) => {
    const dispatch = useDispatch();
    // const status = props?.status ? props?.status : 'etc'
    let history = useHistory();

    const [name, setName] = React.useState("");
    const [companyId, setCompanyId] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [registNum1, setRegistNum1] = React.useState("");
    const [registNum2, setRegistNum2] = React.useState("");

    const [btndisabled, setBtndisabled] = React.useState(true);
    const [value, setValue] = React.useState("N");
    const [failed, setFailed] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);

    const [flag, setFlag] = React.useState(false);
    const [query, setQuery] = React.useState(undefined);
    const [keyString, setKeyString] = React.useState("");

    const [checkList, setCheckList] = React.useState([]);
    const [checkIdList, setCheckIdList] = React.useState([]);
    const allcheck = document.getElementsByName("requiredCheck");
    const [essentialCheck, setEssentialCheck] = React.useState([]);

    const [req_check6, setReq_check6] = React.useState("N");
    const [req_check7, setReq_check7] = React.useState("N");
    const [req_check8, setReq_check8] = React.useState("N");
    const [c_AGREE01, setC_AGREE01] = React.useState("N");
    const [c_AGREE02, setC_AGREE02] = React.useState("N");

    const [status, setStatus] = React.useState("etc");

    const [isPersonal, setIsPersonal] = React.useState(true);
    const [gubun, setGubun] = React.useState(2);
    const [eid, setEid] = React.useState("");
    const [cid, setCid] = React.useState("");

    React.useEffect(() => {
        let checkboxIdList = [];
        document.getElementsByName("requiredCheck").forEach((item, i) => {
            checkboxIdList[i] = item.getAttribute("id");
        });

        setCheckIdList(checkboxIdList);

        // url에서 status 따옴
        if (props?.match?.params?.status && props?.match?.params?.status !== "") {
            setStatus(props?.match?.params?.status);
        }

        const searchParams = new URLSearchParams(window.location.search);
        const paramEid = searchParams.get("ETMTSEQ");
        const paramCid = searchParams.get("LOGID");

        setCid(paramCid);
        setEid(paramEid);

    }, []);

    React.useEffect(() => {
        if (props?.match?.params?.status && props?.match?.params?.status === "error") {
            window.location.reload();
        }
    }, []);

    // 부모창 / 자식창 구분
    React.useEffect(() => {
        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        }
    }, []);

    React.useEffect(() => {
        if (
            name.length >= 2 &&
            registNum1.length === 6 &&
            registNum2.length === 7 &&
            phone.length >= 9 &&
            essentialCheck.length === 3 &&
            value !== null
        ) {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    }, [name, registNum1, registNum2, checkList, value, phone]);

    React.useEffect(() => {
        if (flag && query) {
            async function func() {
                dispatch(getPass.get.request(query));
                history.push("/consent/complete", { flag: true, status: status });
            }
            func();
        }
    }, [flag, query]);

    // 입력된 값 validCheck
    const onTextChange = (type) => (e) => {
        const pattern1 = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|ㅏ-ㅣ|ㆍᆢ ]*$/;
        const pattern2 = /^[0-9]*$/;

        const {
            target: { value },
        } = e;

        if (type === "name") {
            pattern1.test(value) && setName(value);
        } else if (type === "registNum1") {
            pattern2.test(value) && setRegistNum1(value);
        } else if (type === "registNum2") {
            pattern2.test(value) && setRegistNum2(value);
        } else if (type === "phone") {
            pattern2.test(value) && setPhone(value);
        } else if (type === "companyId") {
            pattern2.test(value) && setCompanyId(value);
        }
    };

    // 요약동의서 동의
    const handleChange = (v) => {
        setValue(v);
    };

    // 개인 사업자 구분 처리
    const handleChangeIsPersonal = (v) => {
        if (v === 2) {
            setIsPersonal(true);
        } else {
            setIsPersonal(false);
        }
        console.log(v)
        setGubun(v);
    };

    // 항목 동의
    const handleOnChangeCheck = (e) => {
        if (e.target.name === "requiredCheck") {
            if (e.target.checked) {
                setCheckList([...checkList, e.target.id]);
                if (e.target.id.includes("req_check")) {
                    setEssentialCheck([...essentialCheck, e.target.id]);
                }

                checkType("check", "Y", e.target.id);
            } else {
                setCheckList(checkList.filter((checkedId) => checkedId !== e.target.id));
                if (e.target.id.includes("req_check")) {
                    setEssentialCheck(essentialCheck.filter((checkedId) => checkedId !== e.target.id));
                }

                checkType("check", "N", e.target.id);
            }
        }
    };

    // 전체 항목 동의
    const handleOnChangeCheckAll = (e) => {
        setCheckList(e.target.checked ? checkIdList : []);
        for (let i = 0; i < allcheck.length; i++) {
            if (e.target.checked) {
                allcheck[i].checked = true;
                setEssentialCheck(["req_check6", "req_check7", "req_check8"]);
                checkType("allCheck", "Y");
            } else {
                allcheck[i].checked = false;
                setEssentialCheck([]);
                checkType("allCheck", "N");
            }
        }
    };
    // 항목 선택 값 변경
    const checkType = (type, YN, id) => {
        if (type === "allCheck") {
            setReq_check6(YN);
            setReq_check7(YN);
            setReq_check8(YN);
            setC_AGREE01(YN);
            setC_AGREE02(YN);
        } else if (type === "check") {
            id === "req_check6"
                ? setReq_check6(YN)
                : id === "req_check7"
                ? setReq_check7(YN)
                : id === "req_check8"
                ? setReq_check8(YN)
                : id === "C_AGREE01"
                ? setC_AGREE01(YN)
                : setC_AGREE02(YN);
        }
    };

    // base64 인코딩
    const base64Encoding = (text) => {
        return window.btoa(unescape(encodeURIComponent(text)));
    };

    // PASS API
    const handleOnClickBtn = async () => {
        if (btndisabled) {
            setFailed(true);
        } else {
            new Promise(async (resolve, reject) => {
                try {
                    fetch("/api/v1/niceplus/auth", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            state: "agree",
                            type: status,
                            name: base64Encoding(name),
                            rssno: base64Encoding(registNum1 + registNum2),
                            mobTel: base64Encoding(phone),
                            agreeYn: value,
                            CRE_P_USE_YN: req_check6,
                            CRE_P_SHE_YN: req_check7,
                            CRE_P_SUP_YN: req_check8,
                            CRE_S_USE_YN: c_AGREE01,
                            CRE_S_SUP_YN: c_AGREE02,
                            cid: cid,
                            eid: eid,
                            companyId: isPersonal ? "" : companyId,
                            gubun: gubun,
                        }),
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                return response.json();
                            }
                        })
                        .then(async (res) => {
                            if (res && res?.message !== "") {
                                await setKeyString(res?.message);
                                openCertWindow();
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } catch (e) {
                    reject(e);
                }
            });
        }
    };

    // 자식창 생성
    const openCertWindow = () => {
        window.open(
            "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb",
            "popupChk",
            "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
        );
        document.certForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.certForm.target = "popupChk";
        document.certForm.submit();

        window.addEventListener("message", getMsg);
    };

    const getMsg = async (e) => {
        if (e.origin === window.location.origin) {
            if (e.data.query) {
                if (e.data.query["status"] && e.data.query["status"] === "success") {
                    await setFlag(true);
                    await setQuery(e.data.query);
                } else {
                    setModalOpen(true);
                }
            }
        }
    };

    return status !== "ishb" ? (
        <>
            <Header onClick={() => history.push("/")} />
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="개인(신용)정보" style={{ marginBottom: "32px" }} substyle={{ color: "#666666" }}>
                            <p>
                                <span>개인(신용)정보</span> 동의를 위해 아래의 정보를 입력해 주세요.
                            </p>
                        </MainTitle>
                        {/* <input type="file" name="pic" accept="image/*" /> */}
                        <SubTitle title="개인 / 사업자 구분" isSpecial descYn="Y">
                            개인 / 사업자 선택해주세요
                        </SubTitle>
                        <RadioboxDetailTriple
                            // 법인 ='1', 개인 ='2', 개인사업자 ='3'
                            name={"gubun"}
                            id1={"g1"}
                            value1={2}
                            lblNm1={"개인"}
                            id2={"g2"}
                            value2={3}
                            lblNm2={"개인사업자"}
                            id3={"g3"}
                            value3={1}
                            lblNm3={"법인(연대보증인)"}
                            onChange={handleChangeIsPersonal}
                        />
                        <div className={styles.contBox}>
                            <form>
                                <label>이름</label>
                                <input
                                    type="text"
                                    maxLength={40}
                                    onChange={onTextChange("name")}
                                    value={name}
                                    placeholder={"이름을 입력해 주세요."}
                                ></input>
                                <label>주민등록번호</label>
                                <div className={styles.inputWrap}>
                                    <input
                                        type="text"
                                        maxLength={6}
                                        onChange={onTextChange("registNum1")}
                                        value={registNum1}
                                        placeholder={"앞 6자리를 입력해 주세요."}
                                    />
                                    <p>-</p>
                                    <input
                                        maxLength={7}
                                        type="password"
                                        onChange={onTextChange("registNum2")}
                                        value={registNum2}
                                        placeholder={"* * * * * * *"}
                                    />
                                </div>
                                {!isPersonal ? (
                                    <>
                                        <label>사업자번호</label>
                                        <input
                                            type="text"
                                            maxLength={10}
                                            onChange={onTextChange("companyId")}
                                            value={companyId}
                                            placeholder={"사업자번호 입력해주세요."}
                                        ></input>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <label>전화번호</label>
                                <input
                                    maxLength={11}
                                    type="text"
                                    onChange={onTextChange("phone")}
                                    value={phone}
                                    placeholder={"하이픈(-) 없이 입력해 주세요."}
                                ></input>
                            </form>
                        </div>
                        {/* jpg jpeg png */}
                        <div className={styles.termsWrap}>
                            <SubTitle title="개인(신용)정보 동의" isSpecial descYn="Y">
                                본 동의서는 요약동의서이며, 요청하면 전체 동의서를 안내 받으실 수 있습니다. 요청하시겠습니까?
                            </SubTitle>
                            <RadioboxDetail
                                name={"requestYn"}
                                id1={"requestY"}
                                value1={"Y"}
                                lblNm1={"예"}
                                id2={"requestN"}
                                value2={"N"}
                                lblNm2={"아니오"}
                                onChange={handleChange}
                            />
                            <CheckboxAll
                                checkLabel={"checkAll"}
                                onChange={handleOnChangeCheckAll}
                                checked={checkList.length === checkIdList.length}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check6"}
                                name={"requiredCheck"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인(신용)정보 필수적 수집, 이용 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={value === "Y" ? "15" : "10"}
                                value={value}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check7"}
                                name={"requiredCheck"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인(신용)정보 필수적 조회 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={value === "Y" ? "16" : "11"}
                                value={value}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check8"}
                                name={"requiredCheck"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인(신용)정보 필수적 제공 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={value === "Y" ? "17" : "12"}
                                value={value}
                            />
                            <CheckboxDetail
                                checkLabel={"C_AGREE01"}
                                name={"requiredCheck"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인(신용)정보 선택적 수집, 이용 동의 (선택)"}
                                summaryStyles={{ padding: "14px 0 14px 8px" }}
                                satisfactionFir
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={value === "Y" ? "18" : "13"}
                                value={value}
                            />
                            <CheckboxDetail
                                checkLabel={"C_AGREE02"}
                                name={"requiredCheck"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인(신용)정보 선택적 제 3자 동의 (선택)"}
                                summaryStyles={{ padding: "14px 0 14px 8px" }}
                                satisfactionSec
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={value === "Y" ? "19" : "14"}
                                value={value}
                            />

                            <Button style={{ margin: "32px 0px 16px 0px" }} text={"인증"} disabled={btndisabled} onClick={handleOnClickBtn} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal01 open={failed} close={() => setFailed(false)} header="인증 실패">
                필수 입력 정보를 <br />
                모두 기입 및 체크해 주세요.
            </Modal01>

            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="본인인증 실패">
                본인인증에 실패하였습니다.
                <br />
                잠시 후 다시 시도해주세요.
            </Modal01>

            {keyString !== "" && (
                <form name="certForm" id="certForm">
                    <label>
                        <input type="hidden" name="m" value="checkplusService" />
                    </label>
                    <label>
                        <input type="hidden" name="EncodeData" value={keyString} />
                    </label>
                </form>
            )}
        </>
    ) : (
        <ConsentIshb status={status} />
    );
};

export default ConsentInput;
