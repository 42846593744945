import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { createBrowserHistory } from "history";

import userReducer from "./userModule";
import arsReducer from "./arsModule";
import passReducer from "./passModule";
import contractReducer from "./contractModule";
import changeCmsReducer from "./changeCmsModule";

export const history = createBrowserHistory();

const reducer = combineReducers({
    user: userReducer,
    ars: arsReducer,
    pass: passReducer,
    contract: contractReducer,
    change: changeCmsReducer,
    router: connectRouter(history),
});

export default reducer;