import React from "react";

const Radio = (props, context) => {
    return (
        props.checked ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g>
                    <g>
                        <path fill="none" d="M0 0H24V24H0z"/>
                        <path fill="#fb0f10" d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1.6A6.4 6.4 0 1 0 14.4 8 6.407 6.407 0 0 0 8 1.6zM8 12a4 4 0 1 1 4-4 4 4 0 0 1-4 4z" transform="translate(4 4)"/>
                    </g>
                </g>
            </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g>
                    <g>
                        <path fill="none" d="M0 0H24V24H0z"/>
                        <path fill="#b6b6b6" d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z" transform="translate(4 4)"/>
                    </g>
                </g>
            </svg>
        )
    );
};

export default Radio;
