import {makeAsyncMethodsActions, makeAsyncMethodsCreator, customActions} from "./common";

export const ARS = makeAsyncMethodsActions("ars/ARS"); // GET, POST, DELETE, PUT
export const getArs = makeAsyncMethodsCreator(ARS); // INDEX, REQUEST, SUCCESS, FAIL

export const RESET = makeAsyncMethodsActions("ars/RESET");
export const resetMsg = makeAsyncMethodsCreator(RESET);

export const CERT = makeAsyncMethodsActions("ars/CERT");
export const setCert = makeAsyncMethodsCreator(CERT);

export const CMS = makeAsyncMethodsActions("ars/CMS");
export const setCms = makeAsyncMethodsCreator(CMS);

const initialState = {
    statusKey: "",
    arsCode: "",
    status: "",
    errorCode: "",

    bildt: "", // 결제일
    bankCd: "", // 결제은행 코드
    bankName: "", // 결제은행 이름
    bankAccount: "", // 계좌번호
    BILDT_ETC: "", // 기타 결제일
    custnm: "", // 예금주명

    recordName: "", // ars return value
    derName: "", // der return value

    cmsKeys: ["bildt", "bankCd", "bankName", "bankAccount", "BILDT_ETC"],
};

const Reducer = customActions(
    {
        [ARS.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                bildt: action.payload?.bildt,
                bankCd: action.payload?.bankcd,
                bankName: action.payload?.banknm,
                bankAccount: action.payload?.acctno,
                BILDT_ETC: action.payload?.BILDT_ETC
            });
        },
        [ARS.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                statusKey: action.payload?.statusKey,
                arsCode: action.payload?.arsCode
            });
        },
        [ARS.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                status: "fail"
            });
        },
        [ARS.GET.REQUEST]: (state, action) => {
            return Object.assign({}, state, {});
        },
        [ARS.GET.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                errorCode: action.payload?.errorCode,
                status: action.payload?.status,
                arsCode: !action.payload?.errorCode ? "" : state.arsCode, // 인증성공시 arsCode 초기화
                recordName: action.payload.recordName,
            });
        },
        [ARS.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {});
        },
        [RESET.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                status: ""
            });
        },
        [RESET.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                statusKey: "",
                arsCode: "",
                status: "",
                errorCode: "",
                bildt: "",
                bankCd: "",
                bankName: "",
                bankAccount: "",
                BILDT_ETC: "",
                custnm: "",
                recordName: "",
                derName: "",
            });
        },
        [CERT.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                derName: action.payload?.derName,
            });
        },
        [CMS.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                ...action.payload
            });
        },
    },
    initialState,
    [
        "ars/ARS",
        "ars/RESET",
        "ars/CERT",
        "ars/CMS"
    ]
);


export default Reducer;
