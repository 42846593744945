import React, { useEffect, useState } from "react";
import styles from "./styles.scss";
import commonStyles from "components/Layout/Common/styles.scss";

import Header from "components/Layout/Header";

import Button from "components/Button";
import MainTitle from "components/Title/MainTitle";
import Modal01 from "components/Modal/Modal01";
import Modal02 from "components/Modal/Modal02";
import { userInfo } from "module/contractModule";
import { useDispatch, useSelector } from "react-redux";
import UploadLoading from "components/Loading/uploadLoading";

const SuccessorEntry = (props) => {
    const dispatch = useDispatch();
    const estimate = useSelector((state) => state.contract.estimate);
    const { data } = useSelector((state) => state.contract);
    const errMsg = useSelector((state) => state.user.errMsg);

    const [Estimate, setEstimate] = useState("");
    const [custID, setCustID] = useState("");

    const [btndisabled, setBtndisabled] = useState(true);

    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [custIdErr, setCustIdErr] = useState(false);
    const [errModal, setErrModal] = useState(false);

    const [pushBtn, setPushBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [btnClick, setBtnClick] = useState(false);
    const [gubun, setGubun] = useState("B");

    const [errorMessage, setErrorMessage] = useState("인증에 실패하였습니다.");

    useEffect(() => {
        if (data) {
            window.location.reload(true);
        }

        const currentPath = window.location.pathname;
        if ("/successor/entry/b/" === currentPath || "/successor/entry/b" === currentPath) {
            setGubun("B");
        } else if ("/successor/entry/a/" === currentPath || "/successor/entry/a" === currentPath) {
            setGubun("A");
        } else {
            setGubun("B");
        }
    }, []);

    useEffect(() => {
        if (Estimate && custID) {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    }, [Estimate, custID]);

    useEffect(() => {
        if (estimate !== "" && btnClick) {
            setIsLoading(false);
            setModalOpen2(true);
            setBtnClick(false);
        }
    }, [estimate]);

    useEffect(() => {
        if (errMsg === "TIME OVER") {
            setIsLoading(false);
        }
    }, [errMsg]);

    const handleOnChangeEstimate = (e) => setEstimate(e.target.value);
    const handleOnChangeCustID = (e) => setCustID(e.target.value);

    const handleOnClickBtn = async () => {
        if (btndisabled) {
            setModalOpen1(true);
        } else if (!(custID.length === 6 || custID.length === 10)) {
            setCustIdErr(true);
        } else {
            setIsLoading(true);
            setPushBtn(true);
            setBtnClick(true);

            const data = {
                I_VBELN: Estimate,
                I_GUBUN: gubun,
                estimate: custID,
            };

            window
                .fetch(`/api/v1/successor/auth`, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                })
                .then((result) => {
                    if (result.status === 200) {
                        return result.json();
                    } else if (result.status === 400) {
                        result.json().then((data) => {
                            setErrorMessage(data.message);
                        });
                    }
                })
                .then(async (res) => {
                    if (res) {
                        setIsLoading(false);
                        dispatch(
                            userInfo.post.request({
                                ...res,
                                estimate: Estimate,
                                custId: custID,
                            })
                        );
                    } else {
                        setIsLoading(false);
                        setErrModal(true);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    setErrModal(true);
                });
        }
    };

    const handleModalSubmit = () => {
        let authParam = "";
        if ("B" === gubun) {
            authParam = "successorAuthB";
        } else {
            authParam = "successorAuthA";
        }
        props.history.push("/auth/successor?auth=" + authParam, { estimate: Estimate });
    };

    const handleModalClose = () => {
        dispatch(userInfo.post.fail());
        setModalOpen1(false);
        setModalOpen2(false);
    };

    return (
        <>
            {isLoading && <UploadLoading />}
            <Header onClick={() => props.history.push("/")} />
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <div className={styles.stepOut}>
                            <div className={styles.stepString}>Step  </div>
                            <div className={styles.stepNumber}> 1/{gubun === "B" ? 4 : 10}</div>
                        </div>

                        <MainTitle mainTitle="본인 인증 (승계 계약)" style={{ marginBottom: "32px" }} substyle={{ color: "#666666" }}>
                            <p>
                                SMS를 통해 전달받으신 <span>계약번호</span>와<br />
                                <span>생년월일(6자리)</span> 또는 <span>사업자번호(10자리)</span>를 하단에 입력해 주세요.
                            </p>
                        </MainTitle>
                        <div className={styles.contBox}>
                            <form>
                                <label>계약번호</label>
                                <input type="text" value={Estimate} onChange={handleOnChangeEstimate} placeholder="계약번호 입력"></input>
                                <label>생년월일(개인) 또는 사업자번호(법인)</label>
                                <input
                                    type="password"
                                    value={custID}
                                    onChange={handleOnChangeCustID}
                                    placeholder="생년월일(6자리) 또는 사업자번호(10자리)를 입력하세요."
                                ></input>
                                <Button style={{ marginTop: "4px" }} text={"인증"} disabled={btndisabled} onClick={handleOnClickBtn} />
                            </form>
                            <div className={commonStyles.noticeWrap}>
                                <p>- 본인인증 절차를 거치지 않으면 서비스를 이용하실 수 없습니다.</p>
                                <p>- 입력하신 휴대폰 정보가 일치하지 않을 경우 휴대폰 PASS 인증앱으로 인증요청이 전송되지않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal01 open={errModal} close={() => setErrModal(false)} header="인증 실패">
                {errorMessage}
                <br />
                <br />
                입력값을 확인해주세요.
            </Modal01>
            <Modal01 open={modalOpen1} close={handleModalClose} header="안내">
                다음 단계로 넘어가기 위해서는
                <br />
                필수 항목에 입력이 필요합니다.
            </Modal01>
            <Modal01 open={custIdErr} close={() => setCustIdErr(false)} header="인증 실패">
                6자리의 생년월일 또는
                <br />
                10자리의 사업자번호를 입력해주세요.
            </Modal01>
            <Modal02
                open={modalOpen2}
                header="인증 완료"
                btnOnclick1={handleModalClose}
                btnOnclick2={handleModalSubmit}
                text1={"취소"}
                text2={"확인"}
                btnStyle2={{ color: "#DA291C" }}
            >
                정상적으로 인증되었습니다.
                <br />
                다음 페이지로 이동합니다.
            </Modal02>
        </>
    );
};

export default SuccessorEntry;
