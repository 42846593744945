import { makeAsyncMethodsActions, makeAsyncMethodsCreator, customActions } from "./common";

export const CHANGE = makeAsyncMethodsActions("cms/CHANGE"); // GET, POST, DELETE, PUT
export const getChange = makeAsyncMethodsCreator(CHANGE); // INDEX, REQUEST, SUCCESS, FAIL

export const initialState = {
    status:"",

    isLoading: false,
    birthday: "", // 생일
    name: "", // 이름, 예금주
    phoneNumber: "", // 폰번
    mobileCo: "", // 통신사
    acctno: "", // 계좌
    banknm: "", // 은행이름
    bankcd: "", // 은행코드
    estimate: "", // 견적번호
    custId: "", // 생년월일 / 사업자번호
    isCompany: false, // 개인/개인사업자, 법인
    kunner: ""
};

const Reducer = customActions(
    {
        [CHANGE.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                status: "FAIL"
            });
        },
        [CHANGE.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                isLoading: state.isLoading ? state.isLoading : action.payload.isLoading,
                isCompany: state.isCompany ? state.isCompany : action.payload.isCompany,
                birthday: state.birthday ? state.birthday : action.payload.birthday,
                name: state.name ? state.name : action.payload.name,
                phoneNumber: state.phoneNumber ? state.phoneNumber : action.payload.phoneNumber,
                mobileCo: state.mobileCo ? state.mobileCo : action.payload.mobileCo,
                acctno: state.acctno ? state.acctno : action.payload.acctno,
                banknm: state.banknm ? state.banknm : action.payload.banknm,
                bankcd: state.bankcd ? state.bankcd : action.payload.bankcd,
                estimate: state.estimate ? state.estimate : action.payload.estimate,
                custId: state.custId ? state.custId : action.payload.custId,
                kunner: state.kunner ? state.kunner : action.payload.kunner
            });
        },
    },
    initialState,
    [
        "cms/CHANGE",
    ]
);

export default Reducer;