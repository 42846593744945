import React from 'react';
import styles from "./styles.scss";


const Modal02 = ( props ) => {
    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴

    return (
        // 모달이 열릴때 openModal 클래스가 생성된다.
        <>
            <div className={ props.open ? [styles.modalWrap, styles.modalOpen].join(' ') : styles.modalWrap }>
                { props.open ? (
                    <section>
                        <header>
                            {props.header}
                        </header>
                        <div>
                            {props.children}
                        </div>
                        <footer>
                            <button style={props.btnStyle1} onClick={props.btnOnclick1}>{props.text1}</button>
                            <button style={props.btnStyle2} onClick={props.btnOnclick2}>{props.text2}</button>
                        </footer>
                    </section>
                ) : null }
            </div>
        </>
    )
}

export default Modal02;