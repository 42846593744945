import React from "react";
import styles from './styles.scss';

const ButtonBar = (props) => {
    return (
        <div className={[styles.buttonBar, props.oneBtn ? styles.oneBtn : undefined].join(" ")}>
            <button onClick={props.leftBtnFunc}>{props.leftBtnTxt}</button>
            {!props.oneBtn && (
                <button
                    className={props.isActive ? undefined : styles.disable}
                    onClick={props.rightBtnFunc}
                >
                    {props.rightBtnTxt}
                </button>
            )}
        </div>
    )
}

export default ButtonBar;