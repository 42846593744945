import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import User from "screens/Auth/User";
import ContractNum from "screens/Auth/ContractNum";
import SuccessorEntry from "screens/Auth/SuccessorEntry";

import ContractInfo from "screens/Auth/ContractInfo";
import Apply from "screens/Cms/Apply";
import ARS1 from "screens/Cms/ARS1";

import SuccessorApply from "screens/Cms/SuccessorApply";
import SuccessorARS1 from "screens/Cms/SuccessorARS1";

import CmsContractNum from "screens/Cms/Change/ContractNum";
import CmsUser from "screens/Cms/Change/User";
import CmsApply from "screens/Cms/Change/Apply";
import CmsARS1 from "screens/Cms/Change/ARS1";
import CmsSignStep1 from "screens/Cms/Change/SignStep1";

import ConfirmFitness from "screens/Confirm/Fitness";
import SuccessorFitness from "screens/Confirm/SuccessorFitness";
import ConfirmProductDesc from "screens/Confirm/ProductDesc";

import AcceptTerms from "screens/Accept/Terms";

import CDD from "screens/CDD";

import ConsentInput from "screens/Consent/Input";
import ConesntComplete from "screens/Consent/complete";

import SignStep1 from "screens/Signature/SignStep1";
import SignStep2 from "screens/Signature/SignStep2";
import Sign from "screens/Signature/Sign";

import CorpMember from "screens/Final/CorpMember";
import Complete from "screens/Complete";

import Modal01 from "components/Modal/Modal01";
import { userLogout } from "module/userModule";
import SuccessorUser from "screens/Auth/SuccessorUser";
import SuccessorContractInfo from "screens/Auth/SuccessorContractInfo";
import SuccessorSignStep2 from "screens/Signature/SuccessorSignStep2";
import SuccessorProductDesc from "screens/Confirm/SuccessorProductDesc";
import SuccessorTerms from "screens/Accept/SuccessorTerms";
import SuccessorSignStep1 from "screens/Signature/SuccessorSignStep1";
import SuccessorAfterContractInfo1 from "screens/Auth/SuccessorAfterContractInfo1";
import SuccessorAfterContractInfo2 from "screens/Auth/SuccessorAfterContractInfo2";
import SuccessorCDD from "screens/SuccessorCDD";

const App = (props) => {
    const dispatch = useDispatch();
    const isLogout = useSelector((state) => state.user.isLogout);
    const errMsg = useSelector((state) => state.user.errMsg);
    const [errModal, setErrModal] = useState(false);
    const [retry, setRetry] = useState(false);

    useEffect(() => {
        if (isLogout) {
            setErrModal(true);
        }
    }, [isLogout]);

    useEffect(() => {
        if (errMsg === "TIME OVER") {
            setRetry(true);
        }
    }, [errMsg]);

    const handleModal = () => {
        dispatch(userLogout.post.fail());
        setErrModal(false);
        window.location.href = "/";
    };

    const handleRetryModal = () => {
        dispatch(userLogout.get.fail());
        setRetry(false);
    };

    return (
        <>
            {isLogout && (
                <Modal01 open={errModal} close={handleModal} header="세션 만료">
                    유효하지 않은 계약입니다.
                    <br />
                    처음부터 다시 시도해주세요.
                </Modal01>
            )}
            {retry && (
                <Modal01 open={retry} close={handleRetryModal} header="요청 실패">
                    요청에 실패했습니다.
                    <br />
                    잠시 후 다시 시도해주세요.
                </Modal01>
            )}

            {/* 계약 프로세스 */}
            <Route exact path="/auth/contractNum" component={ContractNum} />
            <Route exact path="/auth/user" component={User} />
            <Route exact path="/auth/contractInfo" component={ContractInfo} />

            <Route exact path="/confirm/fitness" component={ConfirmFitness} />
            <Route exact path="/confirm/productDesc" component={ConfirmProductDesc} />

            <Route exact path="/accept/terms" component={AcceptTerms} />

            <Route exact path="/cms/apply" component={Apply} />
            <Route exact path="/cms/ars1" component={ARS1} />

            <Route exact path="/cdd" component={CDD} />

            {/* <Route exact path="/signature/keyPoint" component={KeyPoint} /> */}
            <Route exact path="/signature/signStep1" component={SignStep1} />
            <Route exact path="/signature/signStep2" component={SignStep2} />
            <Route exact path="/signature/sign" component={Sign} />

            <Route exact path="/final/corpMember" component={CorpMember} />

            <Route exact path="/complete" component={Complete} />

            {/* 신용정보 조회동의 */}
            <Route exact path="/consent/input/:status?" component={ConsentInput} />
            <Route exact path="/consent/complete" component={ConesntComplete} />

            <Route exact path="/cms/change/contractnum" component={CmsContractNum} />
            <Route exact path="/cms/change/user" component={CmsUser} />
            <Route exact path="/cms/change/apply" component={CmsApply} />
            <Route exact path="/cms/change/ars1" component={CmsARS1} />
            <Route exact path="/cms/change/signstep1" component={CmsSignStep1} />
            {/* <Route exact path="/cms/change/complete" component={CmsComplete} /> */}

            {/* CMS 자동이체 변경 페이지
                본인인증) cms/contractNum
                본인인증) cms/user -> PASS API
                출금이체) cms/ooo
                ars인증) cms/ars
                출금완료) cms/complete
            */}

            <Route exact path="/" render={() => <Redirect to="/auth/contractNum" />} />

            {/* 202307 승계 계약 페이지 */}
            {/* GUBUN : B  승계인*/}
            <Route exact path="/successor/entry/b" component={SuccessorEntry} />
            <Route exact path="/auth/successor" component={SuccessorUser} />
            <Route exact path="/successor/contractInfo" component={SuccessorContractInfo} />
            <Route exact path="/successor/signStep2/b" component={SuccessorSignStep2} />

            {/* GUBUN : A 인수인*/}
            <Route exact path="/successor/entry/a" component={SuccessorEntry} />
            <Route exact path="/successor/afterinfo" component={SuccessorAfterContractInfo1} />
            <Route exact path="/successor/afterinfo2" component={SuccessorAfterContractInfo2} />
            {/* 적합성 원칙 확인서 */}
            <Route exact path="/successor/confirm" component={SuccessorFitness} />
            {/* 상품 설명서  before*/}
            <Route exact path="/successor/productDesc" component={SuccessorProductDesc} />
            {/* 약관 동의 before  */}
            <Route exact path="/successor/terms" component={SuccessorTerms} />
            {/* 계약정보확인페이지 */}
            <Route exact path="/successor/signStep1/a" component={SuccessorSignStep1} />
            <Route exact path="/successor/signStep2/a" component={SuccessorSignStep2} />
            {/* 승계 계약cdd/cms 관련 */}
            <Route exact path="/successor/cms/apply" component={SuccessorApply} />
            <Route exact path="/successor/cms/ars1" component={SuccessorARS1} />
            <Route exact path="/successor/cdd" component={SuccessorCDD} />
        </>
    );
};

export default App;
