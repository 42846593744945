import { makeAsyncMethodsActions, makeAsyncMethodsCreator, customActions } from "./common";

export const SIGN = makeAsyncMethodsActions("user/SIGN"); // GET, POST, DELETE, PUT
export const signature = makeAsyncMethodsCreator(SIGN); // INDEX, REQUEST, SUCCESS, FAIL

export const FITNESS = makeAsyncMethodsActions("user/FITNESS");
export const getFitness = makeAsyncMethodsCreator(FITNESS);

export const ISALL = makeAsyncMethodsActions("user/ISALL");
export const userIsAll = makeAsyncMethodsCreator(ISALL);

export const CDD = makeAsyncMethodsActions("user/CDD");
export const setCDD = makeAsyncMethodsCreator(CDD);

export const RESET = makeAsyncMethodsActions("user/RESET");
export const userReset = makeAsyncMethodsCreator(RESET);

export const LOGOUT = makeAsyncMethodsActions("user/LOGOUT");
export const userLogout = makeAsyncMethodsCreator(LOGOUT);

export const GQ = makeAsyncMethodsActions("user/GQ");
export const setGQ = makeAsyncMethodsCreator(GQ);

export const newCdd = makeAsyncMethodsActions("user/newCdd");
export const getNewCdd = makeAsyncMethodsCreator(newCdd);

export const initialState = {
    signature: "",
    signLoading: false,
    isAll: undefined,
    isLogout: false,
    errMsg: "",

    /* 적합성, 적정석의 원칙 확인서 체크리스트 */
    // 개인 / 법인 공통
    CRE_CK: "", // 전문금융소비자 해당여부
    USE_CK: "", // 대출 용도
    USE_ETC: "", // 대출용도 기타 텍스트
    ASS_CK: "", // 보유자산
    INCOME: "", // 연간소득
    DEPT_CK: "", // 부채
    PLAN: "", // 변제 계획
    PLAN_ETC: "", // 변제 계획 기타 텍스트
    FIX_CK: "", // 고정지출
    OVER: "", // 연체정보

    // 개인
    S1_AGE: "", // 연령
    S1_POINT: "", // 신용점수

    // 법인
    S2_TERM: "", // 업력(법인)
    GAIN_CK: "", // 순이익(법인)

    REJECT: "", // 대출 고지
    UNDERSTAND: "", // 이해 여부

    // typeof boolean
    AGREE01: "",
    AGREE02: "", // 개인 신용정보 선택 전체
    C_AGREE01: "", // 개인 신용정보 선택적 수집, 이용
    C_AGREE02: "", // 개인 신용정보 선택적 제공
    H_OWN: "", // 주택 소유 여부 확인

    CDD1_J_1KFREPRE: "", // 대표자명
    CDD1_J_NATIONAL: "", // 대표자 국적
    CDD1_J_NATIONALC: "", // 대표자 국적
    CDD1_J_BIRTH: "", // 대표자 생년월일
    CDD1_J_TELF2: "", // 대표자 전화번호
    CDD1_J_HDTAD: "", // 대표자 주소
    CDD1_O_NAMEK: "", // 실소유자명
    CDD1_O_NAMEE: "", // 실소유자 영문명
    CDD1_O_BIRTH: "", // 실소유자 생년월일
    CDD1_O_NATIONAL: "", // 실소유자 국가
    CDD1_O_NATIONALC: "", // 실소유자 국가 코드
    CDD1_O_RATE: "", // 실소유자 지분율
    owner: "",

    // 고객의 이해여부 확인을 위한 질의
    G_Q1: false,
    G_Q2: false,
    G_Q3: false,
    G_Q4: false,
    G_Q5: false,
    G_Q6: false,
    G_Q7: false,
    G_Q8: false,
    G_Q9: false,
    G_Q10: false,
    G_Q11: false,
    G_Q12: false,
    G_Q13: false,
    G_Q14: false,
    G_Q15: false,

    keys: [
        "CRE_CK",
        "USE_CK",
        "USE_ETC",
        "ASS_CK",
        "INCOME",
        "DEPT_CK",
        "PLAN",
        "PLAN_ETC",
        "FIX_CK",
        "S2_TERM",
        "GAIN_CK",
        "OVER",
        "S1_AGE",
        "S1_POINT",
        "G_CK",
        "REJECT",
        "UNDERSTAND",
    ],
    completeTrueKeys: ["CMS_AGREE01", "CMS_AGREE02", "R_AGREE01", "R_AGREE02", "R_AGREE03", "R_AGREE04", "H_OWN", "P1_AGREE"],
    completeKeys: ["AGREE01", "AGREE02", "C_AGREE01", "C_AGREE02"],
    cddKeys: [
        "CDD1_J_1KFREPRE",
        "CDD1_J_NATIONAL",
        "CDD1_J_NATIONALC",
        "CDD1_J_BIRTH",
        "CDD1_J_TELF2",
        "CDD1_J_HDTAD",
        "CDD1_O_NAMEK",
        "CDD1_O_NAMEE",
        "CDD1_O_BIRTH",
        "CDD1_O_NATIONAL",
        "CDD1_O_NATIONALC",
        "CDD1_O_RATE",
    ],
    gKeys: ["G_Q1", "G_Q2", "G_Q3", "G_Q4", "G_Q5", "G_Q6", "G_Q7", "G_Q8", "G_Q9", "G_Q10", "G_Q11", "G_Q12", "G_Q13", "G_Q14", "G_Q15"],
};

const Reducer = customActions(
    {
        [SIGN.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                signLoading: true,
            });
        },
        [SIGN.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                signature: action.payload.sign,
                signLoading: false,
            });
        },
        [FITNESS.POST.SUCCESS]: (state, action) => {
            const { payload } = action.payload;
            return Object.assign({}, state, {
                ...payload,
            });
        },
        [ISALL.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                isAll: action.payload.isAll,
                AGREE01: action.payload.AGREE01,
                AGREE02: action.payload.AGREE02,
                C_AGREE01: action.payload.C_AGREE01,
                C_AGREE02: action.payload.C_AGREE02,
                H_OWN: action.payload.H_OWN,
            });
        },
        [CDD.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                ...action.payload,
            });
        },
        [RESET.POST.REQUEST]: (state, action) => {
            return Object.assign({}, state, {
                signature: "",
                signLoading: false,
                isAll: undefined,
                CRE_CK: "",
                USE_CK: "",
                USE_ETC: "",
                ASS_CK: "",
                INCOME: "",
                DEPT_CK: "",
                PLAN: "",
                PLAN_ETC: "",
                FIX_CK: "",
                OVER: "",
                S1_AGE: "",
                S1_POINT: "",
                S2_TERM: "",
                GAIN_CK: "",
                REJECT: "",
                UNDERSTAND: "",
                AGREE01: "",
                AGREE02: "",
                C_AGREE01: "",
                C_AGREE02: "",
                H_OWN: "",
                CDD1_J_1KFREPRE: "",
                CDD1_J_NATIONAL: "",
                CDD1_J_NATIONALC: "",
                CDD1_J_BIRTH: "",
                CDD1_J_TELF2: "",
                CDD1_J_HDTAD: "",
                CDD1_O_NAMEK: "",
                CDD1_O_NAMEE: "",
                CDD1_O_BIRTH: "",
                CDD1_O_NATIONAL: "",
                CDD1_O_NATIONALC: "",
                CDD1_O_RATE: "",
                owner: "",
            });
        },
        [LOGOUT.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                isLogout: true,
            });
        },
        [LOGOUT.POST.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                isLogout: false,
            });
        },
        [LOGOUT.GET.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                errMsg: "TIME OVER",
            });
        },
        [LOGOUT.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                errMsg: "",
            });
        },
        [GQ.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                ...action.payload,
            });
        },
        [newCdd.POST.SUCCESS]: (state, action) => {
            return Object.assign({}, state, {
                ...action.payload,
            });
        },
        [newCdd.GET.FAIL]: (state, action) => {
            return Object.assign({}, state, {
                errMsg: "NEW CDD FAIL",
            });
        },
    },
    initialState,
    ["user/SIGN", "user/TOKEN", "user/FITNESS", "user/ISALL", "user/CDD", "user/LOGOUT", "user/GQ", "user/newCdd"]
);

export default Reducer;
