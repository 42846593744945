import React from "react";

const Arrow = (props, context) => {
    if (props.type === "pageleft") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                <g id="arrow_back_ios-24px" transform="rotate(180 8.5 8.5)">
                    <path style={{ fill: "none", opacity: ".87" }} id="패스_4070" d="M17 0H0v17h17z" data-name="패스 4070" />
                    <path
                        fill={"#8c9cb4"}
                        id="패스_4071"
                        d="M.266.259a.918.918 0 0 1 1.278 0l6 5.855a.69.69 0 0 1 0 .993l-6 5.855a.918.918 0 0 1-1.278 0 .865.865 0 0 1 0-1.247l5.227-5.108L.259 1.5A.863.863 0 0 1 .266.259z"
                        data-name="패스 4071"
                        transform="translate(4.889 1.893)"
                    />
                </g>
            </svg>
        );
    } else if (props.type === "pageright") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="arrow_back_ios-24px" width="17" height="17" viewBox="0 0 17 17">
                <path style={{ fill: "none", opacity: ".87" }} id="패스_4070" d="M17 0H0v17h17z" data-name="패스 4070" />
                <path
                    fill={"#8c9cb4"}
                    id="패스_4071"
                    d="M6.514 2.881a.918.918 0 0 1 1.278 0l6 5.855a.69.69 0 0 1 0 .993l-6 5.855a.918.918 0 0 1-1.278 0 .865.865 0 0 1 0-1.247l5.227-5.108-5.234-5.108a.863.863 0 0 1 .007-1.24z"
                    data-name="패스 4071"
                    transform="translate(-1.359 -.729)"
                />
            </svg>
        );
    } else if (props.type === "newRight") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "24"} height={props.h ? props.h : "24"} viewBox="0 0 24 24">
                <path fill="none" d="M24 24H0V0h24z" opacity="0.87" />
                <path
                    style={{ fill: props.color ? props.color : "#8c9cb4" }}
                    d="M.368 18.409a1.244 1.244 0 0 0 1.766 0l8.291-8.315a1 1 0 0 0 0-1.411L2.134.368A1.251 1.251 0 0 0 .368 2.139l7.224 7.254-7.234 7.255a1.25 1.25 0 0 0 .01 1.761z"
                    transform="translate(7.024 2.607)"
                />
            </svg>
        );
    } else if (props.type === "next") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "12"} height={props.h ? props.h : "12"} viewBox="0 0 12 12">
                <path
                    style={{ fill: props.color ? props.color : "#1e8ffa" }}
                    d="M5.1 3.451l.595-.61a.629.629 0 0 1 .908 0l5.208 5.339a.669.669 0 0 1 0 .932L6.6 14.454a.629.629 0 0 1-.908 0l-.595-.61a.672.672 0 0 1 .011-.943L8.34 9.746H.643A.65.65 0 0 1 0 9.087v-.879a.65.65 0 0 1 .643-.659h7.7l-3.23-3.155a.667.667 0 0 1-.013-.943z"
                    transform="translate(0 -2.647)"
                />
            </svg>
        );
    } else if (props.type === "stickArrow") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "12"} height={props.h ? props.h : "12"} viewBox="0 0 12 12">
                <path
                    style={{ fill: props.color ? props.color : "#fff" }}
                    d="M5.1 3.451l.595-.61a.629.629 0 0 1 .908 0l5.208 5.339a.669.669 0 0 1 0 .932L6.6 14.454a.629.629 0 0 1-.908 0l-.595-.61a.672.672 0 0 1 .011-.943L8.34 9.746H.643A.65.65 0 0 1 0 9.087v-.879a.65.65 0 0 1 .643-.659h7.7l-3.23-3.155a.667.667 0 0 1-.013-.943z"
                    transform="translate(0 -2.647)"
                />
            </svg>
        );
    } else if (props.type === "down") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g>
                    <path
                        fill={props.color ? props.color : "#1e8ffa"}
                        d="M21.405-4.023v29.4l9.6-9.6 2.4 2.486-13.714 13.714L5.977 18.262l2.4-2.4 9.6 9.514v-29.4z"
                        transform="translate(-445 -3051) translate(449.023 3067.023)"
                    />
                    <path fill="none" d="M0 0H48V48H0z" transform="translate(-445 -3051) translate(445 3051)" />
                </g>
            </svg>
        );
    } else if (props.type === "list") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <g>
                    <path fill="none" d="M0 0H18V18H0z" transform="translate(-1020 -200) translate(1020 200)" />
                    <path fill="#8c9cb4" d="M4 0l4 5H0z" transform="translate(-1020 -200) translate(1033 212) rotate(180)" />
                </g>
            </svg>
        );
    } else {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="expand_less"
                width={props.w ? props.w : "18"}
                height={props.h ? props.h : "18"}
                viewBox="0 0 18 18"
            >
                <path id="rectangle" d="M0 0h18v18H0z" fill="none" />
                <path
                    style={{ fill: props.color ? props.color : "#b0b3bb", fillRule: "evenodd", opacity: 1 }}
                    id="path"
                    d="M12 8l-6 6 1.4 1.4 4.6-4.6 4.6 4.6L18 14z"
                    transform="translate(-2.744 -3.023)"
                />
            </svg>
        );
    }
};

export default Arrow;
