import React, { useEffect, useState } from "react";

import styles from "./styles.scss";
import commonStyles from "components/Layout/Common/styles.scss";

import Header from "components/Layout/Header";
import Button from "components/Button";
import Modal01 from "components/Modal/Modal01";
import UploadLoading from "components/Loading/uploadLoading";

import { useDispatch, useSelector } from "react-redux";
import { getPass, userToken } from "module/passModule";
import { getContract } from "module/contractModule";

const User = (props) => {
    const dispatch = useDispatch();
    const estimate = useSelector(state => state.contract.estimate);
    const custId = useSelector(state => state.contract.custId);
    const personal = useSelector(state => state.contract.isPersonal);
    const { data } = useSelector(state => state.contract);
    const token = useSelector(state => state.pass.accessToken);
    const isLoading = useSelector(state => state.contract.isLoading);
    const errMsg = useSelector(state => state.user.errMsg);

    const [modalOpen, setModalOpen] = useState(false);
    const [keyString, setKeyString] = useState("");
    const [flag, setFlag] = useState(false);
    const [query, setQuery] = useState(undefined);
    const [temp, setTemp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnClick, setBtnClick] = useState(false);

    const script_arr = [
        "/SW/initech/extension/common/exinterface.js",
        "/SW/initech/extension/crosswebex6.js",
        "/SW/initech/extension/common/exinstall.js",
        "/SW/initech/extension/crosswebexInit.js",
        "/SW/initech/extension/cw_web6_adt.js",
        "/SW/initech/extension/common/js/exproto_ext_daemon.js"
    ];

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!estimate && !props.location?.state?.estimate) {
            props.history.push("/auth/contractNum");
        }
    }, []);

    useEffect(() => {
        if (!isLoading && data && btnClick) {
            setLoading(false);
            setBtnClick(false);
            props.history.push("/auth/contractInfo");
        } 
    }, [isLoading]);

    useEffect(() => {
        if (flag && query && !temp) {
            async function func() {
                await setTemp(true);
                await setBtnClick(true);
                dispatch(getPass.get.request(query));
                dispatch(getContract.get.request({ estimate, custId, token: query.accessToken }));
            }
            func();
        }
    }, [flag, query]);

    useEffect(() => {
        if (errMsg === "TIME OVER") {
            setLoading(false);
        }
    }, [errMsg]);

    const handleBtnCert = async (e) => {
        if (personal) {
            new Promise(async (resolve, reject) => {
                try {
                    fetch("/api/v1/niceplus/auth", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            state: "auth",
                            I_ETMTSEQ: estimate
                        }),
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                    }).then(async res => {
                        if (res && res?.message !== "") {
                            await setKeyString(res?.message);
                            openCertWindow();
                        }
                    }).catch(error => {
                        reject(error);
                    });
                } catch(e) {
                    reject(e);
                }
            });
        } else {
            // 법인 고객
            let p1 = new Promise((resolve, reject) => {
                for (let sc_src of script_arr) {
                    let ex6sc = document.querySelector('script[src="' + sc_src + '"]');
                    if (!ex6sc) {
                        let sc = document.createElement("script");
                        sc.setAttribute("src", sc_src);
                        if (sc_src === "/SW/initech/extension/crosswebex6.js") {
                            sc.setAttribute("charset", "UTF-8");
                        }
                        document.body.appendChild(sc);
                    }
                }
                resolve();
            });
            p1.then(() => {
                setTimeout(() => {
                    handleClickCertPopup();
                }, 1200);
            });
        }
    };

    const openCertWindow = () => {
        window.open("", "popupChk", "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
        document.certForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.certForm.target = "popupChk";
        document.certForm.submit();

        window.addEventListener("message", getMsg);
    };

    const getMsg = async (e) => {
        if (e.origin === window.location.origin) {
            if (e.data.query) {
                if (e.data.query["status"] && e.data.query["status"] === "success") {
                    await setFlag(true);
                    await setQuery(e.data.query);
                } else {
                    setModalOpen(true);
                }
            }
        }
    }
    
    const SendForm = (result, postData) => {
        if (result) {
            let postData = {
                juminNO: custId,
                PKCS7SignedData: result,
                chkvid: "1",
                isCms: false,
                isLogin: true,
                I_ETMTSEQ: estimate
            };

            setLoading(true);
            window
                .fetch(`/api/v1/derauth`, {
                    method: "POST",
                    headers: { 
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(postData)
                })
                .then(result => {
                    if (result.status === 200) {
                        return result.json();
                    } else {
                        setLoading(false);
                        setModalOpen(true);
                    }
                }).then(res => {
                    if (res) {
                        setBtnClick(true);
                        dispatch(getContract.get.request({ estimate, custId, token: res.accessToken }));
                        dispatch(userToken.post.request({ accessToken: res.accessToken }));
                    }
                }).catch(err => {
                    setModalOpen(true);
                })
        } else {
            if (window.INI_ALERT) window.INI_ALERT("전자서명에 실패하였습니다.");
            else alert("전자서명에 실패하였습니다.");
        }
    };

    const handleClickCertPopup = () => {
        if (window.INIWEBEX) {
            const signStr = `${estimate}=${custId}`;
            window.INIWEBEX.sign({
                signType: "P7",
                targetType: "data",
                data: signStr,
                form: "",
                processCallback: SendForm,
                isHtml5: true,
                iniCache: true,
                viewType: "NONE",
                vid: true,
            });
        }
    };

    return (
        <>
            {loading && <UploadLoading />}
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <div className={styles.certificationWrap}>
                            <h1>본인인증</h1>
                            <p>롯데오토리스를 이용해주셔서 감사합니다.</p>
                            <p>
                                원활한 서비스 이용을 위하여<br/>
                                본인 인증을 진행해주시기 바랍니다.
                            </p>
                            <Button text={"본인인증"}
                                    style={{margin: "40px 0", backgroundColor: "#DA291C"}}
                                    onClick={handleBtnCert}
                            />
                            <div className={commonStyles.noticeWrap} style={{marginTop: "0"}}>
                                <p>
                                    - 본인인증 절차를 거치지 않으면 서비스를 이용하실 수 없습니다.
                                </p>
                                <p>
                                    - 계약자 본인 명의의 PASS 인증 또는 공인인증서를 통해 본인 인증을 진행해 주시기 바랍니다.
                                </p>
                            </div>
                            {keyString !== "" && (
                                <form name="certForm" id="certForm">
                                    <label><input type="hidden" name="m" value="checkplusService"/></label>
                                    <label><input type="hidden" name="EncodeData" value={keyString}/></label>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="본인인증 실패">
                본인인증에 실패하였습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
        </>
    );

};

export default User;