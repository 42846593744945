import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styles from "./styles.scss";

import * as _ from "lodash";
import { hexToRgb } from "components/shared/hexToRgb";
import * as SVG from "components/SVG";

import { validateEmail } from "components/shared/validateEmail";
import validateRegisNum from "functions/validateRegisNum";

const TEXT = "text";

const TextBox = (props, context) => {
    const [isBottom, setBottom] = useState(false);
    const [text, setText] = useState("");
    const [isSelected, setSelected] = useState(false);
    const [isWeb, setWeb] = useState(false);

    const [addr, setAddr] = useState("");
    const [extraAddr, setExtraAddr] = useState("");

    const [openPopup, setOpenPopup] = useState(false);
    const [isErr, setIsErr] = useState(false);
    const [openTextPopup, setOpenTextPopup] = useState(false);
    const [txtClick, setTxtClick] = useState(false);
    const [tempTxtClick, setTempTxtClick] = useState(false);

    const [tempTxt, setTempTxt] = useState("");

    useEffect(() => {
        if (!openPopup) {
            setTempTxt("");
            setIsErr(false);
        }
    }, [openPopup]);

    const handleMouseDown = type => e => {
        if (!props.readonly) {
            !props.isMobile && !props.isTablet && props.onMouseDown && props.onMouseDown(e, props.inx, type);
        }
    };

    const handleMouseUp = e => {
        if (!props.readonly) {
            props.onMouseUp && props.onMouseUp(e, props.inx);
        }
    };

    const handleChangeText = e => {
        let re = /^[0-9]*$/;
        let txt = e.target.value;
        setTempTxt(txt);

        if (txt === "") {
            setIsErr(false);
        } else if (props.fieldData?.coordId?.includes("email") && !validateEmail(txt)) {
            setIsErr(true);
        } else if (props.fieldData?.coordId?.includes("regisNum") && !validateRegisNum(txt)) {
            setIsErr(true);
        } else if (props.fieldData?.coordId?.includes("phone") && !re.test(txt)) {
            setIsErr(true);
        } else {
            setIsErr(false);
        }
    };

    const handleTouchStart = type => e => {
        props.onTouchStart && props.onTouchStart(e, props.inx, type);
    };

    const handleDeleteField = () => {
        props.onDeleteField && props.onDeleteField();
    };

    const handleCloneField = () => {
        props.onCloneField && props.onCloneField([props.inx]);
    };

    const handleMobileClick = () => {
        props.onMobileTouchCondition && props.onMobileTouchCondition(props.inx);
    };

    const handleKeyDown = e => {
        e.stopPropagation();
        props.onKeyDown && props.onKeyDown(e, "condition", props.inx);
    };

    const handleKeyUp = e => {
        e.stopPropagation();
        props.onKeyUp && props.onKeyUp(e, "condition", props.inx);
    };

    useEffect(() => {
        let w = window.innerWidth;

        if (w >= 1024 && !isWeb) {
            setWeb(true);
        }

        if (!props.isSigning && !props.isDragging && props.isSelected) {
            let id = props.isSigning ? props.fieldData.coordId : props.fieldData.coordId;
            let top = props.isSigning ? props.fieldData.top : props.fieldData.style.top;
            let contain = document.getElementById(id);
            let option = contain.querySelector("#option");
            if (option) {
                if (contain.parentNode.offsetHeight * (top / 100) < option.offsetHeight) {
                    setBottom(true);
                } else {
                    setBottom(false);
                }
            }
        }

        new Promise(async (resolve, reject) => {
            if (props.isSigning && (props.referer !== "signform" || !props.fieldData.coordId.includes("popup"))) {
                if (props.fieldData.content !== text) {
                    setText(props.fieldData.content);
                } else if (props.fieldData.content && text === "") {
                    setText(props.fieldData.content);
                }
            } else if (!props.isSigning && !props.fieldData.coordId.includes("popup")) {
                if (props.fieldData.content && text === "") {
                    setText(props.fieldData.content);
                } else if (props.fieldData.content !== text) {
                    setText(props.fieldData.content);
                }
            }
            resolve();
        }).then(() => {
            if (!isErr) setTempTxt(text);
        });

        if (props.isSigning && props.fieldData.content === "")
            if (props.isSelected !== isSelected) {
                setSelected(props.isSelected);
                let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
                if (element_layer) {
                    if (!props.isSelected) {
                        element_layer.style.display = "none";
                    } else {
                        if (!props.isMobile && !props.isTablet) {
                            element_layer.style.display = "block";
                        }
                    }
                }
            }
    }, [props.isDragging, props.isSelected, props.fieldData]);

    useEffect(() => {
        if (props.isSigning && (props.referer !== "signform" || props.fieldData.coordId.includes("popup"))) {
            if (props.clickList?.findIndex(x => x.coordId === props.fieldData?.coordId) > -1 && text === "") {
                setText(props.fieldData.content);
            }
        }
    }, [props.clickList]);

    const openAddrPopup = () => {
        let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
        let bg = document.querySelector("#background");

        let temp = props.addrList.filter(x => x[1] === props.fieldData?.coordId);
        if (temp.length > 0) {
            setAddr(temp[0][2]);
            if (temp[0][3]) setExtraAddr(temp[0][3]);
        }

        if (bg) bg.style.display = "block";
        if (element_layer) {
            let popup_ele = element_layer.querySelector("#searchaddrbox");
            let extra_ele = element_layer.querySelector("#extraaddrbox");
            extra_ele.style.display = "none";
            popup_ele.style.display = "block";
            new window.daum.Postcode({
                oncomplete: function(data) {
                    let sel_addr = ""; // 주소 변수

                    if (data.userSelectedType === "R") {
                        sel_addr = data.roadAddress;
                    } else {
                        sel_addr = data.jibunAddress;
                    }

                    if (sel_addr) {
                        setAddr(sel_addr);
                    }

                    element_layer.style.width = "500px";
                    element_layer.style.height = "350px";
                    element_layer.style.border = "none";
                    element_layer.style.boxShadow = "0 2px 20px 0 rgba(0, 0, 0, 0.16);";
                    popup_ele.style.display = "none";
                    extra_ele.style.display = "flex";
                    let textarea = extra_ele.querySelector("#extraaddrboxtext");
                    if (textarea) {
                        textarea.focus();
                    }
                },
                width: "100%",
                height: "200px",
                maxSuggestItems: 5,
                display: "block",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderRadius: "8px"
            }).embed(popup_ele);

            element_layer.style.display = "block";
            if (temp[0]?.length >= 2 && temp[0][2]) {
                extra_ele.style.display = "flex";
                popup_ele.style.display = "none";
            }

            initLayerPosition(addr);
        }
    };

    const initLayerPosition = data => {
        let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
        if (element_layer) {
            let width = 500; //우편번호서비스가 들어갈 element의 width
            let height = 450; //우편번호서비스가 들어갈 element의 height
            let borderWidth = 2; //샘플에서 사용하는 border의 두께

            if (data) {
                height = 350;
                borderWidth = 0;
            }

            element_layer.style.width = width + "px";
            element_layer.style.height = height + "px";
            element_layer.style.border = borderWidth + "px solid #1e8ffa";
        }
    };

    const closeAddrPopup = () => {
        let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
        let bg = document.querySelector("#background");
        if (element_layer) {
            element_layer.style.display = "none";
        }
        if (bg) {
            bg.style.display = "none";
        }
    };

    const saveAddr = (idx, isAll) => {
        let full_addr = addr;
        if (extraAddr) {
            full_addr = full_addr + " " + extraAddr;
        }
        setText(full_addr);
        let data = { name: "content", value: full_addr };
        props.onOptionChange && props.onOptionChange(props.inx, TEXT, data);
        let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
        let bg = document.querySelector("#background");
        if (element_layer) element_layer.style.display = "none";
        if (bg) bg.style.display = "none";

        let tempList = _.cloneDeep(props.addrList);
        tempList.map(item => {
            if (isAll === true) {
                if (item[0]) {
                    if (item[2]) item[2] = addr;
                    else item.push(addr);
                    if (item[3]) item[3] = extraAddr;
                    else item.push(extraAddr);
                }
            } else {
                if (item[1] === idx) {
                    if (item[2]) item[2] = addr;
                    else item.push(addr);
                    if (item[3]) item[3] = extraAddr;
                    else item.push(extraAddr);
                }
            }
        });
        props.handleAddrList(tempList);

        if (isAll === true) {
            props.handleApplyText(props.fieldData, full_addr);
        }
    };

    const researchAddr = () => {
        setExtraAddr("");
        setAddr("");
        let element_layer = document.querySelector(`#addrpopup${props.fieldData?.coordId}`);
        let popup_ele = element_layer.querySelector("#searchaddrbox");
        let extra_ele = element_layer.querySelector("#extraaddrbox");
        extra_ele.style.display = "none";
        popup_ele.style.display = "block";

        new window.daum.Postcode({
            oncomplete: function(data) {
                let sel_addr = ""; // 주소 변수

                if (data.userSelectedType === "R") {
                    sel_addr = data.roadAddress;
                } else {
                    sel_addr = data.jibunAddress;
                }

                if (sel_addr) {
                    setAddr(sel_addr);
                }

                element_layer.style.width = "500px";
                element_layer.style.height = "350px";
                element_layer.style.border = "none";
                element_layer.style.boxShadow = "0 2px 20px 0 rgba(0, 0, 0, 0.16);";
                popup_ele.style.display = "none";
                extra_ele.style.display = "flex";
                let textarea = extra_ele.querySelector("#extraaddrboxtext");
                if (textarea) {
                    textarea.focus();
                }
            },
            width: "100%",
            height: "200px",
            maxSuggestItems: 5,
            display: "block",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRadius: "8px"
        }).embed(popup_ele);

        initLayerPosition("");
    };

    const handlePopupTxt = () => {
        setText(props.fieldData?.content);
        setTxtClick(true);
        setOpenTextPopup(false);
        let data = { name: "content", value: props.fieldData?.content };
        props.onOptionChange && props.onOptionChange(props.inx, TEXT, data);
        props.onClickList && props.onClickList(props.fieldData);
    };

    const closeTextValuePopup = () => {
        setOpenTextPopup(false);
        if (tempTxtClick) setTempTxtClick(false);
    };

    return props.isSigning ? (
        <>
            <div
                className={styles.setItemsBox}
                id={props.fieldData.coordId}
                data-name={"text"}
                data-user={props.isSelected ? ((props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10) : -1}
                style={{
                    top: props.fieldData.top + "%",
                    left: props.fieldData.left + "%",
                    width: props.fieldData.width + "%",
                    height: props.fieldData.height + "%",
                    pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                    zIndex: props.isSelected ? 2 : 1,
                    touchAction: "none",
                    userSelect: "none",
                    msUserSelect: "none",
                    // backgroundColor: props.fieldData.coordType === "date" ? "#FFF" : undefined
                }}
                // onClick={
                //     !props.readonly ?
                //         (props.fieldData.readonly || props.fieldData.preSigned
                //             ? undefined
                //             : !(props.referer === "signform" || props.referer === "facial" || props.referer === "linkSignform")
                //             ? undefined
                //             : props.referer === "signform" && props.fieldData?.coordId?.includes("popup")
                //             ? () => setOpenTextPopup(true)
                //             : props.fieldData?.coordId.split("_")[0]?.includes("addr")
                //             //  ||
                //             // (props.checkTextApply &&
                //             //     props.checkTextApply("find", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label, props.fieldData?.coordId))
                //             ? undefined
                //             // : () => setOpenPopup(true))
                //             : undefined)
                //     : undefined
                // }
            >
                {props.children}
                {/* {props.fieldData.label &&
                    props.isSelected &&
                    !(props.isMobile || props.isTablet) &&(
                    // props.checkTextApply &&
                    // props.checkTextApply("find", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label, props.fieldData?.coordId) && (
                        <div className={styles.txtHelpBox}>
                            <p
                                style={
                                    props.setting && props.setting.comp_color
                                        ? { backgroundColor: `rgba(${hexToRgb(props.setting.comp_color, "0.2")}` }
                                        : { backgroundColor: `rgba(${hexToRgb("#1e8ffa", "0.2")}` }
                                }
                            >
                                {props.fieldData.label ? props.fieldData.label : undefined}
                            </p>
                        </div>
                    )} */}
                <div className={styles.setText}>
                    {!props.isSelected &&
                    (!props.fieldData.content ||
                        (props.preview_cond && !props.preCondValue))  ? (
                        // ((props.referer !== "signform" || props.fieldData?.coordId?.includes("popup")) &&
                            // props.clickList?.findIndex(x => x.coordId === props.fieldData?.coordId) < 0) ? (
                        // 미리보기 팝업 서명조건 분기 (수정자:JADE): onCondValue/preCondValue
                        <div
                            className={[styles.noContent, props.fieldData?.is_error ? styles.error : undefined].join(" ")}
                            onMouseDown={
                                props.fieldData.readonly
                                    ? undefined
                                    : props.isMobile || props.isTablet
                                    ? undefined
                                    : props.signedField
                                    ? undefined
                                    : props.preview_cond
                                    ? props.onCondValue
                                    : handleMouseDown("drag")
                            }
                            // onTouchStart={handleTouchStart("drag")}
                            onMouseUp={
                                props.fieldData.readonly
                                    ? undefined
                                    : props.isMobile || props.isTablet
                                    ? undefined
                                    : props.signedField
                                    ? undefined
                                    : props.preview_cond
                                    ? undefined
                                    : handleMouseUp
                            }
                            onClick={
                                !props.readonly ?
                                    (props.fieldData.readonly
                                        ? undefined
                                        : props.isMobile || props.isTablet
                                        ? props.signedField
                                            ? undefined
                                            : handleMobileClick
                                        : props.referer === "signform" && props.fieldData?.coordId?.includes("popup")
                                        ? () => setOpenTextPopup(true)
                                        : undefined)
                                : undefined
                            }
                            style={props.fieldData.readonly ? { cursor: "no-drop" } : props.preview_cond ? { cursor: "pointer" } : undefined}
                        >
                            <div className={[styles.textField].join(" ")}>
                                {/* <img
                                src="/static/media/contractpage/png"
                                srcSet="/static/media/contractpage/text@2x.png, /static/media/contractpage/text@3x.png"
                                alt="sign"
                            /> */}
                                {props.scale > 0.8 ? (
                                    <div
                                        className={styles.text}
                                        style={{
                                            fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                                            fontFamily: props.fieldData.fontFamily,
                                            textDecoration: props.fieldData.decoration,
                                            // fontWeight: props.fieldData.fontWeight ? 600 : 300,
                                            fontStyle: props.fieldData.fontStyle,
                                            textAlign: props.fieldData.textAlign,
                                            pointerEvents:
                                                props.fieldData?.coordId.split("_")[0]?.includes("addr") 
                                                // ||
                                                // (props.checkTextApply &&
                                                //     props.checkTextApply(
                                                //         "find",
                                                //         props.fieldData?.coordId?.split("_")[0],
                                                //         props.fieldData?.label,
                                                //         props.fieldData?.coordId
                                                //     ))
                                                    ? undefined
                                                    : "none"
                                        }}
                                    >
                                        {props.fieldData.label && props.fieldData.label !== "" ? props.fieldData.label : ("텍스트 입력")}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={[
                                styles.onText,
                                props.isSelected ? styles.on : styles.off,
                                props.fieldData?.is_error ? styles.error : undefined
                            ].join(" ")}
                        >
                            <div
                                className={styles.fieldLine}
                                style={{
                                    opacity: props.fieldData.opacity,
                                    cursor: props.preview_cond ? "pointer" : props.fieldData.cursor
                                }}
                                // onMouseDown={
                                //     props.referer === "reviewform" || props.is_othercond || props.fieldData?.readonly
                                //         ? undefined
                                //         : props.isMobile || props.isTablet
                                //         ? undefined
                                //         : props.signedField
                                //         ? undefined
                                //         : props.preview_cond
                                //         ? props.onCondValue
                                //         : ["addr"].includes(props.fieldData?.coordId?.split("_")[0])
                                //         ? undefined
                                //         : handleMouseDown("drag")
                                // }
                                // // onTouchStart={handleTouchStart("drag")}
                                // onMouseUp={
                                //     props.referer === "reviewform" || props.is_othercond || props.fieldData?.readonly
                                //         ? undefined
                                //         : props.isMobile || props.isTablet
                                //         ? undefined
                                //         : props.signedField
                                //         ? undefined
                                //         : props.preview_cond
                                //         ? undefined
                                //         : ["addr"].includes(props.fieldData?.coordId?.split("_")[0])
                                //         ? undefined
                                //         : handleMouseUp
                                // }
                                // onClick={
                                //     !props.readonly ?
                                //         (props.referer === "reviewform" || props.is_othercond || props.fieldData?.readonly
                                //             ? undefined
                                //             : props.isMobile || props.isTablet
                                //             ? props.signedField
                                //                 ? undefined
                                //                 : handleMobileClick
                                //             : ["addr"].includes(props.fieldData?.coordId?.split("_")[0])
                                //             ? openAddrPopup
                                //             : undefined)
                                //     : undefined
                                // }
                                data-noselect={true}
                            >
                                {props.referer !== "reviewform" &&
                                !props.is_othercond &&
                                isWeb &&
                                props.isSelected &&
                                !props.isDragging &&
                                !props.signedField &&
                                !props.fieldData.readonly &&
                                !["addr"].includes(props.fieldData?.coordId?.split("_")[0]) ? (
                                    <textarea
                                        style={{
                                            color: props.fieldData.color,
                                            fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                                            fontFamily: props.fieldData.fontFamily,
                                            fontHeight: props.fieldData.fontHeight,
                                            textDecoration: props.fieldData.decoration,
                                            resize: "none",
                                            // fontWeight: props.fieldData.fontWeight ? 600 : 300,
                                            fontStyle: props.fieldData.fontStyle,
                                            textAlign: props.fieldData.textAlign
                                        }}
                                        className={styles.fieldText}
                                        name="content"
                                        id="contentInput"
                                        value={
                                            // (props.checkTextApply &&
                                            //     props.checkTextApply(
                                            //         "find",
                                            //         props.fieldData?.coordId?.split("_")[0],
                                            //         props.fieldData?.label,
                                            //         props.fieldData?.coordId
                                            //     )) ||
                                            text
                                                ? text
                                                : ""
                                        }
                                        onChange={handleChangeText}
                                        data-noselect={true}
                                        onFocus={
                                            props.fieldData.readonly
                                                ? undefined
                                                : props.fieldData?.coordId.split("_")[0]?.includes("addr")
                                                //  ||
                                                //   (props.checkTextApply &&
                                                //       props.checkTextApply(
                                                //           "find",
                                                //           props.fieldData?.coordId?.split("_")[0],
                                                //           props.fieldData?.label,
                                                //           props.fieldData?.coordId
                                                //       ))
                                                ? undefined
                                                : undefined
                                                // : e => {
                                                //       setOpenPopup(true);
                                                //       e.target.blur();
                                                //   }
                                        }
                                        readOnly={props.referer === "signform" && props.fieldData?.coordId?.includes("popup")}
                                        // onClick={
                                        //     (props.checkTextApply && props.checkTextApply("find", props.fieldData.coordId.split("_")[0], props.fieldData.label))
                                        //         ? undefined
                                        //         : () => setOpenPopup(true)
                                        // }
                                    />
                                ) : (
                                    <div
                                        style={{
                                            // color: props.fieldData.color,
                                            fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                                            // fontFamily: props.fieldData.fontFamily,
                                            // fontHeight: props.fieldData.fontHeight,
                                            // textDecoration: props.fieldData.decoration,
                                            resize: "none",
                                            // fontWeight: props.fieldData.fontWeight ? 600 : 300,
                                            // fontStyle: props.fieldData.fontStyle,
                                            textAlign: props.fieldData.textAlign,
                                            width: "100%"
                                        }}
                                        className={styles.fieldText}
                                        name="content"
                                        data-noselect={true}
                                    >
                                        {text}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {/* {props.isMobile || props.isTablet ? (
                undefined
            ) : (
                <button
                    className={[
                        styles.fieldBtnSize,
                        props.isResizing ? styles.resizeOn : null,
                        !props.isDragging && props.isSelected ? styles.on : null
                    ].join(" ")}
                    onMouseDown={handleMouseDown("resize")}
                    onTouchStart={handleTouchStart("resize")}
                    onMouseUp={handleMouseUp}
                >
                    <img
                        src="/static/media/contractpage/resize.png"
                        srcSet="/static/media/contractpage/resize@2x.png, /static/media/contractpage/resize@3x.png"
                        alt="resize"
                    />
                </button>
            )} */}
            </div>
            {["addr"].includes(props.fieldData?.coordId?.split("_")[0]) && !props.isMobile && !props.isTablet && (
                <>
                    <div id={`addrpopup${props.fieldData?.coordId}`} className={[styles.addrPopup, addr ? styles.extra : undefined].join(" ")}>
                        <div className={[styles.header, addr ? styles.extra : undefined].join(" ")}>
                            {addr ? ("상세주소 입력") : ("주소검색")}
                            <div className={styles.closeAddr} onClick={closeAddrPopup}>
                                <SVG.Close color={addr ? undefined : "#fff"} />
                            </div>
                        </div>
                        <div id="searchaddrbox" className={styles.searchAddrBox}></div>
                        <div id="extraaddrbox" className={styles.extraAddrBox}>
                            <div className={styles.addr} id="addrbox1">
                                <label id="addrbox2">{("선택하신 주소")}</label>
                                <div id="addrboxtextwrap" className={styles.inputWrap}>
                                    <input
                                        id="addrboxtext"
                                        className={styles.selAddr}
                                        type="text"
                                        value={addr}
                                        placeholder={("주소를 검색해주세요")}
                                        onChange={e => setAddr(e.target?.value)}
                                        autoComplete="off"
                                        disabled={true}
                                    ></input>
                                    <button id="researchaddrbox" onClick={researchAddr}>
                                        <SVG.Close color="#fff" id="addrboxsvg" />
                                    </button>
                                </div>
                            </div>
                            <div className={styles.extraAddr} id="addrbox3">
                                <label id="addrbox4">{("상세주소")}</label>
                                <div id="addrboxtextwrap" className={styles.inputWrap}>
                                    <input
                                        id="extraaddrboxtext"
                                        type="text"
                                        value={extraAddr}
                                        placeholder={("상세주소를 입력하세요.")}
                                        onChange={e => setExtraAddr(e.target?.value)}
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div id="extraaddrbutton" className={styles.extraButton}>
                                <button
                                    className={styles.left}
                                    style={
                                        // props.checkTextApply &&
                                        // props.checkTextApply("addr", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label)
                                        //     ? undefined
                                        //     : 
                                            { width: "100%", opacity: 1 }
                                    }
                                    onClick={() => saveAddr(props.fieldData?.coordId)}
                                >
                                    {("확인")}
                                </button>
                                {/* {props.checkTextApply &&
                                    props.checkTextApply("addr", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label) && (
                                        <button onClick={() => saveAddr(props.fieldData?.coordId, true)}>{("일괄 적용")}</button>
                                    )} */}
                            </div>
                        </div>
                    </div>
                    <div id={"background"} className={styles.bg} style={{ display: "none" }} onClick={closeAddrPopup}></div>
                </>
            )}
            {openPopup && !(props.isMobile || props.isTablet) && (
                <>
                    <div className={styles.textPopup}>
                        <div className={styles.header}>
                            <div className={styles.title}>{("텍스트 입력")}</div>
                            <div
                                className={styles.close}
                                onClick={() => {
                                    setOpenPopup(false);
                                    props.resetSelectedField();
                                }}
                            >
                                <SVG.Close />
                            </div>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.input}>
                                <div className={styles.label}>{`${("텍스트")}(${
                                    props.fieldData.label ? props.fieldData.label : undefined
                                })`}</div>
                                <textarea
                                    style={{
                                        color: props.fieldData.color,
                                        fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                                        fontFamily: props.fieldData.fontFamily,
                                        fontHeight: props.fieldData.fontHeight,
                                        textDecoration: props.fieldData.decoration,
                                        resize: "none",
                                        // fontWeight: props.fieldData.fontWeight ? 600 : 300,
                                        fontStyle: props.fieldData.fontStyle,
                                        textAlign: props.fieldData.textAlign,
                                        borderColor: isErr ? "#ff1414" : undefined
                                    }}
                                    name="content"
                                    placeholder={
                                        // lang === "en"
                                        //     ? `${("을(를) 입력하세요.")} ${
                                        //           props.fieldData.label ? props.fieldData.label : ("텍스트")
                                        //       }`
                                        //     : 
                                            `${props.fieldData.label ? props.fieldData.label : ("텍스트")}${(
                                                  "을(를) 입력하세요."
                                              )}`
                                    }
                                    value={tempTxt}
                                    onChange={handleChangeText}
                                    data-noselect={true}
                                />
                            </div>
                            {isErr ? (
                                <div className={styles.error}>
                                    {props.fieldData?.coordId?.split("_")[0]?.includes("email")
                                        ? ("* 이메일 형식을 확인해주세요.")
                                        : props.fieldData?.coordId?.split("_")[0]?.includes("regisNum")
                                        ? ("* 숫자만 입력해주세요.")
                                        : props.fieldData?.coordId?.includes("phone")
                                        ? ("* 전화번호를 확인해주세요.")
                                        : ("* 입력값을 확인해주세요.")}
                                </div>
                            ) : (
                                undefined
                            )}
                            <div className={styles.msg}>
                                {("* '일괄 적용'을 누르시면 입력하시는 내용이 ")}
                                {`${("텍스트")}(${props.fieldData.label ? props.fieldData.label : undefined})`}
                                {("에 동일하게 적용되며, '단일 적용'을 누르시면 해당 텍스트 조건에만 적용됩니다.")}
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <button
                                className={styles.left}
                                style={
                                    props.setting?.comp_color ? { backgroundColor: `rgba(${hexToRgb(props.setting.comp_color, "0.6")}` } : undefined
                                }
                                onClick={
                                    isErr || tempTxt === ""
                                        ? undefined
                                        : () => {
                                              setOpenPopup(false);
                                              props.resetSelectedField();
                                            //   props.checkTextApply &&
                                            //       props.checkTextApply("add", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label);
                                              props.onOptionChange && props.onOptionChange(props.inx, TEXT, { name: "content", value: tempTxt });
                                          }
                                }
                            >
                                {("단일 적용")}
                            </button>
                            <button
                                style={props.setting?.comp_color ? { backgroundColor: props.setting.comp_color } : undefined}
                                onClick={
                                    isErr || tempTxt === ""
                                        ? undefined
                                        : () => {
                                              setOpenPopup(false);
                                              props.handleApplyText(props.fieldData, tempTxt);
                                            //   props.checkTextApply &&
                                            //       props.checkTextApply("add", props.fieldData?.coordId?.split("_")[0], props.fieldData?.label);
                                          }
                                }
                            >
                                {("일괄 적용")}
                            </button>
                        </div>
                    </div>
                    <div
                        className={styles.bg}
                        onClick={() => {
                            setOpenPopup(false);
                            props.resetSelectedField();
                        }}
                    ></div>
                </>
            )}
            {openTextPopup && !(props.isMobile || props.isTablet) && (
                <>
                    <div className={styles.txtValueWrap}>
                        <div className={styles.header}>
                            <div className={styles.title}>{("텍스트 서명")}</div>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.label}>
                                <p>*</p>
                                {props.fieldData?.label}
                            </div>
                            <div className={styles.textBox} onClick={tempTxtClick || txtClick ? undefined : () => setTempTxtClick(true)}>
                                {(tempTxtClick || txtClick) && (
                                    <div
                                        className={styles.text}
                                        style={{
                                            fontSize: `${props.fieldData?.content?.length < 7 ? 10 - props.fieldData?.content?.length : 4}vh`
                                        }}
                                    >
                                        {props.fieldData?.content}
                                    </div>
                                )}
                                <div
                                    className={styles.placeholder}
                                    style={{
                                        fontSize: `${props.fieldData?.content?.length < 5 ? 4 : 3}vh`
                                    }}
                                >
                                    {props.fieldData?.content}
                                </div>
                            </div>
                        </div>
                        <div className={[styles.footer, txtClick ? styles.oneBtn : undefined].join(" ")}>
                            {!txtClick && (
                                <div className={styles.cancel} onClick={closeTextValuePopup}>
                                    {("취소")}
                                </div>
                            )}
                            <div
                                className={[styles.apply, tempTxtClick || txtClick ? undefined : styles.disable].join(" ")}
                                onClick={txtClick ? closeTextValuePopup : tempTxtClick ? handlePopupTxt : undefined}
                            >
                                {("확인")}
                            </div>
                        </div>
                    </div>
                    <div className={styles.bg} onClick={closeTextValuePopup}></div>
                </>
            )}
        </>
    ) : (
        <div
            id={props.fieldData.coordId}
            className={[styles.fieldBox, props.single ? styles.single : null].join(" ")}
            data-name={"text"}
            data-user={(props.fieldData?.user + 1) % 10 === 0 ? 10 : (props.fieldData?.user + 1) % 10}
            style={{
                top: props.fieldData.top + "%",
                left: props.fieldData.left + "%",
                width: props.fieldData.width + "%",
                height: props.fieldData.height + "%",
                pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                zIndex: props.isSelected ? 2 : 1,
                touchAction: "none",
                userSelect: "none",
                msUserSelect: "none",
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            tabIndex={0}
        >
            {props.children}
            {!props.isMulti && (
                <div
                    id="option"
                    className={[
                        styles.fieldOption,
                        !props.isDragging && props.isSelected ? styles.on : null,
                        props.single ? styles.single : null,
                        isBottom ? styles.bottom : null
                    ].join(" ")}
                    data-noselect={true}
                >
                    <div className={styles.optionBtn} data-noselect={true}>
                        <button className={styles.on} onClick={!props.readonly ? handleDeleteField : undefined} data-noselect={true}>
                            {("삭제")}
                        </button>
                        <button onClick={!props.readonly ? handleCloneField : undefined} data-noselect={true}>
                            {("복사")}
                        </button>
                    </div>
                </div>
            )}
            <div
                className={[styles.fieldLine, props.fieldData.signed ? styles.signedLine : null].join(" ")}
                style={{
                    pointerEvents: props.isResizing || props.isDragging ? "none" : "auto",
                    opacity: (props.isResizing || props.isDragging) && props.isSelected ? 0.5 : 0.9
                }}
                onMouseDown={handleMouseDown("drag")}
                onTouchStart={handleTouchStart("drag")}
                onMouseUp={handleMouseUp}
            >
                {isWeb && props.isSelected && !props.isDragging ? (
                    <textarea
                        style={{
                            color: props.fieldData.color,
                            fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                            fontFamily: props.fieldData.fontFamily,
                            fontHeight: props.fieldData.fontHeight,
                            textDecoration: props.fieldData.textDecoration,
                            // fontWeight: props.fieldData.fontWeight ? 600 : 300,
                            fontStyle: props.fieldData.fontStyle,
                            textAlign: props.fieldData.textAlign,
                            resize: "none"
                        }}
                        className={[
                            styles.fieldText,
                            props.fieldData.signed ? styles.signedField : null,
                            props.fieldData.content ? null : styles.place,
                            props.isSelected ? styles.fieldOn : undefined,
                            props.fontSize === 0 ? styles.small : null
                        ].join(" ")}
                        name="content"
                        onChange={handleChangeText}
                        value={text}
                    />
                ) : (
                    <div
                        style={{
                            color: props.fieldData.content ? props.fieldData.color : undefined,
                            fontSize: `${props.fieldData.fontSize ? props.fieldData.fontSize * props.scale : 12 * props.scale}px`,
                            fontFamily: props.fieldData.fontFamily,
                            fontHeight: props.fieldData.fontHeight,
                            textDecoration: props.fieldData.textDecoration,
                            fontStyle: props.fieldData.fontStyle,
                            textAlign: props.fieldData.textAlign,
                            justifyContent: "flex-start",
                            boxSizing: "border-box"
                        }}
                        className={[
                            styles.fieldText,
                            props.fieldData.signed ? styles.signedField : null,
                            props.fieldData.content ? null : styles.place,
                            props.fontSize === 0 ? styles.small : null,
                            props.isSelected ? styles.fieldOn : undefined
                        ].join(" ")}
                    >
                        {text ? text : ("텍스트")}
                    </div>
                )}
            </div>
            {!props.isMulti && (
                <button
                    className={[
                        styles.fieldBtnSize,
                        props.isResizing ? styles.resizeOn : null,
                        !props.isDragging && props.isSelected ? styles.on : null
                    ].join(" ")}
                    onMouseDown={handleMouseDown("resize")}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart("resize")}
                >
                    <img
                        src="/static/media/contractpage/resize.png"
                        srcSet="/static/media/contractpage/resize@2x.png, /static/media/contractpage/resize@3x.png"
                        alt="resize"
                    />
                </button>
            )}
        </div>
    );
};

TextBox.propTypes = {
    pdf: PropTypes.object,
    fieldData: PropTypes.object
};

export default TextBox;
