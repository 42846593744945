import React from "react";

const CloseSVG = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id={props.id ? props.id : "prefix__close-24px_1_"}
            width={props.w ? props.w : "30"}
            height={props.h ? props.h : "30"}
            data-name="close-24px (1)"
            viewBox="0 0 30 30"
            data-noselect={true}
        >
            <path fill={"none"} id={props.id ? `${props.id}1` : "prefix__패스_4124"} d="M0 0h30v30H0z" data-name="패스 4124" />
            <path
                fill={props.color ? props.color : "#6980a6"}
                id={props.id ? `${props.id}2` : "prefix__패스_4125"}
                d="M22.053 5.8a1.286 1.286 0 00-1.82 0l-6.313 6.3-6.314-6.315a1.287 1.287 0 00-1.82 1.82l6.314 6.314-6.315 6.313a1.287 1.287 0 001.82 1.82l6.313-6.313 6.313 6.313a1.287 1.287 0 001.82-1.82l-6.313-6.313 6.313-6.313a1.294 1.294 0 00.002-1.806z"
                data-name="패스 4125"
                transform="translate(1.081 1.081)"
            />
        </svg>
    );
};

export default CloseSVG;
