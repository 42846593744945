import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './styles.scss';

import Header from "components/Layout/Header";

import Signing from "components/Signing";
import MainTitle from "components/Title/MainTitle";
import Modal01 from "components/Modal/Modal01";

import { signature as signatureActions, userLogout } from "module/userModule";
import { contractComplete } from "module/contractModule";
import { reset } from "module/passModule";

const Sign = (props) => {
    const dispatch = useDispatch();
    const mapping = useSelector(state => state.contract.mapping);
    const { data } = useSelector(state => state.contract);
    const complete = useSelector(state => state.contract.complete);
    const estimate = useSelector(state => state.contract.estimate);
    const keys = useSelector(state => state.user.keys);
    const personal = useSelector(state => state.contract.isPersonal);
    const completeTrueKeys = useSelector(state => state.user.completeTrueKeys);
    const completeKeys = useSelector(state => state.user.completeKeys);
    const cddKeys = useSelector(state => state.user.cddKeys);
    const owner = useSelector(state => state.user.owner);
    const cmsKeys = useSelector(state => state.ars.cmsKeys);
    const gKeys = useSelector(state => state.user.gKeys);
    const arsState = useSelector(state => state.ars);
    const custId = useSelector(state => state.contract.custId);
    const bankAccount = useSelector(state => state.ars.bankAccount);
    const bankCd = useSelector(state => state.ars.bankCd);
    const custnm = useSelector(state => state.ars.custnm);
    const KUNNR = useSelector(state => state.contract.KUNNR);
    const E_SUBMICD = useSelector(state => state.contract.E_SUBMICD);
    
    const isAll = useSelector(state => state.user.isAll);
    const userState = useSelector(state => state.user);
    const token = useSelector(state => state.pass.accessToken);

    const [modalOpen, setModalOpen] = useState(false);
    const [keyString, setKeyString] = useState("");
    const [flag, setFlag] = useState(false);
    const [temp, setTemp] = useState(false);
    const [sign, setSign] = useState("");

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }
        dispatch(contractComplete.get.request());
        dispatch(reset.get.request());
    }, []);

    useEffect(() => {
        if (complete === "success" && mapping) {
            dispatch(contractComplete.get.request());
            props.history.push("/signature/signStep2");
        }
    }, [complete]);

    useEffect(() => {
        if (flag && !temp) {
            async function setData() {
                await setTemp(true);
                await handleComplete(sign);
            }
            setData();
        }
    }, [flag]);

    const openCertWindow = () => {
        window.open("", "popupChk", "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
        document.certForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.certForm.target = "popupChk";
        document.certForm.submit();
        dispatch(reset.get.request());

        window.addEventListener("message", getMsg);
    };

    const getMsg = async e => {
        if (e.origin === window.location.origin) {
            if (e.data.query){
                if (e.data.query["status"] && e.data.query["status"] === "success") {
                    await setFlag(true);
                } else {
                    setModalOpen(true);
                }
            }
        }
    }

    const handleCloseSignModal = async selectedSign => {
        await setSign(selectedSign);
        await dispatch(signatureActions.post.request({ sign: selectedSign }));
        // props.history.push("/signature/signStep2");
        if (personal) {
            await handleComplete(selectedSign);
        } else {
            new Promise(async (resolve, reject) => {
                try {
                    fetch("/api/v1/niceplus/auth", {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            state: "manager",
                            I_ETMTSEQ: estimate
                        }),
                    }).then(response => {
                        if (response.status === 401) {
                            dispatch(userLogout.post.request());
                        } else if (response.status === 400) {
                            dispatch(userLogout.get.success());
                        } else if (response.status === 200) {
                            return response.json();
                        } else {
                            setModalOpen(true);
                        }
                    }).then(async res => {
                        if (res && res?.message !== "") {
                            await setKeyString(res?.message);
                            openCertWindow();
                        } else {
                            setModalOpen(true);
                        }
                    }).catch(error => {
                        setModalOpen(true);
                    });
                } catch(e) {
                    setModalOpen(true);
                    reject(e);
                }
            });
        }
    };

    const handleComplete = async selectedSign => {
        let condition = [];
        await keys.map(item => {
            if (userState[item] && userState[item] !== "") {
                if (item === "PLAN_ETC") {
                    condition.push({ id: personal ? "S1_PLAN_ETC_TX" : "S2_PLAN_ETC_TX", value: userState[item] });
                } else if (item === "USE_ETC") {
                    condition.push({ id: personal ? "S1_USE_ETC" : "S2_USE_CK3_ETC", value: userState[item] });
                } else if (item === "S1_AGE" || item === "S1_POINT" || item === "S2_TERM") {
                    condition.push({ id: item, value: userState[item] });
                } else {
                    condition.push({ id: userState[item], value: true });
                }
            }
        });
        await completeTrueKeys.map(key => {
            // 필수 동의였던 키들 (무조건 _Y)
            if ((!key.includes("CMS_AGREE") || E_SUBMICD === "B") && (!key.includes("R_AGREE") || personal)) {
                condition.push({ id: `${key}_Y`, value: true });
            }
        });
        if (personal) {
            await completeKeys.map(key => {
                if (userState[key]) {
                    condition.push({ id: `${key}_Y`, value: true });
                } else {
                    condition.push({ id: `${key}_N`, value: true });
                }
            });
        } else {
            await cddKeys.map(key => {
                if (userState[key] && userState[key] !== "") {
                    condition.push({ id: key, value: userState[key] });
                }
            });
            await condition.push({ id: "CDD_TYPE", value: owner  });
        }
        await cmsKeys.map(key => {
            if (arsState[key] && arsState[key] !== "") {
                if (key === "bildt") {
                    condition.push({ id: arsState[key], value: true });
                } else {
                    condition.push({ id: key, value: arsState[key] });
                }
            }
        });
        await gKeys.map(key => {
            if (userState[key]) {
                condition.push({ id: key, value: "Y" });
            }
        });
        await condition.push({ id: "bankIdentification", value: custId });
        await condition.push({ id: `${personal ? "S1" : "S2"}_AGREE_Y`, value: true });

        await dispatch(contractComplete.post.request({
            signData: selectedSign,
            signCondition: condition,
            isAll: isAll,
            I_ETMTSEQ: estimate,
                            
            bankIdentification: custId,
            bankAccount: bankAccount,
            bankCd: bankCd,
            isCompany: !personal,
            custnm: custnm,
            KUNNR: KUNNR,
            token: token
        }));
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="전자 서명" style={{marginBottom:"32px"}} substyle={{color:"#666666"}}>
                            <p>계약 신청을 완료하기 위해, 전자서명과 계약서를 확인해 주세요.</p>
                        </MainTitle>
                        <div className={styles.signWrap}>
                            <Signing
                                onCloseSignModal={handleCloseSignModal}
                                referer={"drawing"}
                                isSigning={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {keyString !== "" && (
                <form name="certForm" id="certForm">
                    <label><input type="hidden" name="m" value="checkplusService"/></label>
                    <label><input type="hidden" name="EncodeData" value={keyString}/></label>
                </form>
            )}
            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="본인인증 실패">
                본인인증에 실패하였습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
        </>
    )

}

export default Sign;