import { connect } from "react-redux";
import React, { Component } from "react";
import styles from "./styles.scss";

const mapStateToProps = (state, ownProps) => {
};

class ConfirmPopup extends Component {
    render() {
        return (
            <div className={styles.wrap} style={this.props.zIndex ? { zIndex: this.props.zIndex } : null}>
                <div className={styles.otherWrap} onClick={this.props.cancel} />
                <div
                    className={[
                        styles.popup,
                        this.props.referer === "createlink" ? styles.link : undefined,
                        this.props.menu === "withdrawal" ? styles.withdrawal : null
                    ].join(" ")}
                >
                    {this.props.noHeader ? null : (
                        <div className={styles.header}>
                            {this.props.noCancelButton ? null : this.props.closeButton ? (
                                <button onClick={this.props.closeButton}>
                                    <img src={"/static/media/common/1177@3x.png"} alt="close" />
                                </button>
                            ) : (
                                <button onClick={this.props.cancel}>
                                    <img src={"/static/media/common/1177@3x.png"} alt="close" />
                                </button>
                            )}
                        </div>
                    )}
                    {this.props.noBody ? null : (
                        <div className={styles.body}>
                            <div className={styles.title} style={this.props.successBtnColor ? { color: "#000" } : null}>
                                {this.props.title}
                            </div>
                            {this.props._checkComponent !== undefined ? (
                                this.props._checkComponent.component !== undefined ? (
                                    <this.props._checkComponent.component />
                                ) : null
                            ) : null}
                            <div className={styles.message}>{this.props.message && this.props.message.map((m, i) => <p key={i}>{m}</p>)}</div>
                            {this.props.checkComponent !== undefined ? (
                                this.props.checkComponent.component !== undefined ? (
                                    <this.props.checkComponent.component />
                                ) : null
                            ) : null}
                        </div>
                    )}
                    {this.props.bodyComponent !== undefined ? (
                        this.props.bodyComponent.component !== undefined ? (
                            <this.props.bodyComponent.component />
                        ) : null
                    ) : null}
                    {this.props.children}
                    {this.props.noButton ? null : (
                        <div className={styles.footer}>
                            {this.props.leftComponent ? (
                                <button
                                    className={styles.cancel}
                                    onClick={this.props.leftComponent.onClick !== undefined ? this.props.leftComponent.onClick : this.props.cancel}
                                    style={
                                        this.props.leftBackColor
                                            ? { backgroundColor: this.props.leftBackColor }
                                            // : this.props.setting && this.props.setting.comp_color
                                            // ? { backgroundColor: `rgba(${hexToRgb(this.props.setting.comp_color, "0.3")})` }
                                            : undefined
                                    }
                                >
                                    {this.props.leftComponent.text ? this.props.leftComponent.text : ("취소")}
                                </button>
                            ) : this.props.noCancel ? null : (
                                <button
                                    className={styles.cancel}
                                    onClick={this.props.cancel}
                                    style={
                                        this.props.leftBackColor
                                            ? { backgroundColor: this.props.leftBackColor }
                                            // : this.props.setting && this.props.setting.comp_color
                                            // ? { backgroundColor: `rgba(${hexToRgb(this.props.setting.comp_color, "0.6")})` }
                                            : undefined
                                    }
                                >
                                    {this.props.cancelText ? this.props.cancelText : ("아니요")}
                                </button>
                            )}
                            {this.props.rightComponent ? (
                                this.props.rightComponent.component !== undefined ? (
                                    <div className={styles.success}>
                                        <this.props.rightComponent.component />
                                    </div>
                                ) : (
                                    <button
                                        className={styles.success}
                                        onClick={
                                            this.props.rightComponent.onClick !== undefined ? this.props.rightComponent.onClick : this.props.success
                                        }
                                        style={
                                            this.props.successBtnColor
                                                ? { backgroundColor: this.props.successBtnColor }
                                                // : this.props.setting && this.props.setting.comp_color
                                                // ? { backgroundColor: this.props.setting.comp_color }
                                                : undefined
                                        }
                                    >
                                        {this.props.rightComponent.text ? this.props.rightComponent.text : ("저장")}
                                    </button>
                                )
                            ) : (
                                <button
                                    className={styles.success}
                                    onClick={this.props.success}
                                    style={
                                        this.props.successBtnColor
                                            ? { backgroundColor: this.props.successBtnColor }
                                            // : this.props.setting && this.props.setting.comp_color
                                            // ? { backgroundColor: this.props.setting.comp_color }
                                            : undefined
                                    }
                                >
                                    {this.props.successText ? this.props.successText : ("확인")}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ConfirmPopup);
