import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './styles.scss';

import Header from "components/Layout/Header";

import ButtonBar from "components/ButtonBar";
import Document from "components/Document";
import Modal01 from "components/Modal/Modal01";
import Modal02 from "components/Modal/Modal02";

import { reset } from "module/passModule";
import { contractComplete } from "module/contractModule";
import { userLogout } from "module/userModule";
import UploadLoading from "components/Loading/uploadLoading";

const SignStep2 = (props) => {
    const dispatch = useDispatch();
    const CI = useSelector(state => state.pass.info);
    const personal = useSelector(state => state.contract.isPersonal);
    const { data } = useSelector(state => state.contract);
    const estimate = useSelector(state => state.contract.estimate);
    const custId = useSelector(state => state.contract.custId);
    const signature = useSelector(state => state.user.signature);
    const mapping = useSelector(state => state.contract.mapping);

    const complete = useSelector(state => state.contract.complete);
    const isAll = useSelector(state => state.user.isAll);
    const E_SUBMICD = useSelector(state => state.contract.E_SUBMICD);
    const recordName = useSelector(state => state.ars.recordName);
    const derName = useSelector(state => state.ars.derName);
    const KUNNR = useSelector(state => state.contract.KUNNR);
    const bankAccount = useSelector(state => state.ars.bankAccount);
    const bankCd = useSelector(state => state.ars.bankCd);
    const custnm = useSelector(state => state.ars.custnm);
    const bildt = useSelector(state => state.ars.bildt);
    const isComplete = useSelector(state => state.contract.isComplete);
    const userState = useSelector(state => state.user);
    const arsState = useSelector(state => state.ars);
    const keys = useSelector(state => state.user.keys);
    const completeTrueKeys = useSelector(state => state.user.completeTrueKeys);
    const completeKeys = useSelector(state => state.user.completeKeys);
    const cddKeys = useSelector(state => state.user.cddKeys);
    const owner = useSelector(state => state.user.owner);
    const cmsKeys = useSelector(state => state.ars.cmsKeys);
    const gKeys = useSelector(state => state.user.gKeys);
    const token = useSelector(state => state.pass.accessToken);
    const errMsg = useSelector(state => state.user.errMsg);

    const [openCert, setOpenCert] = useState(false);
    const [keyString, setKeyString] = useState("");

    const [openCheckModal, setOpenCheckModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [flag, setFlag] = useState(false);
    const [temp, setTemp] = useState(false);

    const script_arr = [
        "/SW/initech/extension/common/exinterface.js",
        "/SW/initech/extension/crosswebex6.js",
        "/SW/initech/extension/common/exinstall.js",
        "/SW/initech/extension/crosswebexInit.js",
        "/SW/initech/extension/cw_web6_adt.js",
        "/SW/initech/extension/common/js/exproto_ext_daemon.js"
    ];
    
    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }
    }, []);

    useEffect(() => {
        if (complete !== "" && isComplete) {
            setIsLoading(false);
            dispatch(contractComplete.get.request()); // reset
            props.history.push("/complete");
        }
    }, [complete]);

    useEffect(() => {
        if (flag && !temp) {
            async function setData() {
                await setTemp(true);

                let condition = [];
                await keys.map(item => {
                    if (userState[item] && userState[item] !== "") {
                        if (item === "PLAN_ETC") {
                            condition.push({ id: personal ? "S1_PLAN_ETC_TX" : "S2_PLAN_ETC_TX", value: userState[item] });
                        } else if (item === "USE_ETC") {
                            condition.push({ id: personal ? "S1_USE_ETC" : "S2_USE_CK3_ETC", value: userState[item] });
                        } else if (item === "S1_AGE" || item === "S1_POINT" || item === "S2_TERM") {
                            condition.push({ id: item, value: userState[item] });
                        } else {
                            condition.push({ id: userState[item], value: true });
                        }
                    }
                });
                await completeTrueKeys.map(key => {
                    // 필수 동의였던 키들 (무조건 _Y)
                    if ((!key.includes("CMS_AGREE") || E_SUBMICD === "B") && (!key.includes("R_AGREE") || personal)) {
                        condition.push({ id: `${key}_Y`, value: true });
                    }
                });
                if (personal) {
                    await completeKeys.map(key => {
                        if (userState[key]) {
                            condition.push({ id: `${key}_Y`, value: true });
                        } else {
                            condition.push({ id: `${key}_N`, value: true });
                        }
                    });
                } else {
                    await cddKeys.map(key => {
                        if (userState[key] && userState[key] !== "") {
                            condition.push({ id: key, value: userState[key] });
                        }
                    });
                    await condition.push({ id: "CDD_TYPE", value: owner  });
                }
                await cmsKeys.map(key => {
                    if (arsState[key] && arsState[key] !== "") {
                        if (key === "bildt") {
                            condition.push({ id: arsState[key], value: true });
                        } else {
                            condition.push({ id: key, value: arsState[key] });
                        }
                    }
                });
                await gKeys.map(key => {
                    if (userState[key]) {
                        condition.push({ id: key, value: "Y" });
                    }
                });
                await condition.push({ id: "bankIdentification", value: custId });
                await condition.push({ id: `${personal ? "S1" : "S2"}_AGREE_Y`, value: true });

                dispatch(reset.get.fail());
                // console.log(condition);
                if (E_SUBMICD === "B") {
                    dispatch(contractComplete.post.request({
                        signData: signature,
                        signCondition: condition,
                        mapping: mapping,
                        isAll: isAll,
                        I_ETMTSEQ: estimate,
                        custId: custId,
                        isComplete: true,
    
                        recordName: recordName,
                        CI: CI,
                        
                        bankIdentification: custId,
                        bankAccount: bankAccount,
                        bankCd: bankCd,
                        isCompany: !personal,
                        custnm: custnm,
                        KUNNR: KUNNR,
                        [bildt]: true,
                        token: token
                    }));
                } else {
                    dispatch(contractComplete.post.request({
                        signData: signature,
                        signCondition: condition,
                        mapping: mapping,
                        isAll: isAll,
                        I_ETMTSEQ: estimate,
                        custId: custId,
                        isComplete: true,
                        token: token,
                        CI: CI,
                    }));
                }
                setIsLoading(true);
            }
            setData();
        }
    }, [flag]);

    useEffect(() => {
        if (errMsg === "TIME OVER") {
            setIsLoading(false);
        }
    }, [errMsg]);

    const handlePass = async () => {
        if (personal) {
            setOpenCheckModal(false);
            new Promise(async (resolve, reject) => {
                try {
                    fetch("/api/v1/niceplus/auth", {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            state: "complete",
                            I_ETMTSEQ: estimate
                        }),
                    }).then(response => {
                        if (response.status === 401) {
                            dispatch(userLogout.post.request());
                        } else if (response.status === 400) {
                            dispatch(userLogout.get.success());
                        } else if (response.status === 200) {
                            return response.json();
                        } else {
                            setModalOpen(true);
                        }
                    }).then(async res => {
                        if (res && res?.message !== "") {
                            await setKeyString(res?.message);
                            openCertWindow();
                        }  else {
                            setModalOpen(true);
                        }
                    }).catch(error => {
                        reject(error);
                    });
                } catch(e) {
                    reject(e);
                }
            });
        } else {
            let p1 = new Promise((resolve, reject) => {
                for (let sc_src of script_arr) {
                    let ex6sc = document.querySelector('script[src="' + sc_src + '"]');
                    if (!ex6sc) {
                        let sc = document.createElement("script");
                        sc.setAttribute("src", sc_src);
                        if (sc_src === "/SW/initech/extension/crosswebex6.js") {
                            sc.setAttribute("charset", "UTF-8");
                        }
                        document.body.appendChild(sc);
                    }
                }
                resolve();
            });
            p1.then(() => {
                setOpenCert(true);
                setOpenCheckModal(false);
                setTimeout(() => {
                    handleClickCertPopup();
                }, 1200);
            });
        }
    };

    const SendForm = (result, postData) => {
        if (result) {
            let postData = {
                juminNO: custId,
                PKCS7SignedData: result,
                chkvid: "1",
                isCms: false,
                I_ETMTSEQ: estimate
            };

            window
                .fetch(`/api/v1/derauth`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(postData)
                })
                .then(result => {
                    if (result.status === 401) {
                        dispatch(userLogout.post.request());
                    } else if (result.status === 400) {
                        dispatch(userLogout.get.success());
                    } else if (result.status === 200) {
                        return result.json();
                    }
                }).then(async res => {
                    setOpenCert(false);
                    if (res) {
                        let condition = [];
                        await keys.map(item => {
                            if (userState[item] && userState[item] !== "") {
                                if (item === "PLAN_ETC") {
                                    condition.push({ id: personal ? "S1_PLAN_ETC_TX" : "S2_PLAN_ETC_TX", value: userState[item] });
                                } else if (item === "USE_ETC") {
                                    condition.push({ id: personal ? "S1_USE_ETC" : "S2_USE_CK3_ETC", value: userState[item] });
                                } else if (item === "S1_AGE" || item === "S1_POINT" || item === "S2_TERM") {
                                    condition.push({ id: item, value: userState[item] });
                                } else {
                                    condition.push({ id: userState[item], value: true });
                                }
                            }
                        });
                        await completeTrueKeys.map(key => {
                            // 필수 동의였던 키들 (무조건 _Y)
                            if ((!key.includes("CMS_AGREE") || E_SUBMICD === "B") && (!key.includes("R_AGREE") || personal)) {
                                condition.push({ id: `${key}_Y`, value: true });
                            }
                        });
                        if (personal) {
                            await completeKeys.map(key => {
                                if (userState[key]) {
                                    condition.push({ id: `${key}_Y`, value: true });
                                } else {
                                    condition.push({ id: `${key}_N`, value: true });
                                }
                            });
                        } else {
                            await cddKeys.map(key => {
                                if (userState[key] && userState[key] !== "") {
                                    condition.push({ id: key, value: userState[key] });
                                }
                            });
                            await condition.push({ id: "CDD_TYPE", value: owner  });
                        }
                        await cmsKeys.map(key => {
                            if (arsState[key] && arsState[key] !== "") {
                                if (key === "bildt") {
                                    condition.push({ id: arsState[key], value: true });
                                } else {
                                    condition.push({ id: key, value: arsState[key] });
                                }
                            }
                        });
                        await gKeys.map(key => {
                            if (userState[key]) {
                                condition.push({ id: key, value: "Y" });
                            }
                        });
                        await condition.push({ id: "bankIdentification", value: custId });
                        await condition.push({ id: `${personal ? "S1" : "S2"}_AGREE_Y`, value: true });
                        // console.log(condition);
                        if (E_SUBMICD === "B") {
                            dispatch(contractComplete.post.request({
                                signData: signature,
                                signCondition: condition,
                                mapping: mapping,
                                isAll: isAll,
                                I_ETMTSEQ: estimate,
                                custId: custId,
                                isComplete: true,

                                derName: derName,

                                bankIdentification: custId,
                                bankAccount: bankAccount,
                                bankCd: bankCd,
                                isCompany: !personal,
                                custnm: custnm,
                                KUNNR: KUNNR,
                                [bildt]: true,
                                token: token
                            }));
                        } else {
                            dispatch(contractComplete.post.request({
                                signData: signature,
                                signCondition: condition,
                                mapping: mapping,
                                isAll: isAll,
                                I_ETMTSEQ: estimate,
                                custId: custId,
                                isComplete: true,
                                token: token
                            }));
                        }
                        setIsLoading(true);
                    }
                })
        } else {
            if (window.INI_ALERT) window.INI_ALERT("전자서명에 실패하였습니다.");
            else alert("전자서명에 실패하였습니다.");
            setOpenCert(false);
        }
    };

    const handleClickCertPopup = () => {
        if (window.INIWEBEX) {
            const signStr = `${estimate}=${custId}`;
            window.INIWEBEX.sign({
                signType: "P7",
                targetType: "data",
                data: signStr,
                form: "",
                processCallback: SendForm,
                isHtml5: true,
                iniCache: true, //캐시된 인증서가 있다면 캐시된 인증서 사용
                viewType: "NONE", //서명할 원문은 GRID 형식으로 보이도록 함
                vid: true, //본인확인을 위한 R값 포함한 서명 생성
            });
        }
    };

    const openCertWindow = () => {
        window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        document.certForm.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        document.certForm.target = 'popupChk';
        document.certForm.submit();

        window.addEventListener("message", getMsg);
    };

    const getMsg = (e) => {
        if (e.origin === window.location.origin) {
            if (e.data.query){
                if (e.data.query["status"] && e.data.query["status"] === "success") {
                    setFlag(true);
                } else {
                    setModalOpen(true);
                    setIsLoading(false);
                }
            }
        }
    }

    return (
        <>
            {isLoading && <UploadLoading />}
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <Document
                        sign={false}
                        type={"all"}
                        fieldDataList={props.location.state?.fieldDataList}
                    />
                </div>
                <ButtonBar
                    isActive
                    leftBtnTxt={"이전"}
                    leftBtnFunc={() => props.history.push("/signature/sign")}
                    rightBtnTxt={"최종 계약 확인"}
                    rightBtnFunc={() => setOpenCheckModal(true)}
                />
            </div>
            <Modal02 open={openCheckModal && !openCert} header="최종 계약 확인"
                    btnOnclick1={() => setOpenCheckModal(false)} btnOnclick2={handlePass}
                    text1={"취소"} text2={"계약 신청완료"}
                    btnStyle2={{color: "#DA291C"}}>
                본인은 계약서에 이상이 없음을 확인하고, 계약을 신청합니다.
            </Modal02>
            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="본인인증 실패">
                본인인증에 실패하였습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
            <Modal01 open={completeModal} close={() => setCompleteModal(false)} header="계약 신청 실패">
                계약 신청에 실패하였습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
            {personal && keyString !== "" && (
                <form name="certForm" id="certForm">
                    <label><input type="hidden" name="m" value="checkplusService"/></label>
                    <label><input type="hidden" name="EncodeData" value={keyString}/></label>
                </form>
            )}
        </>
    )

}

export default SignStep2;