import React from "react";

const Refresh = (props, context) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path fill="#999" d="M16.251 7.762a6 6 0 1 0 1.561 5.738h-1.561a4.5 4.5 0 1 1-4.24-6 4.44 4.44 0 0 1 3.167 1.335l-2.416 2.415h5.253V6z" transform="translate(-6.015 -6)"/>
        </svg>
    );
};

export default Refresh;
