import { call, put, takeEvery } from "redux-saga/effects";
import { ARS, getArs } from "module/arsModule";
import { initialState as pass } from "module/passModule";
import { LOGOUT } from "module/userModule";

function* ArsSaga() {
    yield takeEvery(ARS.POST.REQUEST, ars);
    yield takeEvery(ARS.GET.REQUEST, arsCheck);
}

// ARS 인증 요청
function _postarsAPI(payload) {
    return new Promise((resolve, reject) => {
        try {
            const { token } = pass;

            fetch("/api/v1/ars/auth", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ...payload
                }),
            })
            .then(response => {
                if (response.status === 401 || response.status === 400) {
                    resolve(response.status);
                } else if (response.status === 200) {
                    resolve(response.json());
                }
            })
            .catch(error => {
                reject(error);
            });

        } catch (e) {
            reject(e);
        }
    });
}
function* ars(action) {
    try {
        const response = yield call(_postarsAPI, action.payload);
        if (response === 401) {
            yield put({ type: LOGOUT.POST.SUCCESS });
        } else if (response === 400) {
            yield put({ type: LOGOUT.GET.SUCCESS });
        } else {
            yield put({ type: ARS.POST.SUCCESS, payload: response });
        }
    } catch (e) {
        yield put(getArs.post.fail());
    }
}

// ARS 인증 성공 여부 체크
function _getarsAPI(statusKey) {
    return new Promise((resolve, reject) => {
        try {
            const { token } = pass;

            fetch(`/api/v1/ars/auth?statusKey=${statusKey}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                if (response.status === 200) {
                    resolve(response.json());
                }
            })
            .catch(error => {
                reject(error);
            });
        } catch(e) {
            reject(e);
        }
    });
}
function* arsCheck(action) {
    try {
        const response = yield call(_getarsAPI, action.payload);
        yield put({ type: ARS.GET.SUCCESS, payload: response });
    } catch (e) {
        yield put(getArs.get.fail());
    }
}

export default ArsSaga;
