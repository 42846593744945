import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import styles from "./styles.scss";

import SignaturePad from "react-signature-pad-wrapper";
import UploadLoading from "components/Loading/uploadLoading";
import * as SVG from "components/SVG";

const Signing = (props, context) => {
    const refs = useRef();
    const [drawing, setDrawing] = useState(false);
    const [noDrawing, setNoDrawing] = useState(true);
    const signLoading = useSelector(state => state.user.signLoading);
    const personal = useSelector(state => state.contract.isPersonal);
    const username = useSelector(state => state.pass.name);
    const { data } = useSelector(state => state.contract);

    const _clickSignPad = e => {
        if (!drawing && e.target.tagName === "CANVAS") {
            setDrawing(true);
            setNoDrawing(false);
        }
    };

    const _handleClearSig = () => {
        const signature = refs.current;
        signature.clear();
        setDrawing(false);

        props.onUpdateState &&
            props.onUpdateState({
                on_save: false
            });
    };

    const _handleSaveSig = () => {
        if (drawing) {
            const signature = refs.current;
            const data = signature.toDataURL(); // base64

            props.onCloseSignModal && props.onCloseSignModal(data);
        } else {
            setNoDrawing(true);
        }
    };

    return (
        <>
            <div className={[styles.drawingWrap, styles.page].join(" ")}>
                <div
                    className={[styles.signPad, drawing ? undefined : styles.disable].join(" ")}
                    onMouseDown={_clickSignPad}
                    onTouchStart={_clickSignPad}
                    style={drawing ? { backgroundImage: "none" } : null}
                >
                    {drawing ? null : (
                        <div>
                            <h5>{"* 이곳에 서명을 진행하세요."}</h5>
                            {/* <div className={styles.nameBox}>
                                {personal
                                    ? username
                                    : data && data?.length > 0 && data?.filter(x => x?.id === "P1_NAME1" && x?.value)?.length > 0
                                    ? data?.filter(x => x?.id === "P1_NAME1" && x?.value)[0]?.value
                                    : ""}
                            </div> */}
                        </div>
                    )}
                    <SignaturePad ref={refs} />
                </div>

                <div className={styles.header}>
                    {drawing && (
                        <div className={styles.refresh} onClick={_handleClearSig}>
                            <div className={styles.text}>{("다시 그리기")}</div>
                            <SVG.Refresh />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <button
                    className={drawing ? styles.active : undefined}
                    onClick={drawing ? _handleSaveSig : undefined}
                    style={drawing && props.setting && props.setting.comp_color ? { backgroundColor: props.setting.comp_color } : undefined}
                >
                    {personal ? "최종 계약서 확인" : "계약 담당자 확인"}
                </button>
            </div>

            {signLoading && <UploadLoading />}
        </>
    )
}

export default Signing;