import React from 'react';
import styles from './styles.scss';

const RadioboxDetailTriple = (props) => {
    return (
        <div className={[styles.radioBoxWrap, props.isProduct ? styles.product : undefined].join(" ")} style={props.styles}>
            <div className={styles.radioWrap} style={props.styles}>
                <input type="radio" name={props.name} id={props.id1} value={props.value1}
                    onClick={() => props.onChange(props.value1)} defaultChecked/>
                <label htmlFor={props.id1}>{props.lblNm1}</ label>
            </div>
            <div className={styles.radioWrap}>
                <input type="radio" name={props.name} id={props.id2} value={props.value2}
                    onClick={() => props.onChange(props.value2)}  />
                <label htmlFor={props.id2}>{props.lblNm2}</label>
            </div>
            <div className={styles.radioWrap}>
                <input type="radio" name={props.name} id={props.id3} value={props.value3}
                    onClick={() => props.onChange(props.value3)}  />
                <label htmlFor={props.id3}>{props.lblNm3}</label>
            </div>
        </div>
    )
};

export default RadioboxDetailTriple;


