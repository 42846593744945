import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from './styles.scss';
import mainTitleStyles from 'components/Title/MainTitle/styles.scss';

import Header from "components/Layout/Header";
import Button from "components/Button";
import Modal01 from "components/Modal/Modal01";

import { userLogout } from "module/userModule";

const Complete = (props) => {
    const dispatch = useDispatch();
    const username = useSelector(state => state.pass.name);
    const personal = useSelector(state => state.contract.isPersonal);

    const [errModal, setErrModal] = useState(false);
    const [retry, setRetry] = useState(false);

    const close = () => {
        window.location.href = `/consent/input/${props.location?.state?.status}`;
    }

    const handleModal = () => {
        dispatch(userLogout.post.fail());
        setErrModal(false);
        window.location.href = "/";
    }

    React.useEffect(() => {
        if (!props.location?.state?.flag) {
            props?.history && props?.history?.push(`/consent/input/error`);
        }
    }, []);

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    {!errModal && (
                        <div className={styles.innerWrap}>
                            <div className={[mainTitleStyles.topInfo, mainTitleStyles.complete].join(" ")}>
                                <h1>
                                    {personal && (
                                        <>
                                            <span name="username">{username ? username : "-"}</span> 님의<br/>
                                        </>
                                    )}
                                    <span className={mainTitleStyles.spanRed}>본인 인증이 완료</span>되었습니다.
                                </h1>
                            </div>

                            <Button onClick={close}
                                    style={{backgroundColor: "#DA291C"}} text={"닫기"}/>
                        </div>
                    )}
                </div>
            </div>
            <Modal01 open={retry} close={() => setRetry(!retry)} header="요청 실패">
                요청에 실패했습니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
            <Modal01 open={errModal} close={handleModal} header="세션 만료">
                유효하지 않은 인증입니다.<br/>
                처음부터 다시 시도해주세요.
            </Modal01>
        </>

    )
}


export default Complete;

