import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getPass } from "module/passModule";
import styles from "./styles.scss";
import { useHistory } from "react-router";
import * as SVG from "components/SVG";

import Header from "components/Layout/Header";
import MainTitle from "components/Title/MainTitle";
import Button from "components/Button";
import SubTitle from "components/Title/SubTitle";
import CheckboxAll from "components/List/CheckboxAll";
import CheckboxDetail from "components/List/CheckboxDetail";
import RadioboxDetail from "components/List/RadioboxDetail";
import Modal01 from "components/Modal/Modal01";
import UploadLoading from "components/Loading/uploadLoading";

const ConsentIshb = (props) => {
    const dispatch = useDispatch();
    let history = useHistory()

    // const [name, setName] = React.useState("") // 이름
    // const [phone, setPhone] = React.useState("") // 휴대폰번호
    // const [registNum1, setRegistNum1] = React.useState("") // 주민번호 앞자리
    // const [registNum2, setRegistNum2] = React.useState("") // 주민번호 뒷자리
    // const [invnr, setInvnr] = React.useState("") // 차량번호
    // const [amt, setAmt] = React.useState("") // 희망금액
    // const [c_name, setC_name] = React.useState("") // 상호명
    // const [c_name_p1, setC_name_p1] = React.useState("") // 담당자명
    // const [c_mob_tel_p1, setC_mob_tel_p1] = React.useState("") // 담당자 전화번호
    // const [c_stcd1, setC_stcd1] = React.useState("") // 법인번호
    // const [c_stcd2, setC_stcd2] = React.useState("") // 사업자번호
    // const [j_1kfrepre, setJ_1kfrepre] = React.useState("") // 대표자명

    const [btndisabled, setBtndisabled] = React.useState(true);
    const [value, setValue] = React.useState("N");
    const [isLoading, setIsLoading] = useState(false); // 정보 저장 중인지 확인
    const [btnClick, setBtnClick] = useState(false); // 패스 인증 중인지 확인

    // Modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const [invnrModalOpen, setInvnrModalOpen] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [fileUploadFailed, setFileUploadFailed] = React.useState(false);
    const [c_stcd1ModalOpen, setC_stcd1ModalOpen] = React.useState(false);
    const [c_stcd2ModalOpen, setC_stcd2ModalOpen] = React.useState(false);

    const [flag, setFlag] = React.useState(false);
    const [query, setQuery] = React.useState(undefined);
    const [keyString, setKeyString] = React.useState("");

    const [req_check6, setReq_check6] = React.useState("N");
    const [req_check7, setReq_check7] = React.useState("N");
    const [req_check8, setReq_check8] = React.useState("N");
    const [c_AGREE01, setC_AGREE01] = React.useState("N");
    const [c_AGREE02, setC_AGREE02] = React.useState("N");

    const [status, setStatus] = React.useState("ishb");
    const [KATR7, setKatr7] = React.useState(""); // 고객구분 (1번: 법인 / 2번: 개인 / 3번: 개인사업자)
    const [openCustomerList, setOpenCustomerList] = React.useState(false); // 고객구분 드롭다운

    const [checkList1, setCheckList1] = React.useState([]);
    const [checkIdList1, setCheckIdList1] = React.useState([]);
    const allcheck1 = document.getElementsByName("requiredCheck1");

    const [checkList2, setCheckList2] = React.useState([]); // 유저가 체크한 항목
    const [checkIdList2, setCheckIdList2] = React.useState([]); // 페이지 체크 항목 개수
    const allcheck2 = document.getElementsByName("requiredCheck2");

    const [checkList3, setCheckList3] = React.useState([]);
    const [checkIdList3, setCheckIdList3] = React.useState([]);
    const allcheck3 = document.getElementsByName("requiredCheck3");

    const [att_file1, setAtt_file1] = React.useState(null) // 첨부 파일 1
    const [att_file2, setAtt_file2] = React.useState(null) // 첨부 파일 2
    const [att_file3, setAtt_file3] = React.useState(null) // 첨부 파일 3

    const [pid, setPid] = React.useState("")
    const [mobileCo, setMobileCo] = React.useState("")
    

    const [inputs, setInputs] = React.useState({
        name: "",
        registNum1: "",
        registNum2: "",
        phone: "",
        invnr: "", 
        amt: "",
        c_name: "",
        c_name_p1: "",
        c_mob_tel_p1: "", 
        c_stcd1: "", 
        c_stcd2: "",
        j_1kfrepre: "" 
    })

    React.useEffect(() => {
        let checkboxIdList = [];

        document.getElementsByName(`requiredCheck${KATR7}`).forEach((item, i) => {
            checkboxIdList[i] = item.getAttribute("id");
        });

        KATR7 === "1" ?
        setCheckIdList1(checkboxIdList) :
        KATR7 === "2" ?
        setCheckIdList2(checkboxIdList) :
        setCheckIdList3(checkboxIdList)

        // 체크된 항목 넘겨주기
        if(KATR7 === "3") {
            setCheckList3(checkList2)
            setCheckList2([])
        } else if (KATR7 === "2") {
            setCheckList2(checkList3)
            setCheckList3([])
        } else {
            setCheckList2([])
            setCheckList3([])
        }
    }, [KATR7]);

    // 고객 구분 변경 시 입력된 값 초기화
    React.useEffect(() => {
        setInputs({
            name: "",
            registNum1: "",
            registNum2:"",
            phone: "",
            invnr: "",
            amt: "",
            c_name: "",
            c_name_p1: "",
            c_mob_tel_p1: "",
            c_stcd1: "",
            c_stcd2: "",
            j_1kfrepre: ""
        })

        setAtt_file1(null)
        setAtt_file2(null)
        setAtt_file3(null)
    }, [KATR7])

    // 부모창 / 자식창 구분
    React.useEffect(() => {
        if (window.location?.search && window.location?.search !== "") {
            async function setData() {
                let query = {};
                await window.location?.search?.split("&")?.map((str, idx) => {
                    let split = str.split("=");
                    if (idx === 0) {
                        query[split[0].substring(1)] = decodeURIComponent(split[1]);
                    } else {
                        query[split[0]] = decodeURIComponent(split[1]);
                    }
                });
                if (window.opener && !window.opener.closed) {
                    window.opener.postMessage({ query: query, url: window.location.href }, window.location.origin);
                    window.close();
                }
            }
            setData();
        }
    }, []);

    // 패스 인증 성공 시
    React.useEffect(() => {
        if (flag && query) {
            async function func() {
                dispatch(getPass.get.request(query));
                handleSaveData()
                // history.push("/consent/complete", { flag: true, status: status });
            }
            func();
        }
    }, [flag, query]);

    // 변경된 값 setState
    const onTextChange = (type, value) => {
        setInputs({
            ...inputs,
            [type]: value
        })

        reqTermsCheck()
    }

    // 입력된 값 validCheck
    const validCheck = (type, data_type) => e => {
        const pattern1 = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|ㅏ-ㅣ|ㆍᆢ ]*$/;
        const pattern2 = /^[0-9|,]*$/;

        const {
            target: {value}
        } = e

        if(data_type === "str") {
            pattern1.test(value) && onTextChange(type, value)
        } else if (data_type === "num") {
            pattern2.test(value) && onTextChange(type, value)
        } else {
            onTextChange(type, value)
        }
    }

    // base64 인코딩
    const base64Encoding = text => {
        return window.btoa(unescape(encodeURIComponent(text)))
    }

    // PASS API
    const handleOnClickBtn = async () => {
        if (btndisabled) {
            setFailed(true);
        } else {
            new Promise(async (resolve, reject) => {
                let phoneNum = ""

                const {
                    phone,
                    c_mob_tel_p1
                } = inputs

                if(KATR7 === "1") {
                    phoneNum = base64Encoding(c_mob_tel_p1)
                } else {
                    phoneNum = base64Encoding(phone)
                }

                try {
                    fetch("/api/v1/niceplus/auth", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            state : "loan",
                            mobTel : phoneNum,
                        }),
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                    }).then(async res => {
                        if (res && res?.message !== "") {
                            await setKeyString(res?.message);
                            await openCertWindow();
                        }
                    }).catch(error => {
                        setBtnClick(false)
                        reject(error);
                    });
                } catch(e) {
                    setBtnClick(false)
                    reject(e);
                }
            });
        }
    }

    // 정보 저장 API
    const handleSaveData = async () => {
        setIsLoading(true);
        new Promise(async (resolve, reject) => {

            const {
                name,
                registNum1,
                registNum2,
                phone,
                invnr,
                amt,
                c_name,
                c_name_p1,
                c_mob_tel_p1,
                c_stcd1,
                c_stcd2,
                j_1kfrepre
            } = inputs

            try {
                let formData = new FormData()

                // // 공통
                // formData.append("pid", pid)
                // formData.append("mobileCo", base64Encoding(mobileCo))
                // formData.append("KATR7", KATR7+"")
                // formData.append("INVNR", base64Encoding(invnr))
                // formData.append("AMT", base64Encoding(amt.replace(/[^\d]+/g, "")))
                // att_file1 && formData.append("ATT_FILE1", att_file1[0])
                // att_file2 && formData.append("ATT_FILE2", att_file2[0])
                // att_file3 && formData.append("ATT_FILE3", att_file3[0])

                formData.append("pid", pid)
                formData.append("mobileCo", base64Encoding(mobileCo))
                if(KATR7 !== "2") { // 개인이 아닐 때
                    formData.append("C_NAME", base64Encoding(c_name))
                    formData.append("C_STCD2", base64Encoding(c_stcd2))
                }
                if(KATR7 !== "1") { // 법인이 아닐 때
                    formData.append("name", base64Encoding(name.trim()))
                    formData.append("RSSNO", base64Encoding(registNum1 + registNum2))
                    formData.append("MOB_TEL", base64Encoding(phone))
                }
                if(KATR7 === "1") { // 법인일 때
                    formData.append("C_STCD1", base64Encoding(c_stcd1))
                    formData.append("C_NAME_P1", base64Encoding(c_name_p1))
                    formData.append("J_1KFREPRE", base64Encoding(j_1kfrepre))
                    formData.append("MOB_TEL", base64Encoding(c_mob_tel_p1))
                }
                formData.append("KATR7", KATR7+"")
                formData.append("INVNR", base64Encoding(invnr))
                formData.append("AMT", base64Encoding(amt.replace(/[^\d]+/g, "")))

                if(KATR7 !== "1") { // 법인이 아닐 때
                    formData.append("AGREE_YN", value)
                    formData.append("CRE_P_USE_YN", req_check6)
                    formData.append("CRE_P_SHE_YN", req_check7)
                    formData.append("CRE_P_SUP_YN", req_check8)
                    formData.append("CRE_S_USE_YN", c_AGREE01)
                    formData.append("CRE_S_SUP_YN", c_AGREE02)
                } else {
                    formData.append("CRE_P_USE_YN", req_check6)
                }
                att_file1 && formData.append("ATT_FILE1", att_file1[0])
                att_file2 && formData.append("ATT_FILE2", att_file2[0])
                att_file3 && formData.append("ATT_FILE3", att_file3[0])

                fetch("/api/v1/agree/loan", {
                    method: "POST",
                    headers: {},
                    body: formData
                }).then(response => {
                    if (response.status === 200) {
                        getSave(response.status);
                    } else {
                        setBtnClick(false)
                        setIsLoading(false);
                        setModalOpen(true)
                        return response.json();
                    } 
                }).catch(error => {
                    setBtnClick(false)
                    setIsLoading(false);
                    reject(error);
                });
            } catch(e) {
                setBtnClick(false)
                setIsLoading(false);
                reject(e);
            }
        });
    }

    const validCheckModal = () => {
        setBtnClick(true)
        const {
            invnr,
            c_stcd1,
            c_stcd2
        } = inputs

        if(invnr.length < 7) {
            setInvnrModalOpen(true) // 차량 번호 - 전체 포함
        } else if (KATR7 !== "2" && c_stcd2.length !== 10) {
            setC_stcd2ModalOpen(true) // 사업자 번호 - 법인과 개인사업자
        } else if (KATR7 === "1" && c_stcd1.length !== 13) {
            setC_stcd1ModalOpen(true) // 법인 번호 - 법인
        } else {
            if(btnClick === false) {
                handleOnClickBtn()
                setBtnClick(true)
            }
        }
        
    }

    // 자식창 생성
    const openCertWindow = async () => {
        let certWindow = window.open("https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb", "popupChk", "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no")

        document.certForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.certForm.target = "popupChk";
        document.certForm.submit();

        // 사용자가 자식창을 닫을 경우
        let g_oInterval = window.setInterval(() => {
            try {
                if(certWindow === null || certWindow.closed) {
                    window.clearInterval(g_oInterval)
                    setBtnClick(false)
                }
            } catch(e) {
                
            }
        }, 500)

        await window.addEventListener("message", getMsg, { once : true });
    };

    const getMsg = async (e) => {
        if (e.origin === window.location.origin) {
            if (e.data.query) {
                if (e.data.query["status"] && e.data.query["status"] === "success") {
                    await setPid(e.data.query["pid"])
                    await setMobileCo(e.data.query["mobileCo"])
                    await setFlag(true);
                    await setQuery(e.data.query);
                } else {
                    setBtnClick(false)
                    setModalOpen(true)
                }
            }
        }
    }

    const getSave = async (statusCode) => {
        setIsLoading(false);
        setBtnClick(false)
        if (statusCode && statusCode === 200) {
            history.push("/consent/complete", { flag: true, status: status });
        } else {
            setModalOpen(true)
        }
    }

    // 파일 첨부
    const handleChangeFile = (type) => e => {
        let file = e.target.files
        let maxSize = 20 * 1024 * 1024 // 20mb
        if(file[0]?.size > maxSize) {
            setFileUploadFailed(true);
            e.target.value = null
        } else {
            switch(type) {
                case "1":
                    setAtt_file1(file)
                    break
                case "2":
                    setAtt_file2(file)
                    break
                case "3":
                    setAtt_file3(file)
                    break
            }
        }
        file = null
    }

    // 요약동의서 동의
    const handleChange = v => {
        setValue(v)
    };

    // 항목 선택 값 변경
    const checkType = (type, YN, id) => {
        if(type === "allCheck") {
            setReq_check6(YN)
            setReq_check7(YN)
            setReq_check8(YN)
            setC_AGREE01(YN)
            setC_AGREE02(YN)
        } else {
            id === "req_check6" ? setReq_check6(YN) :
            id === "req_check7" ? setReq_check7(YN) :
            id === "req_check8" ? setReq_check8(YN) :
            id === "C_AGREE01" ? setC_AGREE01(YN) : setC_AGREE02(YN)
        }
    }

    // 필수 항목 체크 여부 및 입력 데이터 체크
    const reqTermsCheck = (_checkManual) => {
        let inputValue = ""

        // 입력 input 이 하나라도 비어있을 시
        for(let i = 0; i < document.getElementsByName(KATR7).length; i++) {
            if (document.getElementsByName(KATR7)[i].value === "") {
              inputValue = "1"
            } 
        }

        if(KATR7 !== "1") {
            if (
                inputValue === "" &&
                document.getElementById("req_check6").checked &&
                document.getElementById("req_check7").checked &&
                document.getElementById("req_check8").checked
            ) {
                setBtndisabled(false);
            } else {
                setBtndisabled(true);
            }
        } else {
            if (
                inputValue === "" &&
                document.getElementById("req_check6").checked
            ) {
                setBtndisabled(false);
            } else {
                setBtndisabled(true);
            }
        }
    };

    // 항목 동의
    const handleOnChangeCheck = (e) => {
        if (e.target.name === "requiredCheck1") { // 법인
            if (e.target.checked) {
                setCheckList1([...checkList1, e.target.id]);
                checkType("check", "Y", e.target.id)
            } else {
                setCheckList1(checkList1.filter((checkedId) => checkedId !== e.target.id));
                checkType("check", "N", e.target.id)
            }
        } else if (e.target.name === "requiredCheck2") { // 개인
            if (e.target.checked) {
                setCheckList2([...checkList2, e.target.id]);
                checkType("check", "Y", e.target.id)
            } else {
                setCheckList2(checkList2.filter((checkedId) => checkedId !== e.target.id));
                checkType("check", "N", e.target.id)
            }
        } else if (e.target.name === "requiredCheck3") { // 개인사업자
            if (e.target.checked) {
                setCheckList3([...checkList3, e.target.id]);
                checkType("check", "Y", e.target.id)
            } else {
                setCheckList3(checkList3.filter((checkedId) => checkedId !== e.target.id));
                checkType("check", "N", e.target.id)
            }
        }
        reqTermsCheck();
    };

    // 항목 전체 동의
    const handleOnChangeCheckAll = (e) => {
        if (e.target.id === "checkAll2") { // 개인
            setCheckList2(e.target.checked ? checkIdList2 : []);
            for (let i = 0; i < allcheck2.length; i++) {
                if (e.target.checked) {
                    allcheck2[i].checked = true;
                    checkType("allCheck", "Y")
                } else {
                    allcheck2[i].checked = false;
                    checkType("allCheck", "N")
                }
            }
        } else if (e.target.id === "checkAll3") { // 개인사업자
            setCheckList3(e.target.checked ? checkIdList3 : []);
            for (let i = 0; i < allcheck3.length; i++) {
                if (e.target.checked) {
                    allcheck3[i].checked = true;
                    checkType("allCheck", "Y")
                } else {
                    allcheck3[i].checked = false;
                    checkType("allCheck", "N")
                }
            }
        }
        reqTermsCheck();
    };

    // 금액 표시 정규식
    const onTextComma = str => {
        const comma = (str) => {
            str = String(str);
            return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        };

        const uncomma = (str) => {
            str = String(str);
            return str.replace(/[^\d]+/g, "");
        };
        
        return comma(uncomma(str));
    };

    return (
        <>
            {isLoading && <UploadLoading />}
            <Header onClick={() => history.push("/")}/>
                <div className={styles.contentBoardWrap} onClick={() => {
                    openCustomerList && setOpenCustomerList(false)
                }}>
                    <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                    <MainTitle mainTitle={"대출 신청서"} style={{marginBottom:"32px", borderBottom:"2px solid red"}} substyle={{color:"#666666"}}></MainTitle>
                        <div className={styles.customerCheck}>
                            <div className={styles.label}>고객 구분</div>
                            <div className={styles.inputBox} onClick={() => {
                                setOpenCustomerList(!openCustomerList)
                            }}>
                                <div className={styles.customerList}>
                                    {KATR7 === "" ? "선택" :
                                    KATR7 === "1" ? "법인" : 
                                    KATR7 === "2" ? "개인" : "개인사업자"}
                                </div>
                                <div className={openCustomerList ? styles.arrowUp : styles.arrowDown}>
                                    <SVG.Arrow />
                                </div>
                                {openCustomerList && 
                                <ul>
                                    <li onClick={() => {
                                        setKatr7("2")
                                    }}>개인</li>
                                    <li onClick={() => {
                                        setKatr7("3")
                                    }}>개인사업자</li>
                                    <li onClick={() => {
                                        setKatr7("1")
                                    }}>법인</li>
                                </ul>}
                            </div>
                        </div>
   
                        {KATR7 !== "" &&
                            <>
                                <div className={styles.contBox}>
                                    <form>

                                        {KATR7 !== "2" &&
                                            <>
                                                <div className={styles.content}>
                                                    <label>{KATR7 === "3" ? "사업장명" : "법인명"}</label>
                                                    <input name={KATR7} type="text" maxLength={40} onChange={validCheck("c_name", "str")} value={inputs.c_name} placeholder={"이름을 입력해 주세요."}></input>
                                                </div>

                                                {KATR7 === "1" && 
                                                    <div className={styles.content}>
                                                        <label>법인번호</label>
                                                        <input name={KATR7} maxLength={13} type="text" onChange={validCheck("c_stcd1", "num")} value={inputs.c_stcd1} placeholder={"'-' 표시없이 표기해 주세요."}></input>
                                                    </div>
                                                }

                                                <div className={styles.content}>
                                                    <label>사업자번호</label>
                                                    <input name={KATR7} maxLength={10} type="text" onChange={validCheck("c_stcd2", "num")} value={inputs.c_stcd2} placeholder={"'-' 표시없이 표기해 주세요."}></input>
                                                </div>
                                            </>
                                        }
                                        

                                        <div className={styles.content}>
                                            <label>{KATR7 === "1" ? "대표자명" : "고객명"}</label>
                                            <input name={KATR7} type="text" maxLength={KATR7 === "1" ? 10 : 40} onChange={KATR7 === "1" ? validCheck("j_1kfrepre", "str") : validCheck("name", "str")} value={KATR7 === "1" ? inputs.j_1kfrepre : inputs.name} placeholder={"이름을 입력해 주세요."}></input>
                                        </div>
                                        
                                        {KATR7 !== "1" &&
                                            <div className={styles.content}>
                                                <label>주민등록번호</label>
                                                <div className={styles.inputWrap}>
                                                    <input name={KATR7} type="text" maxLength={6} onChange={validCheck("registNum1", "num")} value={inputs.registNum1} placeholder={"앞 6자리를 입력해 주세요."} />
                                                    <p>-</p>
                                                    <input name={KATR7} maxLength={7} type="password" onChange={validCheck("registNum2", "num")} value={inputs.registNum2} placeholder={"* * * * * * *"} />
                                                </div>
                                            </div>
                                        }

                                        {KATR7 === "1" &&
                                            <div className={styles.content}>
                                                <label>계약담당자명</label>
                                                <input name={KATR7} type="text" maxLength={40} onChange={validCheck("c_name_p1", "str")} value={inputs.c_name_p1} placeholder={"이름을 입력해 주세요."}></input>
                                            </div>
                                        }
                                        
                                        <div className={styles.content}>
                                            <label>{KATR7 === "1" ? "담당자 연락처" : "휴대폰 번호"}</label>
                                            <input name={KATR7} maxLength={KATR7 === "1" ? 20 : 11} type="text" onChange={KATR7 === "1" ? validCheck("c_mob_tel_p1", "num") : validCheck("phone", "num")} value={KATR7 === "1" ? inputs.c_mob_tel_p1 : inputs.phone} placeholder={"하이픈(-) 없이 입력해 주세요."}></input>
                                        </div>

                                        <div className={styles.content}>
                                            <label>차량번호</label>
                                            <input name={KATR7} maxLength={8} type="text" onChange={validCheck("invnr", "")} value={inputs.invnr} placeholder={"차량번호를 입력해 주세요."}></input>
                                        </div>

                                        <div className={styles.content}>
                                            <label>희망대출금액</label>
                                            {/* maxLength={15} */}
                                            <input name={KATR7} maxLength={19} type="text" onChange={(e) => {
                                                setInputs({
                                                    ...inputs,
                                                    amt: onTextComma(e.target.value)
                                                })
                                            }} value={inputs.amt} placeholder={"희망대출금액을 입력해 주세요."}></input>
                                            <div className={styles.smallLabel}>원</div>
                                        </div>

                                        <div className={styles.content}>
                                            <label>파일첨부1</label>
                                            <div className={styles.inputFile}>{att_file1 ? att_file1[0].name : ""}</div>
                                            <label className={styles.upload}  htmlFor="input-file1">파일첨부</label>
                                            <input maxLength={300} type="file" id="input-file1" onChange={handleChangeFile("1")} accept="image/*, .pdf" />
                                        </div>

                                        <div className={styles.content}>
                                            <label>파일첨부2</label>
                                            <div className={styles.inputFile}>{att_file2 ? att_file2[0].name : ""}</div>
                                            <label className={styles.upload} htmlFor="input-file2">파일첨부</label>
                                            <input maxLength={300} type="file" id="input-file2" onChange={handleChangeFile("2")} accept="image/*, .pdf" />
                                        </div>

                                        <div className={styles.content}>
                                            <label>파일첨부3</label>
                                            <div className={styles.inputFile}>{att_file3 ? att_file3[0].name : ""}</div>
                                            <label className={styles.upload}  htmlFor="input-file3">파일첨부</label>
                                            <input maxLength={300} type="file" id="input-file3" onChange={handleChangeFile("3")} accept="image/*, .pdf" />
                                        </div>
                                    </form>
                                </div>

                                    <div className={styles.termsWrap}>
                                    {KATR7 !== "1" ?
                                        <>
                                            <SubTitle title="개인(신용)정보 동의" isSpecial descYn="Y">
                                                본 동의서는 요약동의서이며, 요청하면 전체 동의서를 안내 받으실 수 있습니다. 요청하시겠습니까?
                                            </SubTitle>
                                            <RadioboxDetail name={"requestYn"}
                                                id1={"requestY"} value1={"Y"} lblNm1={"예"}
                                                id2={"requestN"} value2={"N"} lblNm2={"아니오"}
                                                onChange={handleChange}
                                            />
                                            <CheckboxAll
                                                checkLabel={KATR7 === "2" ? "checkAll2" : "checkAll3"}
                                                onChange={handleOnChangeCheckAll}
                                                checked={KATR7 === "2" ? checkList2.length === checkIdList2.length : checkList3.length === checkIdList3.length}
                                            />
                                            <CheckboxDetail
                                                checkLabel={"req_check6"}
                                                name={KATR7 === "2" ? "requiredCheck2" : "requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인(신용)정보 필수적 수집, 이용 동의 (필수)"}
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={value === "Y" ? "15" : "10"}
                                                value={value}
                                                isSeames={true}
                                            />
                                            <CheckboxDetail
                                                checkLabel={"req_check7"}
                                                name={KATR7 === "2" ? "requiredCheck2" : "requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인(신용)정보 필수적 조회 동의 (필수)"}
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={value === "Y" ? "16" : "11"}
                                                value={value}
                                                isSeames={true}
                                            />
                                            <CheckboxDetail
                                                checkLabel={"req_check8"}
                                                name={KATR7 === "2" ? "requiredCheck2" : "requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인(신용)정보 필수적 제공 동의 (필수)"}
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={value === "Y" ? "17" : "12"}
                                                value={value}
                                            />
                                            <CheckboxDetail
                                                checkLabel={"C_AGREE01"}
                                                name={KATR7 === "2" ? "requiredCheck2" : "requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인(신용)정보 선택적 수집, 이용 동의 (선택)"}
                                                isSeames={true}
                                                summaryStyles={{padding: "14px 0 14px 8px"}}
                                                satisfactionFir
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={value === "Y" ? "18" : "13"}
                                                value={value}
                                            />
                                            <CheckboxDetail
                                                checkLabel={"C_AGREE02"}
                                                name={KATR7 === "2" ? "requiredCheck2" : "requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인(신용)정보 선택적 제3자 제공 동의 (선택)"}
                                                isSeames={true}
                                                summaryStyles={{padding: "14px 0 14px 8px"}}
                                                satisfactionSec
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={value === "Y" ? "19" : "14"}
                                                value={value}
                                            />
                                        </>
                                        :
                                        <>
                                            <CheckboxDetail
                                                checkLabel={"req_check6"}
                                                name={"requiredCheck1"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"법인고객 업무담당자 개인(신용)정보 동의서"}
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={"17"}
                                                value={value}
                                            />
                                        </>
                                    }
                                    <Button style={{margin: '32px 0px 16px 0px'}} text={"인증"}
                                        disabled={btndisabled} onClick={validCheckModal}
                                        // disabled={btndisabled} onClick={test}
                                    />
                                </div> 
                        </>
                    }
                </div>
            </div>
        </div>

        <Modal01 open={failed} close={() => setFailed(false)} header="인증 실패">
            필수 입력 정보를 <br />모두 기입 및 체크해 주세요.
        </Modal01>

        <Modal01 open={invnrModalOpen} close={() => setInvnrModalOpen(false)} header="인증 실패">
            차량번호 확인바랍니다.
        </Modal01>

        <Modal01 open={c_stcd1ModalOpen} close={() => setC_stcd1ModalOpen(false)} header="인증 실패">
            법인번호 확인바랍니다.
        </Modal01>

        <Modal01 open={c_stcd2ModalOpen} close={() => setC_stcd2ModalOpen(false)} header="인증 실패">
            사업자번호 확인바랍니다.
        </Modal01>

        <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="본인인증 실패">
            본인인증에 실패하였습니다.<br/>
            잠시 후 다시 시도해주세요.
        </Modal01>

        <Modal01 open={fileUploadFailed} close={() => setFileUploadFailed(false)} header="용량 제한">
            파일 용량이 20MB가 넘습니다.
        </Modal01>

        {keyString !== "" && (
            <form name="certForm" id="certForm">
                <label><input type="hidden" name="m" value="checkplusService"/></label>
                <label><input type="hidden" name="EncodeData" value={keyString}/></label>
            </form>
        )}
    </>
    )
}

export default ConsentIshb;