import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import styles from "./styles.scss";

import Header from "components/Layout/Header";

import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";
import RadioboxDetail from "components/List/RadioboxDetail";
import Modal01 from "components/Modal/Modal01";
import { useDispatch, useSelector } from "react-redux";

import Document from "components/Document";
import ButtonBar from "components/ButtonBar";
import CheckingBox from "components/CheckingBox";
import * as SVG from "components/SVG";

import { setGQ } from "module/userModule";

const SuccessorProductDesc = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.contract);
    const manualList = useSelector(state => state.contract.manualList);

    const [openPDF, setOpenPDF] = useState(false);
    const [checkPDF, setCheckPDF] = useState(false);

    const [checkList, setCheckList] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [modalOpen1, setModalOpen1] = useState(false);

    const descArr = [
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q2",
            script:
                <>
                    <div>
                        본 상품을 연체하셨을 때에는 법으로 정하는 범위 내에서 약정에 따라 정한 지연배상금을 지불하셔야 합니다. 
                        예를 들어 상환해야 하는 대출원리금이 1,000,000원이고, 30일간 연체한 경우(지연배상금율 9% 가정 시), 최대 7,397원의 
                        지연배상금이 발생합니다.<span>이 내용을 확인하셨습니까?</span>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q3",
            script:
                <>
                    <div>
                        본 상품을 3년 이내에 중도 상환하시는 경우, 정해진 요율에 의하여 중도상환수수료가 발생합니다. 예를 들어 1억원을 중도상환하시는 
                        경우 (중도상환수수료율 2%로 가정), 최대 200만원의<span>중도상환수수료를 납부하셔야 대출상품의 중도 해지가 가능함을 알고 계십니까?</span>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q4",
            script:
                <>
                    <div>
                        고객은 계약체결일 또는 계약서류를 제공받은 날(다만 금전·재화·용역의 지급이 늦게 이루어진 경우에는 그 지급일로 대체함)로부터 
                        14일 이내에 계약에 대한 청약을 철회할 수 있습니다. 다만,<p>동일한 금융회사를 대상으로 최근 1개월 내에 2회 이상 대출계약을</p> 
                        <p>철회하는 경우, 신규대출·만기 연장 거절, 대출한도 축소, 금리우대 제한 등 불이익이 발생할 수 있습니다.</p><br/><span className={styles.space}>이 내용을 확인하셨습니까?</span>
                    </div>
                </>
        },
        {
            isTitle: true,
            isRadio: false,
            title: "※ 금리인하요구권 대상여부 (■대상 □비대상)",
            field: "G_Q5",
            script:
                <>
                    <div>
                        채무자인 고객은<span>본인의</span><div>신용상태가 개선</div><span>되었다고 판단되는 경우(예 : 취업, 승진, 재산증가, 개인신용평점 상승) 금융회사에</span> 
                        <div>금리인하를 요구</div><span>할 수 있습니다.</span><p>단, 신용상태가 금리에 영향을 미치지 않는 상품은 금리인하요구권의 행사 대상에서 제외되오니,</p> 
                        <p>상단의 체크박스를 확인하여 주시기 바랍니다.</p>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: false,
            field: "G_Q6",
            script:
                <>
                    <div>
                        본인은 롯데오토리스와 계약을 체결함에 있어 금융회사 직원과 상담하여 본 설명서를<span>교부</span>받았으며, 위에서 설명한 내용을 포함하여 
                        계약의 주요 내용 및 고객부담비용에 대하여 충분히<span>설명</span>을 듣고<span>이해</span>하였음을 확인합니다.
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: false,
            field: "G_Q7",
            script:
                <>
                    <div>
                        본인은 금융회사 직원으로부터 금리인하요구권, 청약철회권, 위법계약해지권 등 고객의 권리에 대하여 충분히 설명을 듣고 
                        이해하였음을 확인합니다.
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q8",
            script:
                <>
                    <div>
                        대출의 변제기가 도래하거나 기한의 이익이 상실되었음에도 불구하고 채무가 상환되지 않는 경우, 금융회사는 담보물을 처분하여 
                        대출금을 상환하는 데에 이용할 수 있습니다. 이 경우 고객님의 담보물에 대한 소유권이 상실됩니다. <br/>
                        <span className={styles.space}>이러한 위험에도 불구하고 본 상품을 계약하시겠습니까?</span>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q9",
            script:
                <>
                    <div>
                        본 상품은 일반적인 대출상품과 달리 계약이 성립한 날로부터 3년 경과 후에도 최대 2%의 해약금(중도상환수수료)가 발생합니다. 
                        예를 들어 1억원을 중도상환하시는 경우(중도상환수수료율 2%로 가정), 최대 200만원의<span>중도상환수수료를 납부하셔야</span> 
                        <span>대출상품 해지가 가능함을 알고 계십니까?</span>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q10",
            script:
                <>
                    <div>
                        본 상품은 일반적인 대출상품과 달리 금융소비자보호법 상 청약철회권 대상*이 아니지만 할부거래법상의 철회권과 항변권은 
                        적용 가능합니다.<span>이 내용을 확인하셨습니까?</span> <br/>
                        * 재화를 제공받은 경우에 금융소비자보호법 상 청약철회권 행사가 불가능(금융소비자보호법 시행령 제37조제1항제3호가목)
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q11",
            script:
                <>
                    <div>
                        본 상품을 연체하셨을 때에는 법에서 정하는 범위 내에서 지연배상금*을 납부하셔야 합니다. 예를 들어 리스료 100만원을 10일 
                        연체하셨을 경우(지연배상금률 연 20%로 가정 시), 최대 5,479원의 지연배상금이 발생합니다.<span>이 내용을 확인하셨습니까?</span> <br/>
                        * 지연배상금 산식 : 리스료 × 지연배상금율 × 연체일수/365
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q12",
            script:
                <>
                    <div>
                        본 상품은 일반적인 대출상품과 달리 계약이 성립한 날로부터 3년 경과 후에도 중도상환 수수료가 부과(중도해지 후 물건 반납 시 
                        중도해지손해배상금, 물건 매입 시 규정손해배상금 부과)됩니다. 
                        <span>이를 납부하셔야만 중도에 리스계약을 해지하실 수 있다는 점을 확인하셨습니까?</span> <br/>
                        <p>※ 금융리스는 반납 불가가 원칙임을 확인하여 주시기 바랍니다.</p>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q13",
            script:
                <>
                    <div>
                        본 상품은 일반적인 대출상품(신용대출, 오토론 등)과 달리 금융소비자보호법 상 청약철회권 대상*이 아닙니다. 재화를 제공받은 후에는 
                        청약 철회가 불가능하기에 계약 체결에 신중을 기해주시기 바랍니다.<span>이 내용을 확인하셨습니까?</span> <br/>
                        * 재화를 제공받은 경우에 금융소비자보호법 상 청약철회권 행사가 불가능(금융소비자보호법 시행령 제37조제1항제3호가목)
                    </div>
                </>
        },
        {
            isTitle: true, 
            isRadio: false,
            title: "※ 금리인하요구권 대상여부 (□대상 ■비대상)",
            field: "G_Q14",
            script:
                <>
                    <div>
                        채무자인 고객은<span>본인의</span><div>신용상태가 개선</div><span>되었다고 판단되는 경우(예 : 취업, 승진, 재산증가, 개인신용평점 상승) 금융회사에</span> 
                        <div>금리인하를 요구</div><span>할 수 있습니다.</span><p>단, 신용상태가 금리에 영향을 미치지 않는 상품은 금리인하요구권의 행사 대상에서 제외되오니,</p> 
                        <p>상단의 체크박스를 확인하여 주시기 바랍니다.</p>
                    </div>
                </>
        },
        {
            isTitle: false,
            isRadio: true,
            field: "G_Q15",
            script:
                <>
                    <div>
                        고수익을 보장하거나 리스료를 할인해 준다고 약속을 받고 자동차 리스를 신청하시는 경우 자동차는 받지 못하고, 리스료와 차량반납 의무만 떠안는 등 예샹하지 못한 피해가 발생할 수 있음을 이해하고 확인하셨습니까?
                    </div>
                </>
        },
    ];

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }
    }, []);

    const handleOnClickBtn = async () => {
        if (manualList?.length === (checkList.length + 1) && checkPDF) {
            let fieldList = {};
            await checkList.map(check => {
                return fieldList[descArr[check - 2].field] = true;
            });
            await dispatch(setGQ.post.request({ "G_Q1": true, ...fieldList }));
            props.history.push("/accept/terms");
        } else {
            setModalOpen1(true);
        }
    }

    const handleOpenPDF = async () => {
        await setOpenPDF(true);
        setCheckPDF(true);
        if ((checkList.length + 1) === manualList?.length) {
            setDisabled(false);
        }
    }

    const handleReqItem = idx => {
        let _checkList = _.cloneDeep(checkList);
        if (_checkList.includes(idx)) {
            _checkList.splice(_checkList.findIndex(x => x === idx), 1);
        } else {
            _checkList.push(idx);
        }
        if (manualList?.length === (_checkList.length + 1) && checkPDF) {
            setDisabled(false);
        }
        setCheckList(_checkList);
    }

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="상품 설명서">
                            <p>이 설명서는 금융소비자(이하“고객”)의 상품에 대한 이해를 돕고 약관의 중요내용을 알려드리기 위한 참고자료이며, 실제 계약은 대출거래약정서 및 약관이
                                적용됩니다.</p>
                        </MainTitle>
                        <div className={styles.contBox}>
                            <div className={styles.termsWrap}>
                                <SubTitle title="상품 설명서 문서 확인" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc" }} />
                                <div
                                    className={styles.row}
                                    onClick={handleOpenPDF}
                                >   
                                    <div className={styles.left}>
                                        <SVG.Check checked={checkPDF} />
                                        <div className={styles.title}>
                                            {"상품 설명서"}
                                        </div>
                                    </div>
                                    <div className={styles.svg}>{"보기"}</div>
                                </div>
                            </div>

                            <SubTitle title="고객의 이해여부 확인을 위한 질의" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc", marginBottom: "16px" }} />
                            {manualList?.filter(x => x > 1).map(item => {
                                return (
                                    <CheckingBox
                                        key={item}
                                        idx={item}
                                        isTitle={descArr[item - 2]?.isTitle}
                                        isRadio={descArr[item - 2]?.isRadio}
                                        title={descArr[item - 2]?.isTitle ? descArr[item - 2]?.title : ""}
                                        script={descArr[item - 2]?.script}
                                        handleReqItem={handleReqItem}
                                        checked={checkList.includes(item)}
                                    />
                                );
                            })}
                            <Button text={"다음"} style={{ marginTop: "40px" }} disabled={disabled}
                                    onClick={handleOnClickBtn} />
                        </div>
                    </div>
                </div>
                {openPDF && (
                    <div className={styles.pdfPopup}>
                        <Document sign={false} type={"product"} />
                        <ButtonBar
                            isActive={true}
                            leftBtnTxt={"이전"}
                            leftBtnFunc={() => props.history.push("/confirm/fitness")}
                            rightBtnTxt={"확인"}
                            rightBtnFunc={() => setOpenPDF(false)}
                        />
                    </div>
                )}
            </div>
            <Modal01 open={modalOpen1} close={() => setModalOpen1(false)} header="안내">
                다음 단계로 넘어가기 위해<br />
                모든 항목에 동의해주세요.
            </Modal01>
        </>
    )

}

export default SuccessorProductDesc;

