import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styles from "./styles.scss";

import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Header from "components/Layout/Header";
import Button from "components/Button";

import Select from "react-select"
import Modal01 from "components/Modal/Modal01";

import CheckboxAll from "components/List/CheckboxAll";
import CheckboxDetail from "components/List/CheckboxDetail";
import UploadLoading from "components/Loading/uploadLoading";

import { getArs, setCert, setCms } from "module/arsModule";
import { userLogout } from "module/userModule";
import { getChange } from "module/changeCmsModule";
import { userInfo } from "module/contractModule";

const Apply = (props) => {

    const script_arr = [
        "/SW/initech/extension/common/exinterface.js",
        "/SW/initech/extension/crosswebex6.js",
        "/SW/initech/extension/common/exinstall.js",
        "/SW/initech/extension/crosswebexInit.js",
        "/SW/initech/extension/cw_web6_adt.js",
        "/SW/initech/extension/common/js/exproto_ext_daemon.js"
    ];

    const options2 = [
        { value: "001",	label: "한국은행" },
        { value: "002",	label: "산업은행" },
        { value: "003",	label: "기업은행" },
        { value: "004",	label: "국민은행" },
        // { value: "005",	label: "외환은행" },
        { value: "007",	label: "수협중앙회" },
        { value: "008",	label: "수출입은행" },
        { value: "011",	label: "농협은행" },
        { value: "012",	label: "지역농축협" },
        { value: "020",	label: "우리은행" },
        { value: "023",	label: "한국스탠다드차타드은행" },
        { value: "027",	label: "한국씨티은행" },
        { value: "031",	label: "대구은행" },
        { value: "032",	label: "부산은행" },
        { value: "034",	label: "광주은행" },
        { value: "035",	label: "제주은행" },
        { value: "037",	label: "전북은행" },
        { value: "039",	label: "경남은행" },
        { value: "045",	label: "새마을금고중앙회" },
        { value: "048",	label: "신협중앙회" },
        { value: "050",	label: "상호저축은행" },
        { value: "054",	label: "HSBC은행" },
        { value: "055",	label: "도이치은행" },
        { value: "059",	label: "미쓰비시도쿄UFJ" },
        { value: "060",	label: "BOA은행" },
        { value: "061",	label: "비엔피파리바" },
        { value: "064",	label: "산림조합중앙회" },
        { value: "071",	label: "우체국" },
        { value: "081",	label: "KEB하나은행" },
        { value: "088",	label: "신한은행" },
        { value: "089",	label: "케이뱅크" },
        { value: "090",	label: "카카오뱅크" },
        { value: "095",	label: "경찰청" },
        { value: "096",	label: "한국전자금융㈜" },
        { value: "099",	label: "금융결제원" },
        { value: "175",	label: "한양증권" },
        { value: "209",	label: "동양증권" },
        { value: "218",	label: "현대증권" },
        { value: "224",	label: "BNK투자증권" },
        { value: "230",	label: "미래에셋증권" },
        { value: "238",	label: "대우증권" },
        { value: "240",	label: "삼성증권" },
        { value: "243",	label: "한국투자증권" },
        { value: "247", label: "NH투자증권" },
        { value: "261", label: "교보증권" },
        { value: "262", label: "하이투자증권" },
        { value: "263", label: "HMC투자증궈" },
        { value: "264", label: "키움증권" },
        { value: "265", label: "이트레이드증권" },
        { value: "266", label: "에스케이증권" },
        { value: "267", label: "대신증권" },
        { value: "268", label: "아이엠투자증권" },
        { value: "269", label: "한화투자증권" },
        { value: "270", label: "하나대투증권" },
        { value: "278", label: "신한금융투자" },
        { value: "279", label: "DB금융투자(주)" },
        { value: "280", label: "유진투자증권" },
        { value: "287", label: "메리츠종합금융증권" },
        { value: "289", label: "엔에이치농협증권" },
        { value: "290", label: "부국증권" },
        { value: "291", label: "신영증권" },
        { value: "292", label: "엘아이지투자증권" },
        { value: "300", label: "IBK 투자증권" },
        { value: "301", label: "KB증권" },
        { value: "302", label: "우리종합금융" },
        { value: "303", label: "한화증" },
        { value: "304", label: "KTB 투자증권" },
        { value: "305", label: "코리아에셋투자증권" },
        { value: "991", label: "뉴스타오토월드제일차 유한회사" },
    ];

    const options3 = [
        {value: "SKT", label: "SKT"},
        {value: "KTF", label: "KT"},
        {value: "LGT", label: "LGU+"},
        {value: "SKM", label: "SKT 알뜰폰"},
        {value: "KTM", label: "KT 알뜰폰"},
        {value: "LGM", label: "LGU 알뜰폰"},
    ];

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "0",
            fontSize: "13px",
            boxShadow: "none",
            backgroundColor: state.selectProps.backgroundColor,
            "input":{
                textIndent: "-99999999em"
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "0 8px"
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: 0
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.08)" : undefined,
            color: state.isSelected ? "" : "0",
            height: "48px",
            lineHeight: "48px",
            padding: "0 0 0 16px",
            fontSize: "14px"
        }),
        menu: (provided) => ({
            ...provided,
            position: "absolute",
            top: "33px",
            borderRadius: "4px",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
        }),
        placeholder: () => ({
            color: "#cccccc"
        }),
    };

    const history = useHistory();
    const dispatch = useDispatch();
    const arsCode = useSelector(state => state.ars?.arsCode);
    const custId = useSelector(state => state.contract.custId);
    const estimate = useSelector(state => state.contract.estimate);
    const [btndisabled, setBtndisabled] = useState(true);
    const [pushBtn, setPushBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [btnClick, setBtnClick] = useState(false); // bank API 호출 중인지 확인

    /*ARS API*/
    const [telecd, setTelecd] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [phoneNumber] = useState(useSelector(state => state.change?.phoneNumber))
    const [custnm] = useState(decodeURI(useSelector(state => state.change?.name)));
    const [birthday] = useState(useSelector(state => state.change?.birthday));
    const [isCompany] = useState(useSelector(state => state.change?.isCompany));
    const [banknm, setBanknm] = useState("");
    const [bankcd, setBankcd] = useState("");
    const [acctno, setAcctno] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [BILDT_ETC, setBILDT_ETC] = useState("");
    const [acctnoErr, setAcctnoErr] = useState(false);
    const numList = [
        "051", "053", "032", "062", "042", "052", "044", "031", "033", "043", "041", '063', "061", "054", "055", "064",
        "010", "011", "016", "017", "018", "019",
        "070"
    ];

    const [checkList1, setCheckList1] = useState([]);
    const [checkIdList1, setCheckIdList1] = useState([]);
    const allcheck1 = document.getElementsByName("requiredCheck1");

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!estimate && !props.location?.state?.estimate) {
            props.history.push("/cms/change/contractnum");
        }
        let checkboxIdList1 = [];
        document.getElementsByName("requiredCheck1").forEach(function (item, i) {
            checkboxIdList1[i] = item.getAttribute("id");
        });

        setCheckIdList1(checkboxIdList1);
    }, []);

    // 브라우저 뒤로가기 감지
    useEffect(() => {
        let unlisten = history.listen((location) => {
            if (history.action === 'POP') {
                history.push('/cms/change/contractnum');
            }
        });

        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        if (!isCompany) { // 개인/개인사업자일 때
            if (arsCode !== "" && pushBtn) {
                props.history.push("/cms/change/ars1", {
                    telecd,
                    phoneno: phoneNumber,
                    custnm,
                    birthday,
                    // bildt,
                    bankcd,
                    banknm,
                    acctno,
                    url: "apply",
                    estimate: estimate
                });
            }
        }
    }, [arsCode]);

    // Bank API -> ARS 인증요청 (개인) || 본인인증 페이지 (법인)
    const handleOnClickBtnSubmit1 = async () => {
        if (banknm !== "" && acctno !== "" && bankcd !== "") {
            if (bankcd === "003" && (numList.includes(acctno.slice(0, 3)) || acctno.slice(0, 2) === "02") && (acctno.length === 10 || acctno.length === 11)) {
                // 기업은행
                setAcctnoErr(bankcd);
            } else if (bankcd === "011" && ((acctno.slice(1, 2) === "5" && acctno.length === 13) || (acctno.slice(6, 7) === "5" && acctno.length === 14))) {
                // 농협은행
                setAcctnoErr(bankcd);
            } else if (bankcd === "012" && ((acctno.slice(1, 2) !== "5" && acctno.length === 13) || (acctno.slice(6, 7) !== "5" && acctno.length === 14))) {
                // 지역농축협
                setAcctnoErr(bankcd);
            } else {
                setIsLoading(true);
                setBtnClick(true);
                if(!btnClick) {
                    fetch(`/api/v1/cms/bank`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            bankCd: bankcd, // 은행코드
                            bankAccount: acctno, // 은행 계좌번호 
                            bankIdentification: custId, // 사업자 또는 주민등록번호
                            custnm: custnm
                        })
                    }).then(result => {
                        setBtnClick(false);
                        if (result.status === 401) {
                            dispatch(userLogout.post.request());
                        } else if (result.status === 400) {
                            setOpenModal(true);
                        } else if (result.status === 200) {
                            return result.json();
                        }
                    }).then(res => {
                        if (res) {
                            dispatch(setCms.post.request({ custnm: res?.custnm }));
                            dispatch(getChange.post.request({ 
                                phoneNumber: phoneNumber,
                                isCompany: isCompany,
                                bankcd: bankcd,
                                acctno: acctno,
                                banknm: banknm
                             }))
                            setPushBtn(true);
                            if(!isCompany) {
                                dispatch(getArs.post.request({
                                    telecd: telecd,
                                    phoneno: phoneNumber,
                                    custnm: custnm,
                                    birthday: birthday,
                                    banknm: banknm,
                                    acctno: acctno,
                                    bankcd: bankcd,
                                    BILDT_ETC: BILDT_ETC
                                }));
                            } else {
                                props.history.push("/cms/change/user", { estimate: estimate });
                            }
                            setIsLoading(false);
                        } else {
                            setBtnClick(false);
                            setIsLoading(false);
                            setOpenModal(true);
                        }
                    }).catch(err => {
                        setBtnClick(false);
                        setIsLoading(false);
                        setOpenModal(true);
                    });
                }
            }
        }
    };

    // 항목 동의
    const handleOnChangeCheck = (e) => {
        if (e.target.name === "requiredCheck1") {
            if (e.target.checked) {
                setCheckList1([...checkList1, e.target.id]);
            } else {
                setCheckList1(checkList1.filter((checkedId) => checkedId !== e.target.id));
            }
        }
        reqTermsCheck();
    };

    // 항목 전체 동의
    const handleOnChangeCheckAll = (e) => {
        if (e.target.id === "checkAll1") {
            setCheckList1(e.target.checked ? checkIdList1 : []);
            for (let i = 0; i < allcheck1.length; i++) {
                if (e.target.checked) {
                    allcheck1[i].checked = true;
                } else {
                    allcheck1[i].checked = false;
                }
            }
        }
        reqTermsCheck();
    };

    const reqTermsCheck = (_checkManual) => {
        if (
            document.getElementById("req_check1").checked &&
            document.getElementById("req_check2").checked &&
            document.getElementById("req_check3").checked &&
            acctno !== "" &&
            bankcd !== "" &&
            banknm !== "" &&
            phoneNumber !== ""
        ) {
            setBtndisabled(false);
        } else {
            setBtndisabled(true);
        }
    };

    React.useEffect(() => {
        reqTermsCheck()
    }, [acctno, bankcd, banknm, phoneNumber])
    
    return (
        <>
            {isLoading && <UploadLoading />}
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="CMS 출금이체 신청" descYN="N"/>
                        <div className={styles.termsWrap}>
                            <SubTitle title="자동이체 약관" isSpecial descYn="N"/>
                            <CheckboxAll
                                checkLabel={"checkAll1"}
                                onChange={handleOnChangeCheckAll}
                                checked={checkList1.length === checkIdList1.length}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check1"}
                                name={"requiredCheck1"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"자동이체약관 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={"07"}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check2"}
                                name={"requiredCheck1"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인정보 수집, 이용 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={"08"}
                            />
                            <CheckboxDetail
                                checkLabel={"req_check3"}
                                name={"requiredCheck1"}
                                onChange={handleOnChangeCheck}
                                termsTitle={"개인정보 제3자 제공 동의 (필수)"}
                                termsDescTitle={"롯데오토리스 귀중"}
                                isTerms={true}
                                type={"09"}
                            />
                        </div>

                        {/* <SubTitle title="" isSpecial descYn="Y">
                            <p>■ 출금이체 동의여부 및 해지사실 통지 안내</p>
                            <p>은행 등 금융회사 및 금융결제원은 CMS 제도의 안정적 운영을 위하여 고객의 (은행 등 금융회사 및 이용기관 보유) 연락처 정보를 활용하여 문자메세지, 유선 등으로 고객의 출금이체 동의여부 및 해지사실을 통지할 수 있으니 올바른 연락처 등록여부를 확인하시기 바랍니다. 상기 금융거래정보의 제공 및 개인정보의 수집 및 이용, 제3자 제공에 동의하고 자동이체약관을 준수하며 CMS 출금이체 및 즉시출금으로 처리하는 것에 동의합니다.</p>
                            <p>※ “즉시출금”이란 연체금 및 중도상환 등 고객과 대금정산 필요시 고객요청에 따라 실시간으로 출금하는 방법입니다.</p>
                        </SubTitle> */}

                        <div className={styles.infoBox}>
                            <p>■ 출금이체 동의여부 및 해지사실 통지 안내</p><br />
                            <p>은행 등 금융회사 및 금융결제원은 CMS 제도의 안정적 운영을 위하여 고객의 (은행 등 금융회사 및 이용기관 보유) 연락처 정보를 활용하여 문자메세지, 유선 등으로 고객의 출금이체 동의여부 및 해지사실을 통지할 수 있으니 올바른 연락처 등록여부를 확인하시기 바랍니다. 상기 금융거래정보의 제공 및 개인정보의 수집 및 이용, 제3자 제공에 동의하고 자동이체약관을 준수하며 CMS 출금이체 및 즉시출금으로 처리하는 것에 동의합니다.</p><br />
                            <p>※ “즉시출금”이란 연체금 및 중도상환 등 고객과 대금정산 필요시 고객요청에 따라 실시간으로 출금하는 방법입니다.</p>
                        </div>

                        <div className={styles.contBox}>
                            <div className={styles.tblWrap}>
                                <SubTitle title="결제 정보" isSpecial descYn="N"></SubTitle>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>결제은행</td>
                                        <td>
                                            <Select
                                                options={options2}
                                                styles={selectStyles}
                                                isSearchable={false}
                                                placeholder="결제은행을 선택해주세요."
                                                onChange={(e) => {
                                                    setBankcd(e.value);
                                                    setBanknm(e.label);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>계좌번호</td>
                                        <td>
                                            <input type="text" placeholder="계좌번호를 입력해주세요." value={acctno}
                                            onChange={(e) => {
                                                setAcctno((e.target.value).replace(/[^0-9]/g, ''));
                                            }}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

      
                                <>
                                    <div className={styles.tblWrap}>
                                        <SubTitle title={"자동이체 ARS 인증"} isSpecial descYn="N"/>

                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>통신사</td>
                                                    <td>
                                                        <Select
                                                            name="telecd"
                                                            options={options3}
                                                            styles={selectStyles}
                                                            isSearchable={false}
                                                            placeholder="통신사를 선택해주세요."
                                                            onChange={(e) => {
                                                                setTelecd(e.value)
                                                            }}
                                                            value={telecd !== "" ? options3.filter(o => o.value === telecd) : undefined}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>휴대전화 번호</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            placeholder="휴대폰 번호를 입력해주세요."
                                                            value={phoneNumber ? phoneNumber : "-"}
                                                            readOnly={true}
                                                            onChange={(e) => {
                                                                setPhoneno((e.target.value).replace(/[^0-9]/g, ''));
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <Button
                                        text={"인증 요청"}
                                        disabled={btndisabled}
                                        // state가 false 일 때만 클릭 활성화
                                        onClick={handleOnClickBtnSubmit1}
                                    />
                                </>
                        </div>
                    </div>
                </div>
            </div>
            <Modal01 open={openModal} close={() => setOpenModal(false)} header="계좌 인증 실패">
                계좌 인증에 실패하였습니다.<br/>
                계좌 정보를 확인해주세요.
            </Modal01>
            <Modal01
                open={acctnoErr === "003"}
                close={() => setAcctnoErr("")}
                header="계좌 등록 실패"
                width={{ maxWidth: "300px" }}
            >
                평생계좌번호는 CMS 등록이 불가합니다.<br/>
                예시) 010-xxxx-xxxx, 031-xxx-xxxx
            </Modal01>
            <Modal01
                open={acctnoErr === "011" || acctnoErr === "012"}
                close={() => setAcctnoErr("")}
                header="계좌 등록 실패"
                width={{ maxWidth: "320px" }}
            >
                농협은행의 경우 "농협은행"과 "지역농축협"<br/>
                은행을 구분하여 선택해 주시기 바랍니다.
                <div className={styles.table}>
                    <div className={styles.row}>
                        <div className={styles.cell}>
                            {"계좌번호"}
                            <p>{"(농협은행)"}</p>
                        </div>
                        <div className={styles.cell}>
                            {"계좌번호"}
                            <p>{"(지역농축협)"}</p>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-01-******</div>
                        <div className={styles.cell}>******-51-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-02-******</div>
                        <div className={styles.cell}>******-52-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>***-12-******</div>
                        <div className={styles.cell}>******-56-******</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>301-****-****-**</div>
                        <div className={styles.cell}>351-****-****-**</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>302-****-****-**</div>
                        <div className={styles.cell}>352-****-****-**</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.cell}>312-****-****-**</div>
                        <div className={styles.cell}>356-****-****-**</div>
                    </div>
                </div>
            </Modal01>
        </>
    )
}

export default Apply;