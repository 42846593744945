import React from 'react';
import styles from './styles.scss';


const CheckboxAll = (props) => {
    return (
        <div className={styles.checkboxAllWrap}>
            <div className={styles.box}>
                <label htmlFor={props.checkLabel}>
                    <input type="checkBox" id={props.checkLabel} onChange={props.onChange} checked={props.checked}/>
                    <span className={styles.extend} htmlFor={props.checkLabel}></span> 
                    <span>전체 동의</span>
                </label>
            </div>
        </div>
    )
};

export default CheckboxAll;

