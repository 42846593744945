const checkPhoneNum = phone => {
    if (phone) {
        let phone_num = phone.split("-");
        phone_num = phone_num.join("");
        let phonePtr = /^[0-9]*$/;
        if (phone_num === "" || phone_num === undefined || phonePtr.test(phone_num) === false) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

export default checkPhoneNum;
