import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.scss";

import { getArs, resetMsg } from "module/arsModule";

import Header from "components/Layout/Header";

import MainTitle from "components/Title/MainTitle";
import Button from "components/Button";
import FaqDetail from "components/List/FaqDetail";
import Modal01 from "components/Modal/Modal01";

const ARS1 = (props) => {
    const dispatch = useDispatch();
    const arsCode = useSelector(state => state.ars?.arsCode);
    const statusKey = useSelector(state => state.ars?.statusKey);
    const status = useSelector(state => state.ars?.status);
    const { data } = useSelector(state => state.contract);

    const [able, setAble] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successModal2, setSuccessModal2] = useState(false);
    const [failModal, setFailModal] = useState("");

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        }

        setTimeout(() => {
            setAble(true);
        }, 3000);
    }, []);

    useEffect(() => {
        if (status === "success") {
            if (props.location.state?.url === "apply") { // cms 출금이체 신청에서 ARS 요청 시
                setSuccessModal(true);
            }
            dispatch(resetMsg.post.request());
        } else if (status === "phoneFail") {
            setFailModal("fail");
            dispatch(resetMsg.post.request());
        } else if (status === "phoneSend") {
            setFailModal("send");
            dispatch(resetMsg.post.request());
        }
    }, [status]);

    //ARS 인증 재시도 버튼 클릭 시
    const reCallArs = () => {
        dispatch(getArs.post.request({
            telecd: props.location?.state?.telecd,
            phoneno: props.location?.state?.phoneno,
            custnm: props.location?.state?.custnm,
            birthday: props.location?.state?.birthday,
            bildt: props.location?.state?.bildt,
            bankcd: props.location?.state?.bankcd,
            banknm: props.location?.state?.banknm,
            acctno: props.location?.state?.acctno
        }));
    };

    const handleOnClickBtn = () => {
        if (statusKey !== "") {
            dispatch(getArs.get.request(statusKey));
        }
    };

    const handleSuccessModal = () => {
        if (props.location?.state?.url === "apply") {
            props.history.push("/signature/signStep1");
            setSuccessModal(false);
        } else if (props.location.state?.url === "corpMember") {
            props.history.push("/complete");
            setSuccessModal2(false);
        }
    };

    const handleFailModal = () => {
        setFailModal("");
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle
                            mainTitle={props.location?.state?.url === "apply" ? "결제/송금 계좌 등록" : "기업회원 본인인증"}
                            descYN="N"
                        />
                        <div className={styles.whiteBox}>
                            <p className={styles.guide}>
                                <span>ARS 인증 전화 받기 </span>후 <br/>
                                안내에 따라 아래 숫자를 입력해주세요.
                            </p>
                            <p className={styles.number}>
                                {arsCode}
                            </p>
                            <div className={styles.btnWrap}>
                                <Button
                                    style={{backgroundColor: "#fff", border: "1px solid #DA291C", color: "#DA291C"}}
                                    text={"ARS 인증 재시도"}
                                    onClick={reCallArs}
                                />
                                <div
                                    className={[styles.checkBtn, able && statusKey !== "" ? styles.able : undefined].join(" ")}
                                    onClick={handleOnClickBtn}
                                >
                                    <span>{"ARS 인증 완료"}</span>
                                </div>
                            </div>
                        </div>

                        <FaqDetail
                            faq="ARS 인증 전화가 오지 않나요?"
                            faqDesc="일시적으로 통신사 사정 및 통화불능의 경우 전화가 오지 않을 수 있습니다. 전화가 오지 않는 경우 ARS 인증 재시도 버튼을 눌러 주시기 바랍니다."
                            styles={{ height: "40px" }}
                        />
                        <FaqDetail
                            faq="ARS 인증 완료가 되지 않나요?"
                            faqDesc="ARS 음성 안내 시 '삐' 소리를 듣고 화면의 인증번호를 입력하시고, ARS 통화가 끊어지면 잠시 후 ARS 인증 완료 버튼을 눌러 주시기 바랍니다."
                            styles={{ height: "40px" }}
                        />
                    </div>
                </div>
            </div>
            <Modal01 open={successModal} close={handleSuccessModal} header="인증 완료">
                ARS 인증이 완료되었습니다.<br/>
                다음 페이지로 이동합니다.
            </Modal01>
            <Modal01 open={successModal2} close={handleSuccessModal} header="본인인증 완료">
                본인 인증이 완료되었습니다.<br/>
                계약 신청 완료 페이지로 이동합니다.
            </Modal01>
            <Modal01 open={failModal === "fail"} close={handleFailModal} header="인증 실패">
                ARS 인증에 실패하였습니다.<br/>
                다시 시도해주세요.
            </Modal01>
            <Modal01 open={failModal === "send"} close={handleFailModal} header="인증 진행중">
                ARS 인증 진행중입니다.<br/>
                잠시 후 다시 시도해주세요.
            </Modal01>
        </>
    )
}

export default ARS1;