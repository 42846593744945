import { put, takeEvery } from "redux-saga/effects";
import { CHANGE } from "module/changeCmsModule";

function* ChangeCmsSaga() {
    yield takeEvery(CHANGE.POST.REQUEST, setChangeCMSInfo);
}

function* setChangeCMSInfo(action) {
    try {
        yield put({ type: CHANGE.POST.SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: CHANGE.POST.FAIL });
    }
}

export default ChangeCmsSaga;
