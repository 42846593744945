import React from "react";
import styles from "./uploadStyles.scss";

import { history } from "module/configureStore";

import ConfirmPopup from "components/ConfirmPopup";

import Lottie from "react-lottie";
import loading from "lottie/new_loading.json";

const UploadLoading = (props, context) => {
    const [setting, setSetting] = React.useState(undefined);
    const [errPopup, setErrPopup] = React.useState(false);

    React.useEffect(() => {
        let time = 1000 * 60;
        if (props.is_mass_down) {
            time = 1000 * 60 * 10;
        }
        setSetting(
            setTimeout(() => {
                setErrPopup(true);
            }, time)
        );
        return function cleanup() {
            clearTimeout(setting);
            setSetting(undefined);
        };
    }, []);

    const handleErr = () => {
        setSetting(undefined);
        if (setting) {
            clearTimeout(setting);
        }
        history.goBack();
    };

    return errPopup ? (
        <ConfirmPopup
            title={("요청시간 초과")}
            message={[("요청시간이 초과되었습니다."), ("뒤로 돌아가서 다시 시도해주세요.")]}
            noCancel={true}
            cancel={handleErr}
            success={handleErr}
            successText={("확인")}
        />
    ) : (
        <div className={[styles.wrap, props.signProcess ? styles.sign : null].join(" ")}>
            <div className={styles.img}>
                {/* <img src="/static/media/glosign_loading.gif" alt="loading" /> */}
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loading
                    }}
                    isStopped={false}
                    isPaused={false}
                />
                {props.signProcess && (
                    <span>
                        <p>{("최대 3분까지 시간이 소요될 수 있습니다.")}</p>
                        <p>{("잠시만 기다려주세요.")}</p>
                    </span>
                )}
            </div>
        </div>
    );
};

export default UploadLoading;
