import React from 'react';
import styles from './styles.scss';

const MainTitle = (props) => {

    return(
        <div className={styles.topInfo} style={props.style}>
            <h1>{props.mainTitle}</h1>
            {props.descYN !== 'N' ? (
                <div className={styles.desc} style={props.substyle}>
                    {props.children}
                </div>
            ) : null }
        </div>
    );
}

export default MainTitle;