import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import styles from "./styles.scss";
import mainTitleStyles from "components/Title/MainTitle/styles.scss";

import Header from "components/Layout/Header";
import * as SVG from "components/SVG";

import MainTitle from "components/Title/MainTitle";
import SubTitle from "components/Title/SubTitle";
import Button from "components/Button";
import CheckboxAll from "components/List/CheckboxAll";
import CheckboxDetail from "components/List/CheckboxDetail";
import RadioboxDetail from "components/List/RadioboxDetail";
import Modal01 from "components/Modal/Modal01";
import Document from "components/Document";
import ButtonBar from "components/ButtonBar"

import { userIsAll,setCDD,getNewCdd } from "module/userModule";


const AcceptTerms = (props) => {
    const dispatch = useDispatch();
    const EGUBUN = useSelector(state => state.contract.EGUBUN);
    const isSeames = useSelector(state => state.contract.isSeames);
    const personal = useSelector(state => state.contract.isPersonal);
    const estimate = useSelector(state => state.contract.estimate);

    const { data } = useSelector(state => state.contract);
    const coreManualDocsList = useSelector(state => state.contract.coreManualDocsList);
    const [btndisabled, setBtndisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const E_SUBMICD = useSelector(state => state.contract.E_SUBMICD);

    const [checkList1, setCheckList1] = useState([]);
    const [checkIdList1, setCheckIdList1] = useState([]);
    const allcheck1 = document.getElementsByName("requiredCheck1");

    const [checkList2, setCheckList2] = useState([]);
    const [checkIdList2, setCheckIdList2] = useState([]);
    const allcheck2 = document.getElementsByName("requiredCheck2");

    const [checkList3, setCheckList3] = useState([]);
    const [checkIdList3, setCheckIdList3] = useState([]);
    const allcheck3 = document.getElementsByName("requiredCheck3");

    const [value, setValue] = useState(false);
    const [openPDF, setOpenPDF] = useState(-1);
    
    const [product, setProduct] = useState("");
    const [type, setType] = useState("");

    const [checkManual, setCheckManual] = useState([]);

    useEffect(() => {
        window.addEventListener("beforeunload", function(e) {
            e.preventDefault();
            e.returnValue = "변경사항은 저장되지 않습니다";
        });
        if (!data) {
            props.history.push("/auth/contractNum");
        };

        if (personal) document.getElementById("requestN").checked = true;

        let checkboxIdList1 = [];
        document.getElementsByName("requiredCheck1").forEach(function (item, i) {
            checkboxIdList1[i] = item.getAttribute("id");
        });

        setCheckIdList1(checkboxIdList1);

        let checkboxIdList2 = [];
        document.getElementsByName("requiredCheck2").forEach(function (item, i) {
            checkboxIdList2[i] = item.getAttribute("id");
        });

        setCheckIdList2(checkboxIdList2);

        let checkboxIdList3 = [];
        document.getElementsByName("requiredCheck3").forEach(function (item, i) {
            checkboxIdList3[i] = item.getAttribute("id");
        });

        setCheckIdList3(checkboxIdList3);
    }, []);

    useEffect(() => {
        if (EGUBUN !== "" && product === "") {
            async function setData() {
                if (EGUBUN === "LF" || EGUBUN === "LM") {
                    await setProduct("자동차 리스 약관 (필수)");
                    await setType("02");
                } else if (EGUBUN === "HC") {
                    await setProduct("개인신용대출 표준약관 (필수)");
                    await setType("03");
                } else if (EGUBUN === "HA") {
                    await setProduct("오토론 대출약관 (필수)");
                    await setType("05");
                } else if (EGUBUN === "HH") {
                    await setProduct("자동차할부금융 표준약관 (필수)");
                    await setType("04");
                }
            }
            setData();
        }
    }, [EGUBUN]);

    const handleOnClickBtnSubmit = async () => {
        if (btndisabled) {
            setModalOpen(true);
        } else {
            await dispatch(userIsAll.post.request({
                isAll: value,
                // AGREE01: personal ? document.getElementById("C_AGREE01").checked && document.getElementById("C_AGREE02").checked : false,
                // AGREE02: personal ? document.getElementById("C_AGREE01").checked && document.getElementById("C_AGREE02").checked : false,
                AGREE01: value,
                AGREE02: value,
                C_AGREE01: personal ? document.getElementById("C_AGREE01").checked : false,
                C_AGREE02: personal ? document.getElementById("C_AGREE02").checked : false,
                H_OWN: personal && EGUBUN === "HC" ? document.getElementById("H_OWN")?.checked : document.getElementById("req_check5").checked,
            }));
            if (personal) {
                props.history.push("/cms/apply");
            } else {
                // props.history.push("/cdd");

                const payload = {
                    I_ETMTSEQ: estimate,
                  };
                dispatch(getNewCdd.post.request(payload))
                props.history.push("/cms/apply");
                
            }
        }
    };

    const handleOnChangeCheck = (e) => {
        if (e.target.name === "requiredCheck1") {
            if (e.target.checked) {
                setCheckList1([...checkList1, e.target.id]);
            } else {
                setCheckList1(checkList1.filter((checkedId) => checkedId !== e.target.id));
            }
        } else if (e.target.name === "requiredCheck2") {
            if (e.target.checked) {
                setCheckList2([...checkList2, e.target.id]);
            } else {
                setCheckList2(checkList2.filter((checkedId) => checkedId !== e.target.id));
            }
        } else if (e.target.name === "requiredCheck3") {
            if (e.target.checked) {
                setCheckList3([...checkList3, e.target.id]);
            } else {
                setCheckList3(checkList3.filter((checkedId) => checkedId !== e.target.id));
            }
        }
        reqTermsCheck();
    };
    const handleOnChangeCheckAll = (e) => {
        if (e.target.id === "checkAll1") {
            setCheckList1(e.target.checked ? checkIdList1 : []);
            for (let i = 0; i < allcheck1.length; i++) {
                if (e.target.checked) {
                    allcheck1[i].checked = true;
                } else {
                    allcheck1[i].checked = false;
                }
            }
        } else if (e.target.id === "checkAll2") {
            setCheckList2(e.target.checked ? checkIdList2 : []);
            for (let i = 0; i < allcheck2.length; i++) {
                if (e.target.checked) {
                    allcheck2[i].checked = true;
                } else {
                    allcheck2[i].checked = false;
                }
            }
        } else if (e.target.id === "checkAll3") {
            setCheckList3(e.target.checked ? checkIdList3 : []);
            for (let i = 0; i < allcheck3.length; i++) {
                if (e.target.checked) {
                    allcheck3[i].checked = true;
                } else {
                    allcheck3[i].checked = false;
                }
            }
        }
        reqTermsCheck();
    };

    const reqTermsCheck = (_checkManual) => {

        if (
            document.getElementById("req_check1").checked &&
            document.getElementById("req_check2").checked &&
            document.getElementById("req_check5").checked &&
            (!personal ||
                (document.getElementById("req_check6").checked &&
                    document.getElementById("req_check7").checked &&
                    document.getElementById("req_check8").checked )) &&
            (!personal || EGUBUN !== "HC" || (document.getElementById("H_OWN") && document.getElementById("H_OWN").checked)) &&
            (E_SUBMICD !== "B" ||
                (document.getElementById("req_check9") &&
                    document.getElementById("req_check9").checked &&
                    document.getElementById("CMS_AGREE01") &&
                    document.getElementById("CMS_AGREE01").checked &&
                    document.getElementById("CMS_AGREE02") &&
                    document.getElementById("CMS_AGREE02").checked)) &&
            (_checkManual ? _checkManual.length === coreManualDocsList.length : checkManual.length === coreManualDocsList.length) &&
            (EGUBUN !== "HA" || 
                (data && data?.length > 0 && data?.filter(x => x?.id === "Y_NEW_N" && x?.value)?.length < 1) ||
                (document.getElementById("req_check11") && document.getElementById("req_check11")?.checked))
        ) {
            if(personal && isSeames === true) {
                document.getElementById("req_check20").checked ? setBtndisabled(false) : setBtndisabled(true)
            } else {
                setBtndisabled(false);
            }
        } else {
            setBtndisabled(true);
        }
    };

    const handleChange = v => {
        setValue(v === "Y");
    };

    const handleCheckManual = id => {
        let _checkManual = _.cloneDeep(checkManual);
        if (!_checkManual.includes(id)) {
            _checkManual.push(id);
        }
        setCheckManual(_checkManual);
        reqTermsCheck(_checkManual);
    };

    return (
        <>
            <Header onClick={() => props.history.push("/")}/>
            <div className={styles.contentBoardWrap}>
                <div className={styles.contentBoard}>
                    <div className={styles.innerWrap}>
                        <MainTitle mainTitle="약관 동의">
                            <p className={mainTitleStyles.brNone}>
                                전체 동의는 필수 및 선택 정보에 대한 동의도 포함되어 있으며, 개별적으로 동의를 선택하실 수 있습니다.<br/>
                                선택 항목에 대한 동의는 거부하시는 경우에도 서비스는 이용이 가능합니다.
                            </p>
                        </MainTitle>

                        <div className={styles.contBox}>
                            <div className={styles.termsWrap}>
                                <SubTitle title="상품이용약관" isSpecial descYn="N"/>
                                <CheckboxAll
                                    checkLabel={"checkAll1"}
                                    onChange={handleOnChangeCheckAll}
                                    checked={checkList1.length === checkIdList1.length}
                                />
                                <CheckboxDetail
                                    checkLabel={"req_check1"}
                                    name={"requiredCheck1"}
                                    onChange={handleOnChangeCheck}
                                    termsTitle={"여신거래 기본약관 (필수)"}
                                    termsDescTitle={"롯데오토리스 귀중"}
                                    isTerms={true}
                                    type={"01"}
                                />
                                <CheckboxDetail
                                    checkLabel={"req_check2"}
                                    name={"requiredCheck1"}
                                    onChange={handleOnChangeCheck}
                                    termsTitle={product}
                                    termsDescTitle={"롯데오토리스 귀중"}
                                    isTerms={true}
                                    type={type}
                                />
                                {EGUBUN === "HA" && data && data?.length > 0 && data?.filter(x => x?.id === "Y_NEW_N" && x?.value)?.length > 0 && (
                                    <CheckboxDetail
                                        checkLabel={"req_check11"}
                                        name={"requiredCheck1"}
                                        onChange={handleOnChangeCheck}
                                        termsTitle={"중고자동차 대출 표준약관 (필수)"}
                                        termsDescTitle={"롯데오토리스 귀중"}
                                        isTerms={true}
                                        type={"06"}
                                    />
                                )}
                            </div>

                            {E_SUBMICD === "B" && (
                                <div className={styles.termsWrap}>
                                    <SubTitle title="자동이체 약관" isSpecial descYn="N"/>
                                    <CheckboxAll
                                        checkLabel={"checkAll2"}
                                        onChange={handleOnChangeCheckAll}
                                        checked={checkList2.length === checkIdList2.length}
                                    />
                                    <CheckboxDetail
                                        checkLabel={"req_check9"}
                                        name={"requiredCheck2"}
                                        onChange={handleOnChangeCheck}
                                        termsTitle={"자동이체약관 동의 (필수)"}
                                        termsDescTitle={"롯데오토리스 귀중"}
                                        isTerms={true}
                                        type={"07"}
                                    />
                                    <CheckboxDetail
                                        checkLabel={"CMS_AGREE01"}
                                        name={"requiredCheck2"}
                                        onChange={handleOnChangeCheck}
                                        termsTitle={"개인정보 수집, 이용 동의 (필수)"}
                                        termsDescTitle={"롯데오토리스 귀중"}
                                        isTerms={true}
                                        type={"08"}
                                    />
                                    <CheckboxDetail
                                        checkLabel={"CMS_AGREE02"}
                                        name={"requiredCheck2"}
                                        onChange={handleOnChangeCheck}
                                        termsTitle={"개인정보 제3자 제공 동의 (필수)"}
                                        termsDescTitle={"롯데오토리스 귀중"}
                                        isTerms={true}
                                        type={"09"}
                                    />
                                </div>
                            )}

                            <div className={styles.termsWrap}>
                                <SubTitle title="실제 소유자 확인" isSpecial descYn="N"/>
                                <CheckboxDetail
                                    checkLabel={"req_check5"}
                                    summaryOnly
                                    styles={{borderTop: "1px solid #cccccc"}}
                                    summaryStyles={{color: "#333333"}}
                                    termsTitle={"계약자 본인이 실제 소유자임을 확인합니다."}
                                    checkDesc={" ※ 계약자와 실제 소유자가 다른 경우 금융거래가 제한됩니다."}
                                    onChange={handleOnChangeCheck}
                                />
                            </div>

                            {personal && (
                                <div className={styles.termsWrap}>
                                    <SubTitle title="개인(신용)정보 동의" isSpecial descYn="Y">
                                        본 동의서는 요약동의서이며, 요청하면 전체 동의서를 안내 받으실 수 있습니다. 요청하시겠습니까?
                                    </SubTitle>
                                    <RadioboxDetail name={"requestYn"}
                                        id1={"requestY"} value1={"Y"} lblNm1={"예"}
                                        id2={"requestN"} value2={"N"} lblNm2={"아니오"}
                                        onChange={handleChange}
                                    />
                                        <CheckboxAll
                                            checkLabel={"checkAll3"}
                                            onChange={handleOnChangeCheckAll}
                                            checked={checkList3.length === checkIdList3.length}
                                        />
                                        <CheckboxDetail
                                            checkLabel={"req_check6"}
                                            name={"requiredCheck3"}
                                            onChange={handleOnChangeCheck}
                                            termsTitle={"개인(신용)정보 필수적 수집, 이용 동의 (필수)"}
                                            termsDescTitle={"롯데오토리스 귀중"}
                                            isTerms={true}
                                            type={value ? "15" : "10"}
                                            value={value}
                                        />
                                        <CheckboxDetail
                                            checkLabel={"req_check7"}
                                            name={"requiredCheck3"}
                                            onChange={handleOnChangeCheck}
                                            termsTitle={"개인(신용)정보 필수적 조회 동의 (필수)"}
                                            termsDescTitle={"롯데오토리스 귀중"}
                                            isTerms={true}
                                            type={value ? "16" : "11"}
                                            value={value}
                                        />
                                        <CheckboxDetail
                                            checkLabel={"req_check8"}
                                            name={"requiredCheck3"}
                                            onChange={handleOnChangeCheck}
                                            termsTitle={"개인(신용)정보 필수적 제공 동의 (필수)"}
                                            termsDescTitle={"롯데오토리스 귀중"}
                                            isTerms={true}
                                            type={value ? "17" : "12"}
                                            value={value}
                                        />
                                        {
                                            isSeames === true && (
                                                <CheckboxDetail
                                                    checkLabel={"req_check20"}
                                                    name={"requiredCheck3"}
                                                    onChange={handleOnChangeCheck}
                                                    termsTitle={"개인정보 수집 이용 제공 동의서 (e-모빌리티) (필수)"}
                                                    termsDescTitle={"롯데오토리스 귀중"}
                                                    isTerms={true}
                                                    type={"22"}
                                                    isSeames={isSeames === true ? true : false}
                                                />
                                            )
                                        }
                                        <CheckboxDetail
                                            checkLabel={"C_AGREE01"}
                                            name={"requiredCheck3"}
                                            onChange={handleOnChangeCheck}
                                            termsTitle={"개인(신용)정보 선택적 수집, 이용 동의 (선택)"}
                                            summaryStyles={{padding: "14px 0 14px 8px"}}
                                            satisfactionFir
                                            termsDescTitle={"롯데오토리스 귀중"}
                                            isTerms={true}
                                            type={value ? "18" : "13"}
                                            value={value}
                                            isSeames={true}
                                        />
                                        <CheckboxDetail
                                            checkLabel={"C_AGREE02"}
                                            name={"requiredCheck3"}
                                            onChange={handleOnChangeCheck}
                                            termsTitle={"개인(신용)정보 선택적 제3자 제공 동의 (선택)"}
                                            summaryStyles={{padding: "14px 0 14px 8px"}}
                                            satisfactionSec
                                            termsDescTitle={"롯데오토리스 귀중"}
                                            isTerms={true}
                                            type={value ? "19" : "14"}
                                            value={value}
                                            isSeames={true}
                                        />
                                        {EGUBUN === "HC" && (
                                            <CheckboxDetail
                                                checkLabel={"H_OWN"}
                                                name={"requiredCheck3"}
                                                onChange={handleOnChangeCheck}
                                                termsTitle={"개인정보동의서(주택소유여부 확인) (필수)"}
                                                termsDescTitle={"롯데오토리스 귀중"}
                                                isTerms={true}
                                                type={"20"}
                                            />
                                        )}
                                </div>
                            )}

                            {/* {!personal && (
                                <div className={styles.termsWrap}>
                                    <SubTitle title="e-모빌리티 특약" isSpecial descYn="Y"></SubTitle>
                                        {
                                            isSeames === true && (
                                                <CheckboxDetail
                                                    checkLabel={"req_check20"}
                                                    name={"requiredCheck3"}
                                                    onChange={handleOnChangeCheck}
                                                    termsTitle={"개인정보 수집 이용 제공 동의서 (e-모빌리티) (필수)"}
                                                    termsDescTitle={"롯데오토리스 귀중"}
                                                    isTerms={true}
                                                    type={"22"}
                                                    isSeames={isSeames === true ? true : false}
                                                />
                                            )
                                        }
                                </div>
                            )} */}

                            <div className={styles.termsWrap}>
                                <SubTitle title="부속 서류 확인" isSpecial descYn="N" styles={{ borderBottom: "1px solid #cccccc" }} />
                                {coreManualDocsList?.map(docs => {
                                    return (
                                        <div
                                            className={styles.row}
                                            onClick={() => {
                                                setOpenPDF(docs?.id);
                                                handleCheckManual(docs?.id);
                                            }}
                                        >   
                                            <div className={styles.left}>
                                                <SVG.Check checked={docs?.id && checkManual.includes(docs?.id)} />
                                                <div className={styles.title}>
                                                    {docs?.name}
                                                </div>
                                            </div>
                                            <div className={styles.svg}>
                                                보기
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <Button text={"확인"} style={{ width: "100%" }} disabled={btndisabled}
                                    onClick={handleOnClickBtnSubmit}/>
                        </div>
                    </div>
                </div>
            </div>
            {openPDF > -1 && (
                <div className={styles.pdfPopup}>
                    <Document sign={false} type={"keyPoint"} document={openPDF} />
                    <ButtonBar
                        isActive={true}
                        leftBtnTxt={"닫기"}
                        leftBtnFunc={() => setOpenPDF(-1)}
                        rightBtnTxt={"확인"}
                        rightBtnFunc={() => setOpenPDF(-1)}
                    />
                </div>
            )}
            <Modal01 open={modalOpen} close={() => setModalOpen(false)} header="안내">
                다음 단계로 넘어가기 위해서는<br/>
                필수 약관에 동의가 필요합니다.
            </Modal01>
        </>
    )

}


export default AcceptTerms;

