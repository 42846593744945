
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from './styles.scss';
import * as _ from "lodash";

import { getTerms } from "module/contractModule";
import Loading from "components/Loading";

const CheckboxDetail = (props) => {
    const dispatch = useDispatch();
    const terms = useSelector(state => state.contract.terms);
    const token = useSelector(state => state.pass.accessToken);
    const errMsg = useSelector(state => state.user.errMsg);
    
    const [isOpen, setSummary] = useState(false);
    const [term, setTerm] = useState({});
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (props.isTerms) {
            setType(props.type);
        }
    }, []);

    useEffect(() => {
        if (!_.isEqual(terms, term)) {
            setTerm(terms);
            setLoading(false);
        }
    }, [terms]);

    useEffect(() => {
        if (!_.isEqual(value, props.value)) {
            setSummary(false);
            setType(props.type);
            setValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (errMsg === "TIME OVER") {
            setLoading(false);
        }
    }, [errMsg]);

    const handleOnClick = () => {
        if (props.isTerms) {
            if (isOpen) {
                setSummary(!isOpen);
            } else {
                if (!term[props.type]) {
                    dispatch(getTerms.get.request({ type: props.type, token: token }));
                    setLoading(true);
                    setSummary(!isOpen);
                } else {
                    setSummary(!isOpen);
                }
            }
        } else {
            setSummary(!isOpen);
        }
    };

    return (
        <div className={styles.checkboxDetailWrap} style={props.styles}>
            <div className={isOpen ? [styles.details, styles.active].join(' ') : styles.details}>
                <div className={styles.summary} id="summary" onClick={handleOnClick} style={props.summaryStyles}>
                    <div className={styles.flexWrap} style={props.checkLabel === "req_check5" ? { display: "block" } : undefined}>
                        <label htmlFor={props.checkLabel} className={props?.isSeames === true ? styles.seamesLabel : styles.basicLabel}>
                            <div className={styles.checkWrap}>
                                <input
                                    type="checkBox"
                                    id={props.checkLabel}
                                    name={props.name}
                                    onChange={props.onChange}
                                />
                                <span className={styles.extend}></span>
                            </div>
                            <div>
                                <span className={styles.termsTitle}>{props.termsTitle}</span>
                                {props.satisfactionFir ? (
                                    <img
                                        className={styles.satisfaction}
                                        src="/static/media/satisfaction_01.png"
                                        alt="satsfaction"
                                    />
                                ) : props.satisfactionSec ? (
                                    <img
                                        className={styles.satisfaction}
                                        src="/static/media/satisfaction_02.png"
                                        alt="satsfaction"
                                    />
                                ) : undefined}
                            </div>
                        </label>
                        {props.satisfactionFir ? (
                            <img
                                className={styles.satisfaction}
                                src="/static/media/satisfaction_01.png"
                                alt="satsfaction"
                            />
                        ) : props.satisfactionSec ? (
                            <img
                                className={styles.satisfaction}
                                src="/static/media/satisfaction_02.png"
                                alt="satsfaction"
                            />
                        ) : undefined}
                        {props.summaryOnly ? undefined : (
                            <img
                                className={styles.arrow}
                                src="/static/media/ic-arrow-right.svg"
                                alt="arrow-down"
                            />
                        )}
                        {props.summaryOnly ? undefined : (
                            <div className={styles.contents}>
                                <div className={styles.termsDescTitle}>
                                    [{props.termsDescTitle}]
                                </div>
                                {loading && <Loading />}
                                <textarea className={styles.contentsIn} value={props.isTerms ? term[props.type] : props.termsDesc} readOnly />
                            </div>
                        )}
                        {props.checkDesc ? (
                            <div className={styles.checkDesc}>
                                <div>{props.checkDesc}</div>
                            </div>
                        ): undefined}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckboxDetail;
